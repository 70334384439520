import { useCreateProductContext } from "../../contexts/CreateProductContext";
import PricingLogo from "./Logo/PricingLogo";
import { useEffect, useState } from "react";
import { get_pricing_result } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";
import Loader from "../Loader/Loader";

export default function PricingResult() {
  const { logoArray, productId, selectedCompetitors } = useCreateProductContext();
  const { userData } = useCommonContext();

  const [productPricing, setProductPricing] = useState([]);
  const [competitorsPricing, setCompetitorsPricing] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const payloadFirst = {
        productId: productId,
        userId: userData[0]["User_Id"]
      };
      setIsLoading(true);
      const responseData = await get_pricing_result(payloadFirst);
      setIsLoading(false);

      // Get only the first 4 product plans
      const productPlans = responseData.data.data.recommendation_pricing.product_pricing.slice(0, 4);
      setProductPricing(productPlans);

      // Get competitor pricing data
      const competitorPricing = responseData.data.data.recommendation_pricing.competitors_pricing;
      setCompetitorsPricing(competitorPricing);
    } catch (error) {
      console.error("Error fetching product feature pricing:", error);
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      {isLoading ? (
        <Loader /> // Loader will be centered
      ) : (
        <table className="table table-borderless">
          <tbody className="font-medium">
            <PricingLogo logoArray={logoArray} />

            {/* Render Product Pricing */}
            {productPricing.map((product, index) => (
              <tr key={index}>
                <td className="compare-header-color table-feature-wid">Pricing Plan {index + 1}</td>
                <td>
                  {product.Pricing_Cost && product.Pricing_Unit ? (
                    <span className="d-block">
                      $ <span className="font-20 font-bold">{product.Pricing_Cost}</span> / {product.Pricing_Unit}
                    </span>
                  ) : (
                    <span className="compare-header-color">NA</span>
                  )}
                </td>
                {/* Render competitor pricing */}
                {selectedCompetitors.map((competitorId, idx) => {
                  const competitorData = competitorsPricing[idx];
                  if (competitorData && competitorData[index]) {
                    return (
                      <td key={idx}>
                        {`${competitorData[index].Pricing_Cost} / ${competitorData[index].Pricing_Unit}`}
                      </td>
                    );
                  } else {
                    return (
                      <td key={idx}>
                        <span className="compare-header-color">NA</span>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
