import { useHubContext } from "../../contexts/HubContext";

export default function ImpressionCard() {
  const { metrics, setMetrics } = useHubContext();

  return (
    <>
      <div className="col-md-3 mb-3">
        <div className="bg-white p-3 radius-16 h-100">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <img src="images/impression-icon.svg" alt="pending-req-icon" />
            <span
              data-bs-toggle="modal"
              data-bs-target="#impressionPop"
              className="cursor-pointer"
            >
              <img src="images/link-arrow-icon.svg" alt="link-arrow-icon" />
            </span>
          </div>
          <span className="d-block font-14 font-medium grey-v1 color-grey">
            Total Post Impression
          </span>
          <span className="d-block font-20 font-bold">
            {(metrics?.facebook_impression +
              metrics?.instagram_impression +
              metrics?.twitter_impression) ? metrics?.facebook_impression +
              metrics?.instagram_impression +
              metrics?.twitter_impression : 0}
          </span>
        </div>
      </div>
      <div
        className="modal fade"
        id="impressionPop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <h5
                className="font-18 font-bold popup-header-color mb-0"
                id="AddmanuallyLabel"
              >
                Impression by platform
              </h5>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body border-0">
              <div className="d-flex align-items-center justify-content-between border-bottom py-3">
                <div className="font-16 font-medium color-grey align-items-center d-flex gap-2 ">
                  <img
                    src="images/facebook-post-icon.svg"
                    alt="facebook-post-icon"
                  />{" "}
                  Facebook
                </div>
                <span className="font-bold font-18">
                  {metrics?.facebook_impression}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between border-bottom py-3">
                <div className="font-16 font-medium color-grey align-items-center d-flex gap-2 ">
                  <img
                    src="images/insta-caption-icon.svg"
                    alt="facebook-post-icon"
                  />{" "}
                  Instagram
                </div>
                <span className="font-bold font-18">
                  {metrics?.instagram_impression}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between py-3">
                <div className="font-16 font-medium color-grey align-items-center d-flex gap-2 ">
                  <img
                    src="images/twitter-post-icon.svg"
                    alt="facebook-post-icon"
                  />{" "}
                  Twitter
                </div>
                <span className="font-bold font-18">
                  {metrics?.twitter_impression}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
