import React, { useEffect, useState } from "react";
import { deleteFeatureApi } from "../../services/api";
import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { useCommonContext } from "../../contexts/CommonContext";


export default function CreateFeatureAccordian() {
    const { getAllfeatures, featureData, setFeatureName, setFeatureDescription, setFeatureId, productId } = useCreateProductContext();
    const { userData } = useCommonContext()
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);


    useEffect(() => {
        if (productId) {
            getAllfeatures();
        }
        else {
            console.log('newuser')
        }
    }, []);


    const editFeature = async (FeatureId, FeatureName, FeatureDescription) => {
        try {
            setFeatureName(FeatureName)
            setFeatureDescription(FeatureDescription)
            setFeatureId(FeatureId)

        } catch (error) {
            console.error(error);
        }
    }


    const deleteFeature = async (id) => {
        try {
            // console.log("Delete feature called")
            const payload = {
                userId: userData[0]["User_Id"],
                featureId: id
            };
            // console.log(payload);
            const responseData = await deleteFeatureApi(payload);
            // console.log(responseData.data.success);
            if (responseData.data.success === true) {
                getAllfeatures()
            } else {
                console.log('Error occur while Deleting Data from API');
            }
        } catch (error) {
            console.error(error);
        }
    }

    const toggleAccordion = (index) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="col-md-12 mb-4">
            {/* Advanced Analytics acc starts */}
            {featureData?.map((values, index) => {
                return (
                    <div key={index} className="border-bottom mt-5">
                        <div className="d-flex justify-content-between align-items-center my-2" onClick={() => toggleAccordion(index)}>
                            <p className={`font-14 font-medium cursor-pointer mb-0 ${openAccordionIndex === index ? '' : 'collapsed'}`} data-bs-toggle="collapse" data-bs-target={`#collapse-${index}`}>
                                <img src="images/table-accordion-arrow.svg" alt="form-acc-arrow" className={`me-2 grid-arrow ${openAccordionIndex === index ? 'open' : ''}`} />
                                {values.Feature_Name}
                            </p>
                            <span className="d-flex align-items-center justify-content-center gap-2 ">
                                <button className="edit-btn"><img src="images/table-edit.svg" alt="table-edit"
                                    onClick={() => editFeature(values.Feature_Id, values.Feature_Name, values.Feature_Description)}
                                /></button>
                                <span className="border-sape" />
                                <button className="delete-btn"><img src="images/table-delete.svg" alt="table-delete"
                                    onClick={() => deleteFeature(values.Feature_Id)}
                                /></button>
                            </span>
                        </div>
                        <div className={`collapse ${openAccordionIndex === index ? 'show' : ''}`} id={`collapse${index}`}>
                            <p className="ms-4 ps-1 font-14 font-regular cust-label">
                                {values.Feature_Description}
                            </p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}