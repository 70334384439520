import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  fetchNotificationApi,
  getDropDownDetailsApi,
} from "../../services/api.js";
import { useCommonContext } from "../../contexts/CommonContext.jsx";
import Navigation from "../navigation/Navigation.jsx";


export default function Notifications() {
  const { userData,isNavCollapsed } = useCommonContext();
  const navigate = useNavigate();
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [dropDownDetails, setDropDownDetails] = useState([]);
  const [contentType, setContentType] = useState("select");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterOptions, setFilterOptions] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(10);
  const [loadMore, setLoadMore] = useState();

  useEffect(() => {
    fetchNotification(contentType, fromDate, toDate, count);
  }, []);

  const fetchNotification = async (contentType, fromDate, toDate, count) => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const data = {
        user_id: userId,
        content_type: contentType == "select" ? "" : contentType,
        from_date: fromDate,
        to_date: toDate,
        count: count,
      };
      //   from_date,to_date,content_type

      const responseData = await fetchNotificationApi(data);
      //   console.log(responseData.data.data.rowCount);
      if (responseData.status === 200) {
        setNotificationDetails(responseData.data.data.data);
        setLoadMore(responseData.data.data.rowCount);
        const DropDowndata = {
          userId: userData[0]["User_Id"],
          tableName: "Content_Types",
        };
        const dropDownResponse = await getDropDownDetailsApi(DropDowndata);
        if (responseData.status === 200) {
          setDropDownDetails(dropDownResponse.data.data.data);
          console.log(dropDownDetails[0].Content_Type, "===================");
        }
        setIsLoading(false);
      }
    } catch (error) {
      //   await insertErrorApi(error);
    }
  };


  const reviewReport = async (contentId) => {
    // debugger;
    console.info(contentId.Content_Id, "oqoqoqoqoqoqoqoqoqoqo");
    const Role = userData[0]["Role_Name"];
    if (Role === "Content Manager") {
      navigate("/approvecontent", {
        state: {
          contentId: contentId,

        },
      });
    } else {
       
      
      // console.info(notificationDetails,"Content Types thissss")
      // const contentTypeId = .filter((value, index) => {
      //   return value.Content_Type 
      // })[0]?.Content_Type_Id;

      // let toLink;

      // if (contentType === "Case Study") {
      //   toLink = "/yourcasestudies";
      // } else if (contentType === "Web Pages") {
      //   toLink = "/yourwebpages";
      // } else if (contentType === "Press Release") {
      //   toLink = "/yourpressreleases";
      // } else if (contentType === "Article") {
      //   toLink = "/yourarticles";
      // } else if (type === "Social Media Post") {
      //   toLink = "/yoursocialmediaposts";
      // }

      // navigate(toLink, {
      //   state: {
      //     contentTypeId: contentTypeId,
      //     contentType: "",
      //   },
      // });

      // async function contentNavigator(type) {
      //   try {
      //     const contentTypeId = contentTypes.filter((value, index) => {
      //       return value.Content_Type === type;
      //     })[0]?.Content_Type_Id;

      //     let toLink;

      //     if (type === "Case Study") {
      //       toLink = "/yourcasestudies";
      //     } else if (type === "Web Pages") {
      //       toLink = "/yourwebpages";
      //     } else if (type === "Press Release") {
      //       toLink = "/yourpressreleases";
      //     } else if (type === "Article") {
      //       toLink = "/yourarticles";
      //     } else if (type === "Social Media Post") {
      //       toLink = "/yoursocialmediaposts";
      //     }

      //     navigate(toLink, {
      //       state: {
      //         contentTypeId: contentTypeId,
      //         contentType: type,
      //       },
      //     });
      //   }
      //   catch (error) {

      //   }
      // }
    }
  };

  //   console.log(contentType, "?????????????????");
  //   console.log(fromDate, "?????????????????");
  //   console.log(toDate, "?????????????????");
  //   console.log(notificationDetails, "asdasd");
  //   console.log(notificationDetails.length, "asdasd");

  function cancelButton() {
    fetchNotification("", "", "", count);
    setFilterOptions(true);
  }

  function bindNotification() {
    return notificationDetails.map((notification, index) => {
      return (
        <li
          className="bg-white notify-li-radius shadow-sm mb-3 p-3"
          key={index}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <span>
                <img
                  src={notification.User_Image}
                  alt="user-img"
                  className="notif-list-userprof"
                />
              </span>
              <div className="ms-3">
                <h5 className="font-16 font-regular check-label">
                  <span className="font-bold">
                    {notification.From_User_Firstname}
                  </span>{" "}
                  {console.log(notification.Notification_Type)}
                  {notification.Notification_Type == "Approved" ||
                  notification.Notification_Type == "Rejected"
                    ? `has ${notification.Notification_Type} your request for `
                    : "has Requested to review "}
                  {/* has requested to review{" "} */}
                  <span className="font-bold">{notification.Content_Name}</span>
                </h5>
                <label className="font-12 font-medium cust-label">
                  Submitted Date:
                  <span className=" check-label">
                    {" "}
                    {moment(notification.Created_Date).format(
                      "MMM DD, YYYY h:mm A"
                    )}
                  </span>
                </label>
                <label className="font-12 font-medium ms-4 cust-label">
                  Content type:
                  <span className=" check-label">
                    {" "}
                    {notification.Content_Type_Name}
                  </span>
                </label>
              </div>
            </div>

            <div className="d-flex align-items-end">
              <button
                type="button"
                className="px-4 violet-btn primary-darkblue font-medium font-18 text-nowrap box-shadow-none"
                onClick={() => {
                  const Role = userData[0]["Role_Name"];
                  if (Role === "Content Manager") {
                    navigate("/approvecontent", {
                      state: {
                        contentId: notification.Content_Id,
                        socialMediaId : notification.Social_Media_Content_Id
                      },
                    });
                  } else {
                    if(notification.
                      Content_Type_Name == "Case Study"
                      ){
                        navigate("/createcontent", {
                          state: {
                            contentId: notification.Content_Id,
                            socialMediaId : notification.Social_Media_Content_Id,
                            contentType: notification.Content_Type_Name
                          },
                        });
                      }
                    else if(notification.Content_Type_Name == "Article"){
                      navigate("/createcontent", {
                        state: {
                          contentId: notification.Content_Id,
                          socialMediaId : notification.Social_Media_Content_Id,
                          contentType: notification.Content_Type_Name
                        },
                      });
                    }
                    else if(notification.Content_Type_Name == "Press Release"){
                      navigate("/createcontent", {
                        state: {
                          contentId: notification.Content_Id,
                          socialMediaId : notification.Social_Media_Content_Id,
                          contentType: notification.Content_Type_Name
                        },
                      });
                    }
                    else if(notification.Content_Type_Name == "Social Media Post"){
                      navigate("/createcontent", {
                        state: {
                          contentId: notification.Content_Id,
                          socialMediaId : notification.Social_Media_Content_Id,
                          contentType: notification.Content_Type_Name
                        },
                      });
                    }
                    else {
                      navigate("/createcontent", {
                        state: {
                          contentId: notification.Content_Id,
                          socialMediaId : notification.Social_Media_Content_Id,
                          contentType: notification.Content_Type_Name

                        },
                      });
                    }                  
                }}
              }
              >{
                console.info(notification,"=========Notititi")
              }
                Review Report
              </button>
            </div>
          </div>
        </li>
      );
    });
  }

  return (
    <>
      <div className={`${(!isNavCollapsed) ?"content-conatiner content-generated": "collapsed-con"}`} id="contentBox">
        <div className="row ">
          <div className="d-flex justify-content-between my-4">
            <h2 className="font-bold font-22 mb-0">
              Notifications
              <span>({loadMore})</span>
            </h2>
            {/* filter starts here */}
            <div className="dropdown">
              <button
                className="btn white-btn d-flex align-items-center p-3 box-shadow-none"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={() => setFilterOptions(!filterOptions)}
              >
                <img src="images/filter-line.svg" alt="filter-line" />
              </button>
              <div
                hidden={filterOptions}
                className="dropdown-menu dropdown-menu-end filter-drop show"
                style={{
                  position: "absolute",
                  inset: "0px auto auto 0px",
                  margin: "0px",
                  transform: "translate(-344px, 48px)",
                }}
              >
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="font-20 font-bold m-0">Filter</p>
                  <img
                    src="images/close-icon.svg"
                    alt="close-icon"
                    className="cursor-pointer"
                    onClick={() => setFilterOptions(true)}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <label
                      htmlFor="fromDate"
                      className="form-label mb-0 cust-label font-14 font-medium"
                    >
                      Content Type
                    </label>
                    <select
                      className="form-select custom-form  font-14 font-regular"
                      id="conetentType"
                      onChange={(e) => setContentType(e.target.value)}
                      value={contentType}
                    >
                      <option value="select">select</option>
                      <option value={dropDownDetails[4]?.Content_Type_Id}>
                        {dropDownDetails[4]?.Content_Type}
                      </option>
                      <option value={dropDownDetails[3]?.Content_Type_Id}>
                        {dropDownDetails[3]?.Content_Type}
                      </option>
                      <option value={dropDownDetails[1]?.Content_Type_Id}>
                        {dropDownDetails[1]?.Content_Type}
                      </option>
                      <option value={dropDownDetails[2]?.Content_Type_Id}>
                        {dropDownDetails[2]?.Content_Type}
                      </option>
                      <option value={dropDownDetails[0]?.Content_Type_Id}>
                        {dropDownDetails[0]?.Content_Type}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="fromDate"
                      className="form-label mb-0 cust-label font-14 font-medium"
                    >
                      From
                    </label>
                    <input
                      type="date"
                      className="form-control custom-form cust-date font-14 font-regular"
                      id="fromDate"
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="toDate"
                      className="form-label mb-0 cust-label font-14 font-medium"
                    >
                      To
                    </label>
                    <input
                      type="date"
                      className="form-control custom-form cust-date font-14 font-regular"
                      id="toDate"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => cancelButton()}
                    className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      fetchNotification(contentType, fromDate, toDate, count);
                    }}
                    disabled={
                      contentType == "select" && toDate == "" && fromDate == ""
                    }
                    className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            {/* filter ends here */}
          </div>
          <div className="col-md-12 px-4 py-5">
            <ul className="list-unstyled ps-0">
              {bindNotification()}
              <li
                hidden={notificationDetails.length !== 0}
                className="bg-white notify-li-radius shadow-sm mb-3 px-3 py-5"
              >
                <div className="ps-3 text-center">
                  <span>
                    <img
                      src="images/no-notify-icon.svg"
                      alt="no-notify-icon"
                      className="my-3"
                    />
                  </span>
                  <h5 className="font-14 font-bold primary-darkblue mt-1">
                    No Notification Received
                  </h5>
                  <p className="font-12 font-regular grid-norec-gray">
                    It looks like you haven't received any notification yet!
                  </p>
                </div>
              </li>
              {console.log(loadMore, "This is loadmore count", count)}
             {notificationDetails !="" ?(<div className="d-flex justify-content-center mt-3">
                {loadMore <= 10 || count >= loadMore ? null : (
                  <button
                    className="btn shadow-btn d-flex align-items-center py-2 px-3  font-bold font-16"
                    onClick={() => {
                      let newCount = count;
                      newCount += 10;
                      setCount(count + 10);
                      fetchNotification(
                        contentType,
                        fromDate,
                        toDate,
                        newCount
                      );
                    }}
                  >
                    Load More
                  </button>
                )}
              </div>):null }
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
