import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkDetailsApi, getDropDownDetailsApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";
import Cookies from "js-cookie";

export default function Login() {
  const { verifyJwtData, getUserData, openToast, userData , setIsNotUser} =
    useCommonContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginEnabled, setLoginEnabled] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateForm(e.target.value, password);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validateForm(email, e.target.value);
  };

  const validateForm = (email, password) => {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail =
      email !== null && email !== "" && emailRegex.test(email);
    const isPasswordValid =
      password !== null && password !== "" && password.length >= 8;

    setLoginEnabled(isValidEmail && isPasswordValid);
  };

  const handleLoginClick = async () => {
    if (isLoginEnabled) {
      try {
        const checkDetailsResult = await checkDetailsApi({ email, password });

        if (checkDetailsResult.status === 200) {
          window.localStorage.setItem(
            "token",
            checkDetailsResult.data.data.token
          );
          const userData = await getUserData();
          const contentTypes = await getContentIds();
          if (rememberMe) {
            Cookies.set("userData", checkDetailsResult.data.data.token, {
              expires: 30,
            });
          }

          const role = userData[0]["Role_Name"];

       
          if (role === "Content Manager") {
            setTimeout(() => {
              navigate("/hub");
            });
          } else if (role === "Content Writer") {
            setTimeout(() => {
              navigate("/yourarticles", {
                state: {
                  contentTypeId: contentTypes.filter((value) => {
                    return value.Content_Type == "Article";
                  })[0]["Content_Type_Id"],
                  contentType: "Article",
                },
              });
            });
          } else if (role === "Product Owner") {
            setTimeout(() => {
              navigate("/productcontrast");
            });
          }
          openToast("Login success", "images/toast-check.svg");
        }
      } catch (error) {
        console.log(error.message);
        if (error?.response?.data?.message === "Invalid credentials") {
          openToast("Invalid Credentials", "images/red-cross-toast.svg");
        }
      }
    }
  };

  useEffect(() => {
    localStorage.clear();
    // localStorage.setItem("token","Kab")
    // if (window.localStorage.getItem("token")){
    //   getContentIds();
    // }
  }, []);


  const [contentTypes, setContentTypes] = useState([]);
  async function getContentIds() {
    try {
      const payload = {
        userId: "",
        tableName: "Content_Types",
      };

      const responseData = await getDropDownDetailsApi(payload);
      setContentTypes(responseData.data.data.data);
      return responseData.data.data.data
    } catch (error) {
      openToast(error?.response?.data?.message, "");
    }
  }


  // useEffect(()=>{
  //   setInterval(()=>{
  //     document.getElementById("button2").click()
  //   },3000)
  // },[])

  useEffect(() => {
    let currentIndex = 0; // Initialize index to track which button to click
    const interval = setInterval(() => {
      document.getElementById(`button${currentIndex + 1}`).click();
      currentIndex = (currentIndex + 1) % 3; // Ensure index loops between 0, 1, and 2
    }, 4000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);




  // return (


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {/* Your carousel component */}
          <div className=" col-xl-7 d-xl-block d-none py-2 pe-2">
            <div className="login-bg-height position-relative my-0">
              <div className="login-bg-height login-bg-img">
                <div
                  id="carouselExampleIndicators"
                  className="carousel pt-5 slide"
                  data-bs-ride="carousel"

                >
                  <div className="carousel-indicators custom-carousel justify-content-start mx-5" >
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={0}
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                      id="button1"
                      class="active"
                    />
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={1}
                      aria-label="Slide 2"
                      id="button2"
                    />
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={2}
                      aria-label="Slide 3"
                      id="button3"
                    />
                  </div>
                  <div className="carousel-inner mx-5 py-5 mt-5">
                    <div className="carousel-item active">
                      <h5 className="mb-3 font-bold color-white font-28">
                        Rapid Article Production
                      </h5>
                      <p className="mt-3 mb-5 font-18 font-regular color-white content-wrap">
                        AI-Powered Article Creation.
                      </p>
                    </div>
                    <div className="carousel-item">
                      <h5 className="mb-3 font-bold color-white font-32">
                        Seamless Social Media Post Generation
                      </h5>
                      <p className="mt-3 mb-5 font-16 font-regular color-white content-wrap">
                        Instantly create social media post with AI.
                      </p>
                    </div>
                    <div className="carousel-item">
                      <h5 className="mb-3 font-bold color-white font-32">
                        Event Management
                      </h5>
                      <p className="mt-3 mb-5 font-16 font-regular color-white content-wrap">
                        Transform your event experience with AI-driven content
                        creation for seamless engagement across articles, blogs,
                        and social platforms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Login form */}
          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
            <div className="form-signup">
              <div className="row justify-content-center">
                <div className="col-md-10 col-sm-12 py-lg-0 py-4">
                  <h5 className="mb-3 font-bold primary-darkblue font-32">
                    Login
                  </h5>
                  <p className="mt-3 mb-5 font-16 font-regular grey-v2">
                    Please click on login to proceed
                  </p>
                  {/* Email Input */}
                  <div className="mb-3">
                    <label
                      htmlFor="Emailaddress"
                      className="form-label mb-0 cust-label font-14 font-medium mb-2"
                    >
                      Email address
                    </label>
                    <input
                      type="text"
                      placeholder="Enter email address"
                      className="form-control custom-form py-2 font-14 font-regular"
                      id="Emailaddress"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                  {/* Password Input */}
                  <div className="mb-3">
                    <label
                      htmlFor="Password"
                      className="form-label mb-0 cust-label font-14 font-medium mb-2"
                    >
                      Password
                    </label>
                    <div style={{ position: 'relative' }}>
                    <input
  type={showPassword ? 'text' : 'password'}
  placeholder="Enter password"
  className="form-control custom-form py-2 font-14 font-regular"
  id="Password"
  value={password}
  onChange={handlePasswordChange}
  onKeyPress={(event) => {
    if (event.key === 'Enter') {
      handleLoginClick();
    }
  }}
/>
                    {/* Eye Icon to Toggle Password Visibility */}
      <span
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1, // Add zIndex to ensure the icon is clickable
        }}
        onClick={toggleShowPassword}
      >
        {showPassword ? (
          <i className="fas fa-eye-slash"></i>
        ) : (
          <i className="fas fa-eye"></i>
        )}
      </span>
                    </div>
                  </div>
                  {/* Remember Me Checkbox */}
                  <div className="d-flex justify-content-between">
                    <div className="form-check">
                      <input
                        className="form-check-input cust-check box-shadow-none"
                        type="checkbox"
                        defaultValue
                        id="fbPost"
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                      />
                      <label
                        className="form-check-label font-14 font-medium check-label"
                        htmlFor="fbPost"
                      >
                        Remember me
                      </label>
                    </div>
                    {/* Forgot Password Link */}
                    <a
                      className="primary-link-color font-16 font-medium "
                      onClick={() => {
                        setIsNotUser(false)
                        navigate(`/forgotpassword`)}}
                      style={{ cursor: 'pointer' }}
                    >
                      Forgot Password?
                    </a>
                  </div>
                  {/* Login Button */}
                  <button
                    type="button"
                    className={`btn px-4 mt-5 w-100 primary-gradient-btn text-white gap-2 text-center font-bold font-16 text-nowrap`}
                    disabled={!isLoginEnabled}
                    data-bs-dismiss="modal"
                    onClick={handleLoginClick}
                    style={{ "cursor": "pointer" }}
                  >
                    Login
                  </button>
                  {/* Signup Link */}
                  <div className="text-center mt-3">
                    <label className="font-16 font-regular">
                      Don't have an account yet?
                    </label>
                    <a
                      className="primary-link-color font-16 font-medium text-decoration-none"
                      onClick={() => {
                        setIsNotUser(false)
                        navigate(`/signup`)}}
                      style={{ cursor: 'pointer' }}
                    > Sign up
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}