import { useEffect, useRef, useState } from "react";
import { useCreateContentContext } from "../../contexts/CreateContentContext";
import { useLocation } from "react-router-dom";
// import Loader from "../Common/Loader";

import Header from "./Header";
import AskButton from "./AskButton";
import AskAi from "./AskAi";
import AskAiPrompts from "./AskAiPrompts";
import AskAiResponse from "./AskAiResponse";
import { generateImageApi } from "../../services/api";
import ArticleFields from "./CreateArticle/ArticleFields";
import { useCommonContext } from "../../contexts/CommonContext";
import { submitRequestApi } from "../../services/api";
import { useNavigate } from "react-router-dom";
import CaseStudyFields from "./createcasestudy/CaseStudyFields";
import Highlights from "../HighLights/Highlights";
import HighlightSelectCanvas from "../HighLights/HighlightSelectCanvas";
import PressReleaseFields from "./CreatePressRelease/PressReleaseFields";
import SolutionOfferingsFields from "./CreateWebPage/SolutionOffering";
import ProductWebpageFields from "./CreateWebPage/ProductWebPageFields";
import GeneralwebpageFields from "./CreateWebPage/GeneralFields";
import Loader from "../Loader/Loader";

export default function CreateContent() {
  const {
    formDataNew,
    generatedContent,
    setGeneratedContent,
    isLoading,
    setIsLoading,
    position,
    setPosition,
    selectedText,
    setSelectedText,
    isTextSelected,
    setIsTextSelected,
    parentRef,
    formData,
    setFormData,
    caseStudyformData,
    setCaseStudyFormData,
    pressReleaseformData,
    setPressReleaseFormData,
    imageUri,
    setImageUri,
    oldImageUrl,
    setOldImageUrl,
    imageData,
    setImageData,
    counts,
    setCounts,
    contentID,
    pdfRef,
    approvalStatus,
    contentType,
    account,
    webpageType,
    setAccount,
    reviewCommands,
    setReviewCommands,
    solutionFields,
    setSolutionFields,
    product,
    setProduct,
    general,
    setGeneral,
    socialMediaId,
    contentTypeId
  } = useCreateContentContext();

  const isPendingOrApproved =
    approvalStatus === "Pending" || approvalStatus === "Approved";

  const location = useLocation();

  const { userData, openToast } = useCommonContext();
  const { isNavCollapsed, setIsNavCollapsed } = useCommonContext();
  const [BlobUrl, setBlobUrl] = useState([]);
  // const [state, setState] = useState([{ id: 1, name: "" }]);

  // Update position on mouse move
  const handleMouseMove = (e) => {
    console.info(e, "this is e");
    setPosition({ x: e.clientX, y: e.clientY });
  };

  const navigate = useNavigate();
  console.info(userData[0].Role_Name, "000000000000000000000000000000000");

  // Listen for selection change
  // console.info(!isPendingOrApproved, "!isPendingOrApproved");
  // useEffect(() => {
  //   const handleSelectionChange = () => {
  //     console.info("Insiode the function for the selectiosnnkxsk");
  //     if (!isPendingOrApproved) {
  //       const selection = window.getSelection();
  //       if (selection && selection.toString()) {
  //         setSelectedText(selection.toString());
  //         setIsTextSelected(true);
  //       } else {
  //         setIsTextSelected(false);
  //       }
  //     }
  //     document.addEventListener("mouseup", handleSelectionChange);
  //     return () => {
  //       document.removeEventListener("mouseup", handleSelectionChange);
  //     };
  //   };
  // }, []);

  console.info(location.state.socialMediaId, "-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-");

  const convertBlobToBase64 = (blobUrl) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
          console.info(reader.result, "<><><><><><><><><><><><><><><>");
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", blobUrl);
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  async function generateImage(count) {
    // Determine the appropriate configuration based on contentType and webpageType
    const configurationJson = contentType == "Article"
    ? formData
    : contentType == "Case Study"
      ? caseStudyformData
      : contentType == "Press Release"
        ? pressReleaseformData
        : contentType == "Web Pages" &&
          webpageType == "Solution Offerings"
          ? solutionFields
          : contentType == "Web Pages" && webpageType == "Product"
            ? product
            : contentType == "Web Pages" && webpageType == "General"
              ? general
              : null // Default to null if no condition is met

    // Create the payload with the dynamically set configurationJson

    console.log(configurationJson,"ccccccccccccccccccccccc")
    let payload = {
        userId: userData[0]["User_Id"],
        configurationJson: configurationJson,
        contentType: contentType,
    };

    let responseData = await generateImageApi(payload);
    setBlobUrl(responseData.data.data);

    responseData.data.data = await convertBlobToBase64(responseData.data.data);
    console.info(responseData.data.data, "83892384186367136871393433668");

    let htmlString = generatedContent;

    htmlString = htmlString.replace(
      `<div class="position-relative content-upload text-center mb-4"><button class="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none" onClick="generateImage(${count})"><img src="images/generate-icon.svg" alt="generate-icon" class="me-2"/>Generate image</button><p class="font-16 font-medium mt-4 mb-1">or <label class="primary-theme-color cursor-pointer" for="uploadImg2">upload files</label> from your computer</p><p class="font-14 font-regular color-grey">( .png, .jpg, .pdf up to 25MB in size)</p><input type="file" class="cust-file" id="uploadImg2" accept="image/*" onchange="initialFileUpload(event,${count})"/></div>`,
      `<div class="img-container-article mb-4 position-relative"><img src='${responseData.data.data}' alt="ai-gen-img" class="img-article"><div class="dropdown position-absolute img-action"><button class=" border-0 bg-transparent " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src="images/img-action-icon.svg" alt="img-action-icon"></button><ul class="dropdown-menu img-action-drop" aria-labelledby="dropdownMenuButton1"><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="regenerateImage('${responseData.data.data}')"><img src="images/regen-icon.svg">Regenerate</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="deleteImage('${responseData.data.data}')"><img src="images/delete-black-icon.svg">Delete</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="handleUploadClick('${responseData.data.data}')"><img src="images/upload-black-icon.svg">Upload</li></ul></div></div>`
    );
    setGeneratedContent(htmlString);
  }

  async function regenerateImage(oldImageUrl) {
    console.log(oldImageUrl, "This is the old image url");
    let payload = {
      userId: userData[0]["User_Id"],
      configurationJson: caseStudyformData,
      contentType: "Case Study",
    };
    const responseData = await generateImageApi(payload);

    let htmlString = generatedContent;

    htmlString = htmlString.replace(
      `<div class="img-container-article mb-4 position-relative"><img src='${oldImageUrl}' alt="ai-gen-img" class="img-article"><div class="dropdown position-absolute img-action"><button class=" border-0 bg-transparent " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src="images/img-action-icon.svg" alt="img-action-icon"></button><ul class="dropdown-menu img-action-drop" aria-labelledby="dropdownMenuButton1"><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="regenerateImage('${oldImageUrl}')"><img src="images/regen-icon.svg">Regenerate</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="deleteImage('${oldImageUrl}')"><img src="images/delete-black-icon.svg">Delete</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="handleUploadClick('${oldImageUrl}')"><img src="images/upload-black-icon.svg">Upload</li></ul></div></div>`,
      `<div class="img-container-article mb-4 position-relative"><img src='${responseData.data.data}' alt="ai-gen-img" class="img-article"><div class="dropdown position-absolute img-action"><button class=" border-0 bg-transparent " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src="images/img-action-icon.svg" alt="img-action-icon"></button><ul class="dropdown-menu img-action-drop" aria-labelledby="dropdownMenuButton1"><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="regenerateImage('${responseData.data.data}')"><img src="images/regen-icon.svg">Regenerate</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="deleteImage('${responseData.data.data}')"><img src="images/delete-black-icon.svg">Delete</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="handleUploadClick('${responseData.data.data}')"><img src="images/upload-black-icon.svg">Upload</li></ul></div></div>`
    );
    setGeneratedContent(htmlString);
  }

  function deleteImage(imageUrl) {
    let htmlString = generatedContent;
    htmlString = htmlString.replace(
      `<div class="img-container-article mb-4 position-relative"><img src='${imageUrl}' alt="ai-gen-img" class="img-article"><div class="dropdown position-absolute img-action"><button class=" border-0 bg-transparent " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src="images/img-action-icon.svg" alt="img-action-icon"></button><ul class="dropdown-menu img-action-drop" aria-labelledby="dropdownMenuButton1"><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="regenerateImage('${imageUrl}')"><img src="images/regen-icon.svg">Regenerate</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="deleteImage('${imageUrl}')"><img src="images/delete-black-icon.svg">Delete</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="handleUploadClick('${imageUrl}')"><img src="images/upload-black-icon.svg">Upload</li></ul></div></div>`,
      ``
    );
    setGeneratedContent(htmlString);
  }

  useEffect(() => {
    if (imageUri !== "") {
      let htmlString = generatedContent;

      htmlString = htmlString.replace(
        `<div class="img-container-article mb-4 position-relative"><img src='${oldImageUrl}' alt="ai-gen-img" class="img-article"><div class="dropdown position-absolute img-action"><button class=" border-0 bg-transparent " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src="images/img-action-icon.svg" alt="img-action-icon"></button><ul class="dropdown-menu img-action-drop" aria-labelledby="dropdownMenuButton1"><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="regenerateImage('${oldImageUrl}')"><img src="images/regen-icon.svg">Regenerate</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="deleteImage('${oldImageUrl}')"><img src="images/delete-black-icon.svg">Delete</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="handleUploadClick('${oldImageUrl}')"><img src="images/upload-black-icon.svg">Upload</li></ul></div></div>`,
        `<div class="img-container-article mb-4 position-relative"><img src='${imageUri}' alt="ai-gen-img" class="img-article"><div class="dropdown position-absolute img-action"><button class=" border-0 bg-transparent " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src="images/img-action-icon.svg" alt="img-action-icon"></button><ul class="dropdown-menu img-action-drop" aria-labelledby="dropdownMenuButton1"><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="regenerateImage('${imageUri}')"><img src="images/regen-icon.svg">Regenerate</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="deleteImage('${imageUri}')"><img src="images/delete-black-icon.svg">Delete</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="handleUploadClick('${imageUri}')"><img src="images/upload-black-icon.svg">Upload</li></ul></div></div>`
      );
      setGeneratedContent(htmlString);
      setImageUri("");
      setOldImageUrl("");
    }
  }, [imageUri]);

  useEffect(() => {
    if (imageData !== "") {
      let htmlString = generatedContent;

      htmlString = htmlString.replace(
        `<div class="position-relative content-upload text-center mb-4"><button class="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none" onClick="generateImage(${counts})"><img src="images/generate-icon.svg" alt="generate-icon" class="me-2"/>Generate image</button><p class="font-16 font-medium mt-4 mb-1">or <label class="primary-theme-color cursor-pointer" for="uploadImg2">upload files</label> from your computer</p><p class="font-14 font-regular color-grey">( .png, .jpg, .pdf up to 25MB in size)</p><input type="file" class="cust-file" id="uploadImg2" accept="image/*" onchange="initialFileUpload(event,${counts})"/></div>`,
        `<div class="img-container-article mb-4 position-relative"><img src='${imageData}' alt="ai-gen-img" class="img-article"><div class="dropdown position-absolute img-action"><button class=" border-0 bg-transparent " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src="images/img-action-icon.svg" alt="img-action-icon"></button><ul class="dropdown-menu img-action-drop" aria-labelledby="dropdownMenuButton1"><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="regenerateImage('${imageData}')"><img src="images/regen-icon.svg">Regenerate</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="deleteImage('${imageData}')"><img src="images/delete-black-icon.svg">Delete</li><li class="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick="handleUploadClick('${imageData}')"><img src="images/upload-black-icon.svg">Upload</li></ul></div></div>`
      );
      setGeneratedContent(htmlString);
      setImageData("");
      setCounts(1);
    }
  }, [imageData]);

  console.log(location, "This is the location");

  function handleUploadClick(oldImage) {
    setOldImageUrl(oldImage);
    // Create a file input element
    var fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".png, .jpg, .pdf"; // Limit accepted file types

    // Listen for changes in the selected file
    fileInput.addEventListener("change", function (event) {
      var file = event.target.files[0];
      if (file) {
        // Check file size
        if (file.size > 25 * 1024 * 1024) {
          // Limit to 25MB (in bytes)
          alert("File size exceeds the limit of 25MB.");
          return;
        }

        // Read the file as a Base64 URI
        var reader = new FileReader();
        reader.onload = function (event) {
          var base64Uri = event.target.result;
          // Set the Base64 URI in the state variable
          setImageUri(base64Uri);
        };
        reader.readAsDataURL(file);
      }
    });

    // Trigger click event on the file input element to prompt file selection
    fileInput.click();
  }

  const initialFileUpload = (event, count) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result;
      setImageData(base64Data);
      setCounts(count);
    };

    // Make sure a file is selected
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  //function to submit the request;
  // const handleMouseMove = (e) => {
  //   setPosition({ x: e.clientX, y: e.clientY });
  // };

  // Listen for selection change
  // useEffect(() => {
  //   const handleSelectionChange = () => {
  //     const selection = window.getSelection();
  //     if (selection && selection.toString()) {
  //       setSelectedText(selection.toString());
  //       setIsTextSelected(true);
  //     } else {
  //       setIsTextSelected(false);
  //     }
  //   };
  //   document.addEventListener("mouseup", handleSelectionChange);
  //   return () => {
  //     document.removeEventListener("mouseup", handleSelectionChange);
  //   };
  // }, []);

  const submitRequest = async () => {
    try {
      const payload = {
        contentId: contentID,
        userId: userData[0]?.User_Id,
      };

      const responseData = await submitRequestApi(payload);

      let toLink = "";
      if (contentType === "Case Study") {
        toLink = "/yourcasestudies";
      } else if (contentType === "Web Pages") {
        toLink = "/yourwebpages";
      } else if (contentType === "Press Release") {
        toLink = "/yourpressreleases";
      } else if (contentType === "Article") {
        toLink = "/yourarticles";
      } else if (contentType === "Social Media Post") {
        toLink = "/yoursocialmediaposts";
      }

      if (responseData.status === 200) {
        openToast("Submitted successfully", "images/toast-check.svg");
        setTimeout(() => {
          navigate(toLink, {
            state: {
              contentTypeId: contentTypeId,
              contentType: contentType,
            },
          });
        }, 5000);
      }

      console.log(responseData);
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
      console.log(error.message);
    }
  };

  window.initialFileUpload = initialFileUpload;
  window.handleUploadClick = handleUploadClick;
  window.deleteImage = deleteImage;
  window.generateImage = generateImage;
  window.regenerateImage = regenerateImage;

  console.log(location, "This is the location from craete content");

  return (
    <>
      <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
        <div className="row">
          <Header />
          <div className="col-md-12">
            <div className="row">
              {contentType == "Article" ? (
                <ArticleFields />
              ) : contentType == "Case Study" ? (
                <CaseStudyFields />
              ) : contentType == "Press Release" ? (
                <PressReleaseFields />
              ) : contentType == "Web Pages" &&
                webpageType == "Solution Offerings" ? (
                <SolutionOfferingsFields />
              ) : contentType == "Web Pages" && webpageType == "Product" ? (
                <ProductWebpageFields />
              ) : contentType == "Web Pages" && webpageType == "General" ? (
                <GeneralwebpageFields />
              ) : null}

              {/* contentType == "webpageType" &&
                  webpageType == "Solution Offerings"
                ? solutionFields
                : contentType == "webpageType" && webpageType == "Product"
                ? product
                : contentType == "webpageType" && webpageType == "General"
                ? general
                : null */}

              <div
                className="col-md-9 content-generated"
                style={{
                  cursor: isPendingOrApproved ? "not-allowed" : "auto",
                }}
              >
                {isLoading && <Loader />}
                {generatedContent != "" ? (<div
                  hidden={isLoading}
                  class="generated-container mt-4"
                  id="Content-Place"
                  ref={pdfRef}
                  onMouseUp={(e) => {
                    console.info("Im called ");
                    const selection = window?.getSelection();
                    if (selection) {
                      handleMouseMove(e);
                      if (!isPendingOrApproved) {
                        handleMouseMove(e);
                        const range = selection
                          .getRangeAt(0)
                          .getBoundingClientRect();
                        setPosition({
                          x: range.x,
                          y: range.y + window.scrollY,
                        });
                        if (selection && selection.toString()) {
                          setSelectedText(selection?.toString());
                          setIsTextSelected(true);
                        } else {
                          setIsTextSelected(false);
                        }
                      }
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: generatedContent,
                  }}
                ></div>) : (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                  <p className="font-14 font-medium cursor-pointer" data-bs-toggle="collapse" data-bs-target="#toneStyle">
                    <label style={{ top: '1000px', right: '500' }} htmlFor="toneContent" className="form-label mb-0 cust-label font-14 font-medium mb-2">
                      {!isLoading ? 'Your content generated by Seraphine will appear here' : ''}
                    </label>
                  </p>
                </div>)}
                <AskButton />
                <AskAi />
                <AskAiPrompts />
                <AskAiResponse />
                {/* <div id="example-div">
                    <h1>This is a Heading</h1>
                    <p>
                      This is a paragraph with some text. Line 172:6: React Hook
                      useEffect has a missing dependency: 'contentId'. Either
                      include it or remove the dependency array
                      react-hooks/exhaustive-deps Line 187:23: Expected '==='
                      and instead saw '==' eqeqeq Line 189:30: Expected '==='
                      and instead saw '==' eqeqeq Line 236:6: React Hook
                      useEffect has a missing dependency: 'saveContent'. Either
                      include it or remove the dependency array
                      react-hooks/exhaustive-deps Line 239:45: Expected '!=='
                      and instead saw '!=' eqeqeq Line 242:6: React Hook
                      useEffect has missing dependencies: 'contentId' and
                      'getContentById'.
                    </p>
                    <ul>
                      <li>Item 1</li>
                      <li>Item 2</li>
                      <li>Item 3</li>
                    </ul>
                  </div> */}
              </div>
            </div>
          </div>

          {userData[0]?.Role_Name &&
            generatedContent != "" &&
            userData[0]?.Role_Name !== "Content Manager" ? (
            <footer
              className="head-bar font-20 font-bold p-3 position-sticky bottom-0  bg-white mb-0 d-flex align-items-center justify-content-end"
              style={{ zIndex: 999 }}
            >
              <button
                className="btn btn-success cust-approve d-flex align-items-center gap-2 px-3"
                disabled={contentID == "" || isPendingOrApproved}
                onClick={() => {
                  submitRequest();
                }}
              >
                Submit
              </button>
            </footer>
          ) : null}

          {/* <button className="p-0 border-0 comment-btn position-fixed">
            <img src="images/comment-btn-icon.svg" alt="comment-btn-icon" />
            <span className="comment-tooltip">
              <span className="d-block font-14 font-bold text-nowrap mb-3 text-start">
                Add Your Comments
              </span>
              <span className="d-block font-12 font-bold text-nowrap color-grey text-start">
                Comment
              </span>
              <textarea
                value={reviewCommands}
                disabled
                className="form-control custom-form cust-tarea  font-12 font-regular"
                rows={5}
                style={{ width: "260px" }}
                defaultValue={""}
              />
            </span>
          </button> */}

          {/* <button
            className="p-0 border-0 highlights-btn position-fixed"
            data-bs-toggle="offcanvas"
            data-bs-target="#promotionalContent"
            aria-controls="offcanvasRight"
          >
            <img
              src="images/highlights-icon.svg"
              alt="highlights-icon"
              data-bs-toggle="tooltip"
              title="Generate highlights"
              data-bs-placement="left"
              className="font-medium"
            /> */}
          {/* <img src="images/highlights-icon.svg" alt="highlights-icon">
    <span class="highlights-tooltip">
        <img src="images/social-media-img.svg">
        <span
            class="font-medium text-white mt-2 mb-1 font-14 d-block color-grey text-start">Try
            the Highlights feature</span>
        <span
            class="font-regular font-12 d-block color-grey text-start">Generate
            Facebook post, LinkedIn post, Twitter Post and more.</span>
    </span> */}
          {/* </button> */}

          <Highlights
            config={
              contentType == "Article"
                ? formData
                : contentType == "Case Study"
                  ? caseStudyformData
                  : contentType == "Press Release"
                    ? pressReleaseformData
                    : contentType == "Web Pages" &&
                      webpageType == "Solution Offerings"
                      ? solutionFields
                      : contentType == "Web Pages" && webpageType == "Product"
                        ? product
                        : contentType == "Web Pages" && webpageType == "General"
                          ? general
                          : null
            }
            setConfig={
              contentType == "Article"
                ? setFormData
                : contentType == "Case Study"
                  ? setCaseStudyFormData
                  : contentType == "Press Release"
                    ? setPressReleaseFormData
                    : contentType == "Web Pages" &&
                      webpageType == "Solution Offerings"
                      ? setSolutionFields
                      : contentType == "Web Pages" && webpageType == "Product"
                        ? setProduct
                        : contentType == "Web Pages" && webpageType == "General"
                          ? setGeneral
                          : null
            }
            contentid={contentID}
          />

          <HighlightSelectCanvas
            setConfig={
              contentType == "Article"
                ? setFormData
                : contentType == "Case Study"
                  ? setCaseStudyFormData
                  : contentType == "Press Release"
                    ? setPressReleaseFormData
                    : contentType == "Web Pages" &&
                      webpageType == "Solution Offerings"
                      ? setSolutionFields
                      : contentType == "Web Pages" && webpageType == "Product"
                        ? setProduct
                        : contentType == "Web Pages" && webpageType == "General"
                          ? setGeneral
                          : null
            }
            config={
              contentType == "Article"
                ? formData
                : contentType == "Case Study"
                  ? caseStudyformData
                  : contentType == "Press Release"
                    ? pressReleaseformData
                    : contentType == "Web Pages" &&
                      webpageType == "Solution Offerings"
                      ? solutionFields
                      : contentType == "Web Pages" && webpageType == "Product"
                        ? product
                        : contentType == "Web Pages" && webpageType == "General"
                          ? general
                          : null
            }
            contentid={contentID}
            account={account}
          ></HighlightSelectCanvas>

        </div>
      </div>
      {userData[0]["Role_Name"] != "Content Manager" ? (
        <button
          hidden={generatedContent == ""}
          className="p-0 border-0 comment-btn position-fixed"
          style={{ float: "right", width: "50px" }}
        >
          <img src="images/comment-btn-icon.svg" alt="comment-btn-icon" />
          <span className="comment-tooltip">
            <span className="d-block font-14 font-bold text-nowrap mb-3 text-start">
              Add Your Comments
            </span>
            <span className="d-block font-12 font-bold text-nowrap color-grey text-start">
              Comment
            </span>
            <textarea
              value={reviewCommands}
              disabled
              className="form-control custom-form cust-tarea  font-12 font-regular"
              rows={5}
              style={{ width: "260px" }}
              defaultValue={""}
            />
          </span>
        </button>
      ) : null}

      {
        console.info(location.state.socialMediaId ? "True" : "False" ,  socialMediaId ? "True" :"False" , "This is the social iddmdm")
      }

      <button
        className="p-0 border-0 highlights-btn position-fixed"
        data-bs-toggle="offcanvas"
        data-bs-target={
          (!(location.state.socialMediaId || socialMediaId))
            ? "#highLightCanvas"
            : "#promotionalContent"
        }
        aria-controls="offcanvasRight"
        style={{ float: "right", width: "50px" }}

      >
        <img
          src="images/highlights-icon.svg"
          alt="highlights-icon"
          data-bs-toggle="tooltip"
          title="Generate highlights"
          data-bs-placement="left"
          className="font-medium"
        />
        {/* <img src="images/highlights-icon.svg" alt="highlights-icon">
    <span class="highlights-tooltip">
        <img src="images/social-media-img.svg">
        <span
            class="font-medium text-white mt-2 mb-1 font-14 d-block color-grey text-start">Try
            the Highlights feature</span>
        <span
            class="font-regular font-12 d-block color-grey text-start">Generate
            Facebook post, LinkedIn post, Twitter Post and more.</span>
    </span> */}
      </button>

    </>
    // "images/toast-check.svg"
    //"images/red-cross-toast.svg"
  );
}
