// Upcoming_Events_2
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

// Upcoming_Events_3
// import { getAllDetails, insertErrorApi } from './api.js';

// Upcoming_Events_4
import { useNavigate } from "react-router-dom";

// Upcoming_Events_5
import { useCommonContext } from "../../contexts/CommonContext";
import {
  getAllUpcomingEventsApi,
  insertUpcomingEventsStatusApi,
  getDropDownDetailsApi,
} from "../../services/api";

// Upcoming_Events_8
export default function UpcommingEvents() {
  // Upcoming_Events_9
  const navigate = useNavigate();

  const { verifyJwtData, getUserData, userData } = useCommonContext();

  // Upcoming_Events_10
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  const [eventDeptId, setEventDeptId] = useState([]);
  const {isNavCollapsed, setIsNavCollapsed } = useCommonContext();

  // Upcoming_Events_11
  const [whichClick, setWhichClick] = useState({});

  // Upcoming_Events_12
  const [isPopOpen, setIsPopOpen] = useState(false);

  // Upcoming_Events_13

  const [rowCount, setrowCount] = useState(0);

  // Upcoming_Events_14
  const [RsvpDropDown, setRsvpDropDown] = useState([]);

  // Upcoming_Events_15
  const [isFilterOpen, setisFilterOpen] = useState(false);

  // Upcoming_Events_16
  const [selectedDates, setselectedDates] = useState({
    fromDate: "",
    toDate: "",
  });

  // Upcoming_Events_17
  const [eventName, seteventName] = useState("");

  const [eventType, seteventType] = useState([]);

  // Upcoming_Events_18
  const [count, setCount] = useState(10);

  // Upcoming_Events_19

  // Upcoming_Events_20
  const getUpcomingEvents = async () => {
    // Upcoming_Events_21
    try {
      console.log(
        getAllUpcomingEventsApi,
        "get all upcoming events triggered in the use effect"
      );
      const currentDate = new Date();

      // Add 10 days to the current date
      const futureDate = new Date(currentDate);
      futureDate.setDate(currentDate.getDate() + 10);

      // Format the dates for the database
      const formattedCurrentDate = currentDate.toISOString().split("T")[0];
      console.log(formattedCurrentDate, "Current date");
      const formattedFutureDate = futureDate.toISOString().split("T")[0];
      console.log(formattedFutureDate, "To date");

      const payload = {
        userId: userData[0]?.["User_Id"],
        from_date: selectedDates.fromDate
          ? selectedDates.fromDate
          : formattedCurrentDate,
        to_date: selectedDates.toDate
          ? selectedDates.toDate
          : formattedFutureDate,
        event_name: eventName ? eventName : "",
        count: count,
        event_type: eventDeptId ? eventDeptId : "",
      };

      console.log(payload, "payload with all the details");

      // Upcoming_Events_22
      const responseData = await getAllUpcomingEventsApi(payload);
      console.log(responseData, "Response from the getAllUpcomingEventsApi ");
      // Upcoming_Events_32
      setUpcomingEvents(responseData.data.data.data);
      console.log(responseData.data.data.data, "Upcoming events set stated");
      // Upcoming_Events_41
      setrowCount(responseData.data.data.rowCount, "Row Count");
      console.log(responseData.data.data.rowCount, "response data");
    } catch (error) {
      // Upcoming_Events_44
      // insertErrorApi(error);
    }
  };
  {
    console.log(upcomingEvents, "row Count state variable  value ");
  }

  // Upcoming_Events_23
  useEffect(() => {
    getDropDownData();
  }, [userData]);

  useEffect(() => {
    if (eventName == "") {
      getUpcomingEvents();
    }
  }, [eventName]);

  useEffect(() => {
    getUpcomingEvents();
  }, [count, userData]);

  // Upcoming_Events_42

  const handleMoreDetails = (whichClick) => {
    window.open(whichClick.Event_Url, "_blank");
  };

  const loadMore = () => {
    if (rowCount >= count) {
      console.log("Entered in to the load more function");
      const countIncreamented = count + 10;
      setCount(countIncreamented);
    }
  };

  const getDropDownData = async (e) => {
    try {
      console.log(getDropDownData, "Entered into the get drop down function");
      const data1 = {
        userId: userData[0]?.["User_Id"],
        tableName: "RVSP_Types",
      };
      const response1 = await getDropDownDetailsApi(data1);
      console.log(response1, "rvsp drop down data RSVP");
      setRsvpDropDown(response1.data.data.data);
      // console.log(RsvpDropDown," rsvp drop downnnnnnnn")

      const data2 = {
        userId: userData[0]?.["User_Id"],
        tableName: "Event_Department",
      };
      const response2 = await getDropDownDetailsApi(data2);
      console.log(response2, "rvsp drop down data ");
      seteventType(response2.data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  {
    console.log(RsvpDropDown, "This is the opdated");
    console.log(eventType, "event type dropdown state variable");
  }

  // Upcoming_Events_43
  const handleRsvpDropdownChange = async (e) => {
    const value = e.target.value;

    if (value) {
      // Upcoming_Events_58
      const data = {
        userId: userData[0]?.["User_Id"],
        eventId: whichClick.Upcoming_Event_Id,
        rvspTypeId: value,
      };
      // Upcoming_Events_59

      try {
        // Upcoming_Events_61
        const response = await insertUpcomingEventsStatusApi(data);
        // Upcoming_Events_64

        if (response.status == 200) {
          console.log("response success true");
          document.getElementById("EventDetailClose").click();
          getUpcomingEvents();

          // Upcoming_Events_72
          setIsPopOpen(false);
        }
      } catch (error) {
        // Upcoming_Events_74
        console.error(error);
      }
    }
  };

  // const formatTime = (timeString) => {
  //   const [hours, minutes] = timeString.split(":");
  //   const time = new Date(0, 0, 0, hours, minutes);
  //   return time.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" });

  // Upcoming_Events_75
  return (
    <>
      <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
        <div className="row">
          <div className="col-md-12 ">
            <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
              <h2 className="font-bold font-22">
                Upcoming Events <span>{rowCount}</span>
              </h2>
              <div className="d-flex gap-3">
                {/* search starts here */}
                <div
                  className="input-group search-field"
                  hidden={upcomingEvents.length == 0}
                >
                  <input
                    type="search"
                    className="form-control box-shadow-none font-16 font-medium"
                    placeholder="Search"
                    onChange={(e) => {
                      seteventName(e.target.value);
                    }}
                  />
                  <button
                    className="btn white-btn box-shadow-none d-flex align-items-center"
                    type="button"
                    id="button-addon2"
                    onClick={() => {
                      getUpcomingEvents();
                    }}
                  >
                    <img src="/images/search-icon.svg" alt="search-icon" />
                  </button>
                </div>
                {/* search starts here */}
                {/* filter starts here */}
                <div className="dropdown" hidden={upcomingEvents.length == 0}>
                  <button
                    className="btn white-btn d-flex align-items-center p-3 box-shadow-none"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="/images/filter-line.svg" alt="filter-line" />
                  </button>
                  <div className="dropdown-menu dropdown-menu-end filter-drop">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="font-20 font-bold m-0">Filter</p>
                      <img
                        src="/images/close-icon.svg"
                        alt="close-icon"
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="fromDate"
                          className="form-label mb-0 cust-label font-14 font-medium"
                        >
                          Event Type
                        </label>
                        <select
                          className="form-select custom-form  font-14 font-regular"
                          id="purposeOfTheContent"
                          onChange={(e) => {
                            console.log(
                              e.target,
                              "The debug filter",
                              e.target.value
                            );
                            setEventDeptId(e.target.value);
                          }}
                        >
                          <option>Select</option>
                          {eventType?.map((event, index) => (
                            <option
                              key={index}
                              value={event.Event_Department_Id}
                            >
                              {event.Event_Department}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <p className="font-20 font-bold mb-3">Date</p>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="fromDate"
                          className="form-label mb-0 cust-label font-14 font-medium"
                        >
                          From
                        </label>
                        <input
                          type="date"
                          className="form-control custom-form cust-date font-14 font-regular"
                          id="fromDate"
                          onChange={(event) =>
                            setselectedDates((prevDates) => ({
                              ...prevDates,
                              fromDate: event.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="toDate"
                          className="form-label mb-0 cust-label font-14 font-medium"
                        >
                          To
                        </label>
                        <input
                          min={selectedDates.fromDate}
                          type="date"
                          className="form-control custom-form cust-date font-14 font-regular"
                          id="toDate"
                          onChange={(event) =>
                            setselectedDates((prevDates) => ({
                              ...prevDates,
                              toDate: event.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2">
                        Cancel
                      </button>
                      <button
                        className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                        onClick={() => {
                          getUpcomingEvents();
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
                {/* filter ends here */}
              </div>
            </div>

            {upcomingEvents.length != 0 ? (
              upcomingEvents.map((event, index) => (
                <div className="bg-white border-0 shadow-sm py-3 px-4 radius-16 mb-3 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center gap-3">
                    <div className="border-end pe-3 text-center">
                      <span className="font-bold font-16 d-block">
                        {moment(event.Event_From_Date).format("MMM")}
                      </span>
                      <span className="font-bold font-28 d-block">
                        {moment(event.Event_From_Date).date()}
                      </span>
                    </div>
                    <div>
                      <span className="font-bold font-16 d-block mb-2 pb-1">
                        {event.Event_Name}
                      </span>
                      <span className="d-flex align-items-center font-medium font-12 gap-3 color-grey">
                        <span className="d-flex align-items-center gap-2">
                          <img
                            src="/images/clock-green-icon.svg"
                            alt="clock-green-icon"
                          />
 {
  event?.Event_From_Time && event?.Event_To_Time ? 
    `${event.Event_From_Time !== "" ? moment(event.Event_From_Time, "HH:mm").isValid() ? moment(event.Event_From_Time, "HH:mm").format("h:mm A") : "" : ""} - ${event.Event_To_Time !== "" ? moment(event.Event_To_Time, "HH:mm").isValid() ? moment(event.Event_To_Time, "HH:mm").format("h:mm A") : "" : ""}` :
  event?.Event_From_Time ? 
    moment(event.Event_From_Time, "HH:mm").isValid() ? moment(event.Event_From_Time, "HH:mm").format("h:mm A") : "" :
    "" 
}


                        </span>

                        <span className="d-flex align-items-center gap-2">
                          <img
                            src="/images/video-green-icon.svg"
                            alt="clock-green-icon"
                          />
                          {event.Location ? event.Location : "Online"}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    {event?.Accepted_User_Id?.split(",").includes(
                      userData[0]?.User_Id
                    ) ? (
                      <span className="status-green py-1 px-2 font-bold font-12 rounded-pill">
                        Attending{" "}
                      </span>
                    ) : null}

                    <button
                      className="btn shadow-btn d-flex align-items-center py-2 px-3  font-medium font-16"
                      data-bs-toggle="modal"
                      data-bs-target="#eventDetails"
                      onClick={() => {
                        setWhichClick(event);
                        setIsPopOpen(true);
                      }}
                    >
                      Event details
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <div className="ps-3 text-center">
                  <span>
                    <img
                      src="images/no-notify-icon.svg"
                      alt="no-notify-icon"
                      className="my-3"
                    />
                  </span>
                  <h5 className="font-14 font-bold primary-darkblue mt-1">
                    No Upcoming Events
                  </h5>
                </div>
              </div>
            )}

            {upcomingEvents.length == 0 || rowCount <= count ? null : (
              <div className="d-flex justify-content-center">
                <button
                  className="btn shadow-btn d-flex align-items-center py-2 px-3  font-bold font-16"
                  value={count}
                  onClick={() => {
                    loadMore();
                  }}
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="eventDetails"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-hidden="true"
        value={isPopOpen}
      >
        <div className="modal-dialog modal-lg" style={{ width: "690px" }}>
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <h5
                className="font-18 font-bold popup-header-color mb-0"
                id="AddmanuallyLabel"
              >
                {whichClick?.Event_Name}
              </h5>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                id="EventDetailClose"
                aria-label="Close"
              />
            </div>
            <div className="modal-body border-0">
              <p className="font-medium font-16 mb-0">Description</p>
              <p className="font-regular font-16 grey-v1 mb-4">
                {whichClick.Event_Description}
              </p>
              <div className="row ">
                <div className="col-md-4">
                  <p className="font-medium font-16 mb-0">Start date & time</p>
                  <p className="font-regular font-16 grey-v1 mb-4">
                    <span className="font-bold font-16 d-block">
                      {moment(
                        `${whichClick.Event_From_Date} ${whichClick.Event_From_Time}`
                      ).format("MMM DD, YYYY h:mm A")}
                    </span>
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="font-medium font-16 mb-0">End date & time</p>
                  <p className="font-regular font-16 grey-v1 mb-4">
                    <span className="font-bold font-16 d-block">
                      {moment(
                        `${whichClick.Event_To_Date} ${whichClick.Event_To_Time}`
                      ).format("MMM DD, YYYY h:mm A")}
                    </span>
                  </p>
                </div>
              </div>
              <div className="modal-footer border-0 d-flex align-items-center flex-nowrap ps-0 justify-content-between">
                <select
                  className="form-select custom-form  font-14 font-bold w-auto  font-18"
                  id="eventType"
                  value={
                    whichClick?.Accepted_User_Id?.split(",").includes(
                      userData[0]["User_Id"]
                    )
                      ? RsvpDropDown.filter((value) => {
                          return value.Rvsp_Type == "Attending";
                        })[0]["Rvsp_Type_Id"]
                      : ""
                  }
                  onChange={handleRsvpDropdownChange}
                >
                
                  <option value={""} class="font-bold">
                    RSVP
                  </option>
                  {RsvpDropDown.map((event) => (
                    <React.Fragment key={event.Rvsp_Type}>
                      <option value={event.Rvsp_Type_Id}>
                        {event.Rvsp_Type}
                      </option>
                    </React.Fragment>
                  ))}
                </select>
                <div className="d-flex align-items-center gap-3">
                  <button
                    className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {whichClick.Event_Url ? (
                    <button
                      type="button"
                      className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        handleMoreDetails(whichClick);
                      }}
                    >
                      More Info
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
