import React, { useState, useEffect, useContext } from "react";
// import { useCommonContext } from "../../contexts/CommonContext";
import EmojiConvertor from 'emoji-js'
import { useSocialMediaContext } from "../../../contexts/SocialMediaContext";
import {
  postFacebookApi,
  postInstagramApi,
  postTwitterApi,
  postLinkedInApi,
  savePostContentApi,
  generateImageSmApi,
  getEventPostsApi,
  imageuploadApi,
} from "../../../services/api";
import { useCommonContext } from "../../../contexts/CommonContext";
import { useRef } from "react";
import Loader from "../../Loader/Loader";

export default function AllMediaPostComponent() {
  const {
    // ContentDetails,
    // ContentId,
    // setContentDetails,
    // generateSocialMedia,
    // editPost,
    // setEditPost,
    // Prompt,
    // contentId,
    // socialMediaIds,
    // specifyKeyContent,
    // setSpecifyKeyContent

    specifyKeyContent,

    targetAudience,

    audienceLevel,

    ContentDetails,
    setContentDetails,
    socialMediaAccount,

    toneOfTheContent,

    styleOfTheContent,
    SocialMediaContentId,//social media id OG
    hashtags,
    
    contId,
    socialMediaIds,
    generateSocialMedia,
    Prompt,
    setIsLoading,
    isLoading
  } = useSocialMediaContext();

  const { userData, openToast } = useCommonContext();
  // const navigate = useNavigate();
  const [faceBook, setfacebook] = useState(false);
  const facebookRef = useRef(null);
  const [instagram, setInstagram] = useState(false);
  const instagramRef = useRef(null);
  const [linkedin, setLinkedin] = useState(false);
  const linkedinRef = useRef(null);

  const [twitter, setTwitter] = useState(false);
  const twitterRef = useRef(null);

  const [postGeneratedContent, setPostGeneratedContent] = useState([]);
  const [roleId, setRoleId] = useState([]);
  const [imageUpload, SetimageUpload] = useState("");

  const emojiConvertor = new EmojiConvertor();


  const postInstagram = async () => {
    try {
      setIsLoading(true);
      const data = {
        userId: userData[0]?.["User_Id"],
        image_url: ContentDetails[0]?.Instagram_Post_Image_Url,
        content_id: ContentDetails[0]?.Social_Media_Content_Id,
        account_id: ContentDetails[0]?.Account_Id,
        caption: ContentDetails[0]?.Instagram_Content,
      };
      const responseData = await postInstagramApi(data);
      if (responseData.status === 200) {
        // openToast(
        //   "Instagram Content Posted Successfully ",
        //   "images/toast-check.svg"
        // );

        const payload = {
          userId: userData[0]["User_Id"],
          socialMediaId: SocialMediaContentId,
        };
        // Assuming getEventPostsApi sends a request to get event posts
        const getEventRes = await getEventPostsApi(payload);
        setContentDetails(getEventRes.data.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      // openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  const postFaceBook = async () => {
    try {
      setIsLoading(true);

      const data = {
        userId: userData[0]?.["User_Id"],
        image_url: ContentDetails[0]?.Social_Media_Image_Url,
        contentId: ContentDetails[0]?.Social_Media_Content_Id,//socialmedia id we arw passing to update social media contents table
        Account_Id:ContentDetails[0]?.Account_Id,
        caption_message: ContentDetails[0]?.Facebook_Content,
        // caption_message, Account_Id, contentId"
      };
      const responseData = await postFacebookApi(data);
      if (responseData.status === 200) {
        console.log(contId, "contentid in the  all edia components");
        const payload = {
          userId: userData[0]["User_Id"],
          socialMediaId:  ContentDetails[0]?.Social_Media_Content_Id,
        };
        // Assuming getEventPostsApi sends a request to get event posts
        const getEventRes = await getEventPostsApi(payload);
        setContentDetails(getEventRes.data.data.data);
        // openToast(
        //   "FaceBook Content Posted Successfully ",
        //   "images/toast-check.svg"
        // );
        setIsLoading(false);
      }
    } catch (error) {
      // openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  const postTwitter = async () => {
    try {
      setIsLoading(true);

      const data = {
        userId: userData[0]?.["User_Id"],
        imageUrl: ContentDetails[0]?.Twitter_Post_Image_Url,
        contentId: ContentDetails[0]?.Social_Media_Content_Id,
        accountId: ContentDetails[0]?.Account_Id,
        caption: ContentDetails[0]?.Twitter_Content,
      };
      const responseData = await postTwitterApi(data);
      if (responseData.status === 200) {
        const payload = {
          userId: userData[0]["User_Id"],
          socialMediaId:  ContentDetails[0]?.Social_Media_Content_Id,
        };
        // Assuming getEventPostsApi sends a request to get event posts
        const getEventRes = await getEventPostsApi(payload);
        setContentDetails(getEventRes.data.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      // openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  const postLinkedin = async () => {
    try {
      setIsLoading(true);
      const data = {
        userId: userData[0]?.["User_Id"],
        image_url: ContentDetails[0]?.Linked_Post_Image_Url,
        content_id: ContentDetails[0]?.Social_Media_Content_Id,
        account_id: ContentDetails[0]?.Account_Id,
        caption: ContentDetails[0]?.Linkedin_Content,
      };
      const responseData = await postLinkedInApi(data);
      if (responseData.status === 200) {

        const payload = {
          userId: userData[0]["User_Id"],
          socialMediaId: ContentDetails[0]?.Social_Media_Content_Id,
        };
        // Assuming getEventPostsApi sends a request to get event posts
        const getEventRes = await getEventPostsApi(payload);
        setContentDetails(getEventRes.data.data.data);
        // openToast(
        //   "Linkedin Content Posted Successfully ",
        //   "images/toast-check.svg"
        // );
        setIsLoading(false);
      }
    } catch (error) {
      // openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  // const regeneratePost = async (platform) => {
  //   try {
  //     setIsLoading(true);
  //     const userId = "212E5785-5D32-4AD1-849D-0BEAFC8786ED";
  //     const data = {
  //       userId: userId,
  //       config_json: props.config,
  //       platform_selected: [platform],

  //       // platform_selected: ["twitter", "instagram", "facebook", "linkedin"],

  //       type: "highlight",
  //       Social_Media_Id: ContentDetails[0]?.Social_Media_Content_Id,
  //       account_choosed: ContentDetails[0]?.Account_Id,
  //       Event_Id: "",
  //     };
  //     const responseData = await regeneratePostApi(data);
  //     if (responseData.status === 200) {
  //       openToast("Content Regerated Successfully ", "images/toast-check.svg");
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
  //   }
  // };

  // const regenerateImage = async () => {
  //   try {
  //     setIsLoading(true);
  //     const userId = "212E5785-5D32-4AD1-849D-0BEAFC8786ED";
  //     const data = {
  //       userId: userId,
  //       config_json: props.config,
  //       platform_selected: ["instagram"],

  //       // platform_selected: ["twitter", "instagram", "facebook", "linkedin"],

  //       type: "highlight",
  //       Social_Media_Id: ContentDetails[0]?.Social_Media_Content_Id,
  //       isSocialMediaReload: true,
  //       account_choosed: ContentDetails[0]?.Account_Id,
  //       Event_Id: "",
  //     };
  //     const responseData = await regeneratePostApi(data);
  //     if (responseData.status === 200) {
  //       openToast("Content Regerated Successfully ", "images/toast-check.svg");
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
  //   }
  // };

  const handleDownload = () => {
    const fileName = ContentDetails[0]?.Social_Media_Image_Url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = ContentDetails[0]?.Social_Media_Image_Url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  async function savePostContent(platform) {
    try {
      let content;
      let ref;

      switch (platform) {
        case "Facebook_Content = ? ":
          ref = facebookRef;
          break;
        case "Instagram_Content = ? ":
          ref = instagramRef;
          break;
        case "Linkedin_Content = ? ":
          ref = linkedinRef;
          break;
        case "Twitter_Content = ? ":
          ref = twitterRef;
          break;
        default:
          throw new Error("Invalid platform");
      }

      if (ref && ref.current) {
        content = ref.current.innerHTML; // Get the innerHTML directly
      } else {
        throw new Error("Ref is null or undefined");
      }

      const payload = {
        userId: userData[0]?.["User_Id"],
        socialMediaId: socialMediaIds
          ? socialMediaIds
          : ContentDetails[0]?.Social_Media_Content_Id,
        platform: platform,
        content: content,
      };

      setfacebook(false);

      const responseData = await savePostContentApi(payload);
      console.log(responseData, "Response data from the savePostContentApi");
    } catch (error) {
      console.error("Error saving post content:", error);
    }
  }
  //regenerate
  async function regeneratePlatformImage(platform) {
    try {
      const socialImage = {
        config_json: {
          message: specifyKeyContent
            ? specifyKeyContent
            : Prompt["specifyKeyContent"],
          targetAudience: targetAudience
            ? targetAudience
            : Prompt["targetAudience"],
          toneOfTheContent: toneOfTheContent
            ? toneOfTheContent
            : Prompt["toneOfTheContent"],
          styleOfTheContent: styleOfTheContent
            ? styleOfTheContent
            : Prompt["styleOfTheContent"],
          audienceLevel: audienceLevel
            ? audienceLevel
            : Prompt["audienceLevel"],
          specifyhashtags: hashtags ? hashtags : Prompt["hashtags"],
        },
        userId: userData[0]["User_Id"],
        socialMediaContentId: SocialMediaContentId,
        platform: platform,
        account_choosed: socialMediaAccount,
      };

      await generateImageSmApi(socialImage);

      const payload = {
        userId: userData[0]["User_Id"],
        socialMediaId: SocialMediaContentId,
      };

      const getEventRes = await getEventPostsApi(payload);

      setContentDetails(getEventRes.data.data.data);
    } catch (error) {
      console.log(error.message);
    }
  }

  console.log(ContentDetails, "durrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");

  const handleFileInputChange = (event, plat) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = async () => {
      const base64String = reader.result.split(",")[1];
      const imageUrl = "data:image/png;base64," + base64String; // Appending base64 prefix

      console.info(imageUrl, "this");

      // Assuming platform comes from somewhere else, update accordingly
      const platform = plat;

      const payload1 = {
        socialMediaId: SocialMediaContentId,
        userId: userData[0]["User_Id"],
        updateUrl: imageUrl, // Use the imageUrl with base64 prefix
        platform: platform, // Update platform accordingly
      };

      try {
        // Assuming imageuploadApi sends a POST request to upload the image
        const upload = await imageuploadApi(payload1);
        console.log("Image uploaded successfully:", upload);

        const payload = {
          userId: userData[0]["User_Id"],
          socialMediaId: SocialMediaContentId,
        };
        // Assuming getEventPostsApi sends a request to get event posts
        const getEventRes = await getEventPostsApi(payload);
        setContentDetails(getEventRes.data.data.data);

        // SetimageUpload(getEventRes.data.data);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  async function deleteImage(platform) {
    try {

      const payload1 = {
        socialMediaId: SocialMediaContentId,
        userId: userData[0]["User_Id"],
        updateUrl: null, // Use the imageUrl with base64 prefix
        platform: platform, // Update platform accordingly
      };

      try {
        // Assuming imageuploadApi sends a POST request to upload the image
        const upload = await imageuploadApi(payload1);
        console.log("Image uploaded successfully:", upload);

        const payload = {
          userId: userData[0]["User_Id"],
          socialMediaId: SocialMediaContentId,
        };
        // Assuming getEventPostsApi sends a request to get event posts
        const getEventRes = await getEventPostsApi(payload);
        setContentDetails(getEventRes.data.data.data);

        // SetimageUpload(getEventRes.data.data);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } catch (error) {
      console.log(error.message);
    }
  }


  return (
    <div className="col-md-9 content-generated">
      {isLoading && <Loader />}
      <div className="generated-container mt-4"
      hidden={isLoading}>
        <div className="row">
          <div
            className="card border-0 shadow-sm p-4 radius-16 mb-4"
            hidden={
              ContentDetails[0]?.Facebook_Content == "" ||
              ContentDetails[0]?.Facebook_Content == null
            }
          >
            <div className="d-flex align-items-center justify-content-between mb-4 border-bottom pb-4">
              <p
                className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
                htmlFor="fbHighlight"
              >
                <img src="images/facebook-post-icon.svg" />
                Facebook post
              </p>
              <span className="d-flex align-items-center fon">
                <button
                  className="edit-btn"
                  hidden={
                    ContentDetails[0]?.Facebook_Post_Status == "Published"
                  }
                >
                  {!faceBook ? (
                    <button
                      className="edit-btn"
                      onClick={() => {
                        setfacebook(true);
                        facebookRef?.current?.focus();
                      }}
                    >
                      <img src="images/edit-black.svg" alt="table-edit" />
                    </button>
                  ) : (
                    <button
                      className="edit-btn"
                      onClick={() => {
                        savePostContent("Facebook_Content = ? ");
                      }}
                    >
                      <img src="images/tick.svg" alt="table-edit" />
                    </button>
                  )}
                </button>
                <button
                  hidden={
                    ContentDetails[0]?.Facebook_Post_Status == "Published"
                  }
                  className="edit-btn"
                  onClick={() => {
                    generateSocialMedia("facebook");
                  }}
                >
                  <img src="images/refresh.svg" alt="table-edit" />
                </button>
              </span>
            </div>
            <div className="d-flex align-items-center gap-2 mb-3">
              <span className="post-icon">
                <img src="images/asnap-icon.svg" alt="cloudgen-icon" />
              </span>
              <span>
                <span className="d-block font-bold font-16">Asnap</span>
                <span className="d-block  font-medium font-12 color-grey">
                  Today at 12:04 pm
                </span>
              </span>
            </div>
            <p
  className="font-14 font-bold color-grey"
  ref={facebookRef}
  contentEditable={faceBook}
  dangerouslySetInnerHTML={{
    __html: ContentDetails[0]?.Facebook_Content
      ? emojiConvertor.replace_colons(
          ContentDetails[0]?.Facebook_Content.replace(
            /\\u([0-9a-fA-F]{4})|\\U([0-9a-fA-F]{8})/g,
            (match, grp1, grp2) => {
              if (grp1) {
                return String.fromCharCode(parseInt(grp1, 16));
              } else if (grp2) {
                return String.fromCodePoint(parseInt(grp2, 16));
              }
              return match; // Return the original match if not replaced
            }
          ).replace(/\\n\\n/g, "<br>") // Replace \\n\\n with <br>
        )
      : "",
  }}
></p>




            <div className="img-container-article mb-4 position-relative">
              {(ContentDetails[0]?.Facebook_Post_Image_Url) ? <img
                src={ContentDetails[0]?.Facebook_Post_Image_Url}
                alt="ai-gen-img"
                className="img-article"
              /> : null}
              <div className="dropdown position-absolute img-action">
                <button
                  className=" border-0 bg-transparent "
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="images/img-action-icon.svg" alt="img-action-icon" />
                </button>
                <ul
                  className="dropdown-menu img-action-drop"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li
                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                    onClick={() => {
                      regeneratePlatformImage("facebook");
                    }}
                  >
                    <img src="images/regen-icon.svg" />
                    Regenerate
                  </li>
                  <li className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2" onClick={()=>{
                    deleteImage("facebook")
                  }}>
                    <img src="images/delete-black-icon.svg" />
                   
                    Delete
                  </li>
                  <li
                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                    onClick={() => [
                      document.getElementById("imageClick").click(),
                    ]}
                  >
                    <img src="images/upload-black-icon.svg" />
                    <input
                      type="file"
                      accept="image/*"
                      name=""
                      id="imageClick"
                      onChange={(e) => {
                        handleFileInputChange(e, "facebook");
                      }}
                      style={{ display: "none" }}
                    />
                    Upload
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {ContentDetails[0]?.Facebook_Post_Status == "Published" ? (
               <button
               className="btn cust-btn-success font-bold font-16 text-nowrap  box-shadow-none"
             >
               Published
             </button>
              ) : (
                <button
                  className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                  onClick={() => {
                    postFaceBook();
                  }}
                >
                  Post
                </button>
              )}
            </div>
          </div>
          <div
            className="card border-0 shadow-sm p-4 radius-16 mb-4"
            hidden={
              ContentDetails[0]?.Linkedin_Content == "" ||
              ContentDetails[0]?.Linkedin_Content == null
            }
          >
            <div className="d-flex align-items-center justify-content-between mb-4 border-bottom pb-4">
              <p
                className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
                htmlFor="fbHighlight"
              >
                <img src="images/linkedin-post-icon.svg" />
                linkedin post
              </p>
              <span className="d-flex align-items-center fon">
                <button
                  className="edit-btn"
                  hidden={
                    ContentDetails[0]?.Linkedin_Content_Post_Status ==
                    "Published"
                  }
                >
                  {!linkedin ? (
                    <button
                      className="edit-btn"
                      onClick={() => {
                        setLinkedin(true);
                        linkedinRef?.current?.focus();
                      }}
                    >
                      <img src="images/edit-black.svg" alt="table-edit" />
                    </button>
                  ) : (
                    <button
                      className="edit-btn"
                      onClick={() => {
                        savePostContent("Linkedin_Content = ? ");
                      }}
                    >
                      <img src="images/tick.svg" alt="table-edit" />
                    </button>
                  )}
                </button>
                <button
                  className="edit-btn"
                  hidden={
                    ContentDetails[0]?.Linkedin_Content_Post_Status ==
                    "Published"
                  }
                >
                  <img
                    src="images/refresh.svg"
                    alt="table-edit"
                    onClick={() => {
                      generateSocialMedia("linkedin", 1);
                    }}
                  />
                </button>
              </span>
            </div>
            <div className="d-flex align-items-center gap-2 mb-3">
              <span className="post-icon">
                <img src="images/asnap-icon.svg" alt="cloudgen-icon" />
              </span>
              <span>
                <span className="d-block font-bold font-16">Asnap</span>
                <span className="d-block  font-medium font-12 color-grey">
                  Today at 12:04 pm
                </span>
              </span>
            </div>
            <p
  className="font-14 font-bold color-grey"
  ref={linkedinRef} // Assuming you have a ref for LinkedIn similar to facebookRef for Facebook
  contentEditable={linkedin}
  dangerouslySetInnerHTML={{
    __html: ContentDetails[0]?.Linkedin_Content
      ? emojiConvertor.replace_colons(ContentDetails[0]?.Linkedin_Content.replace(
          /\\U([0-9a-fA-F]{8})|\\u([0-9a-fA-F]{4})/g,
          (match, grp, grp2) => {
            if (grp) {
              return String.fromCodePoint(parseInt(grp, 16));
            } else if (grp2) {
              return String.fromCodePoint(parseInt(grp2, 16));
            }
            return match;
          }
        ).replace(/\\n\\n/g, "<br>")) // Replace \\n\\n with <br>
      : "",
  }}
></p>

            <div className="img-container-article mb-4 position-relative">
              <img
                src={ContentDetails[0]?.Linked_Post_Image_Url}
                alt="ai-gen-img"
                className="img-article"
              />
              <div className="dropdown position-absolute img-action">
                <button
                  className=" border-0 bg-transparent "
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="images/img-action-icon.svg" alt="img-action-icon" />
                </button>
                <ul
                  className="dropdown-menu img-action-drop"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2">
                    <img
                      src="images/regen-icon.svg"
                      onClick={() => {
                        regeneratePlatformImage("linkedin");
                      }}
                    />
                    Regenerate
                  </li>
                  <li className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2">
                    <img src="images/delete-black-icon.svg" />
                    <input
                      type="file"
                      accept="image/*"
                      name=""
                      id="imageClick"
                      onChange={(e) => {
                        handleFileInputChange(e, null);
                      }}
                      style={{ display: "none" }}
                    />
                    Delete
                  </li>
                  <li className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2">
                    <img src="images/upload-black-icon.svg" />
                    <input
                      type="file"
                      accept="image/*"
                      name=""
                      id="imageClick"
                      onChange={(e) => {
                        handleFileInputChange(e, "linkedin");
                      }}
                      style={{ display: "none" }}
                    />
                    Upload
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {ContentDetails[0]?.Linkedin_Post_Status == "Published" ? (
                <button
                className="btn cust-btn-success font-bold font-16 text-nowrap  box-shadow-none"
              >
                Published
 
                </button>
              ) : (
                <button
                  className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                  onClick={() => {
                    postLinkedin();
                  }}
                >
                  Post
                </button>
              )}
            </div>
          </div>
          <div
            className="card border-0 shadow-sm p-4 radius-16 mb-4"
            hidden={
              ContentDetails[0]?.Instagram_Content == "" ||
              ContentDetails[0]?.Instagram_Content == null
            }
          >
            <div className="d-flex align-items-center justify-content-between mb-4 border-bottom pb-4">
              <p
                className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
                htmlFor="fbHighlight"
              >
                <img src="images/insta-caption-icon.svg" />
                Instagram Caption
              </p>
              <span className="d-flex align-items-center fon">
                <button
                  className="edit-btn"
                  hidden={
                    ContentDetails[0]?.Instagram_Post_Status == "Published"
                  }
                >
                  {!instagram ? (
                    <button
                      className="edit-btn"
                      onClick={() => {
                        setInstagram(true);
                        instagramRef?.current?.focus();
                      }}
                    >
                      <img src="images/edit-black.svg" alt="table-edit" />
                    </button>
                  ) : (
                    <button
                      className="edit-btn"
                      onClick={() => {
                        savePostContent("Instagram_Content = ? ");
                      }}
                    >
                      <img src="images/tick.svg" alt="table-edit" />
                    </button>
                  )}
                </button>
                <button
                  className="edit-btn"
                  hidden={
                    ContentDetails[0]?.Instagram_Post_Status == "Published"
                  }
                >
                  <img
                    src="images/refresh.svg"
                    alt="table-edit"
                    onClick={() => {
                      generateSocialMedia("instagram");
                    }}
                  />
                </button>
              </span>
            </div>
            <div className="d-flex align-items-center gap-2 mb-3">
              <span className="post-icon">
                <img src="images/asnap-icon.svg" alt="cloudgen-icon" />
              </span>
              <span>
                <span className="d-block font-bold font-16">Asnap</span>
                <span className="d-block  font-medium font-12 color-grey">
                  Today at 12:04 pm
                </span>
              </span>
            </div>
            <p
  className="font-14 font-bold color-grey"
  ref={instagramRef} // Assuming you have a ref for Instagram similar to facebookRef for Facebook
  contentEditable={instagram}
  dangerouslySetInnerHTML={{
    __html: ContentDetails[0]?.Instagram_Content
      ? emojiConvertor.replace_colons(
          ContentDetails[0]?.Instagram_Content.replace(
            /\\u([0-9a-fA-F]{4})|\\U([0-9a-fA-F]{8})/g,
            (match, grp1, grp2) => {
              if (grp1) {
                return String.fromCharCode(parseInt(grp1, 16));
              } else if (grp2) {
                return String.fromCodePoint(parseInt(grp2, 16));
              }
              return match; // Return the original match if not replaced
            }
          ).replace(/\\n\\n/g, "<br>") // Replace \\n\\n with <br>
        )
      : "",
  }}
></p>


            <div className="img-container-article mb-4 position-relative">
              <img
                src={ContentDetails[0]?.Instagram_Post_Image_Url}
                alt="ai-gen-img"
                className="img-article"
              />
              <div className="dropdown position-absolute img-action">
                <button
                  className=" border-0 bg-transparent "
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="images/img-action-icon.svg"
                    alt="img-action-icon"
                    onClick={() => {
                      regeneratePlatformImage("instagram");
                    }}
                  />
                </button>
                <ul
                  className="dropdown-menu img-action-drop"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2">
                    <img src="images/regen-icon.svg" />
                    Regenerate
                  </li>
                  <li className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2">
                    <img src="images/delete-black-icon.svg" />
                    <input
                      type="file"
                      accept="image/*"
                      name=""
                      id="imageClick"
                      onChange={(e) => {
                        handleFileInputChange(e, null);
                      }}
                      style={{ display: "none" }}
                    />
                    Delete
                  </li>
                  <li className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2">
                    <img src="images/upload-black-icon.svg" />
                    <input
                      type="file"
                      accept="image/*"
                      name=""
                      id="imageClick"
                      onChange={(e) => {
                        handleFileInputChange(e, "instagram");
                      }}
                      style={{ display: "none" }}
                    />
                    Upload
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {ContentDetails[0]?.Instagram_Post_Status == "Published" ? (
                <button
                className="btn cust-btn-success font-bold font-16 text-nowrap  box-shadow-none">
                Published
                </button>
              ) : (
                <button
                  className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                  onClick={() => {
                    postInstagram();
                  }}
                >
                  Post
                </button>
              )}
            </div>
          </div>
          <div
            className="card border-0 shadow-sm p-4 radius-16 mb-4"
            hidden={
              ContentDetails[0]?.Twitter_Content == "" ||
              ContentDetails[0]?.Twitter_Content == null
            }
          >
            <div className="d-flex align-items-center justify-content-between mb-4 border-bottom pb-4">
              <p
                className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
                htmlFor="fbHighlight"
              >
                <img src="images/twitter-post-icon.svg" />
                Twitter Post
              </p>
              <span className="d-flex align-items-center fon">
                <button
                  className="edit-btn"
                  hidden={ContentDetails[0]?.Twitter_Post_Status == "Published"}
                >
                  {!twitter ? (
                    <button
                      className="edit-btn"
                      onClick={() => {
                        setTwitter(true);
                        twitterRef?.current?.focus();
                      }}
                    >
                      <img src="images/edit-black.svg" alt="table-edit" />
                    </button>
                  ) : (
                    <button
                      className="edit-btn"
                      onClick={() => {
                        savePostContent("Twitter_Content = ? ");
                      }}
                    >
                      <img src="images/tick.svg" alt="table-edit" />
                    </button>
                  )}
                </button>
                <button
                  className="edit-btn"
                  hidden={ContentDetails[0]?.Twitter_Post_Status == "Published"}
                >
                  <img
                    src="images/refresh.svg"
                    alt="table-edit"
                    onClick={() => {
                      generateSocialMedia("twitter");
                    }}
                  />
                </button>
              </span>
            </div>
            <div className="d-flex align-items-center gap-2 mb-3">
              <span className="post-icon">
                <img src="images/asnap-icon.svg" alt="cloudgen-icon" />
              </span>
              <span>
                <span className="d-block font-bold font-16">Asnap</span>
                <span className="d-block  font-medium font-12 color-grey">
                  Today at 12:04 pm
                </span>
              </span>
            </div>

            <p
  className="font-14 font-bold color-grey"
  ref={twitterRef} // Assuming you have a ref for Twitter similar to facebookRef for Facebook
  contentEditable={twitter}
  dangerouslySetInnerHTML={{
    __html: ContentDetails[0]?.Twitter_Content
      ? emojiConvertor.replace_colons(
          ContentDetails[0]?.Twitter_Content.replace(
            /\\u([0-9a-fA-F]{4})|\\U([0-9a-fA-F]{8})/g,
            (match, grp1, grp2) => {
              if (grp1) {
                return String.fromCharCode(parseInt(grp1, 16));
              } else if (grp2) {
                return String.fromCodePoint(parseInt(grp2, 16));
              }
              return match; // Return the original match if not replaced
            }
          ).replace(/\\n\\n/g, "<br>") // Replace \\n\\n with <br>
        )
      : "",
  }}
></p>

            <div className="img-container-article mb-4 position-relative">
              <img
                src={ContentDetails[0]?.Twitter_Post_Image_Url}
                alt="ai-gen-img"
                className="img-article"
              />
              <div className="dropdown position-absolute img-action">
                <button
                  className=" border-0 bg-transparent "
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="images/img-action-icon.svg"
                    alt="img-action-icon"
                    onClick={() => {
                      regeneratePlatformImage("twitter");
                    }}
                  />
                </button>
                <ul
                  className="dropdown-menu img-action-drop"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2">
                    <img src="images/regen-icon.svg" />
                    Regenerate
                  </li>
                  <li className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2">
                    <img src="images/delete-black-icon.svg" />
                    <input
                      type="file"
                      accept="image/*"
                      name=""
                      id="imageClick"
                      onChange={(e) => {
                        handleFileInputChange(e, null);
                      }}
                      style={{ display: "none" }}
                    />
                    Delete
                  </li>
                  <li className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2">
                    <img src="images/upload-black-icon.svg" />
                    <input
                      type="file"
                      accept="image/*"
                      name=""
                      id="imageClick"
                      onChange={(e) => {
                        handleFileInputChange(e, "twitter");
                      }}
                      style={{ display: "none" }}
                    />
                    Upload
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {ContentDetails[0]?.Twitter_Post_Status == "Published" ? (
                <button
                className="btn cust-btn-success font-bold font-16 text-nowrap  box-shadow-none"
              >
                Published
 
                </button>
              ) : (
                <button
                  className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                  onClick={() => {
                    postTwitter();
                  }}
                >
                  Post
                </button>
              )}
            </div>
          </div>
          <div
            className="card border-0 shadow-sm p-4 radius-16 mb-4"
            hidden={
              ContentDetails[0]?.Social_Media_Image_Url == "" ||
              ContentDetails[0]?.Social_Media_Image_Url == null
            }
          >
            <div className="d-flex align-items-center justify-content-between mb-4">
              <p
                className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
                htmlFor="fbHighlight"
              >
                Social Media Image
              </p>
              <span className="d-flex align-items-center fon">
                <button
                  className="edit-btn"
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  <img src="images/download-icon.svg" alt="table-edit" />
                </button>
                <button className="edit-btn">
                  <img
                    src="images/refresh.svg"
                    alt="table-edit"
                    onClick={() => {
                      generateSocialMedia("socialmediaImage");
                    }}
                  />
                </button>
              </span>
            </div>
            <img
              src={ContentDetails[0]?.Social_Media_Image_Url}
              alt="ai-gen-img"
              className="social-media-gen mb-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}