import RoutesComponent from "./routes/RoutesComponent";
import { CommonContextProvider } from "./contexts/CommonContext";
import { CreateProductContextProvider } from "./contexts/CreateProductContext";
import { CreateContentContextProvider } from "./contexts/CreateContentContext";
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {

  if (localStorage.getItem("enableLogs") == 0) {
    console.log = function () { };
    console.table = function () { };
    console.info = function () { };
    console.error = function () { };
    console.warn = function () { };
  }





  return (
    <CommonContextProvider>
      <div class="container-fluid">
        <div class="row">
          <RoutesComponent />
        </div>
      </div>
    </CommonContextProvider>
  );
}

export default App;
