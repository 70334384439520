import { useEffect, useState } from "react";
import { insert_feature_recommendation } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";
import { useCreateProductContext } from "../../contexts/CreateProductContext";


export default function FeatureRecommondations() {
  const [recommondationArray, setRecommondationArray] = useState([]);
  const { userData } = useCommonContext();
  const { productId } = useCreateProductContext();


  useEffect(() => {
    getFeatureRecommondation();
  }, []);

  async function getFeatureRecommondation() {
    try {
      const payload = {
        productId: productId,
        userId: userData[0]["User_Id"]
      };

      const responseData = await insert_feature_recommendation(payload);
      console.log(responseData.data.data, "get the feature_recommendation1");
      console.log(responseData.data.data.recommendation_feature, "get the feature_recommendation2");
      setRecommondationArray(responseData.data.data.recommendation_feature);
    } catch (error) {
      console.log(error.message);
    }
  }



  return (
    <>
      <h2 className="font-bold font-20 primary-darkblue">Recommendations</h2>
      <div className="table-responsive theme-tbl p-0 c-faeture-grid my-3 border-0 rounded-0">
        <table className="table table-borderless">
          <thead className="weight-500">
            <tr>
              <th className="weight-500">Description</th>
              <th className="weight-500">Based on</th>
              <th className="weight-500">Impact</th>
            </tr>
          </thead>
          <tbody className="font-medium">
            {recommondationArray.map((value, index) => {
              return (
                <tr>
                  <td>{value.Description}</td>
                  <td className="py-4">
                    <img src={value["Based on"]} alt="alt img" height={"20px"} />
                  </td>
                  {/* <td>
                    {value["Based on"]}
                  </td> */}
                  <td className="text-center">
                    <span className={`${value["Impact"]}-b d-inline-block`}>
                      <span className="d-flex align-items-center">
                        <span className={`${value["Impact"]}`}></span>
                        <span className="font-10 font-medium ms-1">
                          {value["Impact"]}
                        </span>
                      </span>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
