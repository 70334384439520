import { useCommonContext } from "../../contexts/CommonContext";
import { useCreateContentContext } from "../../contexts/CreateContentContext";
import { askAiApi } from "../../services/api";
export default function AskAi() {
  const {
    isAskAiOpened,
 
    askAiPromptText,
    setAskAiPromptText,
    setIsAskAiResponseReturned,
    setAskAiResponsetext,
    selectedText,
    position,
    setIsAskAiOpened,
    isAskAiPrompt,
    setIsAskAiPrompt,
    isAskresult,
    setIsAskResult,
  } = useCreateContentContext();
 
  const { userData } = useCommonContext();
  console.info("posotion", position);
  async function askAi() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        prompt: askAiPromptText,
        selectedText: selectedText,
      };
 
      const responseData = await askAiApi(payload);
 
      setAskAiResponsetext(responseData.data.data);
      setIsAskAiResponseReturned(true);
      setIsAskAiOpened(true);
      setIsAskResult(false);
    } catch (error) {
      console.log(error.message);
    }
  }
 
  return (
    <span
      className="input-group mb-3 shadow-sm position-absolute ask-ai-input"
      // data-bs-toggle="dropdown"
      hidden={isAskAiPrompt}
      style={{
        top: `${position.y + 30}px`,
        // height: "30px",
        width: "46%",
        right: "104px",
      }}
    >
      <span className="input-group-text bg-white border-0">
        <img src="images/magic-wizard-icon.svg" alt="magic-wizard-icon" />
      </span>
      <input
        type="text"
        className="form-control box-shadow-none border-0 font-regular font-16 color-grey"
        placeholder="Ask Seraphine AI"
        onChange={(e) => {
          setAskAiPromptText(e.target.value);
        }}
        value={askAiPromptText}
      />
      <button
        className="transp-btn bg-white border-0 rounded-3"
        onClick={() => {
          askAi();
        }}
      >
        <img src="images/send-btn-plain.svg" alt="send-btn-plain" />
      </button>
    </span>
  );
}