//ComparisonResult_140
import { useEffect, useState } from "react";
import { insert_roadmap_recommendation } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";
import { useCreateProductContext } from "../../contexts/CreateProductContext";

export function ProductRoadMapRecommendations() {
  const [recommendationArray, setRecommendationArray] = useState([]);
  const { userData } = useCommonContext();
  // ComparisonResult_141
  const { productId } = useCreateProductContext();

 // ComparisonResult_144
  useEffect(() => {
    getRoadmapRecommendations();
  }, []);

  //ComparisonResult_145

  async function getRoadmapRecommendations() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        productId: productId
      };
      //ComparisonResult_147
      const responseData = await insert_roadmap_recommendation(payload);
      console.log(responseData.data.data, "get the roadmap_recommendations1");
      console.log(responseData.data.data.recommendation_feature, "get the roadmap_recommendations2");
      setRecommendationArray(responseData.data.data.recommendation_feature);
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>
      <h2 className="font-bold font-20 primary-darkblue">Recommendations</h2>
      <div className="table-responsive theme-tbl p-0 c-faeture-grid my-3 border-0">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Description</th>
              <th>Based on</th>
              <th className="text-center">Impact</th>
            </tr>
          </thead>
          <tbody className="font-medium">
            {recommendationArray.map((value, index) => {
              return (
                <tr>
                  <td>{value.Description}</td>
                  <td>
                    <img src={value["Based on"]} alt="alt img" height={"20px"} />
                  </td>
                  <td className="text-center">
                    <span className={`${value["Impact"]}-b d-inline-block`}>
                      <span className="d-flex align-items-center">
                        <span className={`${value["Impact"]}`}></span>
                        <span className="font-10 font-medium ms-1">
                          {value["Impact"]}
                        </span>
                      </span>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}