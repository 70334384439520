import Agenda from "./Agenda";
import DateAndDay from "./DateAndDay";
import DateNavigationButtons from "./DateNavigationButtons";
import DayMonthToggle from "./DayMonthToggle";

export default function EventHeader() {
  return (
    <div className="d-flex justify-content-between sticky-top bg-white">
      <DateAndDay />
      <div className="d-flex align-items-center">
        <Agenda />
        <DateNavigationButtons />
        <DayMonthToggle />
      </div>
    </div>
  );
}
