import React, { useContext, useEffect, useState } from "react";
import { deleteContentApi, getUserDataApi } from "../services/api";
import { jwtDecode } from "jwt-decode";
// import { useNavigate } from "react-router-dom";

const CommonContext = React.createContext();

export function CommonContextProvider({ children }) {
  const [userData, setUserData] = useState([
    {
      User_Id: "",
      Firstname: "",
      Lastname: "",
      Email_Id: "",
      Role_Id: "",
      Reviewer_Id: null,
      Role_Name: "",
      Profile_Image_Url: "",
    },
  ]);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastImage, setToastImage] = useState("");
  const [deleteContentId, setDeleteContentId] = useState("");
  const [NavigationTest, SetNavigationTest] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const [isTokenToast, setIsTokenToast] = useState(true)
  const [isNotUser, setIsNotUser] = useState(true)

  // const navigate = useNavigate();

  const getUserData = async () => {
    try {
      if (localStorage.getItem("token")) {
        let userId = jwtDecode(localStorage.getItem("token"))["User_Id"];
        console.log(userId, "From context");
        const payload = {
          userId,
        };
        const responseData = await getUserDataApi(payload);
        setUserData(responseData.data.data);
        return responseData.data.data;
      }
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  async function contentDelete() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        contentId: deleteContentId,
      };

      const responseData = await deleteContentApi(payload);

      setDeleteContentId("");
    } catch (error) {
      console.error(error.message);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  const verifyJwtData = (key) => {
    return userData[0][key];
  };

  function openToast(message, image) {

    setIsToastOpen(true);
    setToastImage(image);
    setToastMessage(message);

    let messageArray = [
      "This Product Id does not belong to this  User Id",
      "Invalid email format",
      "Email does not exist",
      "Invalid credentials",
      "Emailid already exists",
      "Email is not in a correct format",
      "Password must be at least 8 characters long",
      "Invalid credentials",
      "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character.",
      "The new password cannot be the same as any of the old passwords.",
      "Invalid email format",
      "Password must be at least 8 characters long and contain at least one letter, one number, and one special character",
      "Invalid email format",
      "Password must be at least 8 characters long and contain at least one letter, one number, and one special character",
      "Email Id already exists",
      "Password must be at least 8 characters long and contain at least one letter, one number, and one special character",
      "Email Id already exists",
      "Email is not in a correct format",
      "Old password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character.",
      "New password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character.",
      "Old password is incorrect",
      "The new password cannot be the same as any of the old passwords.",
      "The event doesnot belong to the user",
      "Unable to create meeting in Teams",
      "Unable to create meeting in Google Meet",
      "Invalid platform specified",
      'token is required',
      "Token expired",
      "Invalid token",
      "Exception occured while validating token",
      "session expired",
      "Your password has been updated. Please log in again to continue.",
      "The content doesnot belong to the user",
      "The account doesnot belong to the user",
      "Error in inserting details into the Content table",
      "Request body doesn't contain the Event_Id missing keys",
      "Error in updating Social media content  for the particular Social_Media_Content_Id",
      "Invalid credentials",
      "The account doesnot belong to the user",
      "Error in updating details for the particular content id",
      "Error in posting the post to Facebook",
      "The Account id passed is invalid error in fetching the account creds",
      "Image URL is None",
      "Error in posting the post to the twitter",
      "The Account Id is passed is invalid error in fetching the account creds",
      "Error in posting the Instagram content",
      "There is a problem in fetching the Instagram id for the account creds",
      "Unable to fetch the creds for the account Id",
      "The content doesnot belong to the user",
      "Token is missing",
      "session expired",
      "Your password has been updated. Please log in again to continue.",
      "Unauthorized access: User_Id mismatch between JWT token and request body",
      "You do not have permission to access this resource",
      "Token expired",
      "Token invalid",
      "Token is missing",
      "Token expired",
      "Token invalid",
      "User deleted successfully",
      "data fetched successfully",
      "Data fetched successfully",
      "Pdf created successfully",
      "User inserted successfully",
      "User updated successfully",
      "Logout success",
      "Error logging out",
      "Users fetched successfully",
      'Email sent successfully',
      'Post fetched successfully',
      'Data fetched successfully',
      'Post fetched successfully',
      'Data fetched successfully',
      'Login success',
      'Count fetched successfully',
      'Password updated successfully',
      'data inserted successfully',
      'data inserted successfully',
      'updated successfully',
      "Content created successfully",
      'Post generated successfully',
      'Image generated and updated successfully',
      'Content generated successfully',
      'data fetched successfully',
      'data fetched successfully',
      'data updated successfully',
      'data updated successfully',
      'data inserted successfully',
      "session expired",
      "Your password has been updated. Please log in again to continue.",
      "My events fetched successfully",
      "Token is missing",
      "Unauthorized access: User_Id mismatch between JWT token and request body",
      "You do not have permission to access this resource",
      "Token expired",
      "Token invalid",
      "Successfully added the platform",
      "Account created successfully",
      "Account Deleted successfully", "Account Updated successfully", "Successfully Edited the platform", "Error Inserted Successfully", "My events fetched successfully", "Notification fetched successfully",
      "Request fetched successfully",
      "Social Media Content fetched successfully",
      "Details updated in database",
      "Social Media Content posted successfully",
      "Request submitted successfully",
      'Details updated in the database',
      'Unable to post the Instagram content',
      "Unauthorized access: User_Id mismatch between JWT token and request body",
      "You do not have permission to access this resource", "Competitor URL is missing",
      "Competitors inserted successfully",
      "Product created successfully",
      "Product updated successfully",
      "data deleted successfully",
      "Generated successfully",
      "Products fetched successfully",
      "Pricing fetched successfully",
      "Generated all the features successfully",
      "Competitors fetched successfully",
      "Comparison metrics fetched successfully",
      "Competitors Feature table Inserted successfully",
      "Competitors inserted successfully",
      "Competitors Pricing table Inserted successfully",
      "Competitors inserted successfully",
      "data inserted successfully",
      "Feature saved successfully",
      "data inserted successfully",
      'Invalid Credentials',
      "Old password and new password can't be the same",
      "Email Sent Succesfully"
    ]

    // console.info(messageArray.includes(message), message, "thsisisiis")
    if ((!messageArray.includes(message))&& image == "images/red-cross-toast.svg") {
      setToastMessage("Something went wrong...")
    }

    setTimeout(() => {
      setIsToastOpen(false);
      setToastImage("");
      setToastMessage("");
    }, 5000);
  }

  return (
    <>
      <CommonContext.Provider
        value={{
          userData,
          setUserData,
          verifyJwtData,
          openToast,
          getUserData,
          deleteContentId,
          setDeleteContentId,
          contentDelete,
          NavigationTest,
          SetNavigationTest,
          isNavCollapsed, setIsNavCollapsed,
          isNotUser, setIsNotUser
        }}
      >
        {children}
      </CommonContext.Provider>
      {isToastOpen ? (
        <div
          className="dark-toast font-bold d-flex align-items-center gap-2"
          id={"toastMessage"}
        >
          <img src={toastImage} alt="toast-check" id="toastImage" />
          {toastMessage}
        </div>
      ) : null}

      <div
        className=""
        id={"toastMessages"}
        style={{ display: "none" }}
      >


      </div>

    </>
  );
}

export function useCommonContext() {
  return useContext(CommonContext);
}
