import { useCreateContentContext } from "../../contexts/CreateContentContext";

export default function AskAiPrompts() {
  const {
    isAskAiOpened,
    askAiPromptText,
    setAskAiPromptText,
    position,
    isAskAiPrompt,
    setIsAskAiPrompt,
  } = useCreateContentContext();

  return (
    <span
      className="ask-ai-drop position-absolute"
      hidden={isAskAiOpened}
      style={{
        // left: `${position.x}px`,
        top: `${position.y + 77}px`,
        left: "47%",
      }}
    >
      <span className="d-block p-2">Edit or review selection</span>
      <span
        className="d-flex align-items-center p-2 gap-3 ask-drop-list cursor-pointer rounded-3"
        onClick={() => {
          setAskAiPromptText("Rephrase text");
        }}
      >
        <img src="images/rephrase-text-icon.svg" alt="rephrase-text-icon" />
        Rephrase text
      </span>
      <span
        className="d-flex align-items-center p-2 gap-3 ask-drop-list cursor-pointer rounded-3"
        onClick={() => {
          setAskAiPromptText("Improve writing");
        }}
      >
        <img src="images/improve-writing-icon.svg" alt="improve-writing-icon" />
        Improve writing
      </span>
      <span
        className="d-flex align-items-center p-2 gap-3 ask-drop-list cursor-pointer rounded-3"
        onClick={() => {
          setAskAiPromptText("Make longer");
        }}
      >
        <img src="images/make-longer-icon.svg" alt="make-longer-icon" />
        Make longer
      </span>
      <span
        className="d-flex align-items-center p-2 gap-3 ask-drop-list cursor-pointer rounded-3"
        onClick={() => {
          setAskAiPromptText("Make shorter");
        }}
      >
        <img src="images/make-shoter.svg" alt="make-shoter" />
        Make shorter
      </span>
      <span
        className="d-flex align-items-center p-2 gap-3 ask-drop-list cursor-pointer rounded-3"
        onClick={() => {
          setAskAiPromptText("Simplify");
        }}
      >
        <img src="images/simplify-icon.svg" alt="simplify-icon" />
        Simplify
      </span>
    </span>
  );
}
