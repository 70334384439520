import { useNavigate, useLocation } from "react-router-dom";
// import CompetitorInfo from "./CompetitorInfo";
// import ProductInfo from "./ProductInfo";
import React, { useEffect, useState } from "react";

import Loader from "../Loader/Loader";

import {
  getProductbyIdApi,
  getAllCompetitorsApi,
  addCompetitorsManuallyApi,
  createProductApi,
  selectCompetitorApi,
  insertCompetitorsApi, insertHtmlApi, insert_nameApi, insert_overviewApi, insert_featureApi, insert_pricingApi, insert_feature_tableApi, insert_pricing_tableApi, insert_product_azure_review_tableApi,
  insert_product_aws_review_tableApi, insert_competitor_azure_reviewApi,
  insert_competitor_aws_reviewApi, insert_competitor_roadmap_tableApi
  // check_count,
} from "../../services/api";
import Competitors from "./Competitors";
import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { useCommonContext } from "../../contexts/CommonContext";

export default function Product() {
  const navigate = useNavigate();

  const [count, setCount] = useState(4);
  const [rowCount, setRowCount] = useState();

  const [competitorname, setCompetitorname] = useState("");
  const [competitorurl, setCompetitorurl] = useState("");

  // const [productlogoname, setProductlogoname] = useState('');

  const [showCompetitorInfo, setShowCompetitorInfo] = useState(false); //for the comp info comp jsx and Next btn
  const [showProductInfo, setshowProductInfo] = useState(false); // for the comp info for the search and cancel btn

  const [isOpen, setIsOpen] = useState(true);

  const [isLoading, setIsLoading] = useState(false);


  const {
    setCompetitordata,
    selectedCompetitors,
    setSelectedCompetitors,
    productId,
    name,
    productlogo,
    productlogoname,
    overview,
    keywords,
    producturl,
    setName,
    setProductlogo,
    setProductlogoname,
    setOverview,
    setKeywords,
    setProducturl,
    getProductbyId,
    resetProductForm,
    selected,
    setSelected,
    competitordata,
    filteredCompetitors,
  } = useCreateProductContext();
  const { userData, isNavCollapsed } = useCommonContext();

  const location = useLocation();
  console.log(filteredCompetitors, "filter..............");
  console.log(filteredCompetitors.length, "lengthhhhhh");
  console.log(competitordata, "ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo");

  console.log(productlogoname, "loggggggggggggggggoooooooooooooname");

  console.log(
    productId,
    "get the prodid from uselocation and set in the useEffect"
  );

  console.log(
    selectedCompetitors,
    "check the selectedCompetitors Id in the array"
  );

  console.log(productlogo, "rwelogooooooooooooooooooooo");

  useEffect(() => {
    console.log(
      productId,
      "rwfefeaf................................................"
    );
    console.log(userData[0]["User_Id"], "useridddddddddddddddddddddd");
    if (productId !== null && productId !== "") {
      setShowCompetitorInfo(true);
      setshowProductInfo(false);
      getProductbyId(productId);
      // get_all_competitors(productId);
      if (!isLoading) {
        get_all_competitors(productId);
      }
      console.log("Existing user");
    } else {
      console.log("You are a New User");
      setShowCompetitorInfo(false);
      setshowProductInfo(true);
      resetProductForm();
    }
  }, [count]);

  const get_all_competitors = async (productId) => {
    try {
      const payload = {
        productId: productId,
        userId: userData[0]["User_Id"],
        dataCount: count,
      };
      setIsLoading(true)
      const responseData = await getAllCompetitorsApi(payload);
      setIsLoading(false)
      console.log(responseData.data, "getAll competitors");
      setCompetitordata(responseData.data.data);

      for (let values of responseData.data.data) {
        let oldArray = selectedCompetitors;

        if (values.Is_Selected) {
          if (!oldArray.includes(values.Competitor_Id)) {
            oldArray.push(values.Competitor_Id);
          }
        }
        setSelectedCompetitors(oldArray);
      }

      setRowCount(responseData.data.data[0].Total_Row_Count);
    } catch (error) {
      console.log(error.message);
    }
  };

  const showmore = () => {
    console.log(rowCount, count);
    if (filteredCompetitors.length > count) {
      setCount(prevCount => prevCount + 10);
    }
    // if (rowCount > count) {
    //   const remainingCount = rowCount - count; // Calculate the remaining count
    //   setCount((count) => count + remainingCount); // Update the count state
    // }
  };

  const addCompetitorsManually = async () => {
    try {
      const payload = {
        productId: productId,
        userId: userData[0]["User_Id"],
        competitorName: competitorname,
        competitorUrl: competitorurl,
      };

      const responseData = await addCompetitorsManuallyApi(payload);
      console.log(responseData, "Inserted Successfully");
      get_all_competitors(productId);
    } catch (error) {
      console.log(error.message);
    }

    setCompetitorname("");
    setCompetitorurl("");
  };

  const createProduct = async () => {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        productName: name,
        productLogo: productlogo,
        productLogoname: productlogoname,
        productOverview: overview,
        productKeywords: selected.join(", "),
        productUrl: producturl
      };

      const responseData = await createProductApi(payload);
      console.log(
        responseData.data.data.productId,
        "Inserted and generate ProductId"
      );
      location.state.productId = responseData.data.data.productId;
      setshowProductInfo(false);

      setIsLoading(true)
      await insertCompetitor(responseData.data.data.productId);
      await get_all_competitors(responseData.data.data.productId);
      setIsLoading(false)
      setShowCompetitorInfo(true);

    } catch (error) {
      console.log(error.message);
    }
  };

  const selectCompetitor = async () => {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        productId: productId,
        competitorIdList: selectedCompetitors,
      };

      const responseData = await selectCompetitorApi(payload);
      console.log(responseData, "selectCompetitor - response");
      console.log(
        selectedCompetitors,
        "testtttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt"
      );
      // setSelectedCompetitors(responseData.data)
      setShowCompetitorInfo(true);
      setshowProductInfo(false);
    } catch (error) {
      console.log(error.message);
    }
  };


  const insertCompetitor = async (productId) => {
    const payload = {
      userId: userData[0]["User_Id"],
      productId: productId,
    };
    try {
      const responseData = await insertCompetitorsApi(payload);
      const competitorIds = responseData.data.data;

      // Loop through competitorIds sequentially
      for (const competitorId of competitorIds) {
        let payload = {
          userId: userData[0]["User_Id"],
          productId: productId,
          competitorId: competitorId,
        };

        try {
          // Await insertHtmlApi and then execute the other API calls
          await insertHtmlApi(payload);
          await Promise.all([
            insert_nameApi(payload),
            insert_overviewApi(payload),
            insert_featureApi(payload),
            insert_pricingApi(payload),
            insert_feature_tableApi(payload),
            insert_pricing_tableApi(payload),
            insert_product_azure_review_tableApi(payload),
            insert_product_aws_review_tableApi(payload),
            insert_competitor_azure_reviewApi(payload),
            insert_competitor_aws_reviewApi(payload),
            insert_competitor_roadmap_tableApi(payload)
          ]);
        }
        catch (error) {
          console.error("Error:", error.message);
        }
      }
      console.log(responseData, "insertcompetitor - response");
    } catch (error) {
      console.error("Error:", error.message);
      // Handle errors
    }
  }


  //   const insertCompetitor = async (productId) => {
  //       const payload = {
  //         userId: userData[0]["User_Id"],
  //         productId: productId,
  //       };
  //   try {
  //     const responseData = await insertCompetitorsApi(payload);
  //     const competitorIds = responseData.data.data;

  //     // Array to store all the promises for API calls
  //     const apiPromises = competitorIds.map(async (competitorId) => {
  //       let payload = {
  //         userId: userData[0]["User_Id"],
  //         productId: productId,
  //         competitorId: competitorId,
  //       };

  //       // Await insertHtmlApi and then execute the other API calls
  //       await insertHtmlApi(payload);
  //       await Promise.all([
  //         insert_nameApi(payload),
  //         insert_overviewApi(payload),
  //         insert_featureApi(payload),
  //         insert_pricingApi(payload),
  //         insert_feature_tableApi(payload),
  //         insert_pricing_tableApi(payload)
  //       ]);
  //     });

  //     // Execute all promises concurrently
  //     await Promise.all(apiPromises);

  //     console.log(responseData, "insertcompetitor - response");
  //   } catch (error) {
  //     console.error("Error:", error.message);
  //     // Handle errors
  //   }
  // }



  // const insertCompetitor = async (productId) => {
  //   try {
  //     const payload = {
  //       userId: userData[0]["User_Id"],
  //       productId: productId,
  //     };
  //     const responseData = await insertCompetitorsApi(payload);
  //     const competitorIds = responseData.data.data;
  //     for (const competitorId of competitorIds) {
  //       let payload = {
  //         userId: userData[0]["User_Id"],
  //         productId: productId,
  //         competitorId: competitorId,
  //       };
  //       try {
  //         const insertHtml = await insertHtmlApi(payload);
  //         const insert_name = await insert_nameApi(payload);
  //         const insert_overview = await insert_overviewApi(payload);
  //         const insert_feaature = await insert_featureApi(payload);
  //         const insert_pricing = await insert_pricingApi(payload);
  //         const insert_feature_table = await insert_feature_tableApi(payload);
  //         const insert_pricing_table = await insert_pricing_tableApi(payload);

  //         console.log(insertHtml.data.data);
  //         console.log(insert_name.data.data);
  //         console.log(insert_overview.data.data);
  //         console.log(insert_feaature.data.data);
  //         console.log(insert_pricing.data.data);
  //         console.log(insert_feature_table.data.data);
  //         console.log(insert_pricing_table.data.data);
  //       }
  //       catch (error) {
  //         console.log(error.message);
  //       }
  //     }
  //     // await insertCompetitorData(productId, competitorIds);
  //     console.log(responseData, "insertcompetitor - response");
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  const handleSearch = async () => {
    try {
      console.log("handle Search Called");
      createProduct();
    } catch (error) {
      console.log(error.message);
    }
  };

  const productlogofn = (e) => {
    const file = e.target.files[0];
    console.log(file, "fejbadfdefiowaklkjefina aijiekoej");
    if (file.size > 10 * 1024 * 1024) {
      //10mb restrict
      // setProductlogo('')
      // setProductlogoname('')
    } else if (file) {
      const reader = new FileReader();
      console.log(reader);
      reader.onloadend = () => {
        setProductlogo(reader.result);
        setProductlogoname(file.name);
        console.log(productlogo, "productlogooooooooooooo");
      };
      console.log(productlogo, "prod");
      reader.readAsDataURL(file);
      console.info("true");
      setIsOpen(true);
    }
  };

  //-----------bubble text--------------------
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      if (keywords.trim() !== "") {
        setSelected([...selected, keywords]); // Append current value of keywords
        setKeywords("");
      }
    }
  }

  function removeTag(index) {
    setSelected(selected.filter((_, i) => i !== index));
  }

  const handleClose = () => {
    console.info(false);
    setIsOpen(false);
    setProductlogo("");
  };

  return (

    <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
      <div className="row g-2 py-4">
        <div className="col-auto ">
          <a>
            <img
              src="images/back-icon.svg"
              alt="back-icon"
              className=" me-3"
              onClick={() => navigate("/productcontrast")}
            />
          </a>
        </div>
        <div className="col">
          <h2 className="font-bold font-22 mb-4">
            Create New Product Contrast
          </h2>
          <div className="row justify-content-center">
            <div className="col-md-10 py-3">
              {/* stepper strats */}
              <div className="d-flex justify-content-center">
                <ul className="list-unstyled custom-stepper d-flex">
                  <li className="stepper-sty text-center active">
                    <span className="product-wiz stepper-icon-sty" />
                    <p className="stepper-text mt-1 mb-0">Product</p>
                  </li>
                  <li className=" stepper-sty text-center position-relative">
                    <span className="comparision-wiz stepper-icon-sty stepper-line" />
                    <p className="stepper-text mt-1 mb-0">
                      Comparison Metrics
                    </p>
                  </li>
                  <li className="stepper-sty text-center position-relative">
                    <span className="Features-wiz stepper-icon-sty stepper-line" />
                    <p className="stepper-text mt-1 mb-0">Features</p>
                  </li>
                </ul>
              </div>
              {/* stepper ends */}

              <>
                <div>
                  <div className="row mt-4">
                    <h5 className="font-bold font-18 grey-v3">
                      Product Info
                    </h5>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="Name"
                        className="form-label mb-0 cust-label font-14 font-medium mb-2"
                      >
                        Name
                      </label>
                      { }
                      <input
                        type="text"
                        placeholder="Enter Name"
                        className="form-control custom-form  font-14 font-regular"
                        id="Name"
                        value={name}
                        onChange={(e) =>
                          setName(
                            e.target.value.replace(/[^a-zA-Z0-9\s]/g, "")
                          )
                        }
                        disabled={competitordata.length > 0 || isLoading}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="ProductLogo"
                        className="form-label mb-0 cust-label font-14 font-medium mb-2"
                      >
                        Product Logo
                      </label>
                      <div>
                        {!productlogo && (
                          <label
                            className="position-relative violet-btn px-4 py-2 w-25 d-flex text-center justify-content-center align-items-center"
                            htmlFor="uploadImg2"
                          >
                            <span className="font-14 font-medium">
                              upload
                            </span>
                            <input
                              type="file"
                              className="cust-file pt-1"
                              id="uploadImg2"
                              accept="image/*"
                              onChange={productlogofn}
                              disabled={competitordata.length > 0 || isLoading}
                            />
                          </label>
                        )}
                        {productlogo && isOpen && (
                          <span className="d-flex align-items-center">
                            <span className="blue-batch d-flex align-items-center py-2">
                              <img
                                src={productlogo}
                                alt="Uploaded File"
                                className="product-logo"
                              />
                            </span>
                            <span className="blue-link font-14 font-medium ms-2 me-3 cursor-pointer">
                              {productlogoname}
                            </span>
                            {!competitordata.length > 0 && !isLoading && ( // Only render the close image if competitordata is null
                              <span>
                                <img
                                  src="images/close-icon.svg"
                                  alt="close-icon"
                                  className="cursor-pointer cross-size"
                                  onClick={handleClose}
                                />
                              </span>
                            )}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="Overview"
                        className="form-label mb-0 cust-label font-14 font-medium mb-2"
                      >
                        Overview
                      </label>
                      <textarea
                        type="text"
                        rows={5}
                        placeholder="Enter overview"
                        className="form-control custom-form resize-none font-14 font-regular"
                        id="Overview"
                        value={overview}
                        onChange={(e) =>
                          setOverview(
                            e.target.value.replace(
                              /[^a-zA-Z0-9\s.,'-]/g,
                              ""
                            )
                          )
                        }
                        disabled={competitordata.length > 0 || isLoading}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="keywords"
                        className="form-label mb-0 cust-label font-14 font-medium mb-2"
                      >
                        Keywords
                      </label>
                      <div className="form-control custom-form resize-none font-14 font-regular keyword-hig">
                        {selected.map((item, index) => (
                          <span
                            key={index}
                            className="blue-batch d-inline-flex align-items-center me-2 mb-2"
                          >
                            <span className="font-14 font-medium">
                              {item}
                            </span>
                            {!competitordata.length > 0 && !isLoading && (
                              <img
                                src="images/close-icon.svg"
                                alt="close-icon"
                                className="ms-3 cursor-pointer"
                                onClick={() => removeTag(index)}
                              />
                            )}
                          </span>
                        ))}
                        <input
                          type="text"
                          placeholder="Enter Keywords"
                          className="form-control border-0 ps-0 mb-2 shadow-none font-14 font-regular"
                          id="keywords"
                          value={keywords}
                          onChange={(e) =>
                            setKeywords(
                              e.target.value.replace(
                                /[^a-zA-Z0-9\s.,'-]/g,
                                ""
                              )
                            )
                          }
                          onKeyDown={handleKeyDown}
                          disabled={competitordata.length > 0 || isLoading}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="keywords"
                        className="form-label mb-0 cust-label font-14 font-medium mb-2"
                      >
                        Site URL
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your URL link"
                        className="form-control custom-form  font-14 font-regular"
                        id="URLLink"
                        value={producturl}
                        onChange={(e) =>
                          setProducturl(e.target.value)
                        }
                        disabled={competitordata.length > 0 || isLoading}
                      />
                    </div>

                  </div>
                  {showProductInfo ? (
                    <div className="d-flex justify-content-end mt-5">
                      <button
                        type="button"
                        className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                        onClick={() => {
                          navigate("/productcontrast");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                        onClick={() => {
                          handleSearch();
                        }}
                        disabled={
                          !(
                            name &&
                            overview &&
                            productlogo &&
                            producturl &&
                            selected &&

                            productlogoname && selected.length !== 0
                          )
                        }
                      >
                        <span>
                          <img
                            src="images/search-btn-icon.svg"
                            alt="search-icon"
                            className="me-1"
                          />
                        </span>{" "}
                        Search
                      </button>
                    </div>
                  ) : null}
                </div>
                <div style={{ height: "10px" }}>
                  {isLoading && <Loader />}
                </div>
                {showCompetitorInfo ? (
                  <>
                    <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                      <h5 className="font-bold font-18 grey-v3 mb-0">
                        Competitor Info
                        {rowCount && selectedCompetitors.length > 0 && (
                          <span className="font-16 font-medium grey-v4 ps-3">
                            {selectedCompetitors.length}/{filteredCompetitors.length}
                          </span>
                        )}
                      </h5>


                      <button
                        type="button"
                        className="btn px-4 violet-btn rounded-3 shadow-none  d-flex align-items-center justify-content-center font-bold font-16 text-nowrap"
                        data-bs-toggle="modal"
                        data-bs-target="#Addmanually"
                      >
                        Add Manually
                      </button>
                      <div
                        className="modal fade"
                        id="Addmanually"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex={-1}
                        aria-labelledby="AddmanuallyLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content popup-content py-2 px-3">
                            <div className="modal-header border-0">
                              <h5
                                className="font-18 font-bold popup-header-color mb-0"
                                id="AddmanuallyLabel"
                              >
                                Add Manually
                              </h5>
                              <button
                                type="button"
                                className="btn-close shadow-none"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <div className="modal-body border-0">
                              <div className="col-md-12 mb-3">
                                <label
                                  htmlFor="CompetitorName"
                                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                                >
                                  Competitor Name
                                </label>
                                <input
                                  type="text"
                                  placeholder="Enter name"
                                  className="form-control custom-form py-2   font-14 font-regular"
                                  id="CompetitorName"
                                  value={competitorname}
                                  onChange={(e) =>
                                    setCompetitorname(
                                      e.target.value.replace(
                                        /[^a-zA-Z0-9\s.,'-]/g,
                                        ""
                                      )
                                    )
                                  }
                                />
                              </div>
                              <div className="col-md-12 mb-3">
                                <label
                                  htmlFor="URLLink"
                                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                                >
                                  URL Link
                                </label>
                                <input
                                  type="text"
                                  placeholder="Enter your URL link"
                                  className="form-control custom-form py-2   font-14 font-regular"
                                  id="URLLink"
                                  value={competitorurl}
                                  onChange={(e) =>
                                    setCompetitorurl(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="modal-footer border-0">
                              <button
                                className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  navigate("/product", {
                                    state: { productId: productId },
                                  });
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                                data-bs-dismiss="modal"
                                onClick={addCompetitorsManually}
                                disabled={
                                  competitorname === "" ||
                                  competitorurl === ""
                                }
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!isLoading && (
                      <div>
                        <Competitors />
                      </div>
                    )}

                    <div className="text-center mt-5 mb-4">
                      {rowCount > count && !isLoading && (
                        <button
                          value={count}
                          hidden={filteredCompetitors.length <= count}
                          onClick={showmore}
                          className="border-0 font-15 font-medium px-0 text-blue-btn"
                          type="button"
                        >
                          Show More
                        </button>
                      )}
                    </div>


                    {/* Cancel and Next button */}
                    {!isLoading && (
                      <div className="d-flex justify-content-end mt-5">
                        <button
                          type="button"
                          className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                          onClick={() => {
                            navigate("/productcontrast");
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                          onClick={() => {
                            selectCompetitor();
                            navigate("/comparisionmetrics", {
                              state: { productId: productId },
                            });
                            //sending the ProductId to comparision handler
                          }}
                          disabled={selectedCompetitors.length === 0}
                        >
                          Next{" "}
                          <span>
                            <img
                              src="images/white-arrow.svg"
                              alt="white-arrow"
                              className="ms-1"
                            />
                          </span>
                        </button>
                      </div>
                    )}
                  </>
                ) : null}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
