import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { getMyEventsApi } from "../services/api";
import { useCommonContext } from "./CommonContext";

const MyEventContext = React.createContext();

export function MyEventsContextProvider({ children }) {
  const { userData } = useCommonContext();
  const [myEvents, setMyEvents] = useState([]);
  const [isDayView, setIsDayView] = useState(true);
  const currentDate = new Date();
  const [month, setMonth] = useState(currentDate.getMonth() + 1);
  const [year, setYear] = useState(currentDate.getFullYear());
  const [dateAndDays, setDateAndDays] = useState([]);

  const [currentDateEvent, setCurrentDateEvent] = useState([]);

  const [currentDateForEv, setCurrentDateForEv] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  console.info(currentDateEvent, "This is the current date event");

  function getDaysInMonth(year, month) {
    const daysInMonth = new Date(year, month, 0).getDate();
    const days = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month - 1, day);
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
      const fullDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      days.push({ date: day, dayOfWeek, fullDate });
    }

    return days;
  }
  function splitByWeek(data) {
    const result = [];
    let week = [];

    data.forEach((item) => {
      week.push(item);
      if (item.dayOfWeek === "Saturday") {
        result.push(week);
        week = [];
      }
    });
    if (week.length > 0) {
      result.push(week);
    }

    return result;
  }
  function getPreviousDays(dateString, currentDayOfWeek) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date(dateString);
    const previousDays = [];

    // Calculate the number of previous days based on the current day of the week
    let numPreviousDays;
    switch (currentDayOfWeek) {
      case "Saturday":
        numPreviousDays = 6;
        break;
      case "Friday":
        numPreviousDays = 5;
        break;
      case "Thursday":
        numPreviousDays = 4;
        break;
      case "Wednesday":
        numPreviousDays = 3;
        break;
      case "Tuesday":
        numPreviousDays = 2;
        break;
      case "Monday":
        numPreviousDays = 1;
        break;
      default:
        numPreviousDays = 0;
        break;
    }

    // Calculate and push the previous dates and days
    for (let i = 1; i <= numPreviousDays; i++) {
      const previousDate = new Date(currentDate);
      previousDate.setDate(previousDate.getDate() - i);
      const previousDayOfWeek = daysOfWeek[previousDate.getDay()];
      previousDays.push({
        date: previousDate.getDate(),
        dayOfWeek: previousDayOfWeek,
      });
    }

    return previousDays;
  }
  function getNextDays(dateString, currentDayOfWeek) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date(dateString);
    const nextDays = [];

    // Calculate the number of next days based on the current day of the week
    let numNextDays;
    switch (currentDayOfWeek) {
      case "Sunday":
        numNextDays = 6;
        break;
      case "Monday":
        numNextDays = 5;
        break;
      case "Tuesday":
        numNextDays = 4;
        break;
      case "Wednesday":
        numNextDays = 3;
        break;
      case "Thursday":
        numNextDays = 2;
        break;
      case "Friday":
        numNextDays = 1;
        break;
      case "Saturday":
        numNextDays = 0;
        break;
      default:
        numNextDays = 0;
        break;
    }

    // Calculate and push the next dates and days
    for (let i = 1; i <= numNextDays; i++) {
      const nextDate = new Date(currentDate);
      nextDate.setDate(nextDate.getDate() + i);
      const nextDayOfWeek = daysOfWeek[nextDate.getDay()];
      nextDays.push({
        date: nextDate.getDate(),
        dayOfWeek: nextDayOfWeek,
      });
    }

    return nextDays;
  }

  useEffect(() => {
    getMyEvents();
  }, [month, year]);

  async function getMyEvents() {
    let dateData = getDaysInMonth(year, month);
    let splitArray = splitByWeek(dateData);
    console.log(splitArray, "this is the split array");

    for (let i = 0; i < splitArray.length; i++) {
      let week = splitArray[i];
      if (week.length !== 7) {
        if (week[0].dayOfWeek != "Sunday") {
          let firstDayOfMonth = week[0].dayOfWeek;
          let firstDate = `${year}-${month}-${week[0].date}`;
          const weekDay = getPreviousDays(firstDate, firstDayOfMonth);
          splitArray[i] = weekDay.reverse().concat(week);
          console.log(splitArray[i]);
        }
        if (week[week.length - 1].dayOfWeek != "Saturday") {
          let lastDayOfMonth = week[week.length - 1].dayOfWeek;
          let firstDate = `${year}-${month}-${week[week.length - 1].date}`;
          const weekDay = getNextDays(firstDate, lastDayOfMonth);
          splitArray[i] = splitArray[i].concat(weekDay);
          console.log(splitArray[i], "Last");
        }
      }
    }

    console.log(splitArray, "this is split");

    let dateOfMonth = "";
    for (let val of splitArray) {
      for (let value of val) {
        if (value.fullDate) {
          dateOfMonth = value.fullDate;
          break;
        }
      }
    }

    const payload = {
      userId: userData[0]["User_Id"],
      date: dateOfMonth,
    };
    const responseData = await getMyEventsApi(payload);

    const myEventData = responseData.data.data;

    let daysAndDate = splitArray;
    for (let values of daysAndDate) {
      for (let eachDay of values) {
        eachDay.events = [];
        for (let event of myEventData) {
          console.info(
            moment(event?.Event_From_Date).format("YYYY-MM-DD"),
            eachDay?.fullDate
          );
          if (
            moment(event?.Event_From_Date).format("YYYY-MM-DD") ==
            eachDay?.fullDate
          ) {
            eachDay.events.push(event);
          }
        }
      }
    }
    setMyEvents(responseData.data.data);
    setDateAndDays(daysAndDate);
  }

  return (
    <MyEventContext.Provider
      value={{
        myEvents,
        setMyEvents,
        isDayView,
        setIsDayView,
        month,
        setMonth,
        year,
        setYear,
        dateAndDays,
        setDateAndDays,
        currentDateEvent,
        setCurrentDateEvent,
        currentDateForEv,
        setCurrentDateForEv,
      }}
    >
      {children}
    </MyEventContext.Provider>
  );
}

export function useMyEventContext() {
  return useContext(MyEventContext);
}
