import React, { useEffect, useState } from "react";
import { deletePricingApi } from "../../services/api";
import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { useCommonContext } from "../../contexts/CommonContext";


export default function CreatePricingAccordian() {
    const { userData } = useCommonContext()
    const { getAllpricing, pricingData, setPricingName, setPricingCost, setPricingUnit, setPricingId, productId } = useCreateProductContext();
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

    //load the details based on the mode and product_id
    const [mode, setMode] = useState("edit");
    // const [productId, setproductId] = useState('ADE37620-75C3-4C07-92D2-00C89E7ABB8E');

    useEffect(() => {
        if (productId) {
            getAllpricing();
        }
        else {
            console.log('newuser')
        }
    }, []);


    const editPricing = async (PricingId, PricingName, pricingCost, pricingUnit) => {
        try {
            setPricingName(PricingName)
            setPricingCost(pricingCost)
            setPricingUnit(pricingUnit)
            setPricingId(PricingId)

        } catch (error) {
            console.error(error);
        }
    }

    const deletePricing = async (id) => {
        try {
            // console.log("Delete pricing called")
            const payload = {
                userId: userData[0]["User_Id"],
                pricingId: id
            };
            // console.log(payload);
            const responseData = await deletePricingApi(payload);
            // console.log(responseData.data.success);
            if (responseData.data.success === true) {
                getAllpricing()
            } else {
                console.log('Error occur while Deleting Data from API');
            }
        } catch (error) {
            console.error(error);
        }
    }

    const toggleAccordion = (index) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="col-md-12 mb-4">
            {/* Advanced Analytics acc starts */}
            {
                pricingData?.map((values, index) => {
                    return (
                        <div className="border-bottom mt-5" key={values.Pricing_Id}>
                            <div className="d-flex justify-content-between align-items-center my-2" onClick={() => toggleAccordion(index)}>
                                <p className={`font-14 font-medium cursor-pointer mb-0 ${openAccordionIndex === index ? '' : 'collapsed'}`} data-bs-toggle="collapse" data-bs-target={`#collapse${index}`}>
                                    <img src="images/table-accordion-arrow.svg" alt="form-acc-arrow" className={`me-2 grid-arrow ${openAccordionIndex === index ? 'open' : ''}`} />
                                    {values.Pricing_Name}
                                </p>
                                <span className="d-flex align-items-center justify-content-center gap-2">
                                    <button className="edit-btn"><img src="images/table-edit.svg" alt="table-edit"
                                        onClick={() => editPricing(values.Pricing_Id, values.Pricing_Name, values.Pricing_Cost, values.Pricing_Unit)}
                                    /></button>
                                    <span className="border-sape" />
                                    <button className="delete-btn"><img src="images/table-delete.svg" alt="table-delete"
                                        onClick={() => deletePricing(values.Pricing_Id)}
                                    /></button>
                                </span>
                            </div>
                            <div className={`collapse ${openAccordionIndex === index ? 'show' : ''}`} id={`collapse${index}`}>
                                <p className="ms-4 ps-1 font-14 font-regular cust-label">
                                    $ {values.Pricing_Cost} / {values.Pricing_Unit}
                                </p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
