export default function Agenda() {
  return (
    <div className="d-flex gap-3 align-items-center">
      <span className="font-medium font-12 d-flex align-items-center gap-2 me-3">
        <span className="cal-legend bg-blue" />
        Conference
      </span>
      <span className="font-medium font-12 d-flex align-items-center gap-2 me-3">
        <span className="cal-legend bg-orange" />
        Webinars
      </span>
      <span className="font-medium font-12 d-flex align-items-center gap-2 me-3">
        <span className="cal-legend bg-yellow" />
        Training
      </span>
      <span className="font-medium font-12 d-flex align-items-center gap-2 me-3">
        <span className="cal-legend bg-indigo" />
        Hackathon
      </span>
    </div>
  );
}
