import { useCommonContext } from "../../contexts/CommonContext";
import { useMyEventContext } from "../../contexts/MyEventContext";
import moment from "moment";

export default function MonthView() {
  const { dateAndDays } = useMyEventContext();
  const { openToast } = useCommonContext();

  return (
    <div className="col-md-12 mt-4">
      <div className="month-calendar">
        <table className="table">
          <thead>
            <tr>
              <th className="text-end border-0 font-medium font-14 weight-500">
                Sun
              </th>
              <th className="text-end border-0 font-medium font-14 weight-500">
                Mon
              </th>
              <th className="text-end border-0 font-medium font-14 weight-500">
                Tue
              </th>
              <th className="text-end border-0 font-medium font-14 weight-500">
                Wed
              </th>
              <th className="text-end border-0 font-medium font-14 weight-500">
                Thu
              </th>
              <th className="text-end border-0 font-medium font-14 weight-500">
                Fri
              </th>
              <th className="text-end border-0 font-medium font-14 weight-500">
                Sat
              </th>
            </tr>
          </thead>
          <tbody className="font-regular font-14">
            {dateAndDays.map((value, index) => {
              return (
                <tr key={index}>
                  {value?.map((date, index) => {
                    console.log(date);
                    return (
                      <td>
                        <span className="d-block text-end">{date?.date}</span>
                        {date?.events?.length != 0 ? (
                          <span className="day-content mt-1">
                            {date?.events?.map((event, index) => {
                              return (
                                <>
                                  <span
                                    className="month-calendar-highlight font-10 font-regular p-2 w-100 d-flex gap-1 align-items-center text-nowrap"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <span
                                      className={`cal-legend ${
                                        event?.Event_Type == "Conferences"
                                          ? "bg-blue"
                                          : event?.Event_Type == "Webinars"
                                          ? "bg-orange"
                                          : event?.Event_Type == "Training"
                                          ? "bg-yellow"
                                          : event?.Event_Type == "Hackathon"
                                          ? "bg-indigo"
                                          : ""
                                      }`}
                                    ></span>
                                    {event.Event_Name.length > 18
                                      ? `${event.Event_Name.slice(0, 18)}...`
                                      : event.Event_Name}

                                    {/* Digital Innovation... */}
                                  </span>
                                  <div
                                    className="meeting-content position-absolute bg-white dropdown-menu"
                                    style={{ zIndex: 9, width: 490 }}
                                  >
                                    <p className="d-block font-16 font-bold d-flex align-items-center gap-2 mb-2">
                                      <span
                                        className={`cal-legend ${
                                          event?.Event_Type == "Conferences"
                                            ? "bg-blue"
                                            : event?.Event_Type == "Webinars"
                                            ? "bg-orange"
                                            : event?.Event_Type == "Training"
                                            ? "bg-yellow"
                                            : event?.Event_Type == "Hackathon"
                                            ? "bg-indigo"
                                            : ""
                                        }`}
                                      ></span>
                                      {event?.Event_Name}
                                    </p>
                                    <p className="font-regular font-16 color-grey mb-3">
                                      {event?.Event_Description}
                                    </p>
                                    <p className="d-flex align-items-center gap-2 mb-3 font-regular font-12 grey-v1">
                                      <img
                                        src="images/calender-icon.svg"
                                        alt="calender-icon"
                                      />
                                      <span>
                                        {new Date(
                                          `${
                                            event?.Event_From_Date.split("T")[0]
                                          }T${event?.Event_From_Time}`
                                        ).toLocaleString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "2-digit",
                                          hour: "numeric",
                                          minute: "2-digit",
                                          hour12: true,
                                        })}
                                        {/* {moment(event?.Event_From_Date)
                                          .add(
                                            moment.duration(
                                              event?.Event_From_Time
                                            )
                                          )
                                          .format("MMM DD, YYYY h:mm A")} */}
                                      </span>{" "}
                                      <img
                                        src="images/tail-arrow.svg"
                                        alt="calender-icon"
                                      />{" "}
                                      <span>
                                        {new Date(
                                          `${
                                            event?.Event_To_Date.split("T")[0]
                                          }T${event?.Event_To_Time}`
                                        ).toLocaleString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "2-digit",
                                          hour: "numeric",
                                          minute: "2-digit",
                                          hour12: true,
                                        })}
                                        {/* {moment(event?.Event_To_Date)
                                          .add(
                                            moment.duration(
                                              event?.Event_To_Time
                                            )
                                          )
                                          .format("MMM DD, YYYY h:mm A")} */}
                                      </span>
                                    </p>
                                    <p className="d-flex align-items-center gap-2 mb-3 font-regular font-12 grey-v1">
                                      <img
                                        src="images/location-icon.svg"
                                        alt="location-icon"
                                      />
                                      <span className="ps-1">
                                        {event?.Meeting_Platform}
                                      </span>
                                    </p>
                                    <p className="d-flex align-items-center gap-2 mb-3 font-medium font-12 grey-v1">
                                      <img
                                        src="images/link-icon.svg"
                                        alt="link-icon"
                                      />
                                      <a className="blue-link text-decoration-none">
                                        {event?.Meeting_Url.length > 58
                                          ? event?.Meeting_Url.slice(0, 58) +
                                            "..."
                                          : event?.Meeting_Url}
                                      </a>{" "}
                                      <span
                                        className="cursor-pointer"
                                        onClick={() => {
                                          // Check if the clipboard API is available
                                          if (navigator.clipboard) {
                                            // Copy the Meeting_Url to the clipboard
                                            navigator.clipboard
                                              .writeText(
                                                event?.Meeting_Url || ""
                                              )
                                              .then(() =>
                                                openToast(
                                                  "Copied to clipboard",
                                                  "images/toast-check.svg"
                                                )
                                              )
                                              .catch((err) =>
                                                console.error(
                                                  "Failed to copy URL: ",
                                                  err
                                                )
                                              );
                                          } else {
                                            // Clipboard API not available
                                            alert(
                                              "Clipboard API is not supported in this browser"
                                            );
                                          }
                                        }}
                                      >
                                        <img src="images/copy-icon.svg" />
                                      </span>
                                    </p>
                                    <button
                                      className="btn shadow-btn d-flex align-items-center py-2 px-3 box-shadow-none font-medium font-14"
                                      onClick={() => {
                                        window.open(
                                          event?.Meeting_Url,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      Join Meeting
                                    </button>
                                  </div>
                                </>
                              );
                            })}
                          </span>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
