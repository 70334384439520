import React, { useState } from "react";
import { useCreateMyEventContext } from "../../contexts/CreateMyEventContext";
import CreateMyEventsFormAction from "./CreateMyEventsFormAction";
import TimeSlots from "./TimeSlots";

export default function CreateMyEventsFields() {
  // State object for form inputs
  const {
    formData,
    setFormData,
    accounts,
    setAccounts,
    eventTypes,
    setEventTypes,
    isEventDetailOpen,
    setIsEventDetailOpen,
  } = useCreateMyEventContext();

  console.info(formData, "This is the form data");

  // Event handler for form inputs
  const handleChange = (e) => {
    const { name, value, type, checked, id } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox" ? { ...prevState[name], [id]: checked } : value,
    }));
  };

  // JSX structure with event handlers
  return (
    <div className="col-md-3 mt-4">
      <div className="px-3">
        {/* Event details */}
        <div className="border-bottom mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#topicKeyPoints"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Event details{" "}
          </p>
          <div className="collapse show" id="topicKeyPoints">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="eventName"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Event Name
                </label>
                <input
                  type="text"
                  placeholder="Enter event name"
                  className="form-control custom-form  font-14 font-regular"
                  id="eventName"
                  name="eventName"
                  value={formData.eventName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="eventDescription"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Event Description
                  <span data-bs-toggle="tooltip" title="Lorem ipsumis a dummy">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Briefly describe about the event"
                  rows={3}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="eventDescription"
                  name="eventDescription"
                  value={formData.eventDescription}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12 mb-3">
                <p className="font-14 font-medium">Date &amp; Time</p>
                <label
                  htmlFor="fromDateEvent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  From
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="date"
                      className="form-control custom-form cust-date  font-14 font-regular"
                      id="fromDateEvent"
                      name="fromDateEvent"
                      value={formData.fromDateEvent}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    {/* <input
                      type="time"
                      className="form-control custom-form cust-time font-14 font-regular"
                      id="fromTimeEvent"
                      name="fromTimeEvent"
                      value={formData.fromTimeEvent}
                      onChange={handleChange}
                    /> */}
                    <TimeSlots
                      name={"fromTimeEvent"}
                      value={formData.fromTimeEvent}
                      handleChange={handleChange}
                      id={"fromTimeEvent"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="toDateEvent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  To
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="date"
                      className="form-control custom-form cust-date  font-14 font-regular"
                      id="toDateEvent"
                      name="toDateEvent"
                      value={formData.toDateEvent}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    {/* <input
                      type="time"
                      className="form-control custom-form cust-time font-14 font-regular"
                      id="toTimeEvent"
                      name="toTimeEvent"
                      value={formData.toTimeEvent}
                      onChange={handleChange}
                    /> */}
                    <TimeSlots
                      name={"toTimeEvent"}
                      value={formData.toTimeEvent}
                      handleChange={handleChange}
                      id={"toTimeEvent"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="eventType"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Enter type
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="eventType"
                  name="eventType"
                  value={formData.eventType}
                  onChange={handleChange}
                >
                  <option>Select</option>
                  {eventTypes?.map((value, index) => {
                    return (
                      <option value={value?.Event_Type_Id}>
                        {value?.Event_Type}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Platform and Account */}
        <div className="border-bottom mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#accountPlatform"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Platform &amp; Account
          </p>
          <div className="collapse show" id="accountPlatform">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="accountName"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Account Name
                  <span data-bs-toggle="tooltip" title="Lorem ipsumis a dummy">
                    <img
                      className="ms-2"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="accountName"
                  name="accountName"
                  value={formData.accountName}
                  onChange={handleChange}
                >
                  <option>Select</option>
                  {accounts?.map((value, index) => {
                    return (
                      <option value={value?.Account_Id}>
                        {value?.Account_Name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="meetingPlatform"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Meeting Platform
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="meetingPlatform"
                  name="meetingPlatform"
                  value={formData.meetingPlatform}
                  onChange={handleChange}
                >
                  <option>Select</option>
                  {accounts
                    .filter(
                      (value) => value.Account_Id === formData?.accountName
                    )
                    .map((filteredAccount) => {
                      return (
                        <>
                          <option
                            value={"Google Meet"}
                            hidden={
                              filteredAccount?.Google_Meet_Credentials == 0
                            }
                          >
                            Google Meet
                          </option>
                          <option
                            value={"Teams"}
                            hidden={filteredAccount?.Teams_Credentials == 0}
                          >
                            Microsoft Teams
                          </option>
                        </>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="platforms"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Platforms
                  <span data-bs-toggle="tooltip" title="Lorem ipsumis a dummy">
                    <img
                      className="ms-2"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                {accounts
                  .filter((value) => value.Account_Id === formData?.accountName)
                  .map((filteredAccount) => {
                    return (
                      <>
                        <div
                          className="form-check"
                          hidden={filteredAccount?.Facebook_Credentials == 0}
                        >
                          <input
                            className="form-check-input cust-check box-shadow-none"
                            type="checkbox"
                            id="fbPost"
                            name="platforms"
                            checked={formData.platforms.fbPost}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label font-14 font-medium check-label"
                            htmlFor="fbPost"
                          >
                            Facebook post
                          </label>
                        </div>
                        <div
                          className="form-check"
                          hidden={filteredAccount?.Linkedin_Credentials == 0}
                        >
                          <input
                            className="form-check-input cust-check box-shadow-none"
                            type="checkbox"
                            id="linkedinPost"
                            name="platforms"
                            checked={formData.platforms.linkedinPost}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label  font-14 font-medium check-label"
                            htmlFor="linkedinPost"
                          >
                            Linkedin post
                          </label>
                        </div>
                        <div
                          className="form-check"
                          hidden={filteredAccount?.Twitter_Credentials == 0}
                        >
                          <input
                            className="form-check-input cust-check box-shadow-none"
                            type="checkbox"
                            id="twitterPost"
                            name="platforms"
                            checked={formData.platforms.twitterPost}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label  font-14 font-medium check-label"
                            htmlFor="twitterPost"
                          >
                            Twitter post
                          </label>
                        </div>
                        <div
                          className="form-check"
                          hidden={filteredAccount?.Instagram_Credentials == 0}
                        >
                          <input
                            className="form-check-input cust-check box-shadow-none"
                            type="checkbox"
                            id="instaCap"
                            name="platforms"
                            checked={formData.platforms.instaCap}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label  font-14 font-medium check-label"
                            htmlFor="instaCap"
                          >
                            Instagram caption
                          </label>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        {/* Form actions */}
        <CreateMyEventsFormAction />
      </div>
    </div>
  );
}
