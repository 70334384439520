import React, { useState } from "react";
import { useCreateContentContext } from "../../../contexts/CreateContentContext";
import { generateContentApi } from "../../../services/api";
import { getContentByIdApi } from "../../../services/api";
import { useCommonContext } from "../../../contexts/CommonContext";
import { useLocation } from "react-router-dom";

export default function ProductWebpageFields() {
  //   const [productName, setProductName] = useState('');
  //   const [productDescription, setProductDescription] = useState('');
  //   const [Usp, setUspProduct] = useState('')
  //   const [keyFeatures, setKeyFeatures] = useState('');
  //   const [targetAudienceProduct, setTargetAudienceProduct] = useState('');
  //   const [goalDescription, setGoalDescription] = useState('');
  //   const [integrationAvailable, setIntegrationAvailable] = useState(true);
  //   const [selected, setSelected] = useState([]);
  //   const [tagInputValue, setTagInputValue] = useState('');
  const {
    setGeneratedContent,
    formData,
    setFormData,
    contentID,
    setContentID,
    approvalStatus,
    account,
    setAccount,
    productName,
    setProductName,
    productDescription,
    setProductDescription,
    Usp,
    setUspProduct,
    keyFeatures,
    setKeyFeatures,
    targetAudienceProduct,
    setTargetAudienceProduct,
    goalDescription,
    setGoalDescription,
    integrationAvailable,
    setIntegrationAvailable,
    selected,
    setSelected,
    tagInputValue,
    setTagInputValue,
    contentTypeId,
    product,
    setProduct,
    socialMediaAccount,
    setSocialMediaAccount,
    fbPost,
    setfbPost,
    linkedinPost,
    setLinkedInPost,
    twitterPost,
    setTwitterPost,
    instaCap,
    setInstaCap,
    setIsLoading,
    contentName,setContentName
  } = useCreateContentContext();

  const { userData } = useCommonContext();

  const [state, setState] = useState([{ id: 1, name: "" }]);

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      if (tagInputValue.trim() !== "") {
        setSelected([...selected, tagInputValue]);
        setTagInputValue("");
      }
    }
  }
  const handleAdd = () => {
    if (tagInputValue.trim() !== "") {
      setSelected([...selected, tagInputValue]);
      setTagInputValue("");
    }
  };

  function removeTag(index) {
    //1
    setSelected(selected.filter((_, i) => i !== index));
  }
  const location = useLocation();
  console.log(location,"this is the location from web page")
  const areAllMandatoryFieldsFilled = () => {
    return (
      productName.trim() !== "" &&
      productDescription.trim() !== "" &&
      Usp.trim() !== "" &&
      keyFeatures.trim() !== ""
    );
  };

  // const payload = {
  //   userId: userData[0]["User_Id"],
  //   contentTypeId: contentTypeId,
  //   contentName: "",
  //   configurationJson: {
  //     "Product Information": {
  //       "Product name": productName,
  //       "Product Description": productDescription,
  //       USP: Usp,
  //       "Key Features": keyFeatures,
  //     },
  //     "Target Audience": {
  //       "Target audience": targetAudienceProduct,
  //       "Goal of the Product/Solution": goalDescription,
  //     },
  //     "Integration & Support": {
  //       "Integration available": integrationAvailable,
  //       "Available Integration": selected,
  //     },
  //   },
  //   contentType: "Webpage",
  // };

  async function generateContent() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        contentTypeId: contentTypeId,
        contentName: "",
        contentId: "",
        configurationJson: {
          "Product Information": {
            "Product name": productName,
            "Product Description": productDescription,
            USP: Usp,
            "Key Features": keyFeatures,
          },
          "Target Audience": {
            "Target audience": targetAudienceProduct,
            "Goal of the Product/Solution": goalDescription,
          },
          "Integration & Support": {
            "Integration available": integrationAvailable,
            "Available Integration": selected,
          },
        },
        contentType: "Webpage",
        webpageTypeId: location?.state?.webpageTypeId,
      };

      setIsLoading(true);
      const resonseData = await generateContentApi(payload);
      setIsLoading(false);

      let contentId = resonseData.data.data.contentId;

      setContentID(contentId);

      const contentByIdPayload = {
        userId: userData[0]["User_Id"],
        contentId: contentId,
      };
      const contentResponseData = await getContentByIdApi(contentByIdPayload);
      setContentName(contentResponseData.data.data.data[0]["Content_Name"])

      console.log(
        contentResponseData.data.data.data[0]["Generated_Content"],
        "This is the html stting from the content gen api"
      );

      let htmlString =
        contentResponseData.data.data.data[0]["Generated_Content"];

      htmlString = htmlString.replaceAll(/^`\\html|\\`$/g, "");

      let counter = 1;
      const getReplacementHtmlSnippet = (count) => {
        return `<div class="position-relative content-upload text-center mb-4"><button class="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none" onClick="generateImage(${count})"><img src="images/generate-icon.svg" alt="generate-icon" class="me-2"/>Generate image</button><p class="font-16 font-medium mt-4 mb-1">or <label class="primary-theme-color cursor-pointer" for="uploadImg2">upload files</label> from your computer</p><p class="font-14 font-regular color-grey">( .png, .jpg, .pdf up to 25MB in size)</p><input type="file" class="cust-file" id="uploadImg2" accept="image/*" onchange="initialFileUpload(event,${count})"/></div>`;
      };

      // Replace all occurrences of <ImagePlaceHolder/> with the replacement HTML snippet
      htmlString = htmlString.replaceAll("<ImagePlaceHolder/>", () => {
        const replacement = getReplacementHtmlSnippet(counter);
        counter++; // Increment counter for the next occurrence
        return replacement;
      });

      setGeneratedContent(htmlString);
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleReferencesChange = (index, value) => {
    setFormData((prevData) => ({
      ...prevData,
      References_Citations: {
        ...prevData.References_Citations,
        references: [
          ...prevData.References_Citations.references.slice(0, index),
          value,
          ...prevData.References_Citations.references.slice(index + 1),
        ],
      },
    }));
  };

  const handleAddDiv = () => {
    const newItem = { id: state.length + 1, name: "" };
    setState([...state, newItem]);
  };

  const handleRemoveDiv = (id) => {
    if (state.length === 1) {
      // If there's only one item, do not remove it
      return;
    }
    const updatedState = state.filter((item) => item.id !== id);
    setState(updatedState);
  };

  const handleChangeName = (id, value) => {
    const updatedState = state.map((item) =>
      item.id === id ? { ...item, name: value } : item
    );
    setState(updatedState);
  };

  const isPendingOrApproved =
    approvalStatus === "Pending" || approvalStatus === "Approved";

  return (
    <div className="col-md-3 mt-4">
      <div className="px-3">
        {/* Product Information starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer"
            data-bs-toggle="collapse"
            data-bs-target="#topicKeyPoints"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Product Information
          </p>
          <div className="collapse show" id="topicKeyPoints">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="topicOrtheme"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Product name <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title="An input field allows users to specify the name of the product.Users should able to input the name of the product.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Describe some key content highlights"
                  rows={7}
                  className="form-control custom-form cust-tarea font-14 font-regular"
                  id="specifyKeyContent"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          {/* Product Description starts */}
          <div className="border-bottom cust-border mb-4">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="productDescription"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Product Description <span className="mandatory-red">*</span>
                </label>
                <span data-bs-toggle="tooltip" title="A text box allows users to provide a description of the product.I Users can input a detailed description of the product">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                <textarea
                  placeholder="Briefly describe the product"
                  rows={3}
                  className="form-control custom-form cust-tarea font-14 font-regular"
                  id="productDescription"
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          {/* Product Description ends */}

          {/* USP starts */}
          <div className="border-bottom cust-border mb-4">
            <div className="collapse show" id="usp">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="usp"
                    className="form-label mb-0 cust-label font-14 font-medium mb-2"
                  >
                    USP <span className="mandatory-red">*</span>
                  </label>
                  <span data-bs-toggle="tooltip" title=" A text box allowing users to specify the unique selling points of the product.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                  <textarea
                    placeholder="Enter the Unique Selling Proposition"
                    rows={3}
                    className="form-control custom-form cust-tarea font-14 font-regular"
                    id="usp"
                    value={Usp}
                    onChange={(e) => setUspProduct(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          {/* USP ends */}

          {/* Key Features starts */}
          <div className="border-bottom cust-border mb-4">
            <div className="collapse show" id="keyFeatures">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="keyFeatures"
                    className="form-label mb-0 cust-label font-14 font-medium mb-2"
                  >
                    Key Features <span className="mandatory-red">*</span>
                  </label>
                  <span data-bs-toggle="tooltip" title="A text box allowing users to list the key features of the product.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                  <textarea
                    placeholder="Enter the key features of the product"
                    rows={3}
                    className="form-control custom-form cust-tarea font-14 font-regular"
                    id="keyFeatures"
                    value={keyFeatures}
                    onChange={(e) => setKeyFeatures(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          {/* Key Features ends */}
        </div>
        {/* Product Information ends */}

        {/* Target audience starts */}
        <div className="border-bottom mb-4 cust-border">
          <p
            className="font-14 font-medium cursor-pointer"
            data-bs-toggle="collapse"
            data-bs-target="#target"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Target Audience
          </p>
          <div className="collapse show" id="target">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="targetAudience"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Target audience{" "}
                  <span data-bs-toggle="tooltip" title="A dropdown menu allowing users to specify the target audience for the product.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form font-14 font-regular"
                  id="targetAudience"
                  onChange={(e) => setTargetAudienceProduct(e.target.value)}
                >
                  <option value="">Select</option>{" "}
                  {/* Keep disabled attribute for the first option */}
                  <option value="Individual Consumers">
                    Individual Consumers
                  </option>
                  <option value="Corporate Clients">Corporate Clients</option>
                  <option value="Industry Professionals">
                    Industry Professionals
                  </option>
                  <option value="Educational Institutions">
                    Educational Institutions
                  </option>
                  <option value="Technological Enthusiasts">
                    Technological Enthusiasts
                  </option>
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="eventDescription"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Goal of the Product/Solution
                  <span data-bs-toggle="tooltip" title=" An input field where users can describe the primary goal or objective of the product/solution.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Briefly describe about the event"
                  rows={3}
                  className="form-control custom-form cust-tarea font-14 font-regular"
                  id="eventDescription"
                  value={goalDescription}
                  onChange={(e) => setGoalDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        {/* Target audience ends */}
      </div>

      {/* Accounts and Platform starts */}
      <div className="border-bottom cust-border mb-4">
        <p
          className="font-14 font-medium cursor-pointer "
          data-bs-toggle="collapse"
          data-bs-target="#accountPlatform"
        >
          <img
            src="images/form-acc-arrow.svg"
            alt="form-acc-arrow"
            className="me-2 form-arrow"
          />
          Account &amp; Platform{" "}
        </p>
        <div className="collapse show" id="accountPlatform">
          <div className="row">
            <div className="col-md-12 mb-3">
              <label
                htmlFor="socialMediaAccount"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Social media account <span className="mandatory-red">*</span>{" "}
                <span data-bs-toggle="tooltip" title="Displays a list of accounts integrated on the integration page.Users can select the desired social media account from this dropdown">
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
              </label>
              <select
                className="form-select custom-form  font-14 font-regular"
                id="socialMediaAccount"
                value={product.Account_And_Platform?.socialMediaAccount}
                onChange={(e) => setSocialMediaAccount(e.target.value)}
              >
                <option value="Select">Select</option>
                {account?.map((value, index) => {
                  return (
                    <option value={value?.Account_Id}>
                      {value?.Account_Name}
                    </option>
                  );
                })}
              </select>
            </div>
            {product.Account_And_Platform?.socialMediaAccount != "Select" ? (
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="platform"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Platforms
                  <span data-bs-toggle="tooltip" title="Lorem ipsumis a dummy">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                {account
                  .filter(
                    (value) =>
                      value.Account_Id ===
                      product.Account_And_Platform?.socialMediaAccount
                  )
                  .map((filteredAccount) => {
                    return (
                      <>
                        <div
                          className="form-check"
                          hidden={filteredAccount.Facebook_Credentials == 0}
                        >
                          <input
                            className="form-check-input cust-check box-shadow-none"
                            type="checkbox"
                            defaultValue
                            id="fbPost"
                            checked={product.Account_And_Platform.fbPost}
                            onChange={(e) => setfbPost(e.target.value)}
                          />
                          <label
                            className="form-check-label font-14 font-medium check-label"
                            htmlFor="fbPost"
                          >
                            Facebook post
                          </label>
                        </div>

                        <div
                          className="form-check"
                          hidden={filteredAccount.Linkedin_Credentials == 0}
                        >
                          <input
                            className="form-check-input cust-check box-shadow-none"
                            type="checkbox"
                            defaultValue
                            id="LinkedinPost"
                            checked={product.Account_And_Platform.linkedinPost}
                            onChange={(e) => setLinkedInPost(e.target.value)}
                          />
                          <label
                            className="form-check-label  font-14 font-medium check-label"
                            htmlFor="LinkedinPost"
                          >
                            Linkedin post
                          </label>
                        </div>

                        <div
                          className="form-check"
                          hidden={filteredAccount.Twitter_Credentials == 0}
                        >
                          <input
                            className="form-check-input cust-check box-shadow-none"
                            type="checkbox"
                            defaultValue
                            id="twitterPost"
                            checked={product.Account_And_Platform.twitterPost}
                            onChange={(e) => setTwitterPost(e.target.value)}
                          />
                          <label
                            className="form-check-label  font-14 font-medium check-label"
                            htmlFor="twitterPost"
                          >
                            Twitter post
                          </label>
                        </div>
                        <div
                          className="form-check"
                          hidden={filteredAccount.Instagram_Credentials == 0}
                        >
                          <input
                            className="form-check-input cust-check box-shadow-none"
                            type="checkbox"
                            defaultValue
                            id="instaCap"
                            checked={product.Account_And_Platform.instaCap}
                            onChange={(e) => setInstaCap(e.target.value)}
                          />
                          <label
                            className="form-check-label  font-14 font-medium check-label"
                            htmlFor="instaCap"
                          >
                            Instagram caption
                          </label>
                        </div>
                      </>
                    );
                  })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* Accounts and Platform ends */}

      {/* Integration starts */}
      <div className="border-bottom cust-border mb-4">
        <p
          className="font-14 font-medium cursor-pointer"
          data-bs-toggle="collapse"
          data-bs-target="#integrationSupport"
        >
          <img
            src="images/form-acc-arrow.svg"
            alt="form-acc-arrow"
            className="me-2 form-arrow"
          />
          Integration & Support
        </p>
        <div className="collapse show" id="integrationSupport">
          <div className="row">
            <div className="col-md-12 mb-3">
              <label
                htmlFor="SpecifySEO"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Integration available
                <span data-bs-toggle="tooltip" title="Users can specify whether integration is available for the product.">
                  <img
                    src="images/info-icon.svg"
                    alt="info-icon"
                    className="ms-2 mb-1"
                  />
                </span>
              </label>
              <div className="d-flex align-items-center gap-5 mt-2">
                <div className="form-check d-flex align-items-center gap-2">
                  <input
                    className="form-check-input mt-0 green-radio"
                    type="radio"
                    name="integrationAvailable"
                    id="IntegrationYes"
                    defaultChecked={integrationAvailable}
                    onChange={(e) => setIntegrationAvailable(true)}
                  />
                  <label
                    className="form-check-label font-14 font-medium"
                    htmlFor="IntegrationYes"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check d-flex align-items-center gap-2">
                  <input
                    className="form-check-input mt-0 green-radio"
                    type="radio"
                    name="integrationAvailable"
                    id="IntegrationNo"
                    defaultChecked={!integrationAvailable}
                    onChange={(e) => setIntegrationAvailable(false)}
                  />
                  <label
                    className="form-check-label font-14 font-medium"
                    htmlFor="IntegrationNo"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            {integrationAvailable && (
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="SpecifyFormat"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Available Integration
                </label>
                <div
                  className="custom-form w-100 px-2 py-1 d-flex align-items-center flex-wrap"
                  htmlFor="availInteration"
                >
                  {selected.map((item, index) => (
                    <span
                      key={index}
                      className="blue-batch d-inline-flex align-items-center me-1"
                    >
                      <span className="font-14 font-medium">{item}</span>
                      <img
                        src="images/close-icon.svg"
                        alt="close-icon"
                        className="ms-3 cursor-pointer cross-size"
                        onClick={() => removeTag(index)}
                      />
                    </span>
                  ))}
                  <input
                    type="text"
                    className="border-0 form-control w-50 box-shadow-none"
                    id="availInteration"
                    value={tagInputValue}
                    onChange={(e) => setTagInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <span className="cursor-pointer ms-auto" onClick={handleAdd}>
                    <img src="images/add-plus-blue.svg" alt="add-plus-blue" />
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Integration ends */}

      <div className="d-flex justify-content-end py-3 form-foot">
        <button className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2">
          Cancel
        </button>
        <button
          className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none"
          onClick={generateContent}
          disabled={!areAllMandatoryFieldsFilled()} // Disable button if any mandatory field is empty
        >
          <img
            src="images/generate-icon.svg"
            alt="generate-icon"
            className="me-2"
          />
          {contentID == "" ? "Generate" : "Regenerate"}
        </button>
      </div>
    </div>
  );
}
