import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCommonContext } from "../../contexts/CommonContext";
import { getRecentActivitiesApi } from "../../services/api";

export default function RecentActivities() {
  const { userData } = useCommonContext();
  const [recentActivitiesData, setRecentActivitiesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAsc, setIsAsc] = useState("DESC");
  const [sortColumn, setSortColumn] = useState("Content_Title");
  const { verifyJwtData } = useCommonContext();

  const navigate = useNavigate();
  //useEffect()
  useEffect(() => {
    getRecentActivities();
  }, [isAsc, sortColumn]);

  async function getRecentActivities() {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const payload = {
        userId,
        sortColumn: sortColumn,
        sortOrder: isAsc ? (isAsc === "ASC" ? "DESC" : "ASC") : "DESC",
      };
      const responseData = await getRecentActivitiesApi(payload);

      setRecentActivitiesData(responseData.data.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  return (
    <div className="bg-white p-3 radius-16  hub-card mb-3">
      <div className="justify-content-between d-flex align-items-center mb-3">
        <span className="font-18 font-bold">Recent Activities</span>
        <span
          className="font-medium font-16"
          onClick={() => {
            navigate("/requests");
          }}
          hidden={recentActivitiesData.length === 0}
        >
          <a className="text-decoration-none" style={{ cursor: 'pointer' }}>See all</a>
        </span>
      </div>
      {recentActivitiesData.length !== 0 ? (
        <div className="table-responsive theme-tbl recent-activity">
          <table className="table table-borderless">
            <thead className="font-medium bg-white">
              <tr>
                <th>
                  Title{" "}
                  <img
                    className="cursor-pointer ms-2"
                    src={
                      isAsc && sortColumn === "Content_Title"
                        ? "images/sort-down.svg"
                        : "images/sort-up.svg"
                    }
                    alt="sort-up"
                    onClick={() => {
                      setIsAsc(!isAsc);
                      setSortColumn("Content_Title");
                    }}
                  />
                </th>
                <th className="text-center">Status</th>
                <th>
                  Type
                  <img
                    className="cursor-pointer ms-2"
                    alt="sort-up"
                    src={
                      isAsc && sortColumn === "Content_Type"
                        ? "images/sort-up.svg"
                        : "images/sort-down.svg"
                    }
                    onClick={() => {
                      setIsAsc(!isAsc);
                      setSortColumn("Content_Type");
                    }}
                  />
                </th>
                <th>
                  Created by
                  <img
                    className="cursor-pointer ms-2"
                    src={
                      isAsc && sortColumn === "Requested_By"
                        ? "images/sort-up.svg"
                        : "images/sort-down.svg"
                    }
                    alt="sort-up"
                    onClick={() => {
                      setIsAsc(!isAsc);
                      setSortColumn("Requested_By");
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody className="font-medium" style={{ cursor: 'pointer' }}>
              {recentActivitiesData.map((values, index) => {
                return (
                  <tr>
                    <td
                      onClick={() => {
                        navigate("/approvecontent", {
                          state: {
                            contentTypeId: values?.Content_Type_Id,
                            contentId: values?.Content_Id,
                            socialMediaId : values?.Social_Media_Content_Id,
                            contentName : values?.Content_Name
                          },
                        });
                      }}
                    >
                      <a>
                        {values?.Content_Title.length > 35
                          ? `${values?.Content_Title.substring(0, 35)}...`
                          : values?.Content_Title}
                      </a>
                    </td>
                    <td className="text-center">
                      <span
                        className={`${
                          values.Approval_Status === "Pending"
                            ? "tbl-status-yellow"
                            : values.Approval_Status === "Approved"
                            ? "tbl-status-green"
                            : "tbl-status-red"
                        } tbl-status`}
                      >
                        {values?.Approval_Status}
                      </span>
                    </td>
                    <td>{values?.Content_Type}</td>
                    <td>{values?.Requested_By}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="d-block text-center">
            <span className="font-bold font-14 d-block mb-3">
              <img src="images/no-approvals.svg" alt="no-post-icon" />
            </span>
            <span className="font-bold font-14 d-block">
              No Pending Approvals
            </span>
            <span className="font-bold font-12 d-block mb-3 color-grey">
              No pending approval requests found at the moment
            </span>
            <a
              className="font-bold font-12 d-block blue-link text-decoration-none"
              onClick={() => {
                navigate("/requests");
              }}
            >
              View Requests
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
