import React, { useState } from "react";
import { useCreateContentContext } from "../../../contexts/CreateContentContext";
import { generateContentApi } from "../../../services/api";
import { getContentByIdApi } from "../../../services/api";
import { useCommonContext } from "../../../contexts/CommonContext";
import { useLocation } from "react-router-dom";

export default function GeneralwebpageFields() {
  // const [businessUnitName, setBusinessUnitName] = useState('');
  // const [purposeDescription, setPurposeDescription] = useState('');
  // const [usp, setUsp] = useState('');
  // const [targetAudience, setTargetAudience] = useState('');
  // const [serviceAndOfferings, setServiceAndOfferings] = useState('');
  // const [toneOfContent, setToneOfContent] = useState('');
  // const [styleOfContent, setStyleOfContent] = useState('');
  // const [keyAchievements, setKeyAchievements] = useState('');
  // const [preferredArticleLength, setPreferredArticleLength] = useState('');
  // const [sectionInfo, setSectionInfo] = useState('');

  const {
    setGeneratedContent,
    setFormData,
    contentID,
    setContentID,
    approvalStatus,
    account,
    setAccount,
    businessUnitName,
    setBusinessUnitName,
    purposeDescription,
    setPurposeDescription,
    usp,
    setUsp,
    targetAudience,
    setTargetAudience,
    serviceAndOfferings,
    setServiceAndOfferings,
    toneOfContent,
    setToneOfContent,
    styleOfContent,
    setStyleOfContent,
    keyAchievements,
    setKeyAchievements,
    preferredArticleLength,
    setPreferredArticleLength,
    sectionInfo,
    setSectionInfo,
    general,
    setGeneral,
    contentTypeId,
    socialMediaAccount,
    setSocialMediaAccount,
    fbPost,
    setfbPost,
    linkedinPost,
    setLinkedInPost,
    twitterPost,
    setTwitterPost,
    instaCap,
    setInstaCap,
    contentName,setContentName,
setIsLoading,
  } = useCreateContentContext();

  const { userData } = useCommonContext();

  const [state, setState] = useState([{ id: 1, name: "" }]);

  const handleInputChange = (e, setState) => {
    setState(e.target.value);
  };

const location = useLocation();
  console.log(location, "this is the location from web page");
  const payload = {
    userId: userData[0]["User_Id"],
    contentTypeId: contentTypeId,
    contentName: "",

    configurationJson: {
      General_Info: {
        "Business Unit Name": businessUnitName,
        Purpose_of_the_Website: purposeDescription,
        USP: usp,
      },
      Target_Audience: {
        Target_Audience: targetAudience,
      },
      Service_and_Offerings: {
        "Service_&_Offerings": serviceAndOfferings,
      },
      "Tone_&_Style": {
        Tone_of_the_content: toneOfContent,
        Style_of_the_content: styleOfContent,
      },
      Key_Achievements: {
        Key_Achievements: keyAchievements,
      },
      Preferred_Article_Length: {
        "Length_&_Structure": preferredArticleLength,
      },
      Section_Info: {
        Section: sectionInfo,
      },
    },
    contentType: "Webpage",
  };

  async function generateContent() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        contentTypeId: contentTypeId,
        contentName: "",
        contentId: "",
        configurationJson: {
          General_Info: {
            "Business Unit Name": businessUnitName,
            Purpose_of_the_Website: purposeDescription,
            USP: usp,
          },
          Target_Audience: {
            Target_Audience: targetAudience,
          },
          Service_and_Offerings: {
            "Service_&_Offerings": serviceAndOfferings,
          },
          "Tone_&_Style": {
            Tone_of_the_content: toneOfContent,
            Style_of_the_content: styleOfContent,
          },
          Key_Achievements: {
            Key_Achievements: keyAchievements,
          },
          Preferred_Article_Length: {
            "Length_&_Structure": preferredArticleLength,
          },
          Section_Info: {
            Section: sectionInfo,
          },
        },
        contentType: "Webpage",
webpageTypeId: location?.state?.webpageTypeId,
      };

setIsLoading(true);
      const resonseData = await generateContentApi(payload);
setIsLoading(false);

      let contentId = resonseData.data.data.contentId;

      setContentID(contentId);

      const contentByIdPayload = {
        userId: userData[0]["User_Id"],
        contentId: contentId,
      };
      setIsLoading(true);
      const contentResponseData = await getContentByIdApi(contentByIdPayload);
      setIsLoading(false);

      console.log(
        contentResponseData.data.data.data[0]["Generated_Content"],
        "This is the html stting from the content gen api"
      );

      setContentName(contentResponseData.data.data.data[0]["Content_Name"])

      let htmlString =
        contentResponseData.data.data.data[0]["Generated_Content"];

      htmlString = htmlString.replaceAll(/^`\\html|\\`$/g, "");

      let counter = 1;
      const getReplacementHtmlSnippet = (count) => {
        return `<div class="position-relative content-upload text-center mb-4"><button class="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none" onClick="generateImage(${count})"><img src="images/generate-icon.svg" alt="generate-icon" class="me-2"/>Generate image</button><p class="font-16 font-medium mt-4 mb-1">or <label class="primary-theme-color cursor-pointer" for="uploadImg2">upload files</label> from your computer</p><p class="font-14 font-regular color-grey">( .png, .jpg, .pdf up to 25MB in size)</p><input type="file" class="cust-file" id="uploadImg2" accept="image/*" onchange="initialFileUpload(event,${count})"/></div>`;
      };

      // Replace all occurrences of <ImagePlaceHolder/> with the replacement HTML snippet
      htmlString = htmlString.replaceAll("<ImagePlaceHolder/>", () => {
        const replacement = getReplacementHtmlSnippet(counter);
        counter++; // Increment counter for the next occurrence
        return replacement;
      });

      setGeneratedContent(htmlString);
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleReferencesChange = (index, value) => {
    setFormData((prevData) => ({
      ...prevData,
      References_Citations: {
        ...prevData.References_Citations,
        references: [
          ...prevData.References_Citations.references.slice(0, index),
          value,
          ...prevData.References_Citations.references.slice(index + 1),
        ],
      },
    }));
  };

  const handleAddDiv = () => {
    const newItem = { id: state.length + 1, name: "" };
    setState([...state, newItem]);
  };

  const handleRemoveDiv = (id) => {
    if (state.length === 1) {
      // If there's only one item, do not remove it
      return;
    }
    const updatedState = state.filter((item) => item.id !== id);
    setState(updatedState);
  };

  const handleChangeName = (id, value) => {
    const updatedState = state.map((item) =>
      item.id === id ? { ...item, name: value } : item
    );
    setState(updatedState);
  };

  const isPendingOrApproved =
    approvalStatus === "Pending" || approvalStatus === "Approved";

  return (
    <div className="col-md-3 mt-4">
      <div className="px-3">
        {/* General Information starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#topicKeyPoints"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            General Info
          </p>
          <div className="collapse show" id="topicKeyPoints">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="topicOrtheme"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Business Unit Name <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title="An input field allowing users to specify the name of the business unit.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Enter your product name"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="specifyKeyContent"
                  value={businessUnitName}
                  onChange={(e) => handleInputChange(e, setBusinessUnitName)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="eventDescription"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Purpose of the Website
                  <span data-bs-toggle="tooltip" title=" A text box allowing users to provide the purpose of the website.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Briefly describe about the product"
                  rows="3"
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="eventDescription"
                  value={purposeDescription}
                  onChange={(e) => handleInputChange(e, setPurposeDescription)}
                ></textarea>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="usp"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  USP
                  <span data-bs-toggle="tooltip" title="A text box allowing users to specify the unique selling points of the website.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Briefly describe about the event"
                  rows="3"
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="usp"
                  value={usp}
                  onChange={(e) => handleInputChange(e, setUsp)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        {/* General Information ends */}
        {/* target audience starts*/}
        <div className="border-bottom mb-4 cust-border">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#targetcontainer"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Target Audience
          </p>
          <div className="collapse show" id="targetcontainer">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="targetAudience"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Target audience{" "}
                  <span data-bs-toggle="tooltip" title="A dropdown menu allowing users to specify the target audience for the website.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="targetAudience"
                  value={targetAudience}
                  onChange={(e) => handleInputChange(e, setTargetAudience)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Professionals">Professionals</option>
                  <option value="Consumers">Consumers</option>
                  <option value="Businesses">Businesses</option>
                  <option value="Educators and Students">
                    Educators and Students
                  </option>
                  <option value="Industry Leaders">Industry Leaders</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* target audience ends */}
        {/* Add other input fields in a similar manner */}
        {/* Service and Offerings */}
        <div className="border-bottom mb-4 cust-border">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#serviceAndOfferings"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Service and Offerings
          </p>
          <div className="collapse show" id="serviceAndOfferings">
            <div className="col-md-12 mb-3"
            >
              <label
                htmlFor="serviceAndOfferings"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Service & Offerings
                <span

                 data-bs-toggle="tooltip" title=" A text box allowing users to provide information about the services and offerings provided by the website.">
                  <img
                    src="images/info-icon.svg"
                    alt="info-icon"
                    className="ms-2"
                  />
                </span>
              </label>
              <textarea
                placeholder="Briefly describe about the event"
                rows="3"
                className="form-control custom-form cust-tarea  font-14 font-regular"
                value={serviceAndOfferings}
                onChange={(e) => handleInputChange(e, setServiceAndOfferings)}
              ></textarea>
            </div>
          </div>
        </div>
        {/* Service and Offerings ends */}
        {/* Tone and Style */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#toneAndStyle"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Tone & Style
          </p>
          <div className="collapse show" id="toneAndStyle">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="toneContent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Tone of the content
                  <span data-bs-toggle="tooltip" title="allows users to specify the tone of the content.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="toneContent"
                  value={toneOfContent}
                  onChange={(e) => handleInputChange(e, setToneOfContent)}
                >
                  {" "}
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Informative">Informative</option>
                  <option value="Conversational">Conversational</option>
                  <option value="Professional">Professional</option>
                  <option value="Inspirational">Inspirational</option>
                  <option value="Casual">Casual</option>
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="styleContent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Style of the content
                  <span data-bs-toggle="tooltip" title="allowing users to specify the style of the content.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="styleContent"
                  value={styleOfContent}
                  onChange={(e) => handleInputChange(e, setStyleOfContent)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Long-form Articles">Long-form Articles</option>
                  <option value="Short-form Blog Posts">
                    Short-form Blog Posts
                  </option>
                  <option value="Visual Content"> Visual Content</option>
                  <option value="Interactive Content">
                    {" "}
                    Interactive Content
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Tone and Style ends */}
        {/* Accounts and Platform starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#accountPlatform"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Account &amp; Platform{" "}
          </p>
          <div className="collapse show" id="accountPlatform">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="socialMediaAccount"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Social media account <span className="mandatory-red">*</span>{" "}
                  <span data-bs-toggle="tooltip" title="Displays a list of accounts integrated on the integration page.Users can select the desired social media account from this dropdown">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="socialMediaAccount"
                  value={general.Account_And_Platform?.socialMediaAccount}
                  onChange={(e) => setSocialMediaAccount(e.target.value)}
                >
                  <option value="Select">Select</option>
                  {account?.map((value, index) => {
                    return (
                      <option value={value?.Account_Id}>
                        {value?.Account_Name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {general.Account_And_Platform?.socialMediaAccount != "Select" ? (
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="platform"
                    className="form-label mb-0 cust-label font-14 font-medium mb-2"
                  >
                    Platforms
                    <span
                      data-bs-toggle="tooltip"
                      title="Lorem ipsumis a dummy"
                    >
                      <img
                        className="ms-2 mb-1"
                        src="images/info-icon.svg"
                        alt="info-icon"
                      />
                    </span>
                  </label>
                  {account
                    .filter(
                      (value) =>
                        value.Account_Id ===
                        general.Account_And_Platform?.socialMediaAccount
                    )
                    .map((filteredAccount) => {
                      return (
                        <>
                          <div
                            className="form-check"
                            hidden={filteredAccount.Facebook_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="fbPost"
                              checked={general.Account_And_Platform.fbPost}
                              onChange={(e) => setfbPost(e.target.value)}
                            />
                            <label
                              className="form-check-label font-14 font-medium check-label"
                              htmlFor="fbPost"
                            >
                              Facebook post
                            </label>
                          </div>

                          <div
                            className="form-check"
                            hidden={filteredAccount.Linkedin_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="LinkedinPost"
                              checked={
                                general.Account_And_Platform.linkedinPost
                              }
                              onChange={(e) => setLinkedInPost(e.target.value)}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="LinkedinPost"
                            >
                              Linkedin post
                            </label>
                          </div>

                          <div
                            className="form-check"
                            hidden={filteredAccount.Twitter_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="twitterPost"
                              checked={general.Account_And_Platform.twitterPost}
                              onChange={(e) => setTwitterPost(e.target.value)}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="twitterPost"
                            >
                              Twitter post
                            </label>
                          </div>
                          <div
                            className="form-check"
                            hidden={filteredAccount.Instagram_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="instaCap"
                              checked={general.Account_And_Platform.instaCap}
                              onChange={(e) => setInstaCap(e.target.value)}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="instaCap"
                            >
                              Instagram caption
                            </label>
                          </div>
                        </>
                      );
                    })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* Accounts and Platform ends */}
        {/* Key Achievements */}
        <div className="border-bottom mb-4 cust-border">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#keyAchievements"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Key Achievements
          </p>
          <div className="collapse show" id="keyAchievements">
            <div className="col-md-12 mb-3">
              <label
                htmlFor="keyAchievements"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Key Achievements
                <span data-bs-toggle="tooltip" title="A text box allowing users to list key achievements related to the website.">
                  <img
                    src="images/info-icon.svg"
                    alt="info-icon"
                    className="ms-2"
                  />
                </span>
              </label>
              <textarea
                placeholder="Specify key achievements "
                rows="3"
                className="form-control custom-form cust-tarea  font-14 font-regular"
                id="keyAchievements"
                value={keyAchievements}
                onChange={(e) => handleInputChange(e, setKeyAchievements)}
              ></textarea>
            </div>
          </div>
        </div>
        {/* Key Achievements ends */}
        {/* Preferred article length */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#preferredArticleLength"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Length of the Content
          </p>
          <div className="collapse show" id="preferredArticleLength">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="lengthStructureLabel"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Preferred article length
                  <span data-bs-toggle="tooltip" title="users to specify the preferred length of articles or content.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="lengthStructureLabel"
                  value={preferredArticleLength}
                  onChange={(e) =>
                    handleInputChange(e, setPreferredArticleLength)
                  }
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Short">Short</option>
                  <option value="Medium">Medium</option>
                  <option value="Long">Long</option>
                  <option value="Very Long">Very Long</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Preferred article length ends */}
        {/* Section info */}
        <div className="border-bottom mb-4 cust-border">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#sectionInfo"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Section Info
          </p>
          <div className="collapse show" id="sectionInfo">
            <div className="col-md-12 mb-3">
              <label
                htmlFor="sectionInfo"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Section
                <span data-bs-toggle="tooltip" title="A text box allowing users to provide information about different sections of the website.">
                  <img
                    src="images/info-icon.svg"
                    alt="info-icon"
                    className="ms-2 mb-1"
                  />
                </span>
              </label>
              <textarea
                placeholder="About Us - Vision and Mission, Our Story, What makes us unique?"
                rows="7"
                className="form-control custom-form cust-tarea  font-14 font-regular"
                value={sectionInfo}
                onChange={(e) => handleInputChange(e, setSectionInfo)}
              ></textarea>
            </div>
          </div>
        </div>
        {/* Section info ends */}
        <div className="d-flex justify-content-end py-3 form-foot">
          <button className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2">
            Cancel
          </button>
          <button
            className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
            onClick={generateContent}
            disabled={
              isPendingOrApproved ||
              socialMediaAccount == "Select" ||
              businessUnitName == ""
            }
          >
            <img
              src="images/generate-icon.svg"
              alt="generate-icon"
              className="me-2"
            />
             {contentID == "" ? "Generate" : "Regenerate"}
          </button>
        </div>
      </div>
    </div>
  );
}
