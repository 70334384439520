import { useEffect, useState } from "react";
import Logo from "./Logo/Logo";
import BasePricing from "./basepricing/BasePricing";
import KeyHighlights from "./keyhighlights/KeyHighlights";
import AzureReview from "./AzureReview/AzureReview";
import AwsReview from "./AwsReview/AwsReview";
import Overview from "./overview/Overview";
import { get_productbyId_FeaturePricing, select_one_competitor } from "../../services/api";
import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { useCommonContext } from "../../contexts/CommonContext";
import Loader from "../Loader/Loader";
import AzureReviewPopup from "./AzureReview/AzureReviewPopup";

export default function OverviewResult() {
  const { userData } = useCommonContext();
  const { logoArray, setLogoArray, productId,Avg ,setAvg,Ratings,setRatings,RatingsReviews,setRatingsReviews,stars_5,setstars_5,stars_4,setstars_4,stars_3,setstars_3,stars_2,setstars_2,stars_1,setstars_1, azureReviewArray,
    setAzureReviewArray } = useCreateProductContext();

  const [overviewArray, setOverViewArray] = useState([]);
  const [keyFeatureArray, setKeyfeature] = useState([]);
  const [azureArray, setAzurearray] = useState([]);
  const [azurepopArray, setAzurepoparray] = useState([])
  const [awsArray, setAwsarray] = useState([]);
  const [basePricing, setBasePricing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllCompetitors();
  }, []);
  console.log(azureArray,"oooioooopppp")
  console.log(awsArray,"ooooooopppp")
  console.log(azurepopArray,"popopopopopopo")
  async function getAllCompetitors() {
    try {
      let logoArrayState = logoArray;
      let overviewArrayState = overviewArray;
      let keyFeatureArrayState = keyFeatureArray;
      let baseArray = basePricing;
      let azurearray = azureArray;
      let azurearraypopup = azurepopArray;
      let awsarray = awsArray;

      const payloadFirst = {
        productId: productId,
        userId: userData[0]["User_Id"]
      };
      setIsLoading(true)
      const responseData = await get_productbyId_FeaturePricing(payloadFirst);
      console.log(responseData.data, "get the Product Feature Pricing");


      let azureReviewClone = azureReviewArray;

      azureReviewClone.push(
        responseData.data.data
      )


      

      if (responseData.data.data.Product_Logo) {
        const productLogo = responseData.data.data.Product_Logo;

        console.log(responseData.data.data,"HHSHSSHHSHSh")
        if (!logoArrayState.includes(productLogo)) {
          logoArrayState.push(productLogo);
          setLogoArray([...logoArrayState]); // Update logoArray
        }
      }

      overviewArrayState.push(responseData.data.data.Product_Overview);
      keyFeatureArrayState.push(responseData.data.data.Feature_Description);
      const pricingCost = responseData.data.data.Pricing_Cost;
      const pricingUnit = responseData.data.data.Pricing_Unit;
      const basePricings = pricingCost + "/" + pricingUnit;
      baseArray.push(basePricings);








      const azureaverage = responseData.data.data.Azure_Average;
      const azureratings = responseData.data.data.Azure_Ratings;
      if (azureaverage !== null && azureratings !== null) {
        const azure = `${azureaverage} (${azureratings})`;
        azurearray.push(azure);
      } else {
        azurearray.push(null);
      }

      const azureratingsreviews = responseData.data.data.Azure_Ratings_reviews;
      const azurestar5 = responseData.data.data.stars_5;
      const azurestar4 = responseData.data.data.stars_4;
      const azurestar3 = responseData.data.data.stars_3;
      const azurestar2 = responseData.data.data.stars_2;
      const azurestar1 = responseData.data.data.stars_1;
      azurearraypopup.push(azureratingsreviews, azurestar5, azurestar4, azurestar3, azurestar2, azurestar1);


      const awsaverage = responseData.data.data.Aws_Average;
      const awsratings = responseData.data.data.Aws_Ratings_reviews;
      if (awsaverage !== null && awsratings !== null) {
        const aws = `${awsaverage} ${awsratings}`;
      awsarray.push(aws);
      } else {
        awsarray.push(null);
      }

      const payload = {
        productId: productId,
        userId: userData[0]["User_Id"]
      };

      const responseDataComp = await select_one_competitor(payload); // Moved here


      

      azureReviewClone = azureReviewClone.concat(responseDataComp.data.data)

      setAzureReviewArray(azureReviewClone)


      setIsLoading(false)
      console.log(responseDataComp.data.data[0], "select competitors");

      let logoArrayLoc = responseDataComp.data.data.map((value, index) => value.Product_Logo);
      logoArrayLoc.forEach(logo => {  
        if (!logoArrayState.includes(logo)) {
          logoArrayState.push(logo);
        }
      });

      setLogoArray([...logoArrayState]); // Update logoArray
      setOverViewArray(overviewArrayState.concat(responseDataComp.data.data.map(value => value.Overview)));
      setKeyfeature(keyFeatureArrayState.concat(responseDataComp.data.data.map(value => value.Feature_Names)));

      setBasePricing(baseArray.concat(responseDataComp.data.data.map(value => {
        const pricingUnit = value.Pricing_Unit ? value.Pricing_Unit : "Month";
        const basePricings = value.Pricing_Cost ? value.Pricing_Cost : "0"  + "/" + pricingUnit;

        if (value.Pricing_Unit && value.Pricing_Cost ){
          return basePricings;
        }else{
          return value.Base_Pricing ? value.Base_Pricing  : "0 / Month"
        }

       
      })));
      
      setAzurearray(azurearray.concat(responseDataComp.data.data.map(value => {
        const azureaverage = value.Azure_Average;
        const azureratings = value.Azure_Ratings;
        return azureaverage && azureratings ? `${azureaverage} (${azureratings})` : null;
      })));

      setAwsarray(awsarray.concat(responseDataComp.data.data.map(value => {
        const awsaverage = value.Aws_Average;
        const awsratings = value.Aws_Ratings_reviews;
        return awsaverage && awsratings ? `${awsaverage} ${awsratings}` : null;
      })));

      setAzurepoparray(azurearraypopup.concat(responseDataComp.data.data.map(value => {
        const azureratingsreviews = value.Azure_Ratings_reviews;
        const azurestar5 = value.stars_5;
        const azurestar4 = value.stars_4;
        const azurestar3 = value.stars_3;
        const azurestar2 = value.stars_2;
        const azurestar1 = value.stars_1;
        return {
          azureratingsreviews,
          azurestar5,
          azurestar4,
          azurestar3,
          azurestar2,
          azurestar1
        };


      },
    ))
  );
      

    } catch (error) {
      console.log(error.message);
    }
  }

  console.log(azurepopArray,"state variable")

  
  return (
    <>
   
    <div className="d-flex justify-content-center align-items-center">
      {isLoading ? (
        <Loader /> // Loader will be centered
      ) : (
        <table className="table table-borderless">
          <tbody className="font-medium">
            <Logo logoArray={logoArray} />
            <Overview overviewArray={overviewArray} />
            <KeyHighlights keyFeatureArray={keyFeatureArray} />
            <AzureReview />
            <AwsReview awsArray={awsArray} />
            <BasePricing basePricing={basePricing} />
          </tbody>
        </table>
      )}
    </div>
     <AzureReviewPopup/>
    </>
  );
}
