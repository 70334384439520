import React, { useState, useContext,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkMailDetailsApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";

export default function ForgotPassword() {
  const { openToast } = useCommonContext();

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [PasswordEnabled, setPasswordEnabled] = useState(false);

  const handleEmailChange = (e) => {
    console.log(e.target.value, "entered email address");
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;

    if (
      e.target.value !== null &&
      e.target.value !== "" &&
      emailRegex.test(e.target.value)
    ) {
      setPasswordEnabled(true);
    } else {
      setPasswordEnabled(false);
    }
    setEmail(e.target.value);
  };

  const handleNextClick = async (email) => {
    if (PasswordEnabled) {
      try {
        console.log(email);
        const responseData = await checkMailDetailsApi({ email });
        if (responseData.status === 200) {
          openToast("Email Sent Succesfully", "images/toast-check.svg");
        }
      } catch (error) {
        if (error.response.request.status === 400) {
          openToast(
            error?.response?.data?.message,
            "images/red-cross-toast.svg"
          );
        }
      }
    }
  };

  const handleBackToLoginClick = () => {
    navigate("/");
  };


  useEffect(() => {
    let currentIndex = 0; // Initialize index to track which button to click
  
    const interval = setInterval(() => {
      // Click the button corresponding to the current index
      document.getElementById(`button${currentIndex + 1}`).click();
  
      // Increment the index for the next iteration
      currentIndex = (currentIndex + 1) % 3; // Ensure index loops between 0, 1, and 2
    }, 3000); // Interval duration (3000ms = 3 seconds)
  
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          {/*Left container starts here */}
          <div className=" col-xl-7 d-xl-block d-none py-2 pe-2">
            <div className="login-bg-height position-relative my-0">
              <div className="login-bg-height login-bg-img">
                <div
                  id="carouselExampleIndicators"
                  className="carousel pt-5 slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators custom-carousel justify-content-start mx-5" >
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={0}
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                      id="button1"
                      class="active"
                    />
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={1}
                      aria-label="Slide 2"
                      id="button2"
                    />
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={2}
                      aria-label="Slide 3"
                      id="button3"
                    />
                  </div>
                  <div className="carousel-inner mx-5 py-5 mt-5">
                    <div className="carousel-item active">
                      <h5 className="mb-3 font-bold color-white font-28">
                      Rapid Article Production                      </h5>
                      <p className="mt-3 mb-5 font-18 font-regular color-white content-wrap">
                      Instantly create compelling content with AI for articles
                      </p>
                    </div>
                    <div className="carousel-item">
                      <h5 className="mb-3 font-bold color-white font-32">
                      Seamless Social Media Post Generation

                      </h5>
                      <p className="mt-3 mb-5 font-16 font-regular color-white content-wrap">
                      Instantly create social media post with AI.

                      </p>
                    </div>
                    <div className="carousel-item">
                      <h5 className="mb-3 font-bold color-white font-32">
                      Event Management

                      </h5>
                      <p className="mt-3 mb-5 font-16 font-regular color-white content-wrap">
                      Transform your event experience with AI-driven content creation for seamless engagement across articles, blogs, and social platforms.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Left container ends here */}
          {/*Right container starts here */}
          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
            <div className="form-signup">
              <div className="row justify-content-center">
                <div className="col-md-10 col-sm-12 py-lg-0 py-4">
                  <h5 className="mb-3 font-bold primary-darkblue font-32">
                    Forgot Password
                  </h5>
                  <p className="mt-3 mb-5 font-16 font-regular grey-v2">
                    Please enter your details to signup
                  </p>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="Emailaddress"
                        className="form-label mb-0 cust-label font-14 font-medium mb-2"
                      >
                        Email address
                      </label>
                      <input
                        type="text"
                        placeholder="Enter email address"
                        className="form-control custom-form py-2 font-14 font-regular"
                        id="Emailaddress"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    disabled={!PasswordEnabled}
                    className={`btn px-4 mt-5 w-100 primary-gradient-btn text-white gap-2 text-center font-bold font-16 text-nowrap`}
                    data-bs-dismiss="modal"
                    onClick={() => handleNextClick(email)}
                  >
                    Next
                  </button>
                  <button
                    type="button"
                    className="btn px-4 mt-3 w-100 violet-btn rounded-3 shadow-none  d-flex align-items-center justify-content-center font-bold font-16 text-nowrap"
                    data-bs-dismiss="modal"
                    onClick={handleBackToLoginClick}
                  >
                    Back to Login
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*Right container ends here */}
        </div>
      </div>
      
    </>
  );
}