import { useEffect } from "react";
import { useCreateProductContext } from "../../../contexts/CreateProductContext"

export default function AzureReviewPopup() {

   const { 
    azurePopupArray,
    setAzurePopupArray} = useCreateProductContext()

    // const azurePopupArray = {
    //     "Product_Logo": "https://avaeusspstgbsdev.blob.core.windows.net/imagecontainer/Cloudgen_9f7365e9-3d3a-4c08-a8f7-33990b8e3654.png",
    //     "Product_Overview": "Terraform codegeneration",
    //     "Feature_Description": "Direct Terraform deployment",
    //     "Pricing_Cost": "100",
    //     "Pricing_Unit": "Month",
    //     "Azure_Average": "4.6",
    //     "Azure_Ratings": "17",
    //     "Azure_Ratings_reviews": "17",
    //     "stars_5": "12",
    //     "stars_4": "1",
    //     "stars_3": "3",
    //     "stars_2": "1",
    //     "stars_1": "0",
    //     "Aws_Average": null,
    //     "Aws_Ratings_reviews": null
    //   }


    console.log(
        azurePopupArray,"This is the pop array!!!"
    )
    // azurePopupArray

  

    function fillStars(averageStars) {
        let coundOfFilledStars = 0;
    if (!Number.isNaN(averageStars)) {
      coundOfFilledStars = Math.round(Number(averageStars))
    }

    let indexArray = [1, 2, 3, 4, 5]
    return indexArray.map((values, index) => {
      console.log(values, "This is the value of the stars")
      return values <= coundOfFilledStars ? <span><img src="images/rating-filled-icon.svg" alt="rating-filled-icon" className={(values == 5) ? "me-2" : ""} /></span> : <span><img src="images/rating-unfilled-icon.svg" alt="rating-unfilled-icon" className={(values == 5) ? "me-2" : ""} /></span>
    })

    }


    const calculatePercentage = (count) => {
        count = isNaN(count) ? 0 : Number(count);
        let five = isNaN(azurePopupArray.stars_5) ? 0 : Number(azurePopupArray.stars_5);
        let four = isNaN(azurePopupArray.stars_4) ? 0 : Number(azurePopupArray.stars_4);
        let three = isNaN(azurePopupArray.stars_3) ? 0 : Number(azurePopupArray.stars_3);
        let two = isNaN(azurePopupArray.stars_2) ? 0 : Number(azurePopupArray.stars_2);
        let one = isNaN(azurePopupArray.stars_1) ? 0 : Number(azurePopupArray.stars_1);
    
        const total = five + four + three + two + one;
        return total > 0 ? ((count / total) * 100).toFixed(2) + "%" : "0%";
    }

    console.log(calculatePercentage(azurePopupArray.stars_5),"This is rhe five")

    return (
        <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog table-width modal-dialog-centered">
                <div className="modal-content border-radius-16">
                    <div className="modal-header border-0 p-4">
                        <h5 className="modal-title font-18 font-bold" id="staticBackdropLabel">
                            Ratings
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body px-4 pt-2 pb-4">
                        <div className="d-flex align-items-center mb-4">
                            <div className="">
                                <div className="productlogo-modal">
                                    <img src={azurePopupArray.Product_Logo ? azurePopupArray.Product_Logo : "https://via.placeholder.com/200"} alt="Asana-logo" width="100%" />
                                </div>
                            </div>
                            <div className="ms-3">
                                <p className="mb-0">Average</p>
                                <p className="mb-0 mt-1 font-28 font-bold">{azurePopupArray.Azure_Average}</p>
                                <div className="d-flex align-items-center gap-1">
                                    {
                                        fillStars(azurePopupArray.Azure_Average)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="d-flex border-bottom mb-4">
                            <div className="col-md-3">
                                <label className="font-16 grey-v2 mb-2">Ratings</label>
                                <p className="font-24 font-medium">{azurePopupArray.Azure_Ratings}</p>
                            </div>
                            <div className="col-md-9 ms-4">
                                <label className="font-16 grey-v2 mb-2">Ratings with reviews</label>
                                <p className="font-24 font-medium">{azurePopupArray.Azure_Ratings_reviews}</p>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex align-items-center mb-3">
                                <label className="col-md-2 font-16 font-medium grey-v2">
                                    <span className="primary-darkblue">5 </span>stars
                                </label>
                                <div className="col-md-3 progress ava-attachment-loader me-2">
                                    <div
                                        className="progress-bar bg-info rounded-4"
                                        role="progressbar"
                                        aria-label="Info example"
                                        style={{ "width": calculatePercentage(azurePopupArray.stars_5) }}
                                        aria-valuenow={50}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    />
                                </div>
                                <label className="col-md-2 ms-3 grey-v2">{azurePopupArray.stars_5}</label>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                <label className="col-md-2 font-16 font-medium grey-v2">
                                    <span className="primary-darkblue">4 </span>stars
                                </label>
                                <div className="col-md-3 progress ava-attachment-loader me-2">
                                    <div
                                        className="progress-bar bg-info rounded-4"
                                        role="progressbar"
                                        aria-label="Info example"
                                        style={{ width: calculatePercentage(azurePopupArray.stars_4) }}
                                        aria-valuenow={20}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    />
                                </div>
                                <label className="col-md-2 ms-3 grey-v2">{azurePopupArray.stars_4}</label>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                <label className="col-md-2 font-16 font-medium grey-v2">
                                    <span className="primary-darkblue">3 </span>stars
                                </label>
                                <div className="col-md-3 progress ava-attachment-loader me-2">
                                    <div
                                        className="progress-bar bg-info rounded-4"
                                        role="progressbar"
                                        aria-label="Info example"
                                        style={{ width: calculatePercentage(azurePopupArray.stars_3)  }}
                                        aria-valuenow={24}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    />
                                </div>
                                <label className="col-md-2 ms-3 grey-v2">{azurePopupArray.stars_3}</label>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                <label className="col-md-2 font-16 font-medium grey-v2">
                                    <span className="primary-darkblue">2 </span>stars
                                </label>
                                <div className="col-md-3 progress ava-attachment-loader me-2">
                                    <div
                                        className="progress-bar bg-info rounded-4"
                                        role="progressbar"
                                        aria-label="Info example"
                                        style={{ width: 
                                            calculatePercentage(azurePopupArray.stars_2) 
                                         }}
                                        aria-valuenow={7}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    />
                                </div>
                                <label className="col-md-2 ms-3 grey-v2">{azurePopupArray.stars_2}</label>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                <label className="col-md-2 font-16 font-medium grey-v2">
                                    <span className="primary-darkblue">1 </span>stars
                                </label>
                                <div className="col-md-3 progress ava-attachment-loader me-2">
                                    <div
                                        className="progress-bar bg-info rounded-4"
                                        role="progressbar"
                                        aria-label="Info example"
                                        style={{ width: calculatePercentage(azurePopupArray.stars_1)  }}
                                        aria-valuenow={16}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    />
                                </div>
                                <label className="col-md-2 ms-3 grey-v2">{azurePopupArray.stars_1}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}