import Stepper from "./Stepper";
import CreateFeature from "./CreateFeature";
import CreatePricing from "./CreatePricing";
import CreateRoadmap from "./CreateRoadmap";

import CreateFeatureAccordian from "./CreateFeatureAccordian";
import CreatePricingAccordian from "./CreatePricingAccordian";
import CreateRoadmapAccordian from "./CreateRoadmapAccordian";

import { useNavigate, useLocation } from "react-router-dom";
import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { useCommonContext } from "../../contexts/CommonContext";


export default function Feature() {

    const navigate = useNavigate();
    const location = useLocation();

    const isFeatureNeeded = location.state.isFeatureNeeded
    const isPricingNeeded = location.state.isPricingNeeded
    const isRoadmapNeeded = location.state.isRoadmapNeeded

    const {isNavCollapsed} = useCommonContext()
    
    // const location = useLocation();

    // const productId = location?.state?.productId;

    const { featureData, pricingData, productId,roadmapData, setLogoArray } = useCreateProductContext();
    console.log(productId,"from feature");
    console.log(roadmapData,"roadmapfeatureDescription")
    // setLogoArray("")

    return (
        <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
            <div className="row g-2 py-4">
                <div className="col-auto ps-4">
                    <a>
                        <img src="images/back-icon.svg" alt="back-icon" className=" me-3"
                            onClick={() => navigate("/comparisionmetrics", { state: { productId: productId, }, })}
                        />
                    </a>
                </div>
                <div className="col">
                    <h2 className="font-bold font-22 mb-4">Create New Product Contrast</h2>
                    <div className="row justify-content-center">
                        <div className="col-md-10 py-3">
                            <Stepper />
                            <div className="row mt-4">
                                <CreateFeature />
                                <CreateFeatureAccordian />
                                <CreatePricing />
                                <CreatePricingAccordian />
                                <CreateRoadmap />
                                <CreateRoadmapAccordian />

                            </div>
                            <div className="d-flex justify-content-between mt-5">
                                <button type="button" className="btn px-3 mt-3 violet-btn rounded-3 shadow-none  d-flex align-items-center justify-content-center font-bold font-16 text-nowrap"
                                    onClick={() => navigate("/comparisionmetrics", { state: { productId: productId, }, })}
                                >
                                    Back
                                </button>
                                <div className="d-flex align-items-center">
                                    <button type="button" className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                                        onClick={() => navigate("/productcontrast")} >
                                        Cancel
                                    </button>
                                    <button type="button" className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                                    disabled={featureData.length === 0 && pricingData.length === 0 && roadmapData.length === 0}
                                    onClick={() => navigate("/result", { 
                                        state: { 
                                            productId: productId,
                                            isFeatureNeeded: isFeatureNeeded,
                                            isPricingNeeded: isPricingNeeded,
                                            isRoadmapNeeded: isRoadmapNeeded,
                                        }
                                    })}
                                    >
                                        Next  <span><img src="images/white-arrow.svg" alt="white-arrow" className="ms-1" /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
