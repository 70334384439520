import { useCreateProductContext } from "../../contexts/CreateProductContext";
// import { useState } from "react";

export default function Competitors() {
  const { competitordata, handleCheckboxChange, setCompetitorData, filteredCompetitors } =
    useCreateProductContext();

  console.log(competitordata, "This is the competitor data ");

  return (
    <div className="row row-cols-1 row-cols-md-3 mb-3 g-4">
      {
        filteredCompetitors.map((values, index) => {
          return (
            // competitordata.Competitor_Name !== "" && 
            <div className="col-md-3" key={index}>
              <div className="card h-100  Competitor-card-sty">
                <div className="card-body py-2">
                  <div className="form-check d-flex align-items-center">
                    <input
                      className="form-check-input me-3 cust-check box-shadow-none"
                      type="checkbox"
                      id="compet"
                      value={values.Competitor_Id}
                      onChange={(e) => {
                        handleCheckboxChange(
                          values.Competitor_Id,
                          e.target.checked
                        );
                      }}
                      checked={values.Is_Selected}
                    />
                    <label
                      className="form-check-label font-14 font-medium check-label d-flex align-items-center"
                      htmlFor="compet"
                    >
                      <span>
                        <img
                          src={values.Product_Logo}
                          alt={values.Product_Logo}
                          className="compet-card-icon me-2"
                        />
                      </span>
                      <span title={values.Competitor_Name}>
                        {values.Competitor_Name.length > 20 
                          ? values.Competitor_Name.slice(0, 15) + "..." : values.Competitor_Name}
                        </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  );
}
