import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useCommonContext } from "./CommonContext";
import {
  getContentIdApi,
  // getAiContentsApi,
  get_configJson,
  insertSocialMediaTableApi,
  generateImageSmApi,
  createContentForMediaApi,
  getEventPostsApi,
  // getContentByIdApi,
} from "../services/api";

const SocialMediaContext = React.createContext();

export function SocialMediaContextProvider({ children }) {
  const location = useLocation();
  console.log("I am called from the context");
  /*Config json state variables starts here */
  // const contentId = "";
  // const socialMediaIds = "";
  const { userData } = useCommonContext();
  const [topicOrTheme, setTopicOrTheme] = useState("");

  const [specifyKeyContent, setSpecifyKeyContent] = useState("");
  const [purposeOfTheContent, setPurposeOfTheContent] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [audienceLevel, setAudienceLevel] = useState("");
  const [socialMediaAccount, setSocialMediaAccount] = useState("");
  const [SocialMediaAccountDropDown, setSocialMediaAccountDropDown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const [editPost, setEditPost] = useState({
    facebook: false,
    twitter: false,
    linked: false,
    insta: false,
  });
  const [platforms, setPlatforms] = useState({
    fbPost: false,
    linkedinPost: false,
    twitterPost: false,
    instaCap: false,
  });
  const [toneOfTheContent, setToneOfTheContent] = useState("");
  const [styleOfTheContent, setStyleOfTheContent] = useState("");
  const [hashtags, setHashtags] = useState("");
  const [socialMediaId, setSocialMediaId] = useState("");
  const [contId, setcontId] = useState("");
  /*ends here */

  const [ContentDetails, setContentDetails] = useState([]);
  const [ContentId, setContentId] = useState("");
  const [SocialMediaContentId, setSocialMediaContentId] = useState("");
  const [Prompt, setPrompt] = useState([]);

  console.info(location, "This is the local");

  // const location = useLocation();

  let contentId = location?.state?.contentId;
  let socialMediaIds = location?.state?.socialMediaId;

  useEffect(() => {
    getSocialMediaContent();
    if (contentId) {
      if (ContentDetails && userData.length != 0) {
        getPrompt();
      }
    }
  }, [userData, contentId]);

  async function getPrompt() {
    debugger;
    console.info("entered into the config json");
    const data = {
      userId: userData[0]?.["User_Id"],
      contentId:
        contentId == "" || contentId == null || contentId == undefined
          ? ContentId
          : contentId,
    };
    console.info(data, "this is the payload for the get data");
    const result = await get_configJson(data);
    console.info(result, "to see the config json");
    const response = result.data.data.data[0].Configuration_Json;
    console.info(response, "configjsonnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");
    const jsonresponse = JSON.parse(response);
    console.info(jsonresponse,"response from the  getprompt")
     setSpecifyKeyContent(jsonresponse?.message);
    setStyleOfTheContent(jsonresponse?.styleOfTheContent);
    setHashtags(jsonresponse?.specifyhashtags);
    setToneOfTheContent(jsonresponse?.toneOfTheContent);
    setSocialMediaAccount(jsonresponse?.account_choosed)
    setPlatforms(jsonresponse.platforms)
    setTargetAudience(jsonresponse?.targetAudience);
   














  }

  async function getSocialMediaContent() {
    try {
      // setIsLoading(true);
      debugger;
      if (contentId && userData.length != 0) {
        setContentId(contentId);
       
        const payload = {
          userId: userData[0]["User_Id"],
          socialMediaId:socialMediaIds ? socialMediaIds:SocialMediaContentId,
        };
        // Assuming getEventPostsApi sends a request to get event posts
        setIsLoading(true);
        const getEventRes = await getEventPostsApi(payload);
        setIsLoading(false)
        setContentDetails(getEventRes.data.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  console.log(ContentDetails, "this is the content details");

  const generateSocialMedia = async (item) => {
    // insert into the contents table
    const data = {
      userId: userData[0]?.["User_Id"],
      contentId: contentId,
      configJson: {
        message: specifyKeyContent
          ? specifyKeyContent
          : Prompt["specifyKeyContent"],
        // purposeOfTheContent: Prompt["purposeOfTheContent"],
        targetAudience: targetAudience
          ? targetAudience
          : Prompt["targetAudience"],
        toneOfTheContent: toneOfTheContent
          ? toneOfTheContent
          : Prompt["toneOfTheContent"],
        styleOfTheContent: styleOfTheContent
          ? styleOfTheContent
          : Prompt["styleOfTheContent"],
        audienceLevel: audienceLevel ? audienceLevel : Prompt["audienceLevel"],
        specifyhashtags: hashtags ? hashtags : Prompt["hashtags"],
        platforms: platforms ? platforms : item,
        account_choosed: socialMediaAccount,
      },
    };
    setIsLoading(true);
    const response = await getContentIdApi(data);
    console.info(response, " response from the content api");
    const scontentId = response.data.data["contentId"];
    setcontId(scontentId);

    console.info(scontentId, "content Id generated successfully");

    // insert into the social media contents table  creating rows in the social media table
    const social = {
      userId: userData[0]?.["User_Id"],
      id: scontentId,
      type: "Content",
    };

    const result = await insertSocialMediaTableApi(social);
    console.info(result, "Result from the  insertSocialMediaTableApi");
    const SocialMediaId = result.data.data["Social_media_content_id"];
    setSocialMediaContentId(SocialMediaId);
    console.info(
      SocialMediaId,
      "SocialMediaId generated successfully and inserted into the social media table"
    );

    let platformChoosed = [];

    if (platforms.fbPost) {
      platformChoosed.push("facebook");
    }
    if (platforms.instaCap) {
      platformChoosed.push("instagram");
    }
    if (platforms.linkedinPost) {
      platformChoosed.push("linkedIn");
    }
    if (platforms.twitterPost) {
      platformChoosed.push("twitter");
    }

    // Array to hold all promises for API calls
    let promises = [];

    for (let platform of platformChoosed) {
      //generate image and insert into social  media table

      await generateImageNew(platform);

      const content = {
        userId: userData[0]?.["User_Id"],
        socialMediaId: SocialMediaId,
        platform: platform,
        configJson: {
          message: specifyKeyContent
            ? specifyKeyContent
            : Prompt["specifyKeyContent"],
          // purposeOfTheContent: Prompt["purposeOfTheContent"],
          targetAudience: targetAudience
            ? targetAudience
            : Prompt["targetAudience"],
          toneOfTheContent: toneOfTheContent
            ? toneOfTheContent
            : Prompt["toneOfTheContent"],
          styleOfTheContent: styleOfTheContent
            ? styleOfTheContent
            : Prompt["styleOfTheContent"],
          audienceLevel: audienceLevel
            ? audienceLevel
            : Prompt["audienceLevel"],
          specifyhashtags: hashtags ? hashtags : Prompt["hashtags"],
        },
        account_choosed: socialMediaAccount,
      };

      promises.push(createContentForMediaApi(content));
    }

    async function generateImageNew(platform) {
      const payload = {
        config_json: {
          message: specifyKeyContent
            ? specifyKeyContent
            : Prompt["specifyKeyContent"],
          // purposeOfTheContent: Prompt["purposeOfTheContent"],
          targetAudience: targetAudience
            ? targetAudience
            : Prompt["targetAudience"],
          toneOfTheContent: toneOfTheContent
            ? toneOfTheContent
            : Prompt["toneOfTheContent"],
          styleOfTheContent: styleOfTheContent
            ? styleOfTheContent
            : Prompt["styleOfTheContent"],
          audienceLevel: audienceLevel
            ? audienceLevel
            : Prompt["audienceLevel"],
          specifyhashtags: hashtags ? hashtags : Prompt["hashtags"],
        },
        userId: userData[0]["User_Id"],
        socialMediaContentId: SocialMediaId,
        platform: platform,
        account_choosed: socialMediaAccount,
      };

      try {
        await generateImageSmApi(payload);

        return true;
      } catch (error) {
        generateImageNew(platform);
      }
    }

    // Execute all promises concurrently and wait for all of them to resolve
    Promise.all(promises)
      .then(async (results) => {
        const socialImage = {
          config_json: {
            message: specifyKeyContent
              ? specifyKeyContent
              : Prompt["specifyKeyContent"],
            // purposeOfTheContent: Prompt["purposeOfTheContent"],
            targetAudience: targetAudience
              ? targetAudience
              : Prompt["targetAudience"],
            toneOfTheContent: toneOfTheContent
              ? toneOfTheContent
              : Prompt["toneOfTheContent"],
            styleOfTheContent: styleOfTheContent
              ? styleOfTheContent
              : Prompt["styleOfTheContent"],
            audienceLevel: audienceLevel
              ? audienceLevel
              : Prompt["audienceLevel"],
            specifyhashtags: hashtags ? hashtags : Prompt["hashtags"],
          },
          userId: userData[0]["User_Id"],
          socialMediaContentId: SocialMediaId,
          platform: "socialmediaImage",
          account_choosed: socialMediaAccount,
        };

        await generateImageSmApi(socialImage);

        // fetch the generated contents:
        const payload = {
          userId: userData[0]["User_Id"],
          socialMediaId: SocialMediaId,
        };

        const getEventRes = await getEventPostsApi(payload);
        setIsLoading(false);

        setContentDetails(getEventRes.data.data.data);
        console.log(
          ContentDetails,
          "ContentDetails setted in the state variable"
        );
      })
      .catch((error) => {
        // Handle errors if any of the API calls fail
        console.error("Error:", error);
      });
  };
  return (
    <SocialMediaContext.Provider
      value={{
        topicOrTheme,
        setTopicOrTheme,
        specifyKeyContent,
        setSpecifyKeyContent,
        purposeOfTheContent,
        setPurposeOfTheContent,
        targetAudience,
        setTargetAudience,
        audienceLevel,
        setAudienceLevel,
        ContentDetails,
        setContentDetails,
        socialMediaAccount,
        setSocialMediaAccount,
        SocialMediaAccountDropDown,
        setSocialMediaAccountDropDown,
        ContentId,
        setContentId,
        contentId,
        platforms,
        setPlatforms,
        toneOfTheContent,
        setToneOfTheContent,
        styleOfTheContent,
        setStyleOfTheContent,
        hashtags,
        setHashtags,
        ContentId,
        setContentId,
        socialMediaId,
        setSocialMediaId,
        socialMediaIds,
        generateSocialMedia,
        editPost,
        setEditPost,
        Prompt,
        setPrompt,
        userData,
        contId,
        SocialMediaContentId,
        setIsLoading,
        isLoading

      }}
    >
      {children}
    </SocialMediaContext.Provider>
  );
}

export function useSocialMediaContext() {
  return useContext(SocialMediaContext);
}