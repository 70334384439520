import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { saveComparisonMetricsApi, getComparisionmetricbyProductidApi, getWatchlistbyProductidApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";



export default function ComponentHandler() {
    const navigate = useNavigate();
    const { productId } = useCreateProductContext();
    const [isFeatureNeeded, setIsFeatureNeeded] = useState(false);
    const [isPricingNeeded, setIsPricingNeeded] = useState(false);
    const [isRoadmapNeeded, setIsRoadmapNeeded] = useState(false);

    const [watchlistProduct, setWatchlistProduct] = useState([])// get from db and bind to the watchlist
    const [tagInputValue, setTagInputValue] = useState([]);

    const {userData,isNavCollapsed} = useCommonContext() 


    // const [productId, setProductId] = useState('')

    console.log(productId,"err");


    const location = useLocation();



    useEffect(() => {
        // setProductId(location.state.productId)
    if (productId !== null && productId!== '') {
            console.log("exist");
            getComparisionmetricbyProductid(productId)
            getWatchlistbyProductid(productId)
        } else {
            saveComparisonMetrics()
            console.log("You are a New User");
        }
    }, []);


    console.log(isFeatureNeeded, isPricingNeeded, "teasdjakdneeeeeeeeeeeeeeeeeeeee");

    const saveComparisonMetrics = async () => {
        console.log("saveComparisonMetrics called");
        try {
            const payload = {
                'productId': productId,
                'userId': userData[0]["User_Id"],
                'isFeatureSelected': isFeatureNeeded,
                'isPricingSelected': isPricingNeeded,
                'isRoadmapSelected': isRoadmapNeeded,
                'watchlist': tagInputValue,
            }
            const responseData = await saveComparisonMetricsApi(payload)
            console.log(responseData)

        } catch (error) {
            console.error(error);
        }
    }


    const getComparisionmetricbyProductid = async (productId) => {
        console.log(productId,"getComparisionmetricbyProductid called");
        try {
            const payload = {
                'productId': productId,
                'userId': userData[0]["User_Id"],
            }
            const responseData = await getComparisionmetricbyProductidApi(payload)
            console.log(responseData)
            setIsFeatureNeeded(responseData.data.data[0].Is_Feature_Needed)
            setIsPricingNeeded(responseData.data.data[0].Is_Pricing_Needed)
            setIsRoadmapNeeded(responseData.data.data[0].Is_Roadmap_Needed)
            
            

        } catch (error) {
            console.error(error);
        }
    }


    const getWatchlistbyProductid = async (productId) => {
        console.log("getWatchlistbyProductid called");
        try {
            const payload = {
                'productId': productId,
                'userId': userData[0]["User_Id"],
            }
            const responseData = await getWatchlistbyProductidApi(payload)
            console.log(responseData.data.data[0].Email_Id,"watchlist")
            setTagInputValue(responseData.data.data.map(item => item.Email_Id));
            console.log(tagInputValue,"Tagggggggggggggggg");
        } catch (error) {
            console.error(error);
        }
    }

    function handleKeyDown(e) {
        if (e.key === "Enter") {
        if (watchlistProduct.trim() !== "") {
            setTagInputValue([...tagInputValue, watchlistProduct]); // Append current value of watchlistProduct
            setWatchlistProduct("");
        }
        }
    }


    function removeTag(index) {
        setTagInputValue(tagInputValue.filter((_, i) => i !== index));
    }

    return (
        <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
            <div className="row g-2 py-4">
                <div className="col-auto ps-4">
                    <a>
                        <img src="images/back-icon.svg" alt="back-icon" className=" me-3"
                            onClick={() => navigate("/product", { state: { productId: productId, }, })} />
                    </a>
                </div>
                <div className="col">
                    <h2 className="font-bold font-22 mb-4">Create New Product Contrast</h2>
                    <div className="row justify-content-center">
                        <div className="col-md-10 py-3">
                            <div className="d-flex justify-content-center">
                                <ul className="list-unstyled custom-stepper d-flex">
                                    <li className="stepper-sty text-center completed">
                                        <span className="product-wiz stepper-icon-sty" />
                                        <p className="stepper-text mt-1 mb-0">Product</p>
                                    </li>
                                    <li className=" stepper-sty text-center position-relative active">
                                        <span className="comparision-wiz stepper-icon-sty stepper-line" />
                                        <p className="stepper-text mt-1 mb-0">Comparision Metrics</p>
                                    </li>
                                    <li className="stepper-sty text-center position-relative ">
                                        <span className="Features-wiz stepper-icon-sty stepper-line" />
                                        <p className="stepper-text mt-1 mb-0">Features</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="row mt-4">
                                <h5 className="font-bold font-18 grey-v3 mb-3">Comparision Metrics</h5>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="Name" className="form-label mb-0 cust-label font-14 font-medium mb-2">What comparison would you like to proceed ?</label>
                                    <div className="row row-cols-1 row-cols-md-3 mb-3 g-4">
                                        <div className="col-md-4">
                                            <div className="card h-100  Competitor-card-sty">
                                                <div className="card-body py-1 d-flex align-items-center">
                                                    <div className="form-check mb-0 py-1">
                                                        <input className="form-check-input cust-check box-shadow-none" type="checkbox" value = {isFeatureNeeded} id="Features"
                                                            checked={isFeatureNeeded}
                                                            onChange={() => setIsFeatureNeeded(!isFeatureNeeded)} />
                                                        <label className="form-check-label font-14 font-medium check-label d-flex align-items-center" htmlFor="Features">
                                                            Features
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card h-100  Competitor-card-sty">
                                                <div className="card-body py-1 d-flex align-items-center">
                                                    <div className="form-check mb-0 py-1">
                                                        <input className="form-check-input cust-check box-shadow-none" type="checkbox" value = {isPricingNeeded}  id="Pricing"
                                                            checked={isPricingNeeded}
                                                            onChange={() => setIsPricingNeeded(!isPricingNeeded)} />
                                                        <label className="form-check-label font-14 font-medium check-label d-flex align-items-center" htmlFor="Pricing">
                                                            Pricing
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card h-100  Competitor-card-sty">
                                                <div className="card-body py-1 d-flex align-items-center">
                                                    <div className="form-check mb-0 py-1">
                                                        <input className="form-check-input cust-check box-shadow-none" type="checkbox" value = {isRoadmapNeeded} id="Roadmap"
                                                            checked={isRoadmapNeeded}
                                                            onChange={() => setIsRoadmapNeeded(!isRoadmapNeeded)} />
                                                        <label className="form-check-label font-14 font-medium check-label d-flex align-items-center" htmlFor="Roadmap">
                                                            Product Roadmap
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                
                        <div className="col-md-6 mb-3">
                          <label htmlFor="ReportWatchlist" className="form-label mb-0 cust-label font-14 font-medium mb-2">Report Watchlist</label>
                          <div className="form-control custom-form resize-none font-14 font-regular keyword-hig">
                            {tagInputValue.map((item, index) => (
                              <span key={index} className="blue-batch d-inline-flex align-items-center me-2 mb-2">
                                <span className="font-14 font-medium">{item}</span>
                                <img
                                  src="images/close-icon.svg"
                                  alt="close-icon"
                                  className="ms-3 cursor-pointer"
                                  onClick={() => removeTag(index)}
                                />
                              </span>
                            ))}
                            <input
                              type="text"
                              placeholder="Enter Watchlist  Reportees"
                              className="form-control border-0 ps-0 mb-2 shadow-none font-14 font-regular"
                              id="watchlistProduct"
                              value={watchlistProduct}
                              onChange={(e) => setWatchlistProduct(e.target.value)}
                              onKeyDown={handleKeyDown}
                            />
                          </div>
                        </div>

                                


                            </div>
                            <div className="d-flex justify-content-between mt-5">
                                <button type="button" className="btn px-3 mt-3 violet-btn rounded-3 shadow-none  d-flex align-items-center justify-content-center font-bold font-16 text-nowrap"
                                    onClick={() => navigate("/product", { state: { productId: productId, }, })} >
                                    Back
                                </button>
                                <div className="d-flex align-items-center">
                                    <button type="button" className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                                        onClick={() => navigate("/productcontrast")} >
                                        Cancel
                                    </button>
                                    <button type="button" className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                                        onClick={() => {
                                            saveComparisonMetrics();
                                            navigate("/feature", { state: 
                                                { 
                                                    productId: productId,
                                                    isFeatureNeeded: isFeatureNeeded,
                                                    isPricingNeeded: isPricingNeeded,
                                                    isRoadmapNeeded: isRoadmapNeeded,
                                                } 
                                            });
                                        }}
                                        disabled={
                                            tagInputValue.length === 0
                                        }>
                                        Next  <span><img src="images/white-arrow.svg" alt="white-arrow" className="ms-1" /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    );

};

