import { client } from "./client";
import { endpoints } from "./endpoints";

export const environment = "prod"

export async function getUserDataApi(data) {
  return await client(
    data,
    endpoints[environment]["getUserDataApi"],
    "POST"
  );
}

export async function checkDetailsApi(data) {
  return await client(
    data,
    endpoints[environment]["checkDetailsApi"],
    "POST"
  );
}

export async function insertDetailsApi(data) {
  return await client(
    data,
    endpoints[environment]["insertDetailsApi"],
    "POST"
  );
}

export async function checkMailDetailsApi(data) {
  return await client(
    data,
    endpoints[environment]["checkMailDetailsApi"],
    "POST"
  );
}

export async function updatePasswordApi(data) {
  return await client(
    data,
    endpoints[environment]["updatePasswordApi"],
    "POST"
  );
}

export async function getPendingRequestCountApi(data) {
  return await client(
    data,
    endpoints[environment]["getPendingRequestCountApi"],
    "POST"
  );
}
export async function getRecentActivitiesApi(data) {
  return await client(
    data,
    endpoints[environment]["getRecentActivitiesApi"],
    "POST"
  );
}
export async function getMediaPostsApi(data) {
  return await client(
    data,
    endpoints[environment]["getMediaPostsApi"],
    "POST"
  );
}

export async function getImpressionApi(data) {
  return await client(
    data,
    endpoints[environment]["getImpressionApi"],
    "POST"
  );
}

/**Integration part starts here */

export async function getAllAccountApi(data) {
  return await client(
    data,
    endpoints[environment]["getAllAccountApi"],
    "POST"
  );
}

export async function createAccountApi(data) {
  return await client(
    data,
    endpoints[environment]["createAccountApi"],
    "POST"
  );
}

export async function deleteAccountApi(data) {
  return await client(
    data,
    endpoints[environment]["deleteAccountApi"],
    "POST"
  );
}

export async function editAccountApi(data) {
  return await client(
    data,
    endpoints[environment]["editAccountApi"],
    "POST"
  );
}

export async function addPlatformApi(data) {
  return await client(
    data,
    endpoints[environment]["addPlatformApi"], "POST"
  );
}

export async function platformConnectionApi(data) {
  return await client(
    data,
    endpoints[environment]["platformConnectionApi"], "POST"
  );
}

export async function editPlatformApi(data) {
  return await client(
    data,
    endpoints[environment]["editPlatformApi"], "POST"
  );
}

/**Highlights starts here */
export async function getAllSoicalMediaContentApi(data) {
  return await client(
    data,
    endpoints[environment]["getAllSoicalMediaContentApi"], "POST"
  );
}

export async function postInstagramApi(data) {
  return await client(
    data,
    endpoints[environment]["postInstagramApi"], "POST"
  );
}

export async function postFaceBookApi(data) {
  return await client(
    data,
    endpoints[environment]["postFaceBookApi"], "POST"
  );
}

export async function postTwitterApi(data) {
  return await client(
    data,
    endpoints[environment]["postTwitterApi"], "POST"
  );
}

export async function postLinkedInApi(data) {
  return await client(
    data,
    endpoints[environment]["postLinkedInApi"], "POST"
  );
}

export async function regeneratePostApi(data) {
  return await client(
    data,
    endpoints[environment]["regeneratePostApi"], "POST"
  );
}

/**UIntegration part ends here */

/**Manage users starte  */
export async function fetchAllUserApi(data) {
  return await client(
    data,
    endpoints[environment]["fetchAllUserApi"], "POST"
  );
}

export async function deleteUserApi(data) {
  return await client(
    data,
    endpoints[environment]["deleteUserApi"], "POST"
  );
}

export async function addUserApi(data) {
  return await client(
    data,
    endpoints[environment]["addUserApi"], "POST"
  );
}
/**Manage users end here */

/*Get dropdown data starts here */
export async function getDropDownDetailsApi(DropDowndata) {
  return await client(
    DropDowndata,
    endpoints[environment]["getDropDownDetailsApi"], "POST"
  );
}
export async function fetchNotificationApi(data) {
  return await client(
    data,
    endpoints[environment]["fetchNotificationApi"], "POST"
  );
}

export async function createMyEventApi(data) {
  return await client(
    data,
    endpoints[environment]["createMyEventApi"], "POST"
  );
}

export async function getAllAccountsApi(data) {
  return await client(
    data,
    endpoints[environment]["getAllAccountsApi"], "POST"
  );
}

export async function getMyEventsApi(data) {
  return await client(
    data,
    endpoints[environment]["getMyEventsApi"], "POST"
  );
}

export async function getAllUpcomingEventsHubApi(data) {
  return await client(
    data,
    endpoints[environment]["getAllUpcomingEventsHubApi"], "POST"
  );
}

export async function getAllUpcomingEventsApi(data) {
  return await client(
    data,
    endpoints[environment]["getAllUpcomingEventsApi"], "POST"
  );
}

export async function insertUpcomingEventsStatusApi(data) {
  return await client(
    data,
    endpoints[environment]["insertUpcomingEventsStatusApi"], "POST"
  );
}

export async function getAllContentsApi(data) {
  return await client(
    data,
    endpoints[environment]["getAllContentsApi"], "POST"
  );
}

export async function deleteContentApi(data) {
  return await client(
    data,
    endpoints[environment]["deleteContentApi"], "POST"
  );
}

export async function getAllRequestApi(data) {
  return await client(
    data,
    endpoints[environment]["getAllRequestApi"], "POST"
  );
}

export async function updateUserDataApi(data) {
  return await client(
    data,
    endpoints[environment]["updateUserDataApi"], "POST"
  );
}

export async function createEventPostsApi(data) {
  return await client(
    data,
    endpoints[environment]["createEventPostsApi"], "POST"
  );
}

export async function getEventPostsApi(data) {
  return await client(
    data,
    endpoints[environment]["getEventPostsApi"], "POST"
  );
}

export async function savePostContentApi(data) {
  return await client(
    data,
    endpoints[environment]["savePostContentApi"], "POST"
  );
}

export async function getContentByIdApi(data) {
  return await client(
    data,
    endpoints[environment]["getContentByIdApi"], "POST"
  );
}

export async function generateContentApi(data) {
  return await client(
    data,
    endpoints[environment]["generateContentApi"], "POST"
  );
}

export async function askAiApi(data) {
  return await client(
    data,
    endpoints[environment]["askAiApi"], "POST"
  );
}

export async function generateImageApi(data) {
  return await client(
    data,
    endpoints[environment]["generateImageApi"], "POST"
  );
}

export async function submitRequestApi(data) {
  return await client(
    data,
    endpoints[environment]["submitRequestApi"], "POST"
  );
}

export async function saveContentApi(data) {
  return await client(
    data,
    endpoints[environment]["saveContentApi"], "POST"
  );
}

/**Product contrast module starts here*/

export async function saveFeatureApi(data) {
  return await client(
    data,
    endpoints[environment]["saveFeatureApi"], "POST"
  );
}

export async function getAllFeatureApi(data) {
  return await client(
    data,
    endpoints[environment]["getAllFeatureApi"], "POST"
  );
}

export async function updateFeatureApi(data) {
  return await client(
    data,
    endpoints[environment]["updateFeatureApi"], "POST"
  );
}

export async function deleteFeatureApi(data) {
  return await client(
    data,
    endpoints[environment]["deleteFeatureApi"], "POST"
  );
}

export async function savePricingApi(data) {
  return await client(
    data,
    endpoints[environment]["savePricingApi"], "POST"
  );
}

export async function getAllPricingApi(data) {
  return await client(
    data,
    endpoints[environment]["getAllPricingApi"], "POST"
  );
}

export async function updatePricingApi(data) {
  return await client(
    data,
    endpoints[environment]["updatePricingApi"], "POST"
  );
}

export async function deletePricingApi(data) {
  return await client(
    data,
    endpoints[environment]["deletePricingApi"], "POST"
  );
}

export async function getAllProductsApi(data) {
  return await client(
    data,
    endpoints[environment]["getAllProductsApi"], "POST"
  );
}

export async function getProductbyIdApi(data) {
  return await client(
    data,
    endpoints[environment]["getProductbyIdApi"], "POST"
  );
}

export async function getComparisonsByProductIdApi(data) {
  return await client(
    data,
    endpoints[environment]["getComparisonsByProductIdApi"], "POST"
  );
}

export async function getAllCompetitorsApi(data) {
  return await client(
    data,
    endpoints[environment]["getAllCompetitorsApi"], "POST"
  );
}

export async function saveComparisonMetricsApi(data) {
  return await client(
    data,
    endpoints[environment]["saveComparisonMetricsApi"], "POST"
  );
}

export async function addCompetitorsManuallyApi(data) {
  return await client(
    data,
    endpoints[environment]["addCompetitorsManuallyApi"], "POST"
  );
}

export async function createProductApi(data) {
  return await client(
    data,
    endpoints[environment]["createProductApi"], "POST"
  );
}

export async function getComparisionmetricbyProductidApi(data) {
  return await client(
    data,
    endpoints[environment]["getComparisionmetricbyProductidApi"], "POST"
  );
}

export async function getWatchlistbyProductidApi(data) {
  return await client(
    data,
    endpoints[environment]["getWatchlistbyProductidApi"], "POST"
  );
}

export async function selectCompetitorApi(data) {
  return await client(
    data,
    endpoints[environment]["selectCompetitorApi"], "POST"
  );
}


// export async function generateComparisonsApi(data) {
//   return await client(data, "http://localhost:7071/api/generate_comparisons", "POST");
// }
export async function generateOverviewApi(data) {
  return await client(
    data,
    endpoints[environment]["generateOverviewApi"], "POST"
  );
}

export async function generateFeatureApi(data) {
  return await client(
    data,
    endpoints[environment]["generateFeatureApi"], "POST"
  );
}

export async function generatePricingApi(data) {
  return await client(
    data,
    endpoints[environment]["generatePricingApi"], "POST"
  );
}

export async function generateRecommendationApi(data) {
  return await client(
    data,
    endpoints[environment]["generateRecommendationApi"], "POST"
  );
}

export async function pdftoBackendApi(data) {
  return await client(
    data,
    endpoints[environment]["pdftoBackendApi"], "POST"
  );
}

export async function insertCompetitorsApi(data) {
  return await client(
    data,
    endpoints[environment]["insertCompetitorsApi"], "POST"
  );
}

export async function insertHtmlApi(data) {
  return await client(
    data,
    endpoints[environment]["insertHtmlApi"], "POST"
  );
}

export async function insert_nameApi(data) {
  return await client(
    data,
    endpoints[environment]["insert_nameApi"], "POST"
  );
}

export async function insert_overviewApi(data) {
  return await client(
    data,
    endpoints[environment]["insert_overviewApi"], "POST"
  );
}

export async function insert_featureApi(data) {
  return await client(
    data,
    endpoints[environment]["insert_featureApi"], "POST"
  );
}

export async function insert_pricingApi(data) {
  return await client(
    data,
    endpoints[environment]["insert_pricingApi"], "POST"
  );
}

export async function insert_feature_tableApi(data) {
  return await client(
    data,
    endpoints[environment]["insert_feature_tableApi"], "POST"
  );
}

export async function insert_pricing_tableApi(data) {
  return await client(
    data,
    endpoints[environment]["insert_pricing_tableApi"], "POST"
  );
}

/**Social Media post api */
export async function postFacebookApi(data) {
  return await client(
    data,
    endpoints[environment]["postFacebookApi"], "POST"
  );
}

export async function getAiContentsApi(data) {
  return await client(
    data,
    endpoints[environment]["getAiContentsApi"], "POST"
  );
}

export async function get_configJson(data) {
  return await client(
    data,
    endpoints[environment]["get_configJson"], "POST"
  );
}

export async function generateSocialMediaContentApi(data) {
  return await client(
    data,
    endpoints[environment]["generateSocialMediaContentApi"], "POST"
  );
}

/**Approve content starts here */
export async function approveStatusApi(data) {
  return await client(
    data,
    endpoints[environment]["approveStatusApi"], "POST"
  );
}

/**Modified social media APIs */
export async function createSocialApi(data) {
  return await client(
    data,
    endpoints[environment]["createSocialApi"], "POST"
  );
}

export async function createContentForMediaApi(data) {
  return await client(
    data,
    endpoints[environment]["createContentForMediaApi"], "POST"
  );
}

export async function createImageForMediaApi(data) {
  return await client(
    data,
    endpoints[environment]["createImageForMediaApi"], "POST"
  );
}

export async function generateImageSmApi(data) {
  return await client(
    data,
    endpoints[environment]["generateImageSmApi"], "POST"
  );
}

export async function getContentIdApi(data) {
  return await client(
    data,
    endpoints[environment]["getContentIdApi"], "POST"
  );
}

export async function insertSocialMediaTableApi(data) {
  return await client(
    data,
    endpoints[environment]["insertSocialMediaTableApi"], "POST"
  );
}

/**Product contrast */

export async function generateOverviewpdfApi(data) {
  return await client(
    data,
    endpoints[environment]["generateOverviewpdfApi"], "POST"
  );
}

export async function generateRecommendationFeatureApi(data) {
  return await client(
    data,
    endpoints[environment]["generateRecommendationFeatureApi"], "POST"
  );
}

export async function generateRecommendationPricingApi(data) {
  return await client(
    data,
    endpoints[environment]["generateRecommendationPricingApi"], "POST"
  );
}

export async function generateRecommendationFeaturepdfApi(data) {
  return await client(
    data,
    endpoints[environment]["generateRecommendationFeaturepdfApi"], "POST"
  );
}

export async function generateRecommendationPricingpdfApi(data) {
  return await client(
    data,
    endpoints[environment]["generateRecommendationPricingpdfApi"], "POST"
  );
}

export async function generateFeaturepdfApi(data) {
  return await client(
    data,
    endpoints[environment]["generateFeaturepdfApi"], "POST"
  );
}

export async function generatePricingpdfApi(data) {
  return await client(
    data,
    endpoints[environment]["generatePricingpdfApi"], "POST"
  );
}

export async function generateRecommendationpdfApi(data) {
  return await client(
    data,
    endpoints[environment]["generateRecommendationpdfApi"], "POST"
  );
}

export async function imageuploadApi(data) {
  return await client(
    data,
    endpoints[environment]["imageuploadApi"], "POST"
  );
}

/**New PC Endpoints */
export async function select_one_competitor(data) {
  return await client(
    data,
    endpoints[environment]["select_one_competitor"], "POST"
  );
}

export async function get_pricing_result(data) {
  return await client(
    data,
    endpoints[environment]["get_pricing_result"], "POST"
  );
}

export async function insert_pricing_recommendation(data) {
  return await client(
    data,
    endpoints[environment]["insert_pricing_recommendation"], "POST"
  );
}

export async function get_productbyId_FeaturePricing(data) {
  return await client(
    data,
    endpoints[environment]["get_productbyId_FeaturePricing"], "POST"
  );
}

export async function get_feature_tick(data) {
  return await client(
    data,
    endpoints[environment]["get_feature_tick"], "POST"
  );
}


export async function insert_feature_recommendation(data) {
  return await client(
    data,
    endpoints[environment]["insert_feature_recommendation"], "POST"
  );
}

export async function logout(data) {
  return await client(
    data,
    endpoints[environment]["logout"], "POST"
  )
}

//CR stars here
export async function save_roadmap_featuresApi(data) {
  return await client(
    data,
    endpoints[environment]["save_roadmap_featuresApi"],
    // "http://localhost:7003/api/save_roadmap_features",
    "POST"
  );
}
export async function update_roadmap_featuresApi(data) {
  return await client(
    data,
    endpoints[environment]["update_roadmap_featuresApi"],
    // "http://localhost:7003/api/update_roadmap_features",
    "POST"
  );
}
export async function delete_roadmap_featuresApi(data) {
  return await client(
    data,
    // "http://localhost:7003/api/delete_roadmap_features",
    endpoints[environment]["update_roadmap_featuresApi"],
    "POST"
  );
}
export async function get_all_roadmap_featuresApi(data) {
  return await client(
    data,
    endpoints[environment]["get_all_roadmap_featuresApi"],


    // "http://localhost:7003/api/get_all_roadmap_features",
    "POST"
  );
}
export async function get_competitor_roadmap_featuresApi(data) {
  return await client(
    data,
    // "http://localhost:7003/api/get_competitor_roadmap_features",
    endpoints[environment]["get_competitor_roadmap_featuresApi"],
    "POST"
  );
}
export async function insert_product_azure_review_tableApi(data) {
  return await client(
    data,
// "http://localhost:7003/api/insert_product_azure_review_table",
endpoints[environment]["insert_product_azure_review_tableApi"],
"POST"
  );
}
export async function insert_product_aws_review_tableApi(data) {
  return await client(
    data,
    endpoints[environment]["insert_product_aws_review_tableApi"],
    // "http://localhost:7003/api/insert_product_aws_review_table",
    "POST"
  );
}
export async function insert_competitor_azure_reviewApi(data) {
  return await client(
    data,
    // "http://localhost:7003/api/insert_competitor_azure_review",
    endpoints[environment]["insert_competitor_azure_reviewApi"],

    "POST"
  );
}
export async function insert_competitor_aws_reviewApi(data) {
  return await client(
    data,
    endpoints[environment]["insert_competitor_aws_reviewApi"],

    // "http://localhost:7003/api/insert_competitor_aws_review",
    "POST"
  );
}
export async function insert_competitor_roadmap_tableApi(data) {
  return await client(
    data,
    endpoints[environment]["insert_competitor_roadmap_tableApi"],

    // "http://localhost:7003/api/insert_competitor_roadmap_table",
    "POST"
  );
}

export async function insert_roadmap_recommendation(data) {
  return await client(
    data,
    endpoints[environment]["insert_roadmap_recommendation"],

    // "http://localhost:7003/api/insert_roadmap_features_recommendation",
    "POST"
  ); 
}

//ComparisonResult_161
export async function get_product_ranking(data) {
  return await client(
    data,
    endpoints[environment]["get_product_ranking"],

    // "http://localhost:7003/api/get_product_ranking",
    "POST"
  );
}
/**Product contrast CR module Ends here */

// {
//   "delete_roadmap_features.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/delete_roadmap_features?code=WXi5uvEW9iYG1d68y1gkxxisHTQ3DwrB-fgvG7RekFSqAzFuzMvheg==",
//   "insert_competitor_aws_review.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_competitor_aws_review?code=rTx8gtHxWUmU-WmSwSRj7nh-3i5cdzPxWIbptyYHSscOAzFuaeeanA==",
//   "get_competitor_roadmap_features.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_competitor_roadmap_features?code=mHIADlYRkMVdEO3g7vVlrKNtA-7Ajhm2VW8WG0Bb7LMqAzFutkNL3g==",
//   "insert_product_azure_review_table.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_product_azure_review_table?code=k8Mm-LgTHb7ZQGCFhlxifahCcCWEbIJ-ak9ZrA6__A8gAzFuVibjbw==",
//   "insert_competitor_azure_review.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_competitor_azure_review?code=8zgRGPTB3POisJ9cDNXa-zsXsH1t25uKH1n_zpqbmc9VAzFu7SohKw==",
//   "insert_product_aws_review_table.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_product_aws_review_table?code=gzdy1d5tZFOJ4_o_MrPnMLx5F82uZfM26i6-cz9k_U_ZAzFusB8uTg==",
//   "update_roadmap_features.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/update_roadmap_features?code=_w8N2I5MK0wcBcpyuoEEWgMa77-2g4w9xQQorOUc-jwcAzFujkPUtw==",
//   "insert_roadmap_features_recommendation.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_roadmap_features_recommendation?code=lacGR4bCXsm3KkV1DOsiTtN2aqQqa8rRInvOV9kjj_dXAzFuJEAiew==",
//   "save_roadmap_features.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/save_roadmap_features?code=TtJjaoPBdXnXlhfiyiufJqLnecVHd9eoDPJkPWwl5AzYAzFu-4kHvA==",
//   "insert_competitor_roadmap_table.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_competitor_roadmap_table?code=ceOLBzBy-viayz9LdK7Zwfqll31gP-unaWZmNNz1Ck7YAzFuhQRs6g==",
//   "get_all_roadmap_features.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_all_roadmap_features?code=M0Lx5yuGb130Ton-gzieqUeX284HuEqh0-WYWr0zViuqAzFuSYsjDA==",
//   "get_product_ranking.py": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_product_ranking?code=oqtr7XQd8LOvh2Mhpht6gFm_7AEgo8qDPeH3NNHipghnAzFuSh6k0A=="
// }