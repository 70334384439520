import { useEffect, useState } from "react";
import { useCreateMyEventContext } from "../../contexts/CreateMyEventContext";
import CreateMyEventsFields from "./CreateMyEventsFields";
import EventDetails from "./EventDetails";
import TimeInput from "./TimeSlots";
import EventSocialMedia from "./EventSocialMedia";
import { useNavigate } from "react-router-dom";
import { useCommonContext } from "../../contexts/CommonContext";

export default function CreateMyEvents() {
  const {
    isEventDetailOpen,
    setIsEventDetailOpen,
    isSocialMediaPostGenerated,
    socialMediaContent,
  } = useCreateMyEventContext();
  const {isNavCollapsed, setIsNavCollapsed } = useCommonContext();

  const navigate = useNavigate();

  return (
    <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
      <div className="row">
        <h3 className="head-bar font-20 font-bold p-3 sticky-top bg-white mb-0 d-flex align-items-center justify-content-between">
          <span className="d-flex align-items-center">
            <img
              src="images/back-icon.svg"
              alt="back-icon"
              className="me-2 cursor-pointer"
              onClick={() => {
                navigate("/myevents");
              }}
            />
            Create New Event
          </span>
        </h3>
        <div className="col-md-12">
          <div className="row">
            {/* left layout starts */}
            <CreateMyEventsFields />
            {/* left layout ends */}
            <div className="col-md-9 content-generated">
              <div className="generated-container mt-4">
                <div className="row">
                  {isEventDetailOpen ? <EventDetails /> : null}
                  {socialMediaContent.length != 0 ? <EventSocialMedia /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
