export default function KeyHighlights(props) {
  // Splitting the features array into two separate arrays for product and competitor
  const competitorFeatures = props.keyFeatureArray.slice(0, props.keyFeatureArray.length / 2);
  const productFeatures = props.keyFeatureArray.slice(props.keyFeatureArray.length / 2);
  
  // Function to render bullet points for each feature
  const renderBulletPoints = (features) => {
    console.log(features,"feature");
    if (features.length === 0) {
      return <span className="compare-header-color">NA</span>;
    }

    return (
      <ul>
        {features.map((value, index) => (
          // Splitting the comma-separated values and rendering each point as a list item
          value?.split(',')?.map((point, idx) => (
            <li key={`${index}-${idx}`}>{point.trim()}</li>
          ))
        ))}
      </ul>
    );
  };

  return (
    <tr>
      <td className="compare-header-color">Key highlights/ features</td>

      {/* Rendering product features in the left column */}
      <td style={{ verticalAlign: "top", textAlign: "left" }}>
        {renderBulletPoints(productFeatures)}
      </td>

      {/* Rendering competitor features in the right column */}
      <td style={{ verticalAlign: "top", textAlign: "left" }}>
        {renderBulletPoints(competitorFeatures)}
      </td>
    </tr>
  );
}
