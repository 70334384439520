export default function Stepper() {
    return (
        <div className="d-flex justify-content-center">
            <ul className="list-unstyled custom-stepper d-flex">
                <li className="stepper-sty text-center completed">
                    <span className="product-wiz stepper-icon-sty" />
                    <p className="stepper-text mt-1 mb-0">Product</p>
                </li>
                <li className=" stepper-sty text-center position-relative completed">
                    <span className="comparision-wiz stepper-icon-sty stepper-line" />
                    <p className="stepper-text mt-1 mb-0">Comparision Metrics</p>
                </li>
                <li className="stepper-sty text-center position-relative active">
                    <span className="Features-wiz stepper-icon-sty stepper-line" />
                    <p className="stepper-text mt-1 mb-0">Features</p>
                </li>
            </ul>
        </div>
    )
}