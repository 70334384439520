import { useCreateContentContext } from "../../contexts/CreateContentContext";
import AskAiResponsePrompt from "./AskAiResponsePrompt";

export default function AskAiResponse() {
  const {
    askAiResponseText,
    selectedText,
    isAskAiResponseReturned,
    askAiPromptText,
    position,
    isAskAiPrompt,
    isAskresult,
    setIsAskResult,
  } = useCreateContentContext();

  return (
    <>
      <span
        className="ask-ai-content-gen "
        hidden={isAskresult}
        style={{
          left: `${position.x}px`,
          top: `${position.y - 181}px`,
          width: "46%",
          left: "46.93%",
          height: "28%",
          overflow: "auto",
        }}
      >
        <span className="d-block font-14 font-medium border-bottom  mb-2 pb-2">
          Selected Text:
        </span>
        <span className="font-14 font-regular d-block mb-4">
          {selectedText}
        </span>
        <span className="d-block font-14 font-medium border-bottom mb-2 pb-2">
          Rewritten Text:
        </span>
        <span className="font-14 font-regular d-block mb-3">
          {askAiResponseText}
        </span>
      </span>
      <AskAiResponsePrompt />
    </>
  );
}
