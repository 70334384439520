import React, { useContext, useState, useEffect } from "react";
import { getAllAccountsApi, getDropDownDetailsApi } from "../services/api";
import { useCommonContext } from "./CommonContext";

const CreateMyEventContext = React.createContext();

export function CreateMyEventContextProvider({ children }) {
  const { userData } = useCommonContext();
  //Fields state starts here
  const [formData, setFormData] = useState({
    eventName: "",
    eventDescription: "",
    fromDateEvent: "",
    fromTimeEvent: "",
    toDateEvent: "",
    toTimeEvent: "",
    eventType: "",
    accountName: "",
    meetingPlatform: "",
    platforms: {
      fbPost: false,
      linkedinPost: false,
      twitterPost: false,
      instaCap: false,
    },
  });
  const [socialMediaId, setSocialMediaId] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [eventId, setEventId] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [isEventDetailOpen, setIsEventDetailOpen] = useState(false);
  const [isSocialMediaPostGenerated, setIsSocialMediaPostGenerated] =
    useState(false);
  const [socialMediaContent, setSocialMediaContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  async function getAllAccounts() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        reviewerId: userData[0]["User_Id"],
      };
      const responseData = await getAllAccountsApi(payload);
      setAccounts(responseData.data.data);

      const dropDownPayload = {
        userId: userData[0]["User_Id"],
        tableName: "Event_Types",
      };

      const dropDownResponse = await getDropDownDetailsApi(dropDownPayload);

      setEventTypes(dropDownResponse.data.data.data);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getAllAccounts();
  }, []);

  return (
    <CreateMyEventContext.Provider
      value={{
        formData,
        setFormData,
        accounts,
        setAccounts,
        eventTypes,
        setEventTypes,
        isEventDetailOpen,
        setIsEventDetailOpen,
        meetingLink,
        setMeetingLink,
        eventId,
        setEventId,
        isSocialMediaPostGenerated,
        setIsSocialMediaPostGenerated,
        socialMediaContent,
        setSocialMediaContent,
        socialMediaId,
        setSocialMediaId,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </CreateMyEventContext.Provider>
  );
}

export function useCreateMyEventContext() {
  return useContext(CreateMyEventContext);
}
