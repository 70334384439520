import React, { useEffect, useState } from "react";
import { useCommonContext } from "../../contexts/CommonContext.jsx";
import { useNavigate } from "react-router-dom";
import {
  getAllAccountApi,
  createAccountApi,
  deleteAccountApi,
  editAccountApi,
  addPlatformApi,
  editPlatformApi,
  platformConnectionApi,
} from "../../services/api.js";
import moment from "moment";

export default function Integrations() {
  const [validEmail, setIsEmailValid] = useState(false);
  const { userData, openToast } = useCommonContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState([]);
  const [search, setSearch] = useState([]);
  const {isNavCollapsed, setIsNavCollapsed } = useCommonContext();
  const [createNewAccountButton, setCreateNewAccountButton] = useState(false);
  const [accountName, setAccountName] = useState([]);
  const [deleteAccountButton, setDeleteAccountButton] = useState(false);
  const [insertAccountStatus, setInsertAccountStatus] = useState(true);
  const [editAccountId, setEditAccountId] = useState("");
  const [deleteAccountId, setDeleteAccountId] = useState("");
  const [platformAccountId, setPlatformAccountId] = useState([]);
  const [editPlatformId, seteditPlatformId] = useState("");
  const [count, setCount] = useState(10);
  const [loadMore, setLoadMore] = useState();
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState(true);

  {
    /**Delete state variable starts here */
  }
  const [deletePlatAccountId, setDeletePlatAccountId] = useState("");
  const [toConnect, setToConnect] = useState(false);
  const [deletePlatform, setDeletePlatform] = useState("");
  {
    /**Delet state variables ends here */
  }
  const [FaceBookCredentials, setFaceBookCredentials] = useState({
    access_token: "",
    facebook_page_id: "",
  });
  const [InstagramCredentials, setInstagramCredentials] = useState({
    access_token: "",
    User_id: "",
  });
  const [TwitterCredentials, setTwitterCredentials] = useState({
    access_Key: "",
    access_secret: "",
    consumer_key: "",
    consumer_secret: "",
    access_token: "",
  });
  const [LinkedInCredentials, setLinkedInCredentials] = useState({
    access_token: "",
  });
  const [GoogleMeetCredentials, setGoogleMeetCredentials] = useState({
    email_id: "",
    credential_file_url: "",
  });
  const [googleMeetJsonData, setGoogleMeetJsonData] = useState(null);
  const [TeamsCredentials, setTeamsCredentials] = useState({
    client_id: "",
    client_secret: "",
    tenant_id: "",
    user_name: "",
    password: "",
  });
  const [isConnected, setIsConnected] = useState(0);
  const[googleMeetFile,setgoogleMeetFile] = useState([])


  console.info(isConnected,"0-0-0-0-0-0-0-0-0-0-0-0-")
  // console.log(InstagramCredentials, "access");
  // console.log(FaceBookCredentials, "access");
  // console.log(TwitterCredentials, "access");
  // console.log(LinkedInCredentials, "access");
  // console.log(GoogleMeetCredentials, "access");
  // console.log(TeamsCredentials, "access");
  // console.log(platformAccountId, "===============");
  // console.log(editPlatformId, "----------------------");

  const handleEmailChange = (e) => {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    setIsEmailValid(emailRegex.test(e.target.value));
    setGoogleMeetCredentials((prevState) => ({
      ...prevState,
      email_id: e.target.value,
    }));
  };

  const handleFileChange = (event) => {
    console.info("hjdshjdshjds",event);
    const file = event.target.files[0];
    const fileName = event.target.files[0].name
    setgoogleMeetFile(fileName)
    console.log(file);
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        console.info("dasdasdasd", e);
        const content = JSON.parse(e.target.result);
        const jsonString = JSON.stringify(content);
        console.log(jsonString, "<<<<<<<<<<<<<<<<<<<<");
        setGoogleMeetCredentials((prevState) => ({
          ...prevState,
          credential_file_url: jsonString,
        }));
      } catch (error) {
        console.error("Error parsing JSON file:", error);
      }
    };

    reader.readAsText(file);
  };

  useEffect(() => {
    if (search == "") {
      getAllAccount();
    }
  }, [search]);

  useEffect(() => {
    getAllAccount();
  }, [count, sortColumn, sortOrder]);

  const getAllAccount = async () => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const data = {
        user_id: userId,
        search: search,
        count: count,
        sort_column: sortColumn,
        sort_order: sortOrder ? "Asc" : "Desc",
      };

      let responseData;

      if (sortColumn != "Platform Integrated") {
        responseData = await getAllAccountApi(data);
        if (responseData.status === 200) {
          let newAcc = responseData.data.data.data;

          for (let account of newAcc) {
            account["platformIntegrated"] =
              account?.Instagram_Credentials.length +
              account?.Facebook_Credentials.length +
              account?.LinkedIn_Credentials.length +
              account?.Twitter_Credentials.length +
              account?.Google_Meet_Credentials.length +
              account?.Teams_Credentials.length;
          }
          setAccountDetails(newAcc);
          setLoadMore(responseData.data.data.rowCount);
          console.log(setAccountDetails, "adsadsasdasdasdasdsaasdasdasd");
          setIsLoading(false);
        }
      } else {
        const sortedData = [...accountDetails].sort((a, b) => {
          if (sortOrder) {
            return a.platformIntegrated - b.platformIntegrated;
          } else {
            return b.platformIntegrated - a.platformIntegrated;
          }
        });
        setAccountDetails(sortedData);
      }
    } catch (error) {
      //   await insertErrorApi(error);
      setIsLoading(false);
    }
  };

  const createAccount = async () => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const data = {
        user_id: userId,
        account_name: accountName,
      };
      const responseData = await createAccountApi(data);
      if (responseData.status === 200 || responseData.status === 201) {
        getAllAccount();
        setAccountName("");
        setCreateNewAccountButton(false);
        document.getElementById("CreateAccountClose").click();
        setIsLoading(false);
      } else {
        throw new Error("Error in creating account");
      }
    } catch (error) {
      // await insertErrorApi(error);
      // setIsLoading(false);
    }
  };

  const deleteAccount = async (deleteId) => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const accountId = deleteId;
      const data = {
        user_id: userId,
        account_id: accountId,
      };
      const responseData = await deleteAccountApi(data);
      if (responseData.status === 200) {
        await getAllAccount();
        setDeleteAccountButton(false);
        setIsLoading(false);
        openToast("Account Deleted successfully", "images/toast-check.svg");
      }
    } catch (error) {
      // await insertErrorApi(error);
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
      setIsLoading(false);
    }
  };

  const editAccount = async () => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const data = {
        user_id: userId,
        account_name: accountName,
        account_id: editAccountId,
      };
      const responseData = await editAccountApi(data);
      if (responseData.status === 200) {
        await getAllAccount();
        setIsLoading(false);
        setAccountName("");
        setEditAccountId("");
        setCreateNewAccountButton(false);
        document.getElementById("CreateAccountClose").click();
        openToast("Account Editted successfully", "images/toast-check.svg");
      }
    } catch (error) {
      // await insertErrorApi(error);
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
      setIsLoading(false);
    }
  };

  const addPlatform = async (platform, accountId) => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      let data = {};
      if (platform === "FaceBook") {
        data = {
          user_id: userId,
          Platform_Type: "facebook",
          account_id: accountId,
          access_token: FaceBookCredentials.access_token,
          facebook_page_id: FaceBookCredentials.facebook_page_id,
        };
      } else if (platform === "Instagram") {
        data = {
          user_id: userId,
          Platform_Type: "instagram",
          account_id: accountId,
          access_token: InstagramCredentials.access_token,
          User_id: InstagramCredentials.User_id,
        };
      } else if (platform === "Twitter") {
        data = {
          user_id: userId,
          Platform_Type: "Twitter",
          account_id: accountId,
          access_Key: TwitterCredentials.access_Key,
          access_secret: TwitterCredentials.access_secret,
          consumer_key: TwitterCredentials.consumer_key,
          consumer_secret: TwitterCredentials.consumer_secret,
          access_token: TwitterCredentials.access_token,
        };
      } else if (platform === "LinkedIn") {
        data = {
          user_id: userId,
          Platform_Type: "LinkedIn",
          account_id: accountId,
          access_token: LinkedInCredentials.access_token,
        };
      } else if (platform === "GoogleMeet") {
        data = {
          user_id: userId,
          Platform_Type: "googleMeet",
          account_id: accountId,
          email_id: GoogleMeetCredentials.email_id,
          credential_file_url: GoogleMeetCredentials.credential_file_url,
        };
      } else if (platform === "Teams") {
        data = {
          user_id: userId,
          Platform_Type: "Teams",
          account_id: accountId,
          client_id: TeamsCredentials.client_id,
          client_secret: TeamsCredentials.client_secret,
          tenant_id: TeamsCredentials.tenant_id,
          Teams_User_Name: TeamsCredentials.user_name,
          Teams_Password: TeamsCredentials.password,
        };
      }
      const responseData = await addPlatformApi(data);
      if (responseData.status === 200 || responseData.status === 201) {
        await getAllAccount();
        setInstagramCredentials((prevState) => ({
          ...prevState,
          access_token: "",
          User_id: "",
        }));
        setFaceBookCredentials((prevState) => ({
          ...prevState,
          access_token: "",
          facebook_page_id: "",
        }));
        setLinkedInCredentials((prevState) => ({
          ...prevState,
          access_token: "",
        }));
        setTeamsCredentials((prevState) => ({
          ...prevState,
          client_secret: "",
          tenant_id: "",
          client_id: "",
          user_name: "",
          password: "",
        }));
        setGoogleMeetCredentials((prevState) => ({
          ...prevState,
          email_id: "",
          credential_file_url: "",
        }));
        setTwitterCredentials((prevState) => ({
          ...prevState,
          access_Key: "",
          access_token: "",
          consumer_key: "",
          consumer_secret: "",
          access_secret: "",
        }));
        setgoogleMeetFile("")
        openToast("Platform Added successfully", "images/toast-check.svg");
        setIsLoading(false);
      } else {
        throw new Error("Error in adding platform");
      }
    } catch (error) {
      // await insertErrorApi(error);
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
      setIsLoading(false);
    }
  };

  const editPlatform = async (platform, accountId) => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      let data = {};
      if (platform === "FaceBook") {
        data = {
          user_id: userId,
          Platform_type: "facebook",
          account_id: accountId,
          access_token: FaceBookCredentials.access_token,
          facebook_page_id: FaceBookCredentials.facebook_page_id,
        };
      } else if (platform === "Instagram") {
        data = {
          user_id: userId,
          Platform_type: "instagram",
          account_id: accountId,
          access_token: InstagramCredentials.access_token,
          User_id: InstagramCredentials.User_id,
        };
      } else if (platform === "Twitter") {
        data = {
          user_id: userId,
          Platform_type: "Twitter",
          account_id: accountId,
          access_Key: TwitterCredentials.access_Key,
          access_secret: TwitterCredentials.access_secret,
          consumer_key: TwitterCredentials.consumer_key,
          consumer_secret: TwitterCredentials.consumer_secret,
          access_token: TwitterCredentials.access_token,
        };
      } else if (platform === "LinkedIn") {
        data = {
          user_id: userId,
          Platform_type: "LinkedIn",
          account_id: accountId,
          access_token: LinkedInCredentials.access_token,
        };
      } else if (platform === "GoogleMeet") {
        data = {
          user_id: userId,
          Platform_type: "googleMeet",
          account_id: accountId,
          email_id: GoogleMeetCredentials.email_id,
          credential_file_url: GoogleMeetCredentials.credential_file_url,
        };
      } else if (platform === "Teams") {
        data = {
          user_id: userId,
          Platform_type: "Teams",
          account_id: accountId,
          client_id: TeamsCredentials.client_id,
          client_secret: TeamsCredentials.client_secret,
          tenant_id: TeamsCredentials.tenant_id,
          Teams_User_Name: TeamsCredentials.user_name,
          Teams_Password: TeamsCredentials.password,
        };
      }
      const responseData = await editPlatformApi(data);
      if (responseData.status === 200 || responseData.status === 201) {
        await getAllAccount();
        seteditPlatformId("");
        setInstagramCredentials((prevState) => ({
          ...prevState,
          access_token: "",
          User_id: "",
        }));
        setFaceBookCredentials((prevState) => ({
          ...prevState,
          access_token: "",
          facebook_page_id: "",
        }));
        setLinkedInCredentials((prevState) => ({
          ...prevState,
          access_token: "",
        }));
        setTeamsCredentials((prevState) => ({
          ...prevState,
          client_secret: "",
          tenant_id: "",
          client_id: "",
          user_name: "",
          password: "",
        }));
        setGoogleMeetCredentials((prevState) => ({
          ...prevState,
          email_id: "",
          credential_file_url: "",
        }));
        setTwitterCredentials((prevState) => ({
          ...prevState,
          access_Key: "",
          access_token: "",
          consumer_key: "",
          consumer_secret: "",
          access_secret: "",
        }));
        setgoogleMeetFile("")
        openToast("Platform Editted successfully", "images/toast-check.svg");
        setIsLoading(false);
      }
    } catch (error) {
      // await insertErrorApi(error);
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
      setIsLoading(false);
    }
  };

  const platformConnection = async () => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const data = {
        user_id: userId,
        account_id: deletePlatAccountId,
        platform: deletePlatform,
        is_connect: toConnect ? 1 : 0,
      };
      const responseData = await platformConnectionApi(data);
      if (responseData.status === 200 || responseData.status === 201) {
        getAllAccount();
        setIsLoading(false);
        openToast(
          isConnected
            ? "Platform Conntected successfully": "Platform Disconnected successfully",
          "images/toast-check.svg"
        );
      }
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
      console.error(error);
    }
  };

  return (
    <>
      <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
              {accountDetails?.length != 0 ? (
                <h2 className="font-bold font-22 mb-0">
                  Integration <span>({loadMore})</span>
                </h2>
              ) : (
                <h2 className="font-bold font-22 mb-0">
                  Integration <span></span>
                </h2>
              )}
              <div className="d-flex gap-3">
                {/* search starts here */}
                {accountDetails?.length != 0 ? (
                  <div className="input-group search-field">
                    <input
                      type="search"
                      className="form-control box-shadow-none font-16 font-medium"
                      placeholder="Search"
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      value={search}
                    />

                    <button
                      className="btn white-btn box-shadow-none d-flex align-items-center"
                      type="button"
                      id="button-addon2"
                      disabled={search == ""}
                      onClick={() => getAllAccount()}
                    >
                      <img src="images/search-icon.svg" alt="search-icon" />
                    </button>
                  </div>
                ) : null}

                {/* search starts here */}

                <button
                  className="btn primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                  data-bs-toggle="modal"
                  data-bs-target="#createnewacc"
                >
                  <img src="images/add-icon.svg" alt="add-icon" />
                  Create New Account
                </button>
              </div>
            </div>
            {accountDetails?.length != 0 ? (
              <>
                <div className="table-responsive theme-tbl">
                  <table className="table table-borderless">
                    <thead className="font-medium bg-white">
                      <tr>
                        <th />
                        <th>
                          Account{" "}
                          <img
                            className="cursor-pointer ms-2"
                            src={
                              sortOrder && sortColumn === "Account_Name"
                                ? "images/sort-up.svg"
                                : "images/sort-down.svg"
                            }
                            alt="sort-up"
                            onClick={() => {
                              setSortOrder(!sortOrder);
                              setSortColumn("Account_Name");
                            }}
                          />
                        </th>
                        <th>
                          Created On
                          <img
                            className="cursor-pointer ms-2"
                            src={
                              sortOrder && sortColumn === "Created_Date"
                                ? "images/sort-up.svg"
                                : "images/sort-down.svg"
                            }
                            alt="sort-up"
                            onClick={() => {
                              setSortOrder(!sortOrder);
                              setSortColumn("Created_Date");
                            }}
                          />
                        </th>
                        <th>
                          Platform Integrated
                          <img
                            className="cursor-pointer ms-2"
                            src={
                              sortOrder && sortColumn === "Platform Integrated"
                                ? "images/sort-up.svg"
                                : "images/sort-down.svg"
                            }
                            alt="sort-up"
                            onClick={() => {
                              setSortOrder(!sortOrder);
                              setSortColumn("Platform Integrated");
                            }}
                          />
                        </th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="font-medium">
                      {accountDetails.map((account, index) => {
                        return (
                          <React.Fragment>
                            <tr className="grid-acc-open-brd">
                              <td>
                                <p
                                  className="font-14 mb-0 font-medium cursor-pointer collapsed"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#integaccount_${index}`}
                                  aria-expanded="false"
                                >
                                  <img
                                    src="images/table-accordion-arrow.svg"
                                    alt="form-acc-arrow"
                                    className="me-2 grid-arrow"
                                  />
                                </p>
                              </td>

                              <td>{account.Account_Name}</td>
                              <td>
                                {moment(account.Created_Date).format(
                                  "MMM DD, YYYY"
                                )}
                              </td>
                              <td>{account?.platformIntegrated}</td>
                              <td className="text-center">
                                <span className="d-flex align-items-center justify-content-center gap-2">
                                  <button
                                    className="edit-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#createnewacc"
                                  >
                                    <img
                                      src="images/table-edit.svg"
                                      alt="table-edit"
                                      onClick={() => {
                                        setAccountName(account.Account_Name);
                                        setEditAccountId(account.Account_Id);
                                      }}
                                    />
                                  </button>
                                  <span className="border-sape" />
                                  <button
                                    className="delete-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete"
                                    onClick={() => {
                                      setDeleteAccountId(account.Account_Id);
                                    }}
                                  >
                                    <img
                                      src="images/table-delete.svg"
                                      alt="table-delete"
                                    />
                                  </button>
                                </span>
                              </td>
                            </tr>
                            <tr
                              className="collapse"
                              id={`integaccount_${index}`}
                            >
                              <td colSpan={5} className="px-5">
                                <div className="table-responsive theme-tbl">
                                  <table className="table table-borderless mb-0">
                                    <thead className="font-medium bg-white">
                                      <tr>
                                        <th className="ps-3">Platform</th>
                                        <th>Status</th>
                                        <th className="text-center">Action</th>
                                      </tr>
                                    </thead>
                                    {account.Instagram_Credentials?.length !=
                                      0 ||
                                    account.Facebook_Credentials.length != 0 ||
                                    account.LinkedIn_Credentials.length != 0 ||
                                    account.Twitter_Credentials.length != 0 ||
                                    account.Google_Meet_Credentials.length !=
                                      0 ||
                                    account.Teams_Credentials.length != 0 ? (
                                      <tbody>
                                        {account.Instagram_Credentials
                                          ?.length != 0 ? (
                                          <tr key={"Insta" + index}>
                                            <td className="ps-3">
                                              <span className="d-flex align-items-center">
                                                <img
                                                  src="images/insta-caption-icon.svg"
                                                  alt="insta-caption-icon"
                                                  className="grid-icons me-3"
                                                />
                                                <label>Instagram</label>
                                              </span>
                                            </td>
                                            <td>
                                              {account.Instagram_Credentials[0]
                                                .Is_Connected
                                                ? "Connected"
                                                : "Disconnected"}
                                            </td>
                                            <td className="text-center">
                                              <span className="d-flex align-items-center justify-content-center gap-2">
                                                <button
                                                  className="edit-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#connectinstagram"
                                                  onClick={() => {
                                                    seteditPlatformId(
                                                      account.Account_Id
                                                    );
                                                    setInstagramCredentials(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        access_token:
                                                          account
                                                            ?.Instagram_Credentials[0]
                                                            .Instagram_Access_Token,
                                                        User_id:
                                                          account
                                                            ?.Instagram_Credentials[0]
                                                            .Instagram_User_Id,
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src="images/table-edit.svg"
                                                    alt="table-edit"
                                                  />
                                                </button>
                                                <span className="border-sape" />
                                                <button
                                                  className="delete-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#deleteplat"
                                                  onClick={() => {
                                                    setDeletePlatAccountId(
                                                      account?.Account_Id
                                                    );
                                                    setToConnect(
                                                      !account
                                                        .Instagram_Credentials[0]
                                                        .Is_Connected
                                                    );
                                                    setDeletePlatform(
                                                      "Instagram_Credentials"
                                                    );
                                                    setIsConnected(account.Instagram_Credentials[0]
                                                      .Is_Connected ? 0 : 1)
                                                  }}
                                                >
                                                  <img
                                                    src="images/integrate-icon.svg"
                                                    alt="integrate-icon"
                                                  />
                                                </button>
                                              </span>
                                            </td>
                                          </tr>
                                        ) : null}

                                        {account.Facebook_Credentials?.length !=
                                        0 ? (
                                          <tr key={"faceb" + index}>
                                            <td className="ps-3">
                                              <span className="d-flex align-items-center">
                                                <img
                                                  src="images/facebook-post-icon.svg"
                                                  alt="facebook-post-icon"
                                                  className="grid-icons me-3"
                                                />
                                                <label>Facebook</label>
                                              </span>
                                            </td>
                                            <td>
                                              {account.Facebook_Credentials[0]
                                                .Is_Connected
                                                ? "Connected"
                                                : "Disconnected"}
                                            </td>
                                            <td className="text-center">
                                              <span className="d-flex align-items-center justify-content-center gap-2">
                                                <button
                                                  className="edit-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#connectFacebook"
                                                  onClick={() => {
                                                    seteditPlatformId(
                                                      account.Account_Id
                                                    );
                                                    setFaceBookCredentials(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        access_token:
                                                          account
                                                            ?.Facebook_Credentials[0]
                                                            .Facebook_Access_Token,
                                                        facebook_page_id:
                                                          account
                                                            ?.Facebook_Credentials[0]
                                                            .Facebook_Page_Id,
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src="images/table-edit.svg"
                                                    alt="table-edit"
                                                  />
                                                </button>
                                                <span className="border-sape" />
                                                <button
                                                  className="delete-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#deleteplat"
                                                  onClick={() => {
                                                    setDeletePlatAccountId(
                                                      account?.Account_Id
                                                    );
                                                    setToConnect(
                                                      !account
                                                        .Facebook_Credentials[0]
                                                        .Is_Connected
                                                    );
                                                    setDeletePlatform(
                                                      "Facebook_Credentials"
                                                    );
                                                    setIsConnected(account.Facebook_Credentials[0]
                                                      .Is_Connected ? 0 : 1)
                                                  }}
                                                >
                                                  <img
                                                    src="images/integrate-icon.svg"
                                                    alt="integrate-icon"
                                                  />
                                                </button>
                                              </span>
                                            </td>
                                          </tr>
                                        ) : null}
                                        {console.log(
                                          account,
                                          "This is the bind account"
                                        )}

                                        {account.Twitter_Credentials?.length !=
                                        0 ? (
                                          <tr key={"Twitter" + index}>
                                            <td className="ps-3">
                                              <span className="d-flex align-items-center">
                                                <img
                                                  src="images/Twitter-post-icon.svg"
                                                  alt="Twitter-post-icon"
                                                  className="grid-icons me-3"
                                                />
                                                <label>Twitter</label>
                                              </span>
                                            </td>
                                            <td>
                                              {account.Twitter_Credentials[0]
                                                .Is_Connected
                                                ? "Connected"
                                                : "Disconnected"}
                                            </td>
                                            <td className="text-center">
                                              <span className="d-flex align-items-center justify-content-center gap-2">
                                                <button
                                                  className="edit-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#connectTwitter"
                                                  onClick={() => {
                                                    seteditPlatformId(
                                                      account.Account_Id
                                                    );
                                                    setTwitterCredentials(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        access_Key:
                                                          account
                                                            ?.Twitter_Credentials[0]
                                                            .Access_Key,
                                                        access_token:
                                                          account
                                                            ?.Twitter_Credentials[0]
                                                            .Access_Token,
                                                        consumer_key:
                                                          account
                                                            ?.Twitter_Credentials[0]
                                                            .Consumer_Key,
                                                        consumer_secret:
                                                          account
                                                            ?.Twitter_Credentials[0]
                                                            .Consumer_Secret,
                                                        access_secret:
                                                          account
                                                            ?.Twitter_Credentials[0]
                                                            .Access_Secret,
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src="images/table-edit.svg"
                                                    alt="table-edit"
                                                  />
                                                </button>
                                                <span className="border-sape" />
                                                <button
                                                  className="delete-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#deleteplat"
                                                  onClick={() => {
                                                    setDeletePlatAccountId(
                                                      account?.Account_Id
                                                    );
                                                    setToConnect(
                                                      !account
                                                        .Twitter_Credentials[0]
                                                        .Is_Connected
                                                    );
                                                    setDeletePlatform(
                                                      "Twitter_Credentials"
                                                    );
                                                    setIsConnected(account.Twitter_Credentials[0]
                                                      .Is_Connected ? 0 : 1)
                                                  }}
                                                >
                                                  <img
                                                    src="images/integrate-icon.svg"
                                                    alt="integrate-icon"
                                                  />
                                                </button>
                                              </span>
                                            </td>
                                          </tr>
                                        ) : null}

                                        {account.LinkedIn_Credentials
                                          ?.length !== 0 ? (
                                          <tr key={"Linkedin" + index}>
                                            <td className="ps-3">
                                              <span className="d-flex align-items-center">
                                                <img
                                                  src="images/LinkedIn-post-icon.svg"
                                                  alt="LinkedIn-post-icon"
                                                  className="grid-icons me-3"
                                                />
                                                <label>LinkedIn</label>
                                              </span>
                                            </td>
                                            <td>
                                              {account.LinkedIn_Credentials[0]
                                                .Is_Connected
                                                ? "Connected"
                                                : "Disconnected"}
                                            </td>
                                            <td className="text-center">
                                              <span className="d-flex align-items-center justify-content-center gap-2">
                                                <button
                                                  className="edit-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#connectlinkedin"
                                                  onClick={() => {
                                                    seteditPlatformId(
                                                      account.Account_Id
                                                    );
                                                    setLinkedInCredentials(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        access_token:
                                                          account
                                                            ?.LinkedIn_Credentials[0]
                                                            .Access_Token,
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src="images/table-edit.svg"
                                                    alt="table-edit"
                                                  />
                                                </button>
                                                <span className="border-sape" />
                                                <button
                                                  className="delete-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#deleteplat"
                                                  onClick={() => {
                                                    setDeletePlatAccountId(
                                                      account?.Account_Id
                                                    );
                                                    setToConnect(
                                                      !account
                                                        .LinkedIn_Credentials[0]
                                                        .Is_Connected
                                                    );
                                                    setDeletePlatform(
                                                      "LinkedIn_Credentials"
                                                    );
                                                    setIsConnected(account.LinkedIn_Credentials[0]
                                                      .Is_Connected ? 0 : 1)
                                                  }}
                                                >
                                                  <img
                                                    src="images/integrate-icon.svg"
                                                    alt="integrate-icon"
                                                  />
                                                </button>
                                              </span>
                                            </td>
                                          </tr>
                                        ) : null}

                                        {account.Google_Meet_Credentials
                                          ?.length != 0 ? (
                                          <tr key={"GoogleMeet" + index}>
                                            <td className="ps-3">
                                              <span className="d-flex align-items-center">
                                                <img
                                                  src="images/popup-googlemeet-icon.svg"
                                                  alt="Google_Meet-post-icon"
                                                  className="grid-icons me-3"
                                                />
                                                <label>Google Meet</label>
                                              </span>
                                            </td>
                                            <td>
                                              {account
                                                .Google_Meet_Credentials[0]
                                                .Is_Connected
                                                ? "Connected"
                                                : "Disconnected"}
                                            </td>
                                            <td className="text-center">
                                              <span className="d-flex align-items-center justify-content-center gap-2">
                                                <button
                                                  className="edit-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#connectGoogleMeet"
                                                  onClick={() => {
                                                    seteditPlatformId(
                                                      account.Account_Id
                                                    );
                                                    setGoogleMeetCredentials(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        email_id:
                                                          account
                                                            ?.Google_Meet_Credentials[0]
                                                            .Email_Id,
                                                        credential_file_url:
                                                          account
                                                            ?.Google_Meet_Credentials[0]
                                                            .Credential_File_Url,
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src="images/table-edit.svg"
                                                    alt="table-edit"
                                                  />
                                                </button>
                                                <span className="border-sape" />
                                                <button
                                                  className="delete-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#deleteplat"
                                                  onClick={() => {
                                                    setDeletePlatAccountId(
                                                      account?.Account_Id
                                                    );
                                                    setToConnect(
                                                      !account
                                                        .Google_Meet_Credentials[0]
                                                        .Is_Connected
                                                    );
                                                    setgoogleMeetFile("")
                                                    setDeletePlatform(
                                                      "Google_Meet_Credentials"
                                                    );
                                                    setIsConnected(account.Google_Meet_Credentials[0]
                                                      .Is_Connected ? 0 : 1)
                                                  }}
                                                >
                                                  <img
                                                    src="images/integrate-icon.svg"
                                                    alt="integrate-icon"
                                                  />
                                                </button>
                                              </span>
                                            </td>
                                          </tr>
                                        ) : null}

                                        {account.Teams_Credentials?.length !=
                                        0 ? (
                                          <tr key={"Teams" + index}>
                                            <td className="ps-3">
                                              <span className="d-flex align-items-center">
                                                <img
                                                  src="images/popup-teams-icon.svg"
                                                  alt="Teams-post-icon"
                                                  className="grid-icons me-3"
                                                />
                                                <label>Teams</label>
                                              </span>
                                            </td>
                                            <td>
                                              {account.Teams_Credentials[0]
                                                .Is_Connected
                                                ? "Connected"
                                                : "Disconnected"}
                                            </td>
                                            <td className="text-center">
                                              <span className="d-flex align-items-center justify-content-center gap-2">
                                                <button
                                                  className="edit-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#connectTeams"
                                                  onClick={() => {
                                                    seteditPlatformId(
                                                      account.Account_Id
                                                    );
                                                    setTeamsCredentials(
                                                      (prevState) => ({
                                                        ...prevState,
                                                        client_secret:
                                                          account
                                                            ?.Teams_Credentials[0]
                                                            .Client_Secret,
                                                        tenant_id:
                                                          account
                                                            ?.Teams_Credentials[0]
                                                            .Tenant_Id,
                                                        client_id:
                                                          account
                                                            ?.Teams_Credentials[0]
                                                            .Client_Id,
                                                        user_name:
                                                          account
                                                            ?.Teams_Credentials[0]
                                                            .Teams_User_Name,
                                                        password:
                                                          account
                                                            ?.Teams_Credentials[0]
                                                            .Teams_Password,
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src="images/table-edit.svg"
                                                    alt="table-edit"
                                                  />
                                                </button>
                                                <span className="border-sape" />
                                                <button
                                                  className="delete-btn"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#deleteplat"
                                                  onClick={() => {
                                                    setDeletePlatAccountId(
                                                      account?.Account_Id
                                                    );
                                                    setToConnect(
                                                      !account
                                                        .Teams_Credentials[0]
                                                        .Is_Connected
                                                    );
                                                    setDeletePlatform(
                                                      "Teams_Credentials"
                                                    );
                                                    setIsConnected(account.Teams_Credentials[0]
                                                      .Is_Connected ? 0 : 1)
                                                  }}
                                                >
                                                  <img
                                                    src="images/integrate-icon.svg"
                                                    alt="integrate-icon"
                                                  />
                                                </button>
                                              </span>
                                            </td>
                                          </tr>
                                        ) : null}
                                        <tr
                                          className="grid-acc-open-brd"
                                          hidden={
                                            account.Instagram_Credentials
                                              .length !== 0 &&
                                            account.Facebook_Credentials
                                              .length !== 0 &&
                                            account.Twitter_Credentials
                                              .length !== 0 &&
                                            account.Google_Meet_Credentials
                                              .length !== 0 &&
                                            account.Teams_Credentials.length !==
                                              0 &&
                                            account.LinkedIn_Credentials
                                              .length !== 0
                                          }
                                        >
                                          <td colSpan={3} className="ps-3">
                                            <div className>
                                              <button
                                                className="border-0 px-0 font-12 font-medium d-flex align-items-center text-blue-btn"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <img
                                                  src="images/blue-btn-add-icon.svg"
                                                  alt="blue-btn-add-icon"
                                                  className="me-1"
                                                />
                                                <span>Add new platform</span>
                                                <img
                                                  src="images/blue-btn-arrow-icon.svg"
                                                  alt="blue-btn-arrow-icon"
                                                  className="ms-1 mt-1"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu addnew-platform-drop"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                {account?.Instagram_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectinstagram"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    Instagram
                                                  </li>
                                                ) : null}

                                                {account?.Facebook_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectFacebook"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    Facebook
                                                  </li>
                                                ) : null}

                                                {account?.Twitter_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectTwitter"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    Twitter
                                                  </li>
                                                ) : null}
                                                {account?.LinkedIn_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectlinkedin"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    LinkedIn
                                                  </li>
                                                ) : null}
                                                {account
                                                  ?.Google_Meet_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectGoogleMeet"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    Google Meet
                                                  </li>
                                                ) : null}
                                                {account?.Teams_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectTeams"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    Teams
                                                  </li>
                                                ) : null}
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr class="grid-acc-open-brd">
                                          <td
                                            colspan="3"
                                            class="ps-3 text-center"
                                          >
                                            <span>
                                              <img
                                                src="images/no-platform-intg-icon.svg"
                                                alt="no-platform-intg-icon"
                                                class="my-3"
                                              />
                                            </span>
                                            <h5 class="font-14 font-bold primary-darkblue mt-1">
                                              No Platforms Integrated
                                            </h5>
                                            <p class="font-12 font-regular grid-norec-gray">
                                              It looks like you haven't
                                              integrated any platforms
                                            </p>
                                            <div className="d-flex justify-content-center">
                                              <button
                                                className="border-0 px-0 font-12 font-medium d-flex align-items-center text-blue-btn"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <img
                                                  src="images/blue-btn-add-icon.svg"
                                                  alt="blue-btn-add-icon"
                                                  className="me-1"
                                                />
                                                <span>Add new platform</span>
                                                <img
                                                  src="images/blue-btn-arrow-icon.svg"
                                                  alt="blue-btn-arrow-icon"
                                                  className="ms-1 mt-1"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu addnew-platform-drop"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                {account?.Instagram_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectinstagram"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    Instagram
                                                  </li>
                                                ) : null}

                                                {account?.Facebook_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectFacebook"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    Facebook
                                                  </li>
                                                ) : null}

                                                {account?.Twitter_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectTwitter"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    Twitter
                                                  </li>
                                                ) : null}
                                                {account?.LinkedIn_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectlinkedin"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    LinkedIn
                                                  </li>
                                                ) : null}
                                                {account
                                                  ?.Google_Meet_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectGoogleMeet"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    Google Meet
                                                  </li>
                                                ) : null}
                                                {account?.Teams_Credentials
                                                  ?.length === 0 ? (
                                                  <li
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#connectTeams"
                                                    className="font-14 font-medium cursor-pointer d-flex align-items-center gap-2"
                                                    onClick={() => {
                                                      setPlatformAccountId(
                                                        account.Account_Id
                                                      );
                                                    }}
                                                  >
                                                    Teams
                                                  </li>
                                                ) : null}
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  {loadMore <= 10 || count >= loadMore ? null : (
                    <button
                      className="btn shadow-btn d-flex align-items-center py-2 px-3  font-bold font-16"
                      onClick={() => {
                        // let newCount = count;
                        // newCount += 10;
                        setCount(count + 10);
                        // getAllAccount(count);
                      }}
                    >
                      Load More
                    </button>
                  )}
                </div>
              </>
            ) : (
              <li
                class="bg-white notify-li-radius shadow-sm mb-3 px-3 py-5"
                style={{ listStyleType: "none" }}
              >
                <div class="ps-3 text-center">
                  <span>
                    <img
                      src="images/no-notify-icon.svg"
                      alt="no-notify-icon"
                      class="my-3"
                    />
                  </span>
                  <h5 class="font-14 font-bold primary-darkblue mt-1">
                    No Account Added
                  </h5>
                  <p class="font-12 font-regular grid-norec-gray">
                    It looks like you haven't added any account yet!
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="border-0 px-0 font-12 font-medium d-flex align-items-center text-blue-btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="modal"
                    data-bs-target="#createnewacc"
                  >
                    <img
                      src="images/blue-btn-add-icon.svg"
                      alt="blue-btn-add-icon"
                      className="me-1"
                    />
                    <span>Create New Account</span>
                    <img
                      src="images/blue-btn-arrow-icon.svg"
                      alt="blue-btn-arrow-icon"
                      className="ms-1 mt-1"
                    />
                  </button>
                </div>
              </li>
            )}
          </div>
        </div>
      </div>
      {/* Create account starts here */}
      <div
        className="modal fade"
        id="createnewacc"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="createnewaccLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <h5
                className="modal-title font-18 font-bold popup-header-color"
                id="createnewaccLabel"
              >
                Create New Account
              </h5>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="CreateAccountClose"
                onClick={() => {
                  setAccountName("");
                  setEditAccountId("");
                }}
              ></button>
            </div>

            <div className="modal-body border-0">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="AccountName"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Account Name
                </label>
                <input
                  type="text"
                  placeholder="Enter Account Name"
                  className="form-control custom-form py-2 font-14 font-regular"
                  id="AccountName"
                  onChange={(e) => {
                    setAccountName(e.target.value);
                  }}
                  value={accountName}
                />
              </div>
            </div>
            <div className="modal-footer border-0 show">
              <button
                className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  setAccountName("");
                  setEditAccountId("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                onClick={() => {
                  if (editAccountId === "") {
                    createAccount();
                  } else {
                    editAccount();
                  }
                }}
                disabled={accountName === ""}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {/**Create account ends here */}
      {/**Delete account starts here */}
      <div
        className="modal fade"
        id="delete"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="connectFacebookLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <span className="modal-title" id="connectFacebookLabel">
                <img
                  src="images/delete-popup-icon.svg"
                  alt="delete-popup-icon"
                  className="popup-logo"
                />
              </span>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body border-0">
              <h5 className="font-18 font-bold popup-header-color">Delete</h5>
              <p className="font-14 font-regular popup-data-color">
                Are you sure you want to delete this Account ?
              </p>
            </div>
            <div className="modal-footer border-0">
              <button
                className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger px-4 cust-reject text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                data-bs-dismiss="modal"
                onClick={() => {
                  deleteAccount(deleteAccountId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/**Delete account ends here */}
      {/**Delete account platform */}
      {/* {Delete platfrom startes here} */}
      <div
        className="modal fade"
        id="deleteplat"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="connectFacebookLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <span className="modal-title" id="connectFacebookLabel">
                <img
                  src="images/delete-popup-icon.svg"
                  alt="delete-popup-icon"
                  className="popup-logo"
                />
              </span>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body border-0">
              <h5 className="font-18 font-bold popup-header-color">
                {toConnect ? "Connect" : "Disconnect"}
              </h5>
              <p className="font-14 font-regular popup-data-color">
                Are you sure you want to {toConnect ? "Connect" : "Disconnect"}{" "}
                the platform?
              </p>
            </div>
            <div className="modal-footer border-0">
              <button
                className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger px-4 cust-reject text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                data-bs-dismiss="modal"
                onClick={() => {
                  platformConnection();
                }}
              >
                {toConnect ? "Connect" : "Disconnect"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/**Delete account platform ends */}
      {/**All account paltform Popups starts here */}
      {/* Connect linkedin popup starts here */}
      <div
        className="modal fade"
        id="connectlinkedin"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="connectlinkedinLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <span className="modal-title" id="connectlinkedinLabel">
                <img
                  src="images/popup-linkedin-icon.svg"
                  alt="popup-linkedin-icon"
                  className="popup-logo"
                />
              </span>
              <button
                type="button"
                className="btn-close shadow-none "
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setLinkedInCredentials((prevState) => ({
                    ...prevState,
                    access_token: "",
                  }));
                  seteditPlatformId("");
                }}
              />
            </div>
            <div className="modal-body border-0">
              <h5 className="font-18 font-bold popup-header-color">
                Connect Linkedln
              </h5>
              <p className="font-12 font-regular popup-data-color">
                Connect LinkedIn for sharing AI-generated content. Paste your
                LinkedIn handle.
              </p>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="AccessToken"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Access Token
                </label>
                <input
                  type="text"
                  placeholder="Paste your access token"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="AccessToken"
                  onChange={(e) => {
                    setLinkedInCredentials((prevState) => ({
                      ...prevState,
                      access_token: e.target.value,
                    }));
                  }}
                  value={LinkedInCredentials.access_token}
                />
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setLinkedInCredentials((prevState) => ({
                    ...prevState,
                    access_token: "",
                  }));
                  seteditPlatformId("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                data-bs-dismiss="modal"
                onClick={() => {
                  if (editPlatformId == "") {
                    addPlatform("LinkedIn", platformAccountId);
                  } else {
                    editPlatform("LinkedIn", editPlatformId);
                  }
                }}
                disabled={LinkedInCredentials.access_token == ""}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Connect linkedin popup ends here */}
      {/* Connect instagram  popup starts here */}(
      <div
        className="modal fade"
        id="connectinstagram"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="connectinstagramLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <span className="modal-title" id="connectinstagramLabel">
                <img
                  src="images/popup-instagram-icon.svg"
                  alt="popup-instagram-icon"
                  className="popup-logo"
                />
              </span>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  //
                  setInstagramCredentials((prevState) => ({
                    ...prevState,
                    access_token: "",
                    User_id: "",
                  }));
                  seteditPlatformId("");
                }}
              />
            </div>
            <div className="modal-body border-0">
              <h5 className="font-18 font-bold popup-header-color">
                Connect Instagram
              </h5>
              <p className="font-12 font-regular popup-data-color">
                Connect Instagram for sharing AI-generated visuals. Paste your
                Instagram handle.
              </p>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="instAccessToken"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Access Token
                </label>
                <input
                  type="text"
                  placeholder="Paste your access token"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="instAccessToken"
                  onChange={(e) => {
                    setInstagramCredentials((prevState) => ({
                      ...prevState,
                      access_token: e.target.value,
                    }));
                  }}
                  value={InstagramCredentials.access_token}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="instuserid"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  User ID
                </label>
                <input
                  type="text"
                  placeholder="Paste your user ID"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="instuserid"
                  onChange={(e) => {
                    setInstagramCredentials((prevState) => ({
                      ...prevState,
                      User_id: e.target.value,
                    }));
                  }}
                  value={InstagramCredentials.User_id}
                />
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                data-bs-dismiss="modal"
                onClick={() => {
                  //
                  setInstagramCredentials((prevState) => ({
                    ...prevState,
                    access_token: "",
                    User_id: "",
                  }));
                  seteditPlatformId("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                data-bs-dismiss="modal"
                onClick={() => {
                  if (editPlatformId == "") {
                    addPlatform("Instagram", platformAccountId);
                  } else {
                    editPlatform("Instagram", editPlatformId);
                  }
                }}
                disabled={
                  (InstagramCredentials.access_token == "" &&
                    InstagramCredentials.User_id == "") ||
                  InstagramCredentials.access_token == "" ||
                  InstagramCredentials.User_id == ""
                }
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      ){/* Connect instagram popup ends here */}
      {/* Connect Twitter popup starts here */}
      <div
        className="modal fade"
        id="connectTwitter"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="connectTwitterLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <span className="modal-title" id="connectTwitterLabel">
                <img
                  src="images/popup-twitter-icon.svg"
                  alt="popup-twitter-icon"
                  className="popup-logo"
                />
              </span>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setTwitterCredentials((prevState) => ({
                    ...prevState,
                    access_Key: "",
                    access_token: "",
                    consumer_key: "",
                    consumer_secret: "",
                    access_secret: "",
                  }));
                  seteditPlatformId("");
                }}
              />
            </div>
            <div className="modal-body border-0">
              <h5 className="font-18 font-bold popup-header-color">
                Connect Twitter
              </h5>
              <p className="font-12 font-regular popup-data-color">
                Integrate Twitter for sharing AI-generated content. Paste your
                Twitter handle.
              </p>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="instAccessKey"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Access Key
                </label>
                <input
                  type="text"
                  placeholder="Paste your access key"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="instAccessKey"
                  onChange={(e) => {
                    setTwitterCredentials((prevState) => ({
                      ...prevState,
                      access_Key: e.target.value,
                    }));
                  }}
                  value={TwitterCredentials.access_Key}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="instAccessSecret"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Access Secret{" "}
                </label>
                <input
                  type="text"
                  placeholder="Paste your access secret"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="instAccessSecret"
                  onChange={(e) => {
                    setTwitterCredentials((prevState) => ({
                      ...prevState,
                      access_secret: e.target.value,
                    }));
                  }}
                  value={TwitterCredentials.access_secret}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="ConsumerKey"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Consumer Key{" "}
                </label>
                <input
                  type="text"
                  placeholder="Paste your consumer key"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="ConsumerKey"
                  onChange={(e) => {
                    setTwitterCredentials((prevState) => ({
                      ...prevState,
                      consumer_key: e.target.value,
                    }));
                  }}
                  value={TwitterCredentials.consumer_key}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="consumersecret"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Consumer Secret{" "}
                </label>
                <input
                  type="text"
                  placeholder="Paste your consumer secret"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="consumersecret"
                  onChange={(e) => {
                    setTwitterCredentials((prevState) => ({
                      ...prevState,
                      consumer_secret: e.target.value,
                    }));
                  }}
                  value={TwitterCredentials.consumer_secret}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="twAccesstoken"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Access token
                </label>
                <input
                  type="text"
                  placeholder="Paste your access token"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="twAccesstoken"
                  onChange={(e) => {
                    setTwitterCredentials((prevState) => ({
                      ...prevState,
                      access_token: e.target.value,
                    }));
                  }}
                  value={TwitterCredentials.access_token}
                />
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setTwitterCredentials((prevState) => ({
                    ...prevState,
                    access_Key: "",
                    access_token: "",
                    consumer_key: "",
                    consumer_secret: "",
                    access_secret: "",
                  }));
                  seteditPlatformId("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                data-bs-dismiss="modal"
                onClick={() => {
                  if (editPlatformId == "") {
                    addPlatform("Twitter", platformAccountId);
                  } else {
                    editPlatform("Twitter", editPlatformId);
                  }
                }}
                disabled={
                  TwitterCredentials.access_Key == "" ||
                  TwitterCredentials.access_secret == "" ||
                  TwitterCredentials.consumer_key == "" ||
                  TwitterCredentials.access_token == "" ||
                  TwitterCredentials.consumer_secret == "" ||
                  (TwitterCredentials.access_Key == "" &&
                    TwitterCredentials.access_secret == "" &&
                    TwitterCredentials.consumer_key == "" &&
                    TwitterCredentials.access_token == "" &&
                    TwitterCredentials.consumer_secret == "")
                }
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Connect Twitter popup ends here */}
      {/* Connect Facebook  popup starts here */}
      <div
        className="modal fade"
        id="connectFacebook"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="connectFacebookLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <span className="modal-title" id="connectFacebookLabel">
                <img
                  src="images/popup-facebook-icon.svg"
                  alt="popup-facebook-icon"
                  className="popup-logo"
                />
              </span>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setFaceBookCredentials((prevState) => ({
                    ...prevState,
                    access_token: "",
                    facebook_page_id: "",
                  }));
                  seteditPlatformId("");
                }}
              />
            </div>
            <div className="modal-body border-0">
              <h5 className="font-18 font-bold popup-header-color">
                Connect Facebook
              </h5>
              <p className="font-12 font-regular popup-data-color">
                Connect Facebook to share AI-generated content. Paste your
                Facebook handle.
              </p>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="FacebooKPageID"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Facebook Page ID
                </label>
                <input
                  type="text"
                  placeholder="Paste your Facebook Page ID"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="FacebooKPageID"
                  onChange={(e) => {
                    setFaceBookCredentials((prevState) => ({
                      ...prevState,
                      facebook_page_id: e.target.value,
                    }));
                  }}
                  value={FaceBookCredentials.facebook_page_id}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="faceAccessToken"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Access Token
                </label>
                <input
                  type="text"
                  placeholder="Paste your access token"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="faceAccessToken"
                  onChange={(e) => {
                    setFaceBookCredentials((prevState) => ({
                      ...prevState,
                      access_token: e.target.value,
                    }));
                  }}
                  value={FaceBookCredentials.access_token}
                />
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setFaceBookCredentials((prevState) => ({
                    ...prevState,
                    access_token: "",
                    facebook_page_id: "",
                  }));
                  seteditPlatformId("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                data-bs-dismiss="modal"
                onClick={() => {
                  if (editPlatformId == "") {
                    addPlatform("FaceBook", platformAccountId);
                  } else {
                    editPlatform("FaceBook", editPlatformId);
                  }
                }}
                disabled={
                  (FaceBookCredentials.access_token == "" &&
                    FaceBookCredentials.facebook_page_id == "") ||
                  FaceBookCredentials.access_token == "" ||
                  FaceBookCredentials.facebook_page_id == ""
                }
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Connect Facebook popup ends here */}
      {/* Connect Teams popup starts here */}
      <div
        className="modal fade"
        id="connectTeams"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="connectTeamsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <span className="modal-title" id="connectTeamsLabel">
                <img
                  src="images/popup-teams-icon.svg"
                  alt="popup-facebook-icon"
                  className="popup-logo"
                />
              </span>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setTeamsCredentials((prevState) => ({
                    ...prevState,
                    client_secret: "",
                    tenant_id: "",
                    client_id: "",
                    user_name: "",
                    password: "",
                  }));
                  seteditPlatformId("");
                }}
              />
            </div>
            <div className="modal-body border-0">
              <h5 className="font-18 font-bold popup-header-color">
                Connect Teams
              </h5>
              <p className="font-12 font-regular popup-data-color">
                Connect Teams to generate your meeting link.
              </p>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="teamsTenantID"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Tenant ID
                </label>
                <input
                  type="text"
                  placeholder="Enter tenant ID"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="teamsTenantID"
                  onChange={(e) => {
                    setTeamsCredentials((prevState) => ({
                      ...prevState,
                      tenant_id: e.target.value,
                    }));
                  }}
                  value={TeamsCredentials.tenant_id}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="teamsClientid"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Client ID
                </label>
                <input
                  type="text"
                  placeholder="Enter your client ID"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="teamsClientid"
                  onChange={(e) => {
                    setTeamsCredentials((prevState) => ({
                      ...prevState,
                      client_id: e.target.value,
                    }));
                  }}
                  value={TeamsCredentials.client_id}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="teamsClientSecret"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Client Secret
                </label>
                <input
                  type="text"
                  placeholder="Enter your client secret"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="teamsClientSecret"
                  onChange={(e) => {
                    setTeamsCredentials((prevState) => ({
                      ...prevState,
                      client_secret: e.target.value,
                    }));
                  }}
                  value={TeamsCredentials.client_secret}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="teamsClientSecret"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  User Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your client secret"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="teamsClientSecret"
                  onChange={(e) => {
                    setTeamsCredentials((prevState) => ({
                      ...prevState,
                      user_name: e.target.value,
                    }));
                  }}
                  value={TeamsCredentials.user_name}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="teamsClientSecret"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Password
                </label>
                <input
                  type="text"
                  placeholder="Enter your client secret"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="teamsClientSecret"
                  onChange={(e) => {
                    setTeamsCredentials((prevState) => ({
                      ...prevState,
                      password: e.target.value,
                    }));
                  }}
                  value={TeamsCredentials.password}
                />
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setTeamsCredentials((prevState) => ({
                    ...prevState,
                    client_secret: "",
                    tenant_id: "",
                    client_id: "",
                    user_name: "",
                    password: "",
                  }));
                  seteditPlatformId("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                data-bs-dismiss="modal"
                onClick={() => {
                  if (editPlatformId == "") {
                    addPlatform("Teams", platformAccountId);
                  } else {
                    editPlatform("Teams", editPlatformId);
                  }
                }}
                disabled={
                  TeamsCredentials.client_id == "" ||
                  TeamsCredentials.client_secret == "" ||
                  TeamsCredentials.tenant_id == "" ||
                  TeamsCredentials.user_name == "" ||
                  TeamsCredentials.password == "" ||
                  (TeamsCredentials.client_id == "" &&
                    TeamsCredentials.client_secret == "" &&
                    TeamsCredentials.tenant_id == "" &&
                    TeamsCredentials.user_name == "" &&
                    TeamsCredentials.password == "")
                }
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Connect Teams popup ends here */}
      {/* Connect Google Meet popup starts here */}
      <div
        className="modal fade"
        id="connectGoogleMeet"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="connectGoogleMeetlabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <span className="modal-title" id="connectGoogleMeetlabel">
                <img
                  src="images/popup-googlemeet-icon.svg"
                  alt="popup-googlemeet-icon"
                  className="popup-logo"
                />
              </span>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setGoogleMeetCredentials((prevState) => ({
                    ...prevState,
                    email_id: "",
                    credential_file_url: "",
                  }));
                  setgoogleMeetFile("")
                  seteditPlatformId("");
                }}
              />
            </div>
            <div className="modal-body border-0">
              <h5 className="font-18 font-bold popup-header-color">
                Connect Google Meet
              </h5>
              <p className="font-12 font-regular popup-data-color">
                Connect Google Meet to generate your meeting link.
              </p>
             { googleMeetFile == "" ?(<div className="col-md-12 mb-3">
                <label
                  htmlFor="teamsTenantID"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Upload JSON file
                </label>
                <label
                  className="position-relative d-block content-upload p-4 text-center mb-4"
                  htmlFor="uploadImg2"
                >
                  <img
                    src="images/upload-icon.svg"
                    alt="upload"
                    className="me-2"
                  />
                  <span className="font-12 font-medium mt-2 d-block mb-1">
                    upload files from your computer
                  </span>
                  <span className="font-10 font-regular d-block color-grey mb-1">
                    Or drag and drop here
                  </span>
                  <input
                    type="file"
                    accept=".json"
                    className="cust-file py-5"
                    id="uploadImg2"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                  />
                </label>
              </div>):(<div className="col-md-12 mb-3">
                <label
                  htmlFor="teamsTenantID"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Uploaded JSON file
                </label>
                <label
                  className="position-relative d-block content-upload p-4 text-center mb-4"
                  htmlFor="uploadImg2"
                >
                  <span className="font-12 font-medium mt-2 d-block mb-1">
                    {googleMeetFile} <img
                    src="images/upload-icon.svg"
                    alt="upload"
                    className="me-2"
                  />
                  </span>

                  {/* <input
                    type="file"
                    className="cust-file py-5"
                    id="uploadImg2"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                  /> */}
                </label>
              </div>)}
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="gmEmailid"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Email ID
                </label>
                <input
                  type="text"
                  placeholder="Enter your email ID"
                  className="form-control custom-form py-2   font-14 font-regular"
                  id="gmEmailid"
                  onChange={handleEmailChange}
                  value={GoogleMeetCredentials.email_id}
                />
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  setGoogleMeetCredentials((prevState) => ({
                    ...prevState,
                    email_id: "",
                    credential_file_url: "",
                  }));
                  setgoogleMeetFile("")
                  seteditPlatformId("");

                }}
                
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                data-bs-dismiss="modal"
                onClick={() => {
                  if (editPlatformId == "") {
                    addPlatform("GoogleMeet", platformAccountId);
                  } else {
                    editPlatform("GoogleMeet", editPlatformId);
                  }
                }}
                disabled={
                  GoogleMeetCredentials.email_id == "" ||
                  GoogleMeetCredentials.credential_file_url == "" ||
                  (GoogleMeetCredentials.email_id == "" &&
                    GoogleMeetCredentials.credential_file_url == "") ||
                  !validEmail
                }
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {/**All accounts platform popups starts here */}
    </>
  );
}
