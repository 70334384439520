import { useLocation } from "react-router-dom";
import { getContentByIdApi, approveStatusApi, environment } from "../../services/api";
import React, { useState, useEffect, useContext } from "react";
import { useCommonContext } from "../../contexts/CommonContext";
import SocialMediaPost from "../ApproveContentHighlights/ApproveContentHighlights";
import Highlights from "../ApproveContentHighlights/ApproveContentHighlights";
import Header from "./Header";
import Loader from "../Loader/Loader";

// import { useNavigate, useLocation } from "react-router-dom";

export default function ApproveContent() {
  const { userData, openToast } = useCommonContext();
  const { isNavCollapsed, setIsNavCollapsed } = useCommonContext();
  const [Details, setDetails] = useState([]);
  const [requestedContentHtml, setRequestedContentHtml] = useState("");
  const location = useLocation();
  console.log(location);
  const contentId = location.state.contentId;
  const contentName = location.state.contentName;
  const [Status, setStatus] = useState([]);
  //   const [requestDetails, setRequestDetails] = useState([]);
  const [toastMessage, setToastMessage] = useState([]);
  const [toastStatus, setToastStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [reviewCommands, setReviewCommands] = useState("");
  const [configjson, setconfigJson] = useState("");

  const approvalStatus = environment == "prod" ? "AD33C371-4E46-4919-BF21-317760D88E5F" : "0C2F041A-7267-46E9-B7CE-3EDB6EE7FC34"

  useEffect(() => {
    getContentById();
  }, []);

  console.info(location.state.socialMediaId, "mmmmmmmmmmmmmmmmmmmmmmmm");

  async function getContentById() {
    const data = {
      userId: userData[0]["User_Id"],
      contentId: contentId,
      isApprove: "yes"
    };
    setIsLoading(true)
    const responseData = await getContentByIdApi(data);
    setIsLoading(false)

    setRequestedContentHtml(
      responseData.data.data.data[0]["Generated_Content"]
    );
    setconfigJson(responseData.data.data.data[0]?.Configuration_Json);

    // console.log(responseData.data.data[0]["Generated_Content"], "This is the content id");
  }
  console.log(requestedContentHtml, "-----------------------");
  console.log(configjson, "9090990900909");

  const approveStatus = async (approveStatus, status) => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const data = {
        user_id: userId,
        content_id: contentId,
        content_status: status ? "Approved" : "Rejected",
        content_status_id: approveStatus,
        review_commands: reviewCommands,
      };
      const responseData = await approveStatusApi(data);
      if (responseData.status === 200 || responseData.status === 201) {
        setReviewCommands("");
        openToast(
          status ? "Content Approved" : "Content Rejected",
          "images/toast-check.svg"
        );
        setIsLoading(false);
      }
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  return (

    <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
      {isLoading && <Loader />}
      <div className="row">
        {!isLoading && <Header contentName={contentName} />}

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 content-generated">
              <div
                className="generated-container mt-4"
                dangerouslySetInnerHTML={{
                  __html: requestedContentHtml,
                }}
              ></div>
            </div>
          </div>
        </div>
        <footer
          className="head-bar font-20 font-bold p-3 position-sticky bottom-0 bg-white mb-0 d-flex align-items-center justify-content-end"
          style={{ zIndex: 999 }}
        >
          {!isLoading && (
            <button
              onClick={() => {
                setStatus(false);
                approveStatus("792911C6-8B2D-424A-A64F-BC777253E5F8", false);
              }}
              className="btn btn-danger cust-reject me-3 d-flex align-items-center gap-2 px-3"
              hidden={!isLoading}
            >
              <img src="images/cross-white.svg" alt="cross-white" />
              Reject
            </button>
          )}
          {!isLoading && (
            <button className="p-0 border-0 comment-btn position-fixed">
              <img src="images/comment-btn-icon.svg" alt="comment-btn-icon" />
              <span className="comment-tooltip">
                <span className="d-block font-14 font-bold text-nowrap mb-3 text-start">
                  Add Your Comments
                </span>
                <span className="d-block font-12 font-bold text-nowrap color-grey text-start">
                  Comment
                </span>
                <textarea
                  onChange={(e) => {
                    setReviewCommands(e.target.value);
                  }}
                  value={reviewCommands}
                  className="form-control custom-form cust-tarea  font-12 font-regular"
                  rows={5}
                  style={{ width: "260px" }}
                  defaultValue={""}
                />
              </span>
            </button>
          )}

          {location.state.socialMediaId ? (
            <button
              className="p-0 border-0 highlights-btn position-fixed"
              data-bs-toggle="offcanvas"
              data-bs-target="#promotionalContent"
              aria-controls="offcanvasRight"
            >
              <img
                src="images/highlights-icon.svg"
                alt="highlights-icon"
                data-bs-toggle="tooltip"
                title="Generate highlights"
                data-bs-placement="left"
                className="font-medium"
              />
              {/* <img src="images/highlights-icon.svg" alt="highlights-icon">
    <span class="highlights-tooltip">
        <img src="images/social-media-img.svg">
        <span
            class="font-medium text-white mt-2 mb-1 font-14 d-block color-grey text-start">Try
            the Highlights feature</span>
        <span
            class="font-regular font-12 d-block color-grey text-start">Generate
            Facebook post, LinkedIn post, Twitter Post and more.</span>
    </span> */}
            </button>
          ) : null}
          {!isLoading && (
            <button
              onClick={() => {
                setStatus(true);
                approveStatus(approvalStatus, true);
              }}
              className="btn btn-success cust-approve d-flex align-items-center gap-2 px-3"
            >
              <img src="images/white-check.svg" alt="white-check" />
              Approve
            </button>
          )}

        </footer>
      </div>
      <Highlights config={configjson} />
    </div>
  );
}