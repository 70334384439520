import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { savePricingApi, updatePricingApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";

export default function CreatePricing() {
    const { setPricingId, setPricingName, setPricingCost, setPricingUnit, pricingName, pricingCost, pricingUnit, pricingId, getAllpricing, productId } = useCreateProductContext();
    const { userData } = useCommonContext()
    const savePricing = async () => {
        try {
            // console.log("savePricing is called");
            const payload = {
                userId: userData[0]["User_Id"],
                pricingName: pricingName,
                pricingCost: pricingCost,
                pricingUnit: pricingUnit,
                productId: productId
            };
            const responseData = await savePricingApi(payload);
            // console.log(responseData.data, "saved")
            if (responseData.data.success === true)
                getAllpricing()
            setPricingId('')
            setPricingName('');
            setPricingCost('');
            setPricingUnit('');

        } catch (error) {
            console.error("Error saving pricing:", error);
            // insertErrorApi(error);
        }
    }

    const updatePricing = async () => {
        try {
            // console.log("updatePricing is called", pricingId);
            const payload = {
                userId: userData[0]["User_Id"],
                pricingName: pricingName,
                pricingId: pricingId,
                pricingCost: pricingCost,
                pricingUnit: pricingUnit,
                productId: productId
            };
            const responseData = await updatePricingApi(payload);
            // console.log(responseData.data, "updated")
            if (responseData.data.success === true)
            getAllpricing()
            setPricingId('')
            setPricingName('');
            setPricingCost('');
            setPricingUnit('');
        } catch (error) {
            console.error("Error saving feature:", error);
            // insertErrorApi(error);
        }
    }

    return (
        <>
            <h5 className="font-bold font-18 grey-v3 mt-4 mb-3">Specify Pricing Information</h5>
            <div className="col-md-6 mb-3">
                <label htmlFor="YourPlanName" className="form-label mb-0 cust-label font-14 font-medium mb-2">Your Plan Name</label>
                <input type="text" placeholder="Enter your plan name" className="py-2 form-control custom-form  font-14 font-regular" id="YourPlanName"
                onChange={(e) => setPricingName(e.target.value.replace(/[^a-zA-Z0-9\s.,'-]/g, ''))} value={pricingName}  />
            </div>
            <div className="col-md-6 mb-3">
                <div className="row g-5">
                    <div className="col-md-6">
                        <label htmlFor="Cost" className="form-label mb-0 cust-label font-14 font-medium mb-2">Cost</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text custom-form font-14 font-medium cust-label gray-v2-bg">$</span>
                            <input type="number" placeholder={0} className=" py-2 form-control custom-form  font-14 font-regular text-end" id="Cost" 
                            onChange={(e) => setPricingCost(e.target.value.replace(/[^a-zA-Z0-9\s.,'-]/g, ''))} value={pricingCost} />
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <label htmlFor="Units" className="form-label mb-0 cust-label font-14 font-medium mb-2">Units </label>
                        <select className="py-2 form-select custom-form  font-14 font-regular" id="Units" 
                        onChange={(e) => setPricingUnit(e.target.value.replace(/[^a-zA-Z0-9\s.,'-]/g, ''))} value={pricingUnit} >
                            <option value={""}>Day/Month/Year</option>
                            <option value={"Day"}>Day</option>
                            <option value={"Month"}>Month</option>
                            <option value={"Year"}>Year</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <button type="button" className="py-2 btn px-4 py-3  mt-0 violet-btn rounded-3 shadow-none  d-flex align-items-center
                justify-content-center font-medium font-16 text-nowrap" style={{ height: '44px' }} onClick={(pricingId === "") ? savePricing 
                : updatePricing} disabled={
                    pricingName === "" || pricingCost === "" || pricingUnit === ""
                }>
                    Save
                </button>
            </div>
        </>
    )
}