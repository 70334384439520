import React, { useContext, useState } from "react";
import { getAllFeatureApi, getAllPricingApi, getProductbyIdApi, get_all_roadmap_featuresApi } from "../services/api";
import { useLocation } from "react-router-dom";
import { useCommonContext } from "./CommonContext";

const CreateProductContext = React.createContext();

export function CreateProductContextProvider({ children }) {

    const [logoArray, setLogoArray] = useState([]);

    //feature states
    const [featureData, setFeatureData] = useState([])
    const [featureName, setFeatureName] = useState("");
    const [featureDescription, setFeatureDescription] = useState("");
    const [featureId, setFeatureId] = useState("")

    const [azureReviewArray,setAzureReviewArray] = useState([])
    const [azurePopupArray,setAzurePopupArray] = useState({})
    //pricing states
    const [pricingData, setPricingData] = useState([])
    const [pricingName, setPricingName] = useState("");
    const [pricingCost, setPricingCost] = useState("");
    const [pricingUnit, setPricingUnit] = useState("");
    const [pricingId, setPricingId] = useState("")
    //roadmap states
    const [roadmapData, setRoadmapData] = useState([])
    const [Upcomingfeature, setUpcomingFeature] = useState("");
    const [roadmapfeatureDescription, setRoadmapFeatureDescription] = useState("");
    const [roadmapId, setRoadmapId] = useState("")

    const [competitordata, setCompetitordata] = useState([])

    const [selectedCompetitors, setSelectedCompetitors] = useState([]);

    const [name, setName] = useState('');
    const [productlogo, setProductlogo] = useState('');
    const [productlogoname, setProductlogoname] = useState('');
    const [overview, setOverview] = useState('');
    const [keywords, setKeywords] = useState('');
    const [producturl, setProducturl] = useState('');
    const [selected, setSelected] = useState([]);
    const [feautreSelected, setFeatureselected] = useState('');
    const [pricingSelected, setPricingselected] = useState('');
    const [roadmapSelected, setRoadmapselected] = useState('');
    const [Avg, setAvg] = useState('');
    const [Ratings, setRatings] = useState('');
    const [RatingsReviews, setRatingsReviews] = useState('');
    const [stars_5, setstars_5] = useState('');
    const [stars_4, setstars_4] = useState('');
    const [stars_3, setstars_3] = useState('');
    const [stars_2, setstars_2] = useState('');
    const [stars_1, setstars_1] = useState('');


  

    

    const { userData } = useCommonContext()
    
    const location = useLocation();

    console.info(feautreSelected,"dsndsjjjsdj");

    const productId = location?.state?.productId;
    const productName = location?.state?.productName;
    console.log(location,"this is the context location")

    console.log(productId,"this is the product id from the context!!!!")
    console.log(productName,"this is the product name from the context!!!!")
    console.log(Upcomingfeature,"Uppcomingfeature")
    console.log(roadmapfeatureDescription,"roadmapfeatureDescription")
    // const [count, setCount] = useState(4);
    // const [rowCount, setRowCount] = useState();

    // const [competitorname, setCompetitorname] = useState('');
    // const [competitorurl, setCompetitorurl] = useState('');

    const getAllfeatures = async () => {
        console.log("getAllFeatures is called")
        try {
            const payload = {
                'productId': productId,
                'userId': userData[0]["User_Id"]
            }

            const responseData = await getAllFeatureApi(payload)
            console.log(responseData.data)
            setFeatureData(responseData.data.data) //has the accordian feature data
        } catch (error) {
            console.log(error.message)
        }
    }

    const getAllpricing = async () => {
        console.log("getAllpricing is called")
        try {
            const payload = {
                'productId': productId,
                'userId': userData[0]["User_Id"]
            }

            const responseData = await getAllPricingApi(payload)
            console.log(responseData.data)
            setPricingData(responseData.data.data) //has the accordian feature data
        } catch (error) {
            console.log(error.message)
        }
    }

    const getAllRoadmap = async () => {
        console.log("getAllFeatures is called")
        try {
            const payload = {
                'productId': productId,
                'userId': userData[0]["User_Id"]
            }

            const responseData = await get_all_roadmap_featuresApi(payload)
            console.log(responseData.data)
            setRoadmapData(responseData.data.data) //has the accordian feature data
        } catch (error) {
            console.log(error.message)
        }
    }

    const getProductbyId = async (productId) => {
        try {
          const payload = {
            'productId': productId,
            'userId': userData[0]["User_Id"]
          }
    
          const responseData = await getProductbyIdApi(payload)
          console.log(responseData.data.data,"producttttttreeeeeeeeeeeeeeeeeeeeeee")
          console.log(responseData.data.data[0].Product_Name, "producttttt")
          setName(responseData.data.data[0].Product_Name)
          setProductlogo(responseData.data.data[0].Product_Logo)
          setProductlogoname(responseData.data.data[0].Logo_name)
          setOverview(responseData.data.data[0].Product_Overview)
          setSelected(responseData.data.data[0].Product_Keywords)
          setProducturl(responseData.data.data[0].Product_Url)
          setFeatureselected(responseData.data.data[0].Is_Feature_Needed)
          setPricingselected(responseData.data.data[0].Is_Pricing_Needed)
          setRoadmapselected(responseData.data.data[0].Is_Roadmap_Needed)

        } catch (error) {
          console.log(error.message)
        }
      }

      const resetProductForm = () => {
        setName('');
        setProductlogo(null);
        setOverview('');
        setKeywords('');
        setProducturl('');
        setCompetitordata([])
        setSelected([])
        // Reset other state variables...
      };
      
      const handleCheckboxChange = (competitorId, isChecked) => {
        console.log(competitorId, isChecked, "handleCheck is called..........and competitorId, isChecked was checkinggggggggggg ");
        if (isChecked === true) {
            if (selectedCompetitors.length < 4) {
                setSelectedCompetitors([...selectedCompetitors, competitorId]);
            } else {
                console.log("Maximum limit reached. You can only select up to four competitors.");
                return; // Exit early if maximum limit reached
            }
            // setSelectedCompetitors([...selectedCompetitors, competitorId]);
        } else {
            setSelectedCompetitors(selectedCompetitors.filter((id) => id !== competitorId));
        }

        let final = competitordata
        for (let value of final) {
            if (value.Competitor_Id === competitorId) {
                value.Is_Selected = isChecked
            }
        }
        setCompetitordata(final)
        console.log(final,"000000000000000000000000000000000000000000000000000000000000000000000000000000000")
        
    };

        // Filter out competitors with null Competitor_Name
        const filteredCompetitors = competitordata.filter(
            (competitor) => competitor.Competitor_Name && competitor.Competitor_Name.trim() !== ""
          );



    console.log(featureData, "this is the feature data")

    return (
        <CreateProductContext.Provider value={{
            featureData, featureName, featureDescription, featureId,
            pricingData, pricingName, pricingCost, pricingUnit, pricingId,
            roadmapData, Upcomingfeature,roadmapfeatureDescription, roadmapId,
            competitordata,
            selectedCompetitors, 
            productId, productName,
            name, productlogo, productlogoname, overview, keywords, producturl, feautreSelected, pricingSelected, roadmapSelected,
            selected, logoArray, filteredCompetitors, stars_1,stars_2,stars_3,stars_4,stars_5,RatingsReviews,Ratings,Avg,
            azureReviewArray,
            azurePopupArray,
            setAzurePopupArray,
            setAzureReviewArray,
            setFeatureData, getAllfeatures, setFeatureName, setFeatureDescription, setFeatureId,
            setPricingData, getAllpricing, setPricingName, setPricingCost, setPricingUnit, setPricingId,
            setRoadmapData, getAllRoadmap, setUpcomingFeature, setRoadmapFeatureDescription, setRoadmapId,
            setCompetitordata,
            setSelectedCompetitors, handleCheckboxChange, 
            setName, setProductlogo, setProductlogoname, setOverview, setKeywords, setProducturl, getProductbyId, setFeatureselected, setPricingselected, setRoadmapselected, resetProductForm,
            setSelected, setLogoArray, setstars_1,setstars_2,setstars_3,setstars_4,setstars_5,setRatingsReviews,setRatings,setAvg
        }}>
            {children}
        </CreateProductContext.Provider>
    );
}

export function useCreateProductContext() {
    return useContext(CreateProductContext)
}