import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { getAllAccountsApi } from "../../../services/api";
// import ReactTooltip from 'react-tooltip';

import { useCommonContext } from "../../../contexts/CommonContext";
import { useSocialMediaContext } from "../../../contexts/SocialMediaContext";

export const ContentDetailsContext = createContext();

export default function SocialMediaFields() {
  // const [topicOrTheme, setTopicOrTheme] = useState("");

  // const {setContentId} = useSocialMediaContext()
  // const [specifyKeyContent, setSpecifyKeyContent] = useState("");
  // const [purposeOfTheContent, setPurposeOfTheContent] = useState("");
  // const [targetAudience, setTargetAudience] = useState("");
  // const [audienceLevel, setAudienceLevel] = useState("");
  // const [socialMediaAccount, setSocialMediaAccount] = useState("");
  // const [SocialMediaAccountDropDown, setSocialMediaAccountDropDown] = useState(
  //   []
  // );
  const { ContentDetails, setContentDetails } = useSocialMediaContext(false);
  const navigate = useNavigate();

  const {
    topicOrTheme,
    setTopicOrTheme,
    specifyKeyContent,
    setSpecifyKeyContent,
    purposeOfTheContent,
    setPurposeOfTheContent,
    targetAudience,
    setTargetAudience,
    audienceLevel,
    setAudienceLevel,
    socialMediaAccount,
    setSocialMediaAccount,
    SocialMediaAccountDropDown,
    setSocialMediaAccountDropDown,
    platforms,
    setPlatforms,
    toneOfTheContent,
    setToneOfTheContent,
    styleOfTheContent,
    setStyleOfTheContent,
    hashtags,
    setHashtags,
    generateSocialMedia,
    contentId,
    Prompt,
    isLoading,
    setIsLoading
  } = useSocialMediaContext();
  // const { setContentDetails } = useSocialMediaContext();

  // const [platforms, setPlatforms] = useState({
  //   fbPost: false,
  //   linkedinPost: false,
  //   twitterPost: false,
  //   instaCap: false,
  // });
  // const [toneOfTheContent, setToneOfTheContent] = useState("");
  // const [styleOfTheContent, setStyleOfTheContent] = useState("");
  // const [hashtags, setHashtags] = useState("");
  const { userData } = useCommonContext();

  const handleSpecifyKeyContentChange = (e) => {
    setSpecifyKeyContent(e.target.value);
  };

  const handleTargetAudienceChange = (e) => {
    setTargetAudience(e.target.value);
  };

  const handleSocialMediaAccountChange = (e) => {
    setSocialMediaAccount(e.target.value);
  };

  const handlePlatformChange = (e) => {
    const platform = e.target.id;

    setPlatforms((prevPlatforms) => ({
      ...prevPlatforms,
      [platform]: !prevPlatforms[platform],
    }));
  };

  const handleToneOfTheContentChange = (e) => {
    setToneOfTheContent(e.target.value);
  };

  const handleStyleOfTheContentChange = (e) => {
    setStyleOfTheContent(e.target.value);
  };

  const handleHashtagsChange = (e) => {
    setHashtags(e.target.value);
  };

  const getDropDownData = async (e) => {
    try {
      console.table(userData[0], "This is the user");
      if (userData.length != 0) {
        console.log(getDropDownData, "Entered into the get drop down function");
        const data1 = {
          userId: userData[0]?.["User_Id"],
          reviewerId:
            userData[0]?.["Role_Name"] === "Content Manager"
              ? userData[0]?.["User_Id"]
              : userData[0]?.["Reviewer_Id"],
        };
        const response1 = await getAllAccountsApi(data1);
        console.log(response1, " account details");
        const result = response1.data.data;
        console.log(result, " account details");

        setSocialMediaAccountDropDown(result);
      }

      // console.log(RsvpDropDown," rsvp drop downnnnnnnn")

      //   const data2 = {
      //     userId: userData[0]?.["User_Id"],
      //     tableName: "Event_Department",
      //   };
      //   const response2 = await getDropDownDetailsApi(data2);
      //   console.log(response2, "rvsp drop down data ");
      //   seteventType(response2.data.data.data);
    } catch (error) {
      console.log(error);
    }

    console.log(
      SocialMediaAccountDropDown,
      "drop downnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn"
    );
  };

  useEffect(() => {
    getDropDownData();
  }, [userData]);

  console.log(ContentDetails, "THis is the content details form social media");

  return (
    <div className="col-md-3 mt-4">
      <div className="px-3">
        {/* Topic & Key Points starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#topicKeyPoints"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Key Message
          </p>
          <div className="collapse show" id="topicKeyPoints">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="topicOrtheme"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Message <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title="A text box allows users to input hashtags relevant to their content.
             Users should be able to enter detailed information regarding the hashtags they wish to include.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Describe some key content highlights"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="specifyKeyContent"
                  value={specifyKeyContent}
                  onChange={handleSpecifyKeyContentChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Topic & Key Points ends */}
        {/* Target Audience starts */}
        <div className="border-bottom mb-4 cust-border">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#target"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Target Audience
          </p>
          <div className="collapse show" id="target">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="targetAudience"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Target audience of the content{" "}
                  <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title="A dropdown menu should allow users to specify the target audience for their social media posts considering demographics, interests, and behaviors.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="targetAudience"
                  value={targetAudience}
                  onChange={handleTargetAudienceChange}
                >
                  <option value="" disabled>
                    Select an audience
                  </option>
                  <option value="youngAdults">Young Adults (18-34)</option>
                  <option value="parentsCaregivers">
                    Parents and Caregivers
                  </option>
                  <option value="professionals">
                    Professionals and Career-oriented Individuals
                  </option>
                  <option value="techEnthusiasts">
                    Technology Enthusiasts
                  </option>
                  <option value="lifestyleWellness">
                    Lifestyle and Wellness Seekers
                  </option>
                  <option value="foodBeverage">
                    Food and Beverage Enthusiasts
                  </option>
                  <option value="travelAdventure">
                    Travel and Adventure Seekers
                  </option>
                  <option value="fashionBeauty">
                    Fashion and Beauty Enthusiasts
                  </option>
                </select>
              </div>
              {/* <div className="col-md-12 mb-3">
                <label
                  htmlFor="audienceLevel"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Audience level of expertise{" "}
                  <span data-bs-toggle="tooltip" title="Lorem ipsumis a dummy">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="audienceLevel"
                  value={audienceLevel}
                  onChange={handleAudienceLevelChange}
                >
                  {" "}
                  <option value="" disabled>
                    Select an audience level
                  </option>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Expert">Expert</option>
                </select>
              </div> */}
            </div>
          </div>
        </div>
        {/* Target Audience starts */}
        {/* Acccount and platform starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#accountPlatform"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Platform & Account
          </p>
          <div className="collapse show" id="accountPlatform">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="socialMediaAccount"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Social media account <span className="mandatory-red">*</span>{" "}
                  <span data-bs-toggle="tooltip" title="Displays a list of accounts integrated on the integration page.Users can select the desired social media account from this dropdown">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="socialMediaAccount"
                  value={socialMediaAccount}
                  onChange={handleSocialMediaAccountChange}
                >
                  <option>Select</option>

                  {SocialMediaAccountDropDown.map((account) => (
                    <option value={account.Account_Id}>
                      {account.Account_Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="platform"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Platforms
                  <span data-bs-toggle="tooltip" title="Lorem ipsumis a dummy">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                {SocialMediaAccountDropDown.filter(
                  (value) => value.Account_Id === socialMediaAccount
                ).map((filteredAccount) => {
                  return (
                    <div>
                      <div
                        className="form-check"
                        hidden={filteredAccount?.Facebook_Credentials === 0}
                      >
                        <input
                          className="form-check-input cust-check box-shadow-none"
                          type="checkbox"
                          id="fbPost"
                          checked={platforms.fbPost}
                          onChange={handlePlatformChange}
                        />
                        <label
                          className="form-check-label font-14 font-medium check-label"
                          htmlFor="fbPost"
                        >
                          Facebook post
                        </label>
                      </div>
                      <div
                        className="form-check"
                        hidden={filteredAccount?.Linkedin_Credentials === 0}
                      >
                        <input
                          className="form-check-input cust-check box-shadow-none"
                          type="checkbox"
                          id="linkedinPost"
                          checked={platforms.linkedinPost}
                          onChange={handlePlatformChange}
                        />
                        <label
                          className="form-check-label font-14 font-medium check-label"
                          htmlFor="LinkedinPost"
                        >
                          Linkedin post
                        </label>
                      </div>
                      <div
                        className="form-check"
                        hidden={filteredAccount?.Twitter_Credentials === 0}
                      >
                        <input
                          className="form-check-input cust-check box-shadow-none"
                          type="checkbox"
                          id="twitterPost"
                          checked={platforms.twitterPost}
                          onChange={handlePlatformChange}
                        />
                        <label
                          className="form-check-label font-14 font-medium check-label"
                          htmlFor="twitterPost"
                        >
                          Twitter post
                        </label>
                      </div>
                      <div
                        className="form-check"
                        hidden={filteredAccount?.Instagram_Credentials === 0}
                      >
                        <input
                          className="form-check-input cust-check box-shadow-none"
                          type="checkbox"
                          id="instaCap"
                          checked={platforms.instaCap}
                          onChange={handlePlatformChange}
                        />
                        <label
                          className="form-check-label font-14 font-medium check-label"
                          htmlFor="instaCap"
                        >
                          Instagram caption
                        </label>
                      </div>
                    </div>
                  );
                })}{" "}
              </div>
            </div>
          </div>
        </div>
        {/* Account and platform ends starts */}
        {/* Tone and style starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#toneStyle"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Tone & Style
          </p>
          <div className="collapse show" id="toneStyle">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="toneContent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Tone of the content <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title="A dropdown menu should provide options for selecting the appropriate tone for the social media post.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="toneContent"
                  value={toneOfTheContent}
                  onChange={handleToneOfTheContentChange}
                >
                  {" "}
                  <option data-tip="Select">Select Tone Of Content</option>
                  <option data-tip="Formal">Formal</option>
                  <option data-tip="Conversational">Conversational</option>
                  <option data-tip="Casual">Casual</option>
                  <option data-tip="Professional">Professional</option>
                  <option data-tip="Humorous">Humorous</option>
                  <option data-tip="Inspirational">Inspirational</option>
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="styleContent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Style of the content <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title="A drop down menu should allow users to specify the stylistic approach or narrative technique employed in the social media post.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="styleContent"
                  value={styleOfTheContent}
                  onChange={handleStyleOfTheContentChange}
                >
                  {" "}
                  <option data-tip="Select">Select</option>
                  <option>Storytelling</option>
                  <option>Visual</option>
                  <option> News/Update</option>
                  <option> Promotional</option>
                  <option> Educational</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Tone and style starts */}
        {/* Topic & Key Points starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#hastag"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Hashtags
          </p>
          <div className="collapse show" id="hastag">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="topicOrtheme"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Hashtags <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title="A text box allows users to input the key message they want to convey through their content.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Describe some key content highlights"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="specifyKeyContent"
                  value={hashtags}
                  onChange={handleHashtagsChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Topic & Key Points ends */}
        {/*Integration & Support ends */}
        {/* form action starts here */}
        <div className="d-flex justify-content-end py-3 form-foot">
          <button
            className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
            onClick={() => {
              navigate("/yoursocialmediaposts", {
                state: {
                  contentTypeId: "1436759C-49D5-4369-A0BA-FE444D4E80C7",
                  contentType: "Social Media Post",
                },
              });
            }}
          >
            Cancel
          </button>
          {!contentId ? (
            <button
              className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
              onClick={() => {
                generateSocialMedia();
                setIsLoading(true)
              }}
              disabled={
                hashtags.length === 0 ||
                styleOfTheContent.length === 0 ||
                toneOfTheContent.length === 0 ||
                platforms.length === 0 ||
                socialMediaAccount.length === 0 ||
                targetAudience.length === 0 ||
                specifyKeyContent.length === 0
              }
            >
              <img
                src="images/generate-icon.svg"
                alt="generate-icon"
                className="me-2"
              />
              Generate
            </button>
          ) : (
            <button
              className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
              onClick={() => {
                generateSocialMedia();
              }}
            >
              <img
                src="images/generate-icon.svg"
                alt="generate-icon"
                className="me-2"
              />
              Regenerate
            </button>
          )}
        </div>
        {/* form action ends here */}
      </div>
      {/* <ReactTooltip place="right" effect="solid" /> */}
    </div>
  );
}