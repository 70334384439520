import React, { useContext, useState, useRef, useEffect } from "react";
import { json, useLocation } from "react-router-dom";
import {
  getAllAccountsApi,
  getContentByIdApi,
  saveContentApi,
} from "../services/api";
import { useCommonContext } from "./CommonContext";
const CreateContentContext = React.createContext();
export function CreateContentContextProvider({ children }) {
  const { userData } = useCommonContext();
  const location = useLocation();
  const [generatedContent, setGeneratedContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [selectedText, setSelectedText] = useState("");
  const [reviewCommands, setReviewCommands] = useState("");
  const [isAskAiOpened, setIsAskAiOpened] = useState(true);
  const [isAskAiPrompt, setIsAskAiPrompt] = useState(true);
  const [isAskresult, setIsAskResult] = useState(true);
  const [isTextSelected, setIsTextSelected] = useState(false);
  const [askAiPromptText, setAskAiPromptText] = useState("");
  const [askAiResponseText, setAskAiResponsetext] = useState("");
  const [isAskAiResponseReturned, setIsAskAiResponseReturned] = useState(false);
  const [imageUri, setImageUri] = useState("");
  const [oldImageUrl, setOldImageUrl] = useState("");
  const [imageData, setImageData] = useState("");
  const [counts, setCounts] = useState(1);
  const parentRef = useRef(null);
  const [account, setAccount] = useState([]);
  const [generateHighlight, setGenerateHighlight] = useState([]);
  const contentType = location.state?.contentType;
  const pdfRef = useRef();
  const [socialMediaId, setSocialMediaId] = useState("");
  const contentTypeId = location.state.contentTypeId;
  const [state, setState] = useState([{ id: 1, name: "" }]);

  console.info(contentTypeId, "Content type id this is the content type");

  // const contentType = location?.state?.contentType;
  const [formData, setFormData] = useState({
    topic_And_Key_Points: {
      topic_or_theme: "", // JSON key: "topic_or_theme"
      specify_Key_Content: "", // JSON key: "specify_Key_Content"
    },
    Purpose: {
      purpose_of_The_Content: "", // JSON key: "purpose_of_The_Content"
    },
    Target_Audience: {
      target_audience: "", // JSON key: "target_audience"
      audience_level: "", // JSON key: "audience_level"
    },
    Tone_And_Style: {
      tone_of_The_Content: "", // JSON key: "tone_of_The_Content"
      style_of_The_Content: "", // JSON key: "style_of_The_Content"
    },
    Account_And_Platform: {
      socialMediaAccount: "Select", // JSON key: "socialMediaAccount"
      fbPost: false, // JSON key: "fbPost"
      linkedinPost: false, // JSON key: "linkedinPost"
      twitterPost: false, // JSON key: "twitterPost"
      instaCap: false, // JSON key: "instaCap"
    },
    Length_And_Structure: {
      length_and_Structure_Label: "", // JSON key: "length_and_Structure_Label"
      preferred_Content_Structure: "", // JSON key: "preferred_Content_Structure"
    },
    Keyword_SEO: {
      specify_SEO: "", // JSON key: "specify_SEO"
      specify_Format: "", // JSON key: "specify_Format"
    },
    References_Citations: {
      references: [], // JSON key: "references"
      form_Upload: [], // JSON key: "form_Upload"
    },
  });

  const [caseStudyformData, setCaseStudyFormData] = useState({
    Working_Title: {
      workingTitle: "", // JSON key: "workingTitle"
    },
    Introduction: {
      introduction: "", // JSON key: "introduction"
    },
    Challenge_Identification: {
      challengeIdentification: "", // JSON key: "challengeIdentification"
    },
    Solution_Overview: {
      solutionOverview: "", // JSON key: "solutionOverview"
    },
    Result_Outcomes: {
      resultOutcomes: "", // JSON key: "resultOutcomes"
    },
    Target_Audience: {
      targetAudience: "", // JSON key: "targetAudience"
      audienceLevel: "", // JSON key: "audienceLevel"
    },
    Tone_And_Style: {
      toneContent: "", // JSON key: "toneContent"
      styleContent: "", // JSON key: "styleContent"
    },
    Account_And_Platform: {
      socialMediaAccount: "Select", // JSON key: "socialMediaAccount"
      fbPost: false, // JSON key: "fbPost"
      linkedinPost: false, // JSON key: "linkedinPost"
      twitterPost: false, // JSON key: "twitterPost"
      instaCap: false, // JSON key: "instaCap"
    },
    Implementation_Process: {
      implementationProcess: "", // JSON key: "implementationProcess"
    },
    Impact_On_Business: {
      impactOnBusiness: "", // JSON key: "impactOnBusiness"
    },
    Future_Considerations: {
      futureConsiderations: "", // JSON key: "futureConsiderations"
    },
    References_Citations: {
      references: [], // JSON key: "references"
      uploadFile: [], // JSON key: "uploadFile"
    },
  });

  const [pressReleaseformData, setPressReleaseFormData] = useState({
    primaryObjective: {
      objective: "",
    },
    keyQuotes: {
      quotes: "",
    },
    eventLaunchDetails: {
      eventLaunchInfo: "",
    },
    targetAudience: {
      audience: "",
    },
    boilerplate: {
      boilerplateInfo: "",
    },

    Account_And_Platform: {
      socialMediaAccount: "Select", // JSON key: "socialMediaAccount"
      fbPost: false, // JSON key: "fbPost"
      linkedinPost: false, // JSON key: "linkedinPost"
      twitterPost: false, // JSON key: "twitterPost"
      instaCap: false, // JSON key: "instaCap"
    },

    contactDetails: {
      name: "",
      position: "",
      companyName: "",
      email: "",
    },
    embargoDetails: {
      embargoInfo: "",
    },
    distributionChannel: {
      channelInfo: "",
    },
    newsworthiness: {
      newsValue: "",
    },
  });

  const [businessUnitName, setBusinessUnitName] = useState("");
  const [purposeDescription, setPurposeDescription] = useState("");
  const [usp, setUsp] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [serviceAndOfferings, setServiceAndOfferings] = useState("");
  const [toneOfContent, setToneOfContent] = useState("");
  const [styleOfContent, setStyleOfContent] = useState("");
  const [keyAchievements, setKeyAchievements] = useState("");
  const [preferredArticleLength, setPreferredArticleLength] = useState("");
  const [sectionInfo, setSectionInfo] = useState("");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [Usp, setUspProduct] = useState("");
  const [keyFeatures, setKeyFeatures] = useState("");
  const [targetAudienceProduct, setTargetAudienceProduct] = useState("");
  const [goalDescription, setGoalDescription] = useState("");
  const [integrationAvailable, setIntegrationAvailable] = useState(true);
  const [selected, setSelected] = useState([]);
  const [tagInputValue, setTagInputValue] = useState("");
  const [solutionName, setSolutionName] = useState("");
  const [solutionDescription, setSolutionDescription] = useState("");
  const [USP, setUSP] = useState("");
  const [valueROI, setValueROI] = useState("");
  const [scalability, setScalability] = useState("");
  const [preferredLength, setPreferredLength] = useState("");
  const [articles, setArticles] = useState([""]);
  const [caseStudies, setCaseStudies] = useState([""]);
  const [socialMediaAccount, setSocialMediaAccount] = useState("Select");
  const [fbPost, setfbPost] = useState(false);
  const [linkedinPost, setLinkedInPost] = useState(false);
  const [twitterPost, setTwitterPost] = useState(false);
  const [instaCap, setInstaCap] = useState(false);
  const [contentName,setContentName] = useState("")

  const webpageType = location?.state?.webpagetype;

  const [solutionFields, setSolutionFields] = useState({});

  const [product, setProduct] = useState({});

  const [general, setGeneral] = useState({});

  useEffect(() => {
    setSolutionFields({
      General_Info: {
        "Solution Name": solutionName,
        "Solution Description": solutionDescription,
        USP: USP,
      },
      Values_and_Scalability: {
        "Value/ROI Info": valueROI,
        Scalability: scalability,
      },
      Preferred_Article_Length: {
        "Preferred Length": preferredLength,
      },
      Account_And_Platform: {
        socialMediaAccount: socialMediaAccount, // JSON key: "socialMediaAccount"
        fbPost: fbPost, // JSON key: "fbPost"
        linkedinPost: linkedinPost, // JSON key: "linkedinPost"
        twitterPost: twitterPost, // JSON key: "twitterPost"
        instaCap: instaCap, // JSON key: "instaCap"
      },
      Articles_and_Case_Studies: {
        Articles: articles,
        "Case Studies": caseStudies,
      },
    });
  }, [
    solutionName,
    solutionDescription,
    valueROI,
    scalability,
    preferredLength,
    articles,
    caseStudies,
    socialMediaAccount,
    fbPost,
    linkedinPost,
    twitterPost,
    instaCap,
  ]);

  useEffect(() => {
    setProduct({
      "Product Information": {
        "Product name": productName,
        "Product Description": productDescription,
        USP: Usp,
        "Key Features": keyFeatures,
      },
      "Target Audience": {
        "Target audience": targetAudienceProduct,
        "Goal of the Product/Solution": goalDescription,
      },
      Account_And_Platform: {
        socialMediaAccount: socialMediaAccount, // JSON key: "socialMediaAccount"
        fbPost: fbPost, // JSON key: "fbPost"
        linkedinPost: linkedinPost, // JSON key: "linkedinPost"
        twitterPost: twitterPost, // JSON key: "twitterPost"
        instaCap: instaCap, // JSON key: "instaCap"
      },
      "Integration & Support": {
        "Integration available": integrationAvailable,
        "Available Integration": selected,
      },
    });
  }, [
    productName,
    productDescription,
    Usp,
    keyFeatures,
    targetAudienceProduct,
    goalDescription,
    integrationAvailable,
    selected,
    socialMediaAccount,
    fbPost,
    linkedinPost,
    twitterPost,
    instaCap,
  ]);

  console.info(webpageType, "======================================ssssssss");
  console.info(contentType, "======================================ssssssss");

  useEffect(() => {
    setGeneral({
      General_Info: {
        "Business Unit Name": businessUnitName,
        Purpose_of_the_Website: purposeDescription,
        USP: usp,
      },
      Target_Audience: {
        Target_Audience: targetAudience,
      },
      Service_and_Offerings: {
        "Service_&_Offerings": serviceAndOfferings,
      },
      "Tone_&_Style": {
        Tone_of_the_content: toneOfContent,
        Style_of_the_content: styleOfContent,
      },
      Account_And_Platform: {
        socialMediaAccount: socialMediaAccount, // JSON key: "socialMediaAccount"
        fbPost: fbPost, // JSON key: "fbPost"
        linkedinPost: linkedinPost, // JSON key: "linkedinPost"
        twitterPost: twitterPost, // JSON key: "twitterPost"
        instaCap: instaCap, // JSON key: "instaCap"
      },
      Key_Achievements: {
        Key_Achievements: keyAchievements,
      },
      Preferred_Article_Length: {
        "Length_&_Structure": preferredArticleLength,
      },
      Section_Info: {
        Section: sectionInfo,
      },
    });
  }, [
    businessUnitName,
    purposeDescription,
    usp,
    targetAudience,
    serviceAndOfferings,
    toneOfContent,
    styleOfContent,
    keyAchievements,
    preferredArticleLength,
    sectionInfo,
    socialMediaAccount,
    fbPost,
    linkedinPost,
    twitterPost,
    instaCap,
  ]);

  let contentId = location?.state?.contentId;
  const approvalStatus = location?.state?.approvalStatus;
  const [contentID, setContentID] = useState("");
  // contentId = contentID;

  console.info(contentID, "//////////////");

  console.log(location, "This is the location");

  useEffect(() => {
    setContentID(contentId);
  }, []);

  console.log(contentId, "This is the content id");

  const getContentById = async () => {
    try {
      const payload = {
        userId: userData[0]?.User_Id,
        contentId: contentId,
      };

      setIsLoading(true)
      const responseData = await getContentByIdApi(payload);
    
      setIsLoading(false)
      setReviewCommands(responseData.data.data.data[0]["Review_Commands"]);
      setContentName(responseData.data.data.data[0]["Content_Name"])
      setSocialMediaId(
        responseData.data.data.data[0]["Social_Media_Content_Id"]
      );
      console.info(
        responseData.data.data.data,
        "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL"
      );
      let htmlString = responseData.data.data.data[0]["Generated_Content"];
      let configJson = responseData.data.data.data[0]["Configuration_Json"];

      console.info(responseData.data.data.data[0]["Configuration_Json"],"this is the data from the sjssj ")
      if (contentType == "Article") {
        setFormData(JSON.parse(configJson));
      } else if (contentType == "Case Study") {
        setCaseStudyFormData(JSON.parse(configJson));
      } else if (contentType == "Press Release") {
        setPressReleaseFormData(JSON.parse(configJson));
      } else if (contentType == "Web Pages" && webpageType == "General") {
        let parsedJson = JSON.parse(configJson);
        setBusinessUnitName(parsedJson["General_Info"]["Business Unit Name"]);
        setPurposeDescription(
          parsedJson["General_Info"]["Purpose_of_the_Website"]
        );
        setUsp(parsedJson["General_Info"]["USP"]);
        setTargetAudience(parsedJson["Target_Audience"]["Target_Audience"]);
        setServiceAndOfferings(
          parsedJson["Service_and_Offerings"]["Service_&_Offerings"]
        );
        setToneOfContent(parsedJson["Tone_&_Style"]["Tone_of_the_content"]);
        setStyleOfContent(parsedJson["Tone_&_Style"]["Style_of_the_content"]);
        setKeyAchievements(parsedJson["Key_Achievements"]["Key_Achievements"]);
        setPreferredArticleLength(
          parsedJson["Preferred_Article_Length"]["Length_&_Structure"]
        );
        setSectionInfo(parsedJson["Section_Info"]["Section"]);
      } else if (contentType == "Web Pages" && webpageType == "Product") {
        let parsedjson = JSON.parse(configJson);
        setProduct(parsedjson["Product Information"]["Product name"]);
        setProductDescription(
          parsedjson["Product Information"]["Product Description"]
        );
        setUspProduct(parsedjson["Product Information"].USP);
        setKeyFeatures(parsedjson["Product Information"]["Key Features"]);
        setTargetAudienceProduct(
          parsedjson["Target Audience"]["Target audience"]
        );
        setGoalDescription(
          parsedjson["Target Audience"]["Goal of the Product/Solution"]
        );
        setIntegrationAvailable(
          parsedjson["Integration & Support"]["Integration available"]
        );
        setSelected(
          parsedjson["Integration & Support"]["Available Integration"]
        );
      } else if (
        contentType == "Web Pages" &&
        webpageType == "Solution Offerings"
      ) {
        let parsedjson = JSON.parse(configJson);
        setSolutionName(parsedjson["General_Info"]["Solution Name"]);
        setSolutionDescription(
          parsedjson["General_Info"]["Solution Description"]
        );
        setUSP(parsedjson["General_Info"]["USP"]);
        setValueROI(parsedjson["Values_and_Scalability"]["Value/ROI Info"]);
        setScalability(parsedjson["Values_and_Scalability"]["Scalability"]);
        setPreferredLength(
          parsedjson["Preferred_Article_Length"]["Preferred Length"]
        );
        setArticles(parsedjson["Articles_and_Case_Studies"]["Articles"]);
        setCaseStudies(parsedjson["Articles_and_Case_Studies"]["Case Studies"]);
      }
      setGeneratedContent(htmlString);
    } catch (error) {
      console.log(error.message);
    }
  };

  const [highlightShow, setHighlightShow] = useState(false);

  console.info(
    formData.References_Citations[0]?.name,
    "this is the highlight mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm"
  );


  const saveContent = async () => {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        contentId: contentID,
        content: generatedContent,
      };

      const responseData = await saveContentApi(payload);

      console.log(responseData.status, "Save content response");
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAccount = async () => {
    try {
      const payload = {
        reviewerId:
          userData[0]["Role_Name"] == "Content Manager"
            ? userData[0]["User_Id"]
            : userData[0]["Reviewer_Id"],
        userId: userData[0]["User_Id"],
      };

      const responseData = await getAllAccountsApi(payload);
      setAccount(responseData.data.data);
      console.log(responseData.status, "Save account response");
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (generatedContent !== "") {
      saveContent();
    }
  }, [generatedContent]);

  useEffect(() => {
    if (contentId !== "" && userData.length != 0) {
      getContentById();
    }
  }, [userData]);

  useEffect(() => {
    getAccount();
  }, []);

  return (
    <CreateContentContext.Provider
      value={{
        generatedContent,
        setGeneratedContent,
        isLoading,
        setIsLoading,
        position,
        setPosition,
        selectedText,
        setSelectedText,
        isAskAiOpened,
        setIsAskAiOpened,
        isTextSelected,
        setIsTextSelected,
        askAiPromptText,
        setAskAiPromptText,
        askAiResponseText,
        setAskAiResponsetext,
        isAskAiResponseReturned,
        setIsAskAiResponseReturned,
        parentRef,
        formData,
        setFormData,
        caseStudyformData,
        setCaseStudyFormData,
        pressReleaseformData,
        setPressReleaseFormData,
        account,
        setAccount,
        imageUri,
        setImageUri,
        oldImageUrl,
        setOldImageUrl,
        imageData,
        setImageData,
        counts,
        setCounts,
        contentId,
        contentID,
        setContentID,
        approvalStatus,
        contentType,
        pdfRef,
        generateHighlight,
        setGenerateHighlight,
        highlightShow,
        setHighlightShow,
        reviewCommands,
        setReviewCommands,
        businessUnitName,
        setBusinessUnitName,
        purposeDescription,
        setPurposeDescription,
        usp,
        setUsp,
        targetAudience,
        setTargetAudience,
        serviceAndOfferings,
        setServiceAndOfferings,
        toneOfContent,
        setToneOfContent,
        styleOfContent,
        setStyleOfContent,
        keyAchievements,
        setKeyAchievements,
        preferredArticleLength,
        setPreferredArticleLength,
        sectionInfo,
        setSectionInfo,
        productName,
        setProductName,
        productDescription,
        setProductDescription,
        Usp,
        setUspProduct,
        keyFeatures,
        setKeyFeatures,
        targetAudienceProduct,
        setTargetAudienceProduct,
        goalDescription,
        setGoalDescription,
        integrationAvailable,
        setIntegrationAvailable,
        selected,
        setSelected,
        tagInputValue,
        setTagInputValue,
        solutionName,
        setSolutionName,
        solutionDescription,
        setSolutionDescription,
        USP,
        setUSP,
        valueROI,
        setValueROI,
        scalability,
        setScalability,
        preferredLength,
        setPreferredLength,
        articles,
        setArticles,
        caseStudies,
        setCaseStudies,
        webpageType,
        solutionFields,
        setSolutionFields,
        product,
        setProduct,
        general,
        setGeneral,
        contentTypeId,
        socialMediaAccount,
        setSocialMediaAccount,
        fbPost,
        setfbPost,
        linkedinPost,
        setLinkedInPost,
        twitterPost,
        setTwitterPost,
        instaCap,
        setInstaCap,
        isAskAiPrompt,
        setIsAskAiPrompt,
        isAskresult,
        setIsAskResult,
        socialMediaId,
        setSocialMediaId,
        state,
        setState,
        contentName,
        setContentName
      }}
    >
      {children}
    </CreateContentContext.Provider>
  );
}
export function useCreateContentContext() {
  return useContext(CreateContentContext);
}
