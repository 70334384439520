import { useState } from "react";
import { useCreateMyEventContext } from "../../contexts/CreateMyEventContext";
import moment from "moment";
import { useCommonContext } from "../../contexts/CommonContext";
import Loader from "../Loader/Loader";

import {
  createContentForMedia,
  createContentForMediaApi,
  createEventPostsApi,
  createImageForMediaApi,
  createMyEventApi,
  createSocialApi,
  getEventPostsApi,
} from "../../services/api";


export default function EventDetails() {

  const { userData } = useCommonContext();
  const {
    formData,
    eventId,
    setEventId,
    meetingLink,
    setMeetingLink,
    isSocialMediaPostGenerated,
    setIsSocialMediaPostGenerated,
    socialMediaContent,
    setSocialMediaContent,
    socialMediaId,
    setSocialMediaId,
    isLoading,
    setIsLoading
  } = useCreateMyEventContext();
  const { openToast } = useCommonContext();

  async function createMyEvent() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        eventName: formData.eventName,
        eventDescription: formData.eventDescription,
        eventFromDate: formData.fromDateEvent,
        eventFromTime: formData.fromTimeEvent,
        eventToDate: formData.toDateEvent,
        eventToTime: formData.toTimeEvent,
        eventtypeId: formData.eventType,
        accountId: formData.accountName,
        meetingPlatform: formData.meetingPlatform,
      };
      setIsLoading(true)
      const responseData = await createMyEventApi(payload);
  
      setEventId(responseData.data.data.eventId);
      setMeetingLink(responseData.data.data.meetingUrl);
      let newFormData = formData;
      newFormData["meetingUrl"] = responseData.data.data.meetingUrl;

      const createSocialPayload = {
        userId: userData[0]["User_Id"],
        id: responseData.data.data.eventId,
        type: "Event",
      };
      setIsLoading(true)
      const responseDataMedia = await createSocialApi(createSocialPayload);
   
      setSocialMediaId(responseDataMedia.data?.data?.Social_media_content_id);

      let platformChoosed = [];
                            
      if (formData.platforms.fbPost) {
        platformChoosed.push("facebook");
      }
      if (formData.platforms.instaCap) {
        platformChoosed.push("instagram");
      }
      if (formData.platforms.linkedinPost) {
        platformChoosed.push("linkedIn");
      }
      if (formData.platforms.twitterPost) {
        platformChoosed.push("twitter");
      }

      // Array to hold all promises for API calls
      let promises = [];

      for (let platform of platformChoosed) {
        const payload = {
          configJson: newFormData,
          userId: userData[0]["User_Id"],
          socialMediaId: responseDataMedia.data?.data?.Social_media_content_id,
          platform: platform,
          account_choosed: formData.accountName,
        };
        promises.push(createContentForMediaApi(payload));
      }
      const imagePayload = {
        userId: userData[0]["User_Id"],
        socialMediaContentId:
          responseDataMedia.data?.data?.Social_media_content_id,
        platform: "socialmediaImage",
        config_json: newFormData,

        account_choosed: formData.accountName,
      };

      // Push each API call promise to the array
      promises.push(createImageForMediaApi(imagePayload));
      // Execute all promises concurrently and wait for all of them to resolve
      Promise.all(promises)
        .then(async (results) => {
          console.log(results, "This is the response from create event posts");
          const getPayload = {
            userId: userData[0]["User_Id"],
            socialMediaId:
              responseDataMedia.data?.data?.Social_media_content_id,
          };
          setIsLoading(true)
          const getEventRes = await getEventPostsApi(getPayload);
          setIsLoading(false)
          setSocialMediaContent(getEventRes.data.data.data);
        })
        .catch((error) => {
          // Handle errors if any of the API calls fail
          console.error("Error:", error);
        });

      openToast(
        "The event has been successfully created",
        "images/toast-check.svg"
      );
    } catch (error) {
      openToast(error?.response?.data?.message, "red-cross-toast.svg");
      console.log(error.message);
    }
  }

  return (
   
    <>
     
      <h3 className="font-24 font-bold mb-4 px-0">Event Details</h3>
      <div className="card border-0 shadow-sm p-4 radius-16 mb-5">
        <p className="font-medium font-16 mb-0">Event name</p>
        <p className="font-regular font-16 color-grey mb-4">
          {formData?.eventName}
        </p>
        <p className="font-medium font-16 mb-0">Description</p>
        <p className="font-regular font-16 color-grey mb-4">
          {formData?.eventDescription}
        </p>
        <div className="row ">
          <div className="col-md-4">
            <p className="font-medium font-16 mb-0">Start date &amp; time</p>
            <p className="font-regular font-16 color-grey mb-4">
              {moment(
                formData.fromDateEvent + " " + formData.fromTimeEvent,
                "YYYY-MM-DD HH:mm"
              ).format("MMM DD, YYYY h:mm A")}
            </p>
          </div>
          <div className="col-md-4">
            <p className="font-medium font-16 mb-0">End date &amp; time</p>
            <p className="font-regular font-16 color-grey mb-4">
              {moment(
                `${formData?.toDateEvent} ${formData?.toTimeEvent}`,
                "YYYY-MM-DD HH:mm"
              ).format("MMM DD, YYYY h:mm A")}
            </p>
          </div>
          <div className="col-md-4">
            <p className="font-medium font-16 mb-0">Platforms</p>
            <p className="font-regular font-16 color-grey mb-4">
              {formData?.meetingPlatform}
            </p>
          </div>
        </div>
        <p className="font-medium font-16 mb-0" hidden={meetingLink == ""}>
          Meeting Link
        </p>
        <a
          className="blue-link text-decoration-none"
          hidden={meetingLink == ""}
        >
          {meetingLink}
        </a>
        <div className="d-flex align-items-center justify-content-end mt-5">
          <button
            class="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
            onClick={() => {
              createMyEvent();
              setIsLoading(true)
            }}
            hidden={meetingLink != ""}
          >
            Create Event
          </button>
          <button
            className="btn cust-btn-success font-bold font-16 text-nowrap  box-shadow-none"
            hidden={meetingLink == ""}
          >
            Event Created
          </button>

        </div>

      </div>
       {isLoading && <Loader />}



    </>
  );
}
