import React, { useState } from "react";
import AccountAndPlatform from "../AccountsAndPlatform";
import { useCreateContentContext } from "../../../contexts/CreateContentContext";
import { generateContentApi } from "../../../services/api";
import { getContentByIdApi } from "../../../services/api";
import { useCommonContext } from "../../../contexts/CommonContext";

export default function PressReleaseFields() {
  const {
    setGeneratedContent,
    pressReleaseformData,
    setPressReleaseFormData,
    account,
    setAccount,
    contentID,
    setContentID,
    approvalStatus,
    contentTypeId,
    setIsLoading,
    contentName,setContentName
  } = useCreateContentContext();

  const { userData } = useCommonContext();

  //generate content function starts here;
  async function generateContent() {
    console.info(pressReleaseformData, "+++++++++++++++++++++++++++++++++");
    console.info(account, "------------------------------");
    try {

      const payload = {
        userId: userData[0]["User_Id"],
        contentTypeId: contentTypeId,
        contentName: "",
        configurationJson: pressReleaseformData,
        contentType: "Press Release",
        contentId: "",
        Distributed_Channel : pressReleaseformData?.distributionChannel?.channelInfo
      };

      setIsLoading(true);
      const resonseData = await generateContentApi(payload);
      setIsLoading(false);

      let contentId = resonseData.data.data.contentId;

      setContentID(contentId);

      console.info(contentID, "content Id");

      const contentByIdPayload = {
        userId: userData[0]["User_Id"],
        contentId: contentId,
      };
      const contentResponseData = await getContentByIdApi(contentByIdPayload);
      setContentName(contentResponseData.data.data.data[0]["Content_Name"])

      console.log(
        contentResponseData.data.data.data[0]["Generated_Content"],
        "This is the html stting from the content gen api"
      );

      let htmlString =
        contentResponseData.data.data.data[0]["Generated_Content"];

      htmlString = htmlString.replaceAll(/^`\\html|\\`$/g, "");

      let counter = 1;
      const getReplacementHtmlSnippet = (count) => {
        return `<div class="position-relative content-upload text-center mb-4"><button class="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none" onClick="generateImage(${count})"><img src="images/generate-icon.svg" alt="generate-icon" class="me-2"/>Generate image</button><p class="font-16 font-medium mt-4 mb-1">or <label class="primary-theme-color cursor-pointer" for="uploadImg2">upload files</label> from your computer</p><p class="font-14 font-regular color-grey">( .png, .jpg, .pdf up to 25MB in size)</p><input type="file" class="cust-file" id="uploadImg2" accept="image/*" onchange="initialFileUpload(event,${count})"/></div>`;
      };

      // Replace all occurrences of <ImagePlaceHolder/> with the replacement HTML snippet
      htmlString = htmlString.replaceAll("<ImagePlaceHolder/>", () => {
        const replacement = getReplacementHtmlSnippet(counter);
        counter++; // Increment counter for the next occurrence
        return replacement;
      });

      setGeneratedContent(htmlString);
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleInputChange = (section, field, value) => {
    setPressReleaseFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleReferencesChange = (index, value) => {
    setPressReleaseFormData((prevData) => ({
      ...prevData,
      References_Citations: {
        ...prevData.References_Citations,
        references: [
          ...prevData.References_Citations.references.slice(0, index),
          value,
          ...prevData.References_Citations.references.slice(index + 1),
        ],
      },
    }));
  };

  const handleUploadFilesChange = (files) => {
    setPressReleaseFormData((prevData) => ({
      ...prevData,
      References_Citations: {
        ...prevData.References_Citations,
        form_Upload: files,
      },
    }));
  };

  const isPendingOrApproved =
    approvalStatus === "Pending" || approvalStatus === "Approved";

  return (
    <div className="col-md-3 mt-4">
      <div className="px-3">
        {/* Primary Objective */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#primaryObject"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Primary Objective
          </p>
          <div className="collapse show" id="primaryObject">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="PreferredContentStructure"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Objective <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title=" A text box allows users to specify the primary objective of their communication or project.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Summarize your key message"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="PreferredContentStructure"
                  defaultValue={""}
                  value={pressReleaseformData.primaryObjective.objective}
                  onChange={(e) =>
                    handleInputChange(
                      "primaryObjective",
                      "objective",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Primary Objective ends */}
        {/* Key Quotes starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#topicKey"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Key Quotes
          </p>
          <div className="collapse show" id="topicKey">
            <div className="col-md-12 mb-3">
              <label
                htmlFor="PreferredContentStructure"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Qoutes <span className="mandatory-red">*</span>
                <span data-bs-toggle="tooltip" title=" A text box allows users to input key quotes or memorable statements relevant to their communication or content.">
                  <img
                    src="images/info-icon.svg"
                    alt="info-icon"
                    className="ms-2 mb-1"
                  />
                </span>
              </label>
              <textarea
                placeholder="Provide key quotes"
                rows={7}
                className="form-control custom-form cust-tarea  font-14 font-regular"
                id="PreferredContentStructure"
                defaultValue={""}
                value={pressReleaseformData.keyQuotes.quotes}
                onChange={(e) =>
                  handleInputChange("keyQuotes", "quotes", e.target.value)
                }
                disabled={isPendingOrApproved}
              />
            </div>
          </div>
        </div>
        {/* Key Ouotes ends */}
        {/* Event/Launch Details  */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#EventLaunch"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Event/Launch Details
          </p>
          <div className="collapse show" id="EventLaunch">
            <div className="col-md-12 mb-3">
              <label
                htmlFor="PreferredContentStructure"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Event/ launch info{" "}
                <span data-bs-toggle="tooltip" title=" A text box allows users to provide details about an upcoming event or launch.Users should able to enter detailed information regarding the event or launch, including date, time, location, agenda, and other pertinent details.">
                  <img
                    src="images/info-icon.svg"
                    alt="info-icon"
                    className="ms-2 mb-1"
                  />
                </span>
              </label>
              <textarea
                placeholder="Details about the event/product"
                rows={7}
                className="form-control custom-form cust-tarea  font-14 font-regular"
                id="PreferredContentStructure"
                defaultValue={""}
                value={pressReleaseformData.eventLaunchDetails.eventLaunchInfo}
                onChange={(e) =>
                  handleInputChange(
                    "eventLaunchDetails",
                    "eventLaunchInfo",
                    e.target.value
                  )
                }
                disabled={isPendingOrApproved}
              />
            </div>
          </div>
        </div>
        {/* Event/Launch ends here */}
        {/* Target Audience starts */}
        <div className="border-bottom mb-4 cust-border">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#targetAudience"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Target Audience{" "}
          </p>
          <div className="collapse show" id="targetAudience">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="targetAudience"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Target Audience
                  <span data-bs-toggle="tooltip" title="A dropdown menu allows users to specify the primary target audience for their communication or content.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="targetAudienceSelect"
                  value={pressReleaseformData.targetAudience.audience}
                  onChange={(e) =>
                    handleInputChange(
                      "targetAudience",
                      "audience",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  <option>Select</option>
                  <option>Industry Professionals</option>
                  <option>Potential Clients</option>
                  <option>Stakeholders</option>
                </select>
              </div>
            </div>
          </div>
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#Boiler"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Boilerplate
          </p>
          <div className="collapse show" id="Boiler">
            <div className="col-md-12 mb-3">
              <label
                htmlFor="PreferredContentStructure"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Boilerplate info <span className="mandatory-red">*</span>
                <span data-bs-toggle="tooltip" title="A text box allows users to input a standardized block of text providing background information about their organization, project, or initiative.">
                  <img
                    src="images/info-icon.svg"
                    alt="info-icon"
                    className="ms-2 mb-1"
                  />
                </span>
              </label>
              <textarea
                placeholder="Brief about the company description or Boilerplate"
                rows={7}
                className="form-control custom-form cust-tarea  font-14 font-regular"
                id="PreferredContentStructure"
                defaultValue={""}
                value={pressReleaseformData.boilerplate.boilerplateInfo}
                onChange={(e) =>
                  handleInputChange(
                    "boilerplate",
                    "boilerplateInfo",
                    e.target.value
                  )
                }
                disabled={isPendingOrApproved}
              />
            </div>
          </div>
        </div>
        {/* Target Audience starts */}

        {/* Social Media starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#accountPlatform"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Account &amp; Platform{" "}
          </p>
          <div className="collapse show" id="accountPlatform">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="socialMediaAccount"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Social media account <span className="mandatory-red">*</span>{" "}
                  <span data-bs-toggle="tooltip" title="Displays a list of accounts integrated on the integration page.Users can select the desired social media account from this dropdown">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="socialMediaAccount"
                  value={
                    pressReleaseformData.Account_And_Platform
                      ?.socialMediaAccount
                  }
                  onChange={(e) =>
                    handleInputChange(
                      "Account_And_Platform",
                      "socialMediaAccount",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  <option value="Select">Select</option>
                  {account?.map((value, index) => {
                    return (
                      <option value={value?.Account_Id}>
                        {value?.Account_Name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {pressReleaseformData.Account_And_Platform?.socialMediaAccount !=
              "Select" ? (
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="platform"
                    className="form-label mb-0 cust-label font-14 font-medium mb-2"
                  >
                    Platforms
                    <span
                      data-bs-toggle="tooltip"
                      title="Lorem ipsumis a dummy"
                    >
                      <img
                        className="ms-2 mb-1"
                        src="images/info-icon.svg"
                        alt="info-icon"
                      />
                    </span>
                  </label>
                  {account
                    .filter(
                      (value) =>
                        value.Account_Id ===
                        pressReleaseformData.Account_And_Platform
                          ?.socialMediaAccount
                    )
                    .map((filteredAccount) => {
                      return (
                        <>
                          <div
                            className="form-check"
                            hidden={filteredAccount.Facebook_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="fbPost"
                              checked={
                                pressReleaseformData.Account_And_Platform.fbPost
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "fbPost",
                                  e.target.checked
                                )
                              }
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label font-14 font-medium check-label"
                              htmlFor="fbPost"
                            >
                              Facebook post
                            </label>
                          </div>

                          <div
                            className="form-check"
                            hidden={filteredAccount.Linkedin_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="LinkedinPost"
                              checked={
                                pressReleaseformData.Account_And_Platform
                                  .linkedinPost
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "linkedinPost",
                                  e.target.checked
                                )
                              }
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="LinkedinPost"
                            >
                              Linkedin post
                            </label>
                          </div>

                          <div
                            className="form-check"
                            hidden={filteredAccount.Twitter_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="twitterPost"
                              checked={
                                pressReleaseformData.Account_And_Platform
                                  .twitterPost
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "twitterPost",
                                  e.target.checked
                                )
                              }
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="twitterPost"
                            >
                              Twitter post
                            </label>
                          </div>
                          <div
                            className="form-check"
                            hidden={filteredAccount.Instagram_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="instaCap"
                              checked={
                                pressReleaseformData.Account_And_Platform
                                  .instaCap
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "instaCap",
                                  e.target.checked
                                )
                              }
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="instaCap"
                            >
                              Instagram caption
                            </label>
                          </div>
                        </>
                      );
                    })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* Social Media ends */}
        {/* Contact Details starts */}
        <div className="border-bottom mb-4 cust-border">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#contactDetails"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Contact Details{" "}
          </p>
          <div className="collapse show" id="contactDetails">
            <div className="col-md-12 mb-3">
              <label
                htmlFor="topicOrtheme"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Name <span className="mandatory-red">*</span>{" "}
                <span data-bs-toggle="tooltip" title="An input field allowing users to provide their names that will be added in the press release.">
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
              </label>
              <input
                type="text"
                placeholder="Enter Name"
                className="form-control custom-form  font-14 font-regular"
                id="topicOrtheme"
                value={pressReleaseformData.contactDetails.name}
                onChange={(e) =>
                  handleInputChange("contactDetails", "name", e.target.value)
                }
                disabled={isPendingOrApproved}
              />
            </div>
            <div className="col-md-12 mb-3">
              <label
                htmlFor="topicOrtheme"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Position/ Title <span className="mandatory-red">*</span>
                <span data-bs-toggle="tooltip" title=" An input field allowing users to provide their position that will be added in the press release.">
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
              </label>
              <input
                type="text"
                placeholder="Enter Designation"
                className="form-control custom-form  font-14 font-regular"
                id="topicOrtheme"
                value={pressReleaseformData.contactDetails.position}
                onChange={(e) =>
                  handleInputChange(
                    "contactDetails",
                    "position",
                    e.target.value
                  )
                }
                disabled={isPendingOrApproved}
              />
            </div>
            <div className="col-md-12 mb-3">
              <label
                htmlFor="topicOrtheme"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Company name <span className="mandatory-red">*</span>{" "}
                <span data-bs-toggle="tooltip" title="An input field allowing users to provide their Company name that will be added in the press release.">
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
              </label>
              <input
                type="text"
                placeholder="Enter your article's topic or theme"
                className="form-control custom-form  font-14 font-regular"
                id="topicOrtheme"
                value={pressReleaseformData.contactDetails.companyName}
                onChange={(e) =>
                  handleInputChange(
                    "contactDetails",
                    "companyName",
                    e.target.value
                  )
                }
                disabled={isPendingOrApproved}
              />
            </div>
            <div className="col-md-12 mb-3">
              <label
                htmlFor="topicOrtheme"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Mail <span className="mandatory-red">*</span>
                <span data-bs-toggle="tooltip" title="An input field allowing users to provide their Email that will be added in the press release.">
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
              </label>
              <input
                type="text"
                placeholder="Enter email address"
                className="form-control custom-form  font-14 font-regular"
                id="topicOrtheme"
                value={pressReleaseformData.contactDetails.email}
                onChange={(e) =>
                  handleInputChange("contactDetails", "email", e.target.value)
                }
                disabled={isPendingOrApproved}
              />
            </div>
          </div>
        </div>
        {/* Contact Details ends */}
        {/* Embargo Details */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#topicKeyPoints"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Embargo Details
          </p>
          <div className="collapse show" id="topicKeyPoints">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="PreferredContentStructure"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Embargo Info
                  <span data-bs-toggle="tooltip" title=" A text box allows users to specify any embargo details related to their communication or content.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Specify about embargo details"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="PreferredContentStructure"
                  defaultValue={""}
                  value={pressReleaseformData.embargoDetails.embargoInfo}
                  onChange={(e) =>
                    handleInputChange(
                      "embargoDetails",
                      "embargoInfo",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Embargo Details ends */}
        {/* Distribution Channels */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#DistributionChannels"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Distribution Channel
          </p>
          <div className="collapse show" id="DistributionChannels">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="topicOrtheme"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Channel Info
                  <span data-bs-toggle="tooltip" title="A text box allows users to specify the distribution channels for their communication or content.Users should be able to enter detailed information regarding the distribution channels they plan to utilize, including media outlets, social media platforms, email lists, and other distribution channels.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Channel name"
                  className="form-control custom-form  font-14 font-regular"
                  id="topicOrtheme"
                  value={pressReleaseformData.distributionChannel.channelInfo}
                  onChange={(e) =>
                    handleInputChange(
                      "distributionChannel",
                      "channelInfo",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Distribution Channels ends */}
        {/* Newsworthiness */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#Newsworthiness"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Newsworthiness
          </p>
          <div className="collapse show" id="Newsworthiness">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="PreferredContentStructure"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  News Value <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title=" A text box allowing users to assess the newsworthiness of their communication or content.Users should able to enter detailed information regarding the news value of their communication, including relevance, timeliness, significance, human interest, and proximity factors.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Highlight timeliness and significance"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="PreferredContentStructure"
                  defaultValue={""}
                  value={pressReleaseformData.newsworthiness.newsValue}
                  onChange={(e) =>
                    handleInputChange(
                      "newsworthiness",
                      "newsValue",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Newsworthiness ends */}
        {/* form action starts here */}
        <div className="d-flex justify-content-end py-3 form-foot">
          <button className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2">
            Cancel
          </button>
          <button
            onClick={() => {
              generateContent();
            }}
            disabled={
              isPendingOrApproved ||
              pressReleaseformData.primaryObjective.objective == "" ||
              pressReleaseformData.keyQuotes.quotes == "" ||
              pressReleaseformData.boilerplate.boilerplateInfo == "" ||
              pressReleaseformData.Account_And_Platform.socialMediaAccount ==
                "Select" ||
              pressReleaseformData.contactDetails.name == "" ||
              pressReleaseformData.contactDetails.position == "" ||
              pressReleaseformData.contactDetails.companyName == "" ||
              pressReleaseformData.contactDetails.email == "" ||
              pressReleaseformData.newsworthiness.newsValue == ""
            }
            className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
          >
            <img
              src="images/generate-icon.svg"
              alt="generate-icon"
              className="me-2"
            />
            {contentID == "" ? "Generate" : "Regenerate"}
          </button>
        </div>
        {/* form action ends here */}
      </div>
    </div>
  );
}
