import React, { useState } from "react";
// import { generateWebContent } from "../../../services/api";
import { ReactSortable } from "react-sortablejs";
import { useCreateContentContext } from "../../../contexts/CreateContentContext";
import { generateContentApi } from "../../../services/api";
import { getContentByIdApi } from "../../../services/api";
import { useCommonContext } from "../../../contexts/CommonContext";
import { useLocation } from "react-router-dom";

export default function SolutionOfferingsFields() {
  //   const [solutionName, setSolutionName] = useState("");
  //   const [solutionDescription, setSolutionDescription] = useState("");
  //   const [USP, setUSP] = useState("");
  //   const [valueROI, setValueROI] = useState("");
  //   const [scalability, setScalability] = useState("");
  //   const [preferredLength, setPreferredLength] = useState("");

  const {
    setGeneratedContent,
    formData,
    setFormData,
    contentID,
    setContentID,
    approvalStatus,
    account,
    setAccount,
    solutionName,
    setSolutionName,
    solutionDescription,
    setSolutionDescription,
    USP,
    setUSP,
    valueROI,
    setValueROI,
    scalability,
    setScalability,
    preferredLength,
    setPreferredLength,
    articles,
    setArticles,
    caseStudies,
    setCaseStudies,
    contentTypeId,
    solutionFields,
    setSolutionFields,
    socialMediaAccount,
    setSocialMediaAccount,
    fbPost,
    setfbPost,
    linkedinPost,
    setLinkedInPost,
    twitterPost,
    setTwitterPost,
    instaCap,
    setInstaCap,
    setIsLoading,
    contentName,setContentName
  } = useCreateContentContext();

  const location = useLocation();

  console.log(location,"this is the location from web page")

  const { userData } = useCommonContext();

  async function generateContent() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        contentTypeId: contentTypeId,
        contentName: "",
        contentId: "",
        configurationJson: {
          General_Info: {
            "Solution Name": solutionName,
            "Solution Description": solutionDescription,
            USP: USP,
          },
          Values_and_Scalability: {
            "Value/ROI Info": valueROI,
            Scalability: scalability,
          },
          Preferred_Article_Length: {
            "Preferred Length": preferredLength,
          },
          Articles_and_Case_Studies: {
            Articles: articles,
            "Case Studies": caseStudies,
          },
        },
        contentType: "Webpage",
        webpageTypeId: location?.state?.webpageTypeId,
      };

      setIsLoading(true);
      const resonseData = await generateContentApi(payload);
      setIsLoading(false);

      let contentId = resonseData.data.data.contentId;

      setContentID(contentId);

      const contentByIdPayload = {
        userId: userData[0]["User_Id"],
        contentId: contentId,
      };
      setIsLoading(true);
      const contentResponseData = await getContentByIdApi(contentByIdPayload);

      setIsLoading(false);

      setContentName(contentResponseData.data.data.data[0]["Content_Name"])

      console.log(
        contentResponseData.data.data.data[0]["Generated_Content"],
        "This is the html stting from the content gen api"
      );

      let htmlString =
        contentResponseData.data.data.data[0]["Generated_Content"];

      htmlString = htmlString.replaceAll(/^`\\html|\\`$/g, "");

      let counter = 1;
      const getReplacementHtmlSnippet = (count) => {
        return `<div class="position-relative content-upload text-center mb-4"><button class="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none" onClick="generateImage(${count})"><img src="images/generate-icon.svg" alt="generate-icon" class="me-2"/>Generate image</button><p class="font-16 font-medium mt-4 mb-1">or <label class="primary-theme-color cursor-pointer" for="uploadImg2">upload files</label> from your computer</p><p class="font-14 font-regular color-grey">( .png, .jpg, .pdf up to 25MB in size)</p><input type="file" class="cust-file" id="uploadImg2" accept="image/*" onchange="initialFileUpload(event,${count})"/></div>`;
      };

      // Replace all occurrences of <ImagePlaceHolder/> with the replacement HTML snippet
      htmlString = htmlString.replaceAll("<ImagePlaceHolder/>", () => {
        const replacement = getReplacementHtmlSnippet(counter);
        counter++; // Increment counter for the next occurrence
        return replacement;
      });

      setGeneratedContent(htmlString);
    } catch (error) {
      console.log(error.message);
    }
  }

  /* article draggable function*/

  const handleInputChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };
  console.info(solutionFields, "Solution Fileds this is ");

  const DraggableDivs = () => {
    const [articles, setArticles] = useState([
      { id: 1, content: "" }, // Initial div
    ]);
  };
  const handleAddDiv = () => {
    const newArticle = { id: articles.length + 1, content: "" };
    setArticles([...articles, newArticle]);
  };

  const handleRemoveDiv = (id) => {
    if (articles.length === 1) {
      // If there's only one item, do not remove it
      return;
    }
    const updatedArticles = articles.filter((item) => item.id !== id);
    setArticles(updatedArticles);
  };

  const handleChangeContent = (id, value) => {
    const updatedArticles = articles.map((item) =>
      item.id === id ? { ...item, content: value } : item
    );
    setArticles(updatedArticles);
  };

  /* casestudies draggable function*/
  const handleAddCaseStudy = () => {
    setCaseStudies([...caseStudies, ""]);
  };

  const handleRemoveCaseStudy = (index) => {
    setCaseStudies(caseStudies.filter((_, i) => i !== index));
  };

  const handleChangeCaseStudy = (index, value) => {
    const updatedCaseStudies = [...caseStudies];
    updatedCaseStudies[index] = value;
    setCaseStudies(updatedCaseStudies);
  };
  const isPendingOrApproved =
    approvalStatus === "Pending" || approvalStatus === "Approved";

  return (
    <div className="col-md-3 mt-4">
      <div className="px-3">
        {/* Solution Information */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer"
            data-bs-toggle="collapse"
            data-bs-target="#topicKeyPoints"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Solution Information
          </p>
          <div className="collapse show" id="topicKeyPoints">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="topicOrtheme"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Solution name <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title="An input field allows users to specify the name of the solution.Users should input the name of the solution.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Describe some key content highlights"
                  rows={7}
                  className="form-control custom-form cust-tarea font-14 font-regular"
                  id="specifyKeyContent"
                  onChange={(e) => setSolutionName(e.target.value)}
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label
                htmlFor="eventDescription"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Solution description
                <span data-bs-toggle="tooltip" title="A text box allowing users to provide a description of the solution.Input Field: Users should describe the solution in detail.">
                  <img
                    src="images/info-icon.svg"
                    alt="info-icon"
                    className="ms-2"
                  />
                </span>
              </label>
              <textarea
                placeholder="Briefly describe about the event"
                rows="3"
                className="form-control custom-form cust-tarea font-14 font-regular"
                id="eventDescription"
                onChange={(e) => setSolutionDescription(e.target.value)}
                disabled={isPendingOrApproved}
              ></textarea>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="usp"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  USP <span className="mandatory-red">*</span>
                </label>
                <span data-bs-toggle="tooltip" title=" A text box allowing users to specify the unique selling points of the solution.">
                  <img
                    src="images/info-icon.svg"
                    alt="info-icon"
                    className="ms-2"
                  />
                </span>
                <textarea
                  placeholder="Enter the Unique Selling Proposition"
                  rows="{3}"
                  className="form-control custom-form cust-tarea font-14 font-regular"
                  id="usp"
                  defaultValue={""}
                  onChange={(e) => setUSP(e.target.value)}
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Solution Information ends */}

        {/* Values and Scalability starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer"
            data-bs-toggle="collapse"
            data-bs-target="#keySeo"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Values & Scalability
          </p>
          <div className="collapse show" id="keySeo">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="SpecifySEO"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Value/ROI info{" "}
                  <span data-bs-toggle="tooltip" title=" A text box allows users to provide information about the value or return on investment (ROI) of the solution.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Describe broader industry or market context."
                  rows={7}
                  className="form-control custom-form cust-tarea font-14 font-regular"
                  id="SpecifySEO"
                  defaultValue={""}
                  onChange={(e) => setValueROI(e.target.value)}
                  disabled={isPendingOrApproved}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="SpecifyFormat"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Scalability{" "}
                  <span data-bs-toggle="tooltip" title="A text box allows users to describe the scalability of the solution.Users should describe the scalability of the solution.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Describe broader industry or market context."
                  rows={7}
                  className="form-control custom-form cust-tarea font-14 font-regular"
                  id="SpecifyFormat"
                  defaultValue={""}
                  onChange={(e) => setScalability(e.target.value)}
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Values and Scalability ends */}

        {/* Preferred article length */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer"
            data-bs-toggle="collapse"
            data-bs-target="#preferredArticleLength"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Length of the content
          </p>
          <div className="collapse show" id="preferredArticleLength">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="lengthStructureLabel"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Preferred article length
                  <span data-bs-toggle="tooltip" title="A dropdown menu allowing users to specify the preferred length of articles or content.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="lengthStructureLabel"
                  onChange={(e) => setPreferredLength(e.target.value)}
                  disabled={isPendingOrApproved}
                >
                  <option value="Short">Short</option>
                  <option value="Medium">Medium</option>
                  <option value="Long">Long</option>
                  <option value="Very Long"> Very Long</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Preferred article length ends */}

        {/* Accounts and Platform starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#accountPlatform"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Account &amp; Platform{" "}
          </p>
          <div className="collapse show" id="accountPlatform">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="socialMediaAccount"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Social media account <span className="mandatory-red">*</span>{" "}
                  <span data-bs-toggle="tooltip" title="Displays a list of accounts integrated on the integration page.Users can select the desired social media account from this dropdown">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="socialMediaAccount"
                  value={
                    solutionFields.Account_And_Platform?.socialMediaAccount
                  }
                  onChange={(e) => setSocialMediaAccount(e.target.value)}
                  disabled={isPendingOrApproved}
                >
                  <option value="Select">Select</option>
                  {account?.map((value, index) => {
                    return (
                      <option value={value?.Account_Id}>
                        {value?.Account_Name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {solutionFields.Account_And_Platform?.socialMediaAccount !=
              "Select" ? (
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="platform"
                    className="form-label mb-0 cust-label font-14 font-medium mb-2"
                  >
                    Platforms
                    <span
                      data-bs-toggle="tooltip"
                      title="Lorem ipsumis a dummy"
                    >
                      <img
                        className="ms-2 mb-1"
                        src="images/info-icon.svg"
                        alt="info-icon"
                      />
                    </span>
                  </label>
                  {account
                    .filter(
                      (value) =>
                        value.Account_Id ===
                        solutionFields.Account_And_Platform?.socialMediaAccount
                    )
                    .map((filteredAccount) => {
                      return (
                        <>
                          <div
                            className="form-check"
                            hidden={filteredAccount.Facebook_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="fbPost"
                              checked={
                                solutionFields.Account_And_Platform.fbPost
                              }
                              onChange={(e) => setfbPost(e.target.value)}
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label font-14 font-medium check-label"
                              htmlFor="fbPost"
                            >
                              Facebook post
                            </label>
                          </div>

                          <div
                            className="form-check"
                            hidden={filteredAccount.Linkedin_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="LinkedinPost"
                              checked={
                                solutionFields.Account_And_Platform.linkedinPost
                              }
                              onChange={(e) => setLinkedInPost(e.target.value)}
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="LinkedinPost"
                            >
                              Linkedin post
                            </label>
                          </div>

                          <div
                            className="form-check"
                            hidden={filteredAccount.Twitter_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="twitterPost"
                              checked={
                                solutionFields.Account_And_Platform.twitterPost
                              }
                              onChange={(e) => setTwitterPost(e.target.value)}
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="twitterPost"
                            >
                              Twitter post
                            </label>
                          </div>
                          <div
                            className="form-check"
                            hidden={filteredAccount.Instagram_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="instaCap"
                              checked={
                                solutionFields.Account_And_Platform.instaCap
                              }
                              onChange={(e) => setInstaCap(e.target.value)}
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="instaCap"
                            >
                              Instagram caption
                            </label>
                          </div>
                        </>
                      );
                    })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* Accounts and Platform ends */}

        {/* Article and Case Studies */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer"
            data-bs-toggle="collapse"
            data-bs-target="#ArticleCaseStudies"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Articles and Case studies
          </p>
          <div className="row" id="ArticleCaseStudies">
            {/* Articles section */}
            <div className="position-relative">
              {" "}
              {/* Add a wrapper with position:relative */}
              <p className="font-14 font-medium">
                Articles
                <span
                  className="ms-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Tooltip for Articles"
                  style={{ cursor: "pointer" }}
                >
                  <img src="images/info-icon.svg" alt="info-icon" title="An input field allows users to input articles related to the solution. Users should be able to rearrange, add, or remove articles using the provided icons.Users should input articles related to the solution." />
                </span>
              </p>
            </div>
            <ReactSortable list={articles} setList={setArticles}>
              {articles.map((item) => (
                <div
                  className="d-flex align-items-center gap-2 grab-comp mb-2"
                  key={item.id}
                >
                  <span>
                    <img src="images/drag-dots.svg" className="cursor-grab" />
                  </span>
                  <input
                    className="form-control custom-form font-14 font-regular"
                    value={item.content}
                    onChange={(e) =>
                      handleChangeContent(item.id, e.target.value)
                    }
                    disabled={isPendingOrApproved}
                  />
                  <span>
                    <img
                      src="images/plus-icon.svg"
                      className="cursor-pointer d-block"
                      onClick={handleAddDiv}
                    />
                  </span>
                  {articles.length > 1 && (
                    <span>
                      <img
                        src="images/minus-icon.svg"
                        className="cursor-pointer d-block py-2"
                        onClick={() => handleRemoveDiv(item.id)}
                      />
                    </span>
                  )}
                </div>
              ))}
            </ReactSortable>
          </div>
          <div className="row" id="ArticleCaseStudies">
            {/* Case Studies section */}
            <div className="position-relative">
              {" "}
              {/* Add a wrapper with position:relative */}
              <p className="font-14 font-medium">
                Case Studies
                <span
                  className="ms-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Tooltip for Case Studies"
                  style={{ cursor: "pointer" }}
                >
                  <img src="images/info-icon.svg" alt="info-icon" title="An input field allows users to input case studies related to the solution. Users should be able to rearrange, add, or remove case studies using the provided icons.Users should input case studies related to the solution." />
                </span>
              </p>
            </div>
            <ReactSortable list={caseStudies} setList={setCaseStudies}>
              {caseStudies.map((study, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center gap-2 grab-comp mb-2"
                >
                  <span>
                    <img src="images/drag-dots.svg" className="cursor-grab" />
                  </span>
                  <input
                    className="form-control custom-form font-14 font-regular"
                    value={study}
                    onChange={(e) =>
                      handleChangeCaseStudy(index, e.target.value)
                    }
                    disabled={isPendingOrApproved}
                  />
                  <span>
                    <img
                      src="images/plus-icon.svg"
                      className="cursor-pointer d-block "
                      onClick={handleAddCaseStudy}
                      disabled={isPendingOrApproved}
                    />
                  </span>
                  {caseStudies.length > 1 && (
                    <span>
                      <img
                        src="images/minus-icon.svg"
                        className="cursor-pointer d-block py-2"
                        onClick={() => handleRemoveCaseStudy(index)}
                        disabled={isPendingOrApproved}
                      />
                    </span>
                  )}
                </div>
              ))}
            </ReactSortable>
          </div>
        </div>
      </div>
      {/* Article and Case Studies ends */}

      {/* Buttons */}
      <div className="d-flex justify-content-end py-3 form-foot">
        <button className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2">
          Cancel
        </button>
        <button
          className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
          onClick={generateContent}
          disabled={isPendingOrApproved}
        >
          <img
            src="images/generate-icon.svg"
            alt="generate-icon"
            className="me-2"
          />
          {contentID == "" ? "Generate" : "Regenerate"}
        </button>
      </div>
    </div>
  );
}
