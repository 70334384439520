import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { insertDetailsApi } from '../../services/api';
import { useCommonContext } from '../../contexts/CommonContext';

export default function Signup() {
  const { verifyJwtData } = useCommonContext();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    password: '',
    confirmPassword: ''
  });
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const [toastImage, setToastImage] = useState("images/toast-check.svg");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleInputChange = async (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const isPasswordRegexValid = /^(?!^\d)(?!^[@$!%*?&])(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_,:"".#$^()+-?><,`~]{8,}$/;

  const handleSignUpClick = async () => {
    const isEmailValid = emailRegex.test(formData.emailId);
    setIsEmailValid(isEmailValid);

    isPasswordRegexValid.test(formData.password);
    const isPasswordEmpty = formData.password.trim() === '';
    const isConfirmPasswordEmpty = formData.confirmPassword.trim() === '';
    const isPasswordLengthValid = !isPasswordEmpty && formData.password.length >= 8;
    setIsPasswordValid(isPasswordLengthValid && isPasswordRegexValid);

    if (isPasswordLengthValid && !isConfirmPasswordEmpty && isPasswordRegexValid && isEmailValid) {
      try {
        const responseData = await insertDetailsApi(formData);
        if (responseData.status === 200) {
          setIsToastOpen(true);
          setToastMessage("Account created successfully");
          setFormData(
            {
              firstName: '',
              lastName: '',
              emailId: '',
              password: '',
              confirmPassword: ''
            }
          )
          setTimeout(() => {
            setIsToastOpen(false)
            navigate(`/`)
          }, 3000);
        }
      } catch (error) {
        
        setToastMessage(error.response.data.message);
        setIsToastOpen(true);
        setToastImage("images/red-cross-toast.svg");
        setTimeout(() => {
          setIsToastOpen(false)
        }, 5000);

      }
    }
  };

  useEffect(() => {
    let currentIndex = 0; // Initialize index to track which button to click
    const interval = setInterval(() => {
      document.getElementById(`button${currentIndex + 1}`).click();
      currentIndex = (currentIndex + 1) % 3; // Ensure index loops between 0, 1, and 2
    }, 3000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        {/*Left container starts here */}
        <div className="col-xl-7 d-xl-block d-none py-2 pe-2">
          <div className="login-bg-height position-relative my-0">
            <div className="login-bg-height login-bg-img">
              <div id="carouselExampleIndicators" className="carousel pt-5 slide" data-bs-ride="carousel">
                <div className="carousel-indicators custom-carousel justify-content-start mx-5">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={0}
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                    id="button1"
                    class="active"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={1}
                    aria-label="Slide 2"
                    id="button2"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={2}
                    aria-label="Slide 3"
                    id="button3"
                  />
                </div>
                <div className="carousel-inner mx-5 py-5 mt-5">
                  <div className="carousel-item active">
                    <h5 className="mb-3 font-bold color-white font-28">Rapid Article Production</h5>
                    <p className="mt-3 mb-5 font-18 font-regular color-white content-wrap">AI-Powered Article Creation.</p>
                  </div>
                  <div className="carousel-item">
                    <h5 className="mb-3 font-bold color-white font-32"> Seamless Social Media Post Generation</h5>
                    <p className="mt-3 mb-5 font-16 font-regular color-white content-wrap">Instantly create social media post with AI.</p>
                  </div>
                  <div className="carousel-item">
                    <h5 className="mb-3 font-bold color-white font-32">Event Management</h5>
                    <p className="mt-3 mb-5 font-16 font-regular color-white content-wrap">Transform your event experience with AI-driven content creation for seamless engagement across articles, blogs, and social platforms.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Left container ends here */}
        {/*Right container starts here */}
        <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
          <div className="form-signup">
            <div className="row justify-content-center">
              <div className="col-md-10 col-sm-12 py-lg-0 py-4">
                <h5 className="mb-3 font-bold primary-darkblue font-32">Signup</h5>
                <p className="mt-3 mb-5 font-16 font-regular grey-v2">Please enter your details to signup</p>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="FirstName" className="form-label mb-0 cust-label font-14 font-medium mb-2">First Name</label>
                    <input type="text" placeholder="Enter first name" className="form-control custom-form py-2 font-14 font-regular" id="FirstName" name="firstName" value={formData.firstName} onChange={handleInputChange} />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="LastName" className="form-label mb-0 cust-label font-14 font-medium mb-2">Last Name</label>
                    <input type="text" placeholder="Enter last name" className="form-control custom-form py-2 font-14 font-regular" id="LastName" name="lastName" value={formData.lastName} onChange={handleInputChange} />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="Emailaddress" className="form-label mb-0 cust-label font-14 font-medium mb-2">Email address</label>
                    <input type="text" placeholder="Enter email address" className="form-control custom-form py-2  font-14 font-regular" id="Emailaddress" name="emailId" value={formData.emailId} onChange={handleInputChange} />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="Password" className="form-label mb-0 cust-label font-14 font-medium mb-2">Password</label>
                    <div style={{ position: 'relative' }}>
  <input
    type={showPassword ? 'text' : 'password'}
    placeholder="Enter password"
    className="form-control custom-form py-2 font-14 font-regular"
    id="Password"
    name="password"
    value={formData.password}
    onChange={handleInputChange}
    style={{ paddingRight: '2.5rem', WebkitTextSecurity: showPassword ? 'none' : 'disc', textSecurity: showPassword ? 'none' : 'disc' }}
  />
  <span
    style={{
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      zIndex: 1,
    }}
    onClick={() => togglePasswordVisibility('password')}
  >
    {showPassword ? (
      <i className="fas fa-eye-slash"></i>
    ) : (
      <i className="fas fa-eye"></i>
    )}
  </span>
</div>

                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="ConfirmPassword" className="form-label mb-0 cust-label font-14 font-medium mb-2" value={formData.password} onChange={handleInputChange}>Confirm Password</label>
                    <div style={{ position: 'relative' }}>
  <input
    type={showConfirmPassword ? 'text' : 'password'}
    placeholder="Confirm password"
    className="form-control custom-form py-2 font-14 font-regular"
    id="ConfirmPassword"
    name="confirmPassword"
    value={formData.confirmPassword}
    onChange={handleInputChange}
  />
  <span
    style={{
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      zIndex: 1,
    }}
    onClick={toggleConfirmPasswordVisibility} // Use the new function here
  >
    {showConfirmPassword ? (
      <i className="fas fa-eye-slash"></i>
    ) : (
      <i className="fas fa-eye"></i>
    )}
  </span>
</div>
                  </div>
                </div>
                <button
                  type="button"
                  className={`btn px-4 mt-5 w-100 primary-gradient-btn text-white gap-2 text-center font-bold font-16 text-nowrap `}
                  disabled={!((formData.password.trim() !== '' && formData.password.length >= 8 && formData.firstName.trim() !== '') && (formData.confirmPassword.trim() !== '') && isPasswordRegexValid.test(formData.password) && emailRegex.test(formData.emailId) && formData.confirmPassword === formData.password)}
                  data-bs-dismiss="modal"
                  onClick={handleSignUpClick}
                >
                  Create Account
                </button>
                <div className="text-center mt-3">
                  <label className="font-16 font-regular ">Already having an account, </label>
                  <a onClick={() => navigate(`/`)} href="#" className="primary-link-color font-16 font-medium text-decoration-none"> Log In</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Right container ends here */}
      </div>
    </div>
  )                    }