import React, { useState, useEffect, useContext, useRef } from "react";
// import { useNavigate } from "react-router-dom";
import {
  getAllSoicalMediaContentApi,
  postInstagramApi,
  postFaceBookApi,
  postTwitterApi,
  postLinkedInApi,
  regeneratePostApi,
  createContentForMediaApi,
  createImageForMediaApi,
  getEventPostsApi,
  savePostContentApi,
} from "../../services/api";
import { useLocation } from "react-router-dom";
import { useCommonContext } from "../../contexts/CommonContext";

export default function Highlights(props) {
  const { userData, openToast } = useCommonContext();
  const { config } = props;
  const location = useLocation();
  // const navigate = useNavigate();
  const [postGeneratedContent, setPostGeneratedContent] = useState([]);
  const [roleId, setRoleId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const socialMediaId = location.state.socialMediaId;
  const [faceBook, setfacebook] = useState(false);
  const facebookRef = useRef(null);
  const [instagram, setInstagram] = useState(false);
  const instagramRef = useRef(null);
  const [linkedin, setLinkedin] = useState(false);
  const linkedinRef = useRef(null);

  const [twitter, setTwitter] = useState(false);
  const twitterRef = useRef(null);

  useEffect(() => {
    getAllSoicalMediaContent();
    // setRoleId(verifyJwtToken('Role_Id'));
  }, []);

  const getAllSoicalMediaContent = async () => {
    console.info(location, "DONDODNODNODN");
    try {
      setIsLoading(true);
      const getPayload = {
        userId: userData[0]["User_Id"],
        socialMediaId: location.state.socialMediaId,
      };

      const getEventRes = await getEventPostsApi(getPayload);
      setPostGeneratedContent(getEventRes.data.data.data);

      // const userId = userData[0]["User_Id"];
      // const data = {
      //   user_id: userId,
      //   content_id: contentId,
      //   Media_Type: "highlight",
      // };

      // const responseData = await getAllSoicalMediaContentApi(data);
      // if (responseData.status === 200) {
      //   setPostGeneratedContent(responseData.data.data.data);
      //   console.log(postGeneratedContent, "post generation content");
      //   console.log(props.contentid, "content id in highlight component");

      //   setIsLoading(false);
      // }
    } catch (error) {
      // openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  const postInstagram = async () => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const data = {
        userId: userId,
        image_url: postGeneratedContent[0]?.Social_Media_Image_Url,
        content_id: postGeneratedContent[0]?.Social_Media_Content_Id,
        account_id: postGeneratedContent[0]?.Account_Id,
        caption: postGeneratedContent[0]?.Instagram_Content,
      };
      const responseData = await postInstagramApi(data);
      if (responseData.status === 200) {
        getAllSoicalMediaContent();
        openToast(
          "Instagram Content Posted Successfully ",
          "images/toast-check.svg"
        );
        setIsLoading(false);
      }
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  const postFaceBook = async () => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const data = {
        userId: userId,
        image_url: postGeneratedContent[0]?.Social_Media_Image_Url,
        contentId: postGeneratedContent[0]?.Social_Media_Content_Id,
        Account_Id: postGeneratedContent[0]?.Account_Id,
        caption_message: postGeneratedContent[0]?.Facebook_Content,
        // caption_message, Account_Id, contentId"
      };
      const responseData = await postFaceBookApi(data);
      if (responseData.status === 200) {
        getAllSoicalMediaContent();
        openToast(
          "FaceBook Content Posted Successfully ",
          "images/toast-check.svg"
        );
        setIsLoading(false);
      }
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  const postTwitter = async () => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const data = {
        userId: userId,
        imageUrl: postGeneratedContent[0]?.Social_Media_Image_Url,
        contentId: postGeneratedContent[0]?.Social_Media_Content_Id,
        accountId: postGeneratedContent[0]?.Account_Id,
        caption: postGeneratedContent[0]?.Twitter_Content,
      };
      const responseData = await postTwitterApi(data);
      if (responseData.status === 200) {
        getAllSoicalMediaContent();
        openToast(
          "Twitter Content Posted Successfully ",
          "images/toast-check.svg"
        );
        setIsLoading(false);
      }
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  const postLinkedin = async () => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const data = {
        userId: userId,
        image_url: postGeneratedContent[0]?.Social_Media_Image_Url,
        content_id: postGeneratedContent[0]?.Social_Media_Content_Id,
        account_id: postGeneratedContent[0]?.Account_Id,
        caption: postGeneratedContent[0]?.Linkedin_Content,
      };
      const responseData = await postLinkedInApi(data);
      if (responseData.status === 200) {
        getAllSoicalMediaContent();
        openToast(
          "Linkedin Content Posted Successfully ",
          "images/toast-check.svg"
        );
        setIsLoading(false);
      }
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  const regeneratePost = async (platform) => {
    try {
      setIsLoading(true);

      const payload = {
        configJson: props.config,
        userId: userData[0]["User_Id"],
        socialMediaId: postGeneratedContent[0]?.Social_Media_Content_Id,
        platform: platform,
        account_choosed: config.Account_And_Platform.socialMediaAccount,
      };

      const responseData = await createContentForMediaApi(payload);
      if (responseData.status === 200) {
        getAllSoicalMediaContent();
        openToast("Content Regerated Successfully ", "images/toast-check.svg");
        setIsLoading(false);
      }

      // const userId = userData[0]["User_Id"];
      // const data = {
      //   userId: userId,
      //   config_json: props.config,
      //   platform_selected: [platform],

      //   type: "highlight",
      //   Social_Media_Id: postGeneratedContent[0]?.Social_Media_Content_Id,
      //   account_choosed: postGeneratedContent[0]?.Account_Id,
      //   Event_Id: "",
      // };
      // const responseData = await regeneratePostApi(data);
      // if (responseData.status === 200) {
      //   getAllSoicalMediaContent();
      //   openToast("Content Regerated Successfully ", "images/toast-check.svg");
      //   setIsLoading(false);
      // }
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  async function savePostContent(platform) {
    try {
      let content;
      let ref;

      switch (platform) {
        case "Facebook_Content = ? ":
          ref = facebookRef;
          break;
        case "Instagram_Content = ? ":
          ref = instagramRef;
          break;
        case "Linkedin_Content = ? ":
          ref = linkedinRef;
          break;
        case "Twitter_Content = ? ":
          ref = twitterRef;
          break;
        default:
          throw new Error("Invalid platform");
      }

      if (ref && ref.current) {
        content = ref.current.innerHTML; // Get the innerHTML directly
      } else {
        throw new Error("Ref is null or undefined");
      }

      const payload = {
        userId: userData[0]?.["User_Id"],
        socialMediaId: location.state.socialMediaId,
        platform: platform,
        content: content,
      };

      setfacebook(false);

      const responseData = await savePostContentApi(payload);
      console.log(responseData, "Response data from the savePostContentApi");
    } catch (error) {
      console.error("Error saving post content:", error);
    }
  }

  const regenerateImage = async () => {
    try {
      setIsLoading(true);

      const imagePayload = {
        userId: userData[0]["User_Id"],
        socialMediaContentId: postGeneratedContent[0]?.Social_Media_Content_Id,
        platform: "socialmediaImage",
        config_json: props.config,
        account_choosed: config.Account_And_Platform.socialMediaAccount,
      };

      const responseData = await createImageForMediaApi(imagePayload);
      if (responseData.status === 200) {
        getAllSoicalMediaContent();
        openToast("Content Regerated Successfully ", "images/toast-check.svg");
        setIsLoading(false);
      }

      // const userId = userData[0]["User_Id"];
      // const data = {
      //   userId: userId,
      //   config_json: props.config,
      //   platform_selected: ["instagram"],

      //   // platform_selected: ["twitter", "instagram", "facebook", "linkedin"],

      //   type: "highlight",
      //   Social_Media_Id: postGeneratedContent[0]?.Social_Media_Content_Id,
      //   isSocialMediaReload: true,
      //   account_choosed: postGeneratedContent[0]?.Account_Id,
      //   Event_Id: "",
      // };
      // const responseData = await regeneratePostApi(data);
      // if (responseData.status === 200) {
      //   getAllSoicalMediaContent();
      //   openToast("Content Regerated Successfully ", "images/toast-check.svg");
      //   setIsLoading(false);
      // }
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  //   const handleDownload = () => {
  //     const imageUrl = postGeneratedContent.Social_Media_Image_Url; // Replace with your image URL
  //     fetch(imageUrl)
  //       .then((response) => response.blob())
  //       .then((blob) => {
  //         const url = window.URL.createObjectURL(blob);
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", "image.png");
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //       })
  //       .catch((error) => console.error("Error downloading image:", error));
  //   };

  //   const handleDownload = async () => {
  //     try {
  //       const imageUrl = postGeneratedContent.Social_Media_Image_Url; // Replace with your image URL
  //       const response = await fetch(imageUrl);

  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }

  //       const blob = await response.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "image.png");
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } catch (error) {
  //       console.error("Error downloading image:", error);
  //     }
  //   };

  const handleDownload = () => {
    const fileName =
      postGeneratedContent[0]?.Social_Media_Image_Url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = postGeneratedContent[0]?.Social_Media_Image_Url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-end cust-canvas"
        tabIndex={-1}
        id="promotionalContent"
        aria-labelledby="offcanvasRightLabel"
        style={{ zIndex: 1051 }}
      >
        <div className="offcanvas-header align-items-center px-4 border-bottom cust-border">
          <h5 className="font-22 font-bold">
            <img
              src="images/back-icon.svg"
              alt="back-icon"
              className="me-2 cursor-pointer"
              //   data-bs-toggle="offcanvas"
              //   data-bs-target="#highLightCanvas"
              data-bs-dismiss="offcanvas"
              aria-controls="offcanvasRight"
            />
            Promotional content
          </h5>
          <button
            type="button"
            className="btn-close text-reset box-shadow-none"
            data-bs-dismiss="offcanvas"
          />
        </div>
        <div className="offcanvas-body position-relative px-4">
          {postGeneratedContent[0]?.Facebook_Content != null ? (
            <div className="theme-border rounded-3 p-3 mb-3">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <p
                  className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
                  htmlFor="fbHighlight"
                >
                  <img src="images/facebook-post-icon.svg" />
                  Facebook post
                </p>
                {postGeneratedContent[0]?.Facebook_Post_Status !=
                "Published" ? (
                  <span className="d-flex align-items-center fon">
                    <button className="edit-btn">
                      {!faceBook ? (
                        <button
                          className="edit-btn"
                          onClick={() => {
                            setfacebook(true);
                            facebookRef?.current?.focus();
                          }}
                        >
                          <img src="images/edit-black.svg" alt="table-edit" />
                        </button>
                      ) : (
                        <button
                          className="edit-btn"
                          onClick={() => {
                            savePostContent("Facebook_Content = ? ");
                          }}
                        >
                          <img src="images/tick.svg" alt="table-edit" />
                        </button>
                      )}
                    </button>
                    <button
                      onClick={() => {
                        regeneratePost("facebook");
                      }}
                      className="edit-btn"
                    >
                      <img src="images/refresh.svg" alt="table-edit" />
                    </button>
                  </span>
                ) : null}
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: postGeneratedContent[0]?.Facebook_Content
                    ? postGeneratedContent[0]?.Facebook_Content.replace(
                        /\\u([0-9a-fA-F]{4})|\\U([0-9a-fA-F]{8})/g,
                        (match, grp1, grp2) => {
                          if (grp1) {
                            return String.fromCharCode(parseInt(grp1, 16));
                          } else if (grp2) {
                            return String.fromCodePoint(parseInt(grp2, 16));
                          }
                          return match; // Return the original match if not replaced
                        }
                      ).replace(/\\n/g, "<br />") // Replace \n with <br />
                    : "",
                }}
                ref={facebookRef}
                contentEditable={faceBook}
                className="font-14 font-bold color-grey"
              >
                {/* {postGeneratedContent[0]?.Facebook_Content} */}
              </p>
              {userData[0]["Role_Name"] == "Content Manager" ? (
                <div className="d-flex justify-content-end">
                  {postGeneratedContent[0]?.Facebook_Post_Status ==
                  "Published" ? (
                    <button class="btn cust-btn-success font-bold font-16 text-nowrap  box-shadow-none">
                      Published
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        postFaceBook();
                      }}
                      className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                    >
                      Post
                    </button>
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
          {postGeneratedContent[0]?.Linkedin_Content != null ? (
            <div className="theme-border rounded-3 p-3 mb-3">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <p
                  className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
                  htmlFor="fbHighlight"
                >
                  <img src="images/linkedin-post-icon.svg" />
                  LinkedIn post
                </p>
                {postGeneratedContent[0]?.Linkedin_Post_Status !=
                "Published" ? (
                  <span className="d-flex align-items-center fon">
                    <button className="edit-btn">
                      {!linkedin ? (
                        <button
                          className="edit-btn"
                          onClick={() => {
                            setLinkedin(true);
                            linkedinRef?.current?.focus();
                          }}
                        >
                          <img src="images/edit-black.svg" alt="table-edit" />
                        </button>
                      ) : (
                        <button
                          className="edit-btn"
                          onClick={() => {
                            savePostContent("Linkedin_Content = ? ");
                          }}
                        >
                          <img src="images/tick.svg" alt="table-edit" />
                        </button>
                      )}
                    </button>
                    <button
                      onClick={() => {
                        regeneratePost("linkedin");
                      }}
                      className="edit-btn"
                    >
                      <img src="images/refresh.svg" alt="table-edit" />
                    </button>
                  </span>
                ) : null}
              </div>
              <p
                className="font-14 font-bold color-grey"
                ref={linkedinRef}
                contentEditable={linkedin}
              >
                {postGeneratedContent[0]?.Linkedin_Content}
              </p>
              {userData[0]["Role_Name"] == "Content Manager" ? (
                <div className="d-flex justify-content-end">
                  {postGeneratedContent[0]?.Linkedin_Post_Status ==
                  "Published" ? (
                    <button class="btn cust-btn-success font-bold font-16 text-nowrap  box-shadow-none">
                      Published
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        postLinkedin();
                      }}
                      className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                    >
                      Post
                    </button>
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
          {postGeneratedContent[0]?.Twitter_Content != null ? (
            <div className="theme-border rounded-3 p-3 mb-3">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <p
                  className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
                  htmlFor="fbHighlight"
                >
                  <img src="images/twitter-post-icon.svg" />
                  Twitter post
                </p>
                {postGeneratedContent[0]?.Twitter_Post_Status != "Published" ? (
                  <span className="d-flex align-items-center fon">
                    <button className="edit-btn">
                      {!twitter ? (
                        <button
                          className="edit-btn"
                          onClick={() => {
                            setTwitter(true);
                            twitterRef?.current?.focus();
                          }}
                        >
                          <img src="images/edit-black.svg" alt="table-edit" />
                        </button>
                      ) : (
                        <button
                          className="edit-btn"
                          onClick={() => {
                            savePostContent("Twitter_Content = ? ");
                          }}
                        >
                          <img src="images/tick.svg" alt="table-edit" />
                        </button>
                      )}
                    </button>
                    <button
                      onClick={() => {
                        regeneratePost("twitter");
                      }}
                      className="edit-btn"
                    >
                      <img src="images/refresh.svg" alt="table-edit" />
                    </button>
                  </span>
                ) : null}
              </div>
              <p
                ref={twitterRef}
                contentEditable={twitter}
                className="font-14 font-bold color-grey"
              >
                {postGeneratedContent[0]?.Twitter_Content}
              </p>
              {userData[0]["Role_Name"] == "Content Manager" ? (
                <div className="d-flex justify-content-end">
                  {postGeneratedContent[0]?.Twitter_Post_Status ==
                  "Published" ? (
                    <button class="btn cust-btn-success font-bold font-16 text-nowrap  box-shadow-none">
                      Published
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        postTwitter();
                      }}
                      className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                    >
                      Post
                    </button>
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
          {postGeneratedContent[0]?.Instagram_Content != null ? (
            <div className="theme-border rounded-3 p-3 mb-3">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <p
                  className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
                  htmlFor="fbHighlight"
                >
                  <img src="images/insta-caption-icon.svg" />
                  Instagram Caption
                </p>
                {postGeneratedContent[0]?.Instagram_Post_Status !=
                "Published" ? (
                  <span className="d-flex align-items-center fon">
                    <button className="edit-btn">
                      {!instagram ? (
                        <button
                          className="edit-btn"
                          onClick={() => {
                            setInstagram(true);
                            instagramRef?.current?.focus();
                          }}
                        >
                          <img src="images/edit-black.svg" alt="table-edit" />
                        </button>
                      ) : (
                        <button
                          className="edit-btn"
                          onClick={() => {
                            savePostContent("Instagram_Content = ? ");
                          }}
                        >
                          <img src="images/tick.svg" alt="table-edit" />
                        </button>
                      )}
                    </button>
                    <button
                      onClick={() => {
                        regeneratePost("instagram");
                      }}
                      className="edit-btn"
                    >
                      <img src="images/refresh.svg" alt="table-edit" />
                    </button>
                  </span>
                ) : null}
              </div>
              <p
                className="font-14 font-bold color-grey"
                ref={instagramRef}
                contentEditable={instagram}
                dangerouslySetInnerHTML={{
                  __html: postGeneratedContent[0]?.Instagram_Content
                    ? postGeneratedContent[0]?.Instagram_Content.replace(
                        /\\u([0-9a-fA-F]{4})|\\U([0-9a-fA-F]{8})/g,
                        (match, grp1, grp2) => {
                          if (grp1) {
                            return String.fromCharCode(parseInt(grp1, 16));
                          } else if (grp2) {
                            return String.fromCodePoint(parseInt(grp2, 16));
                          }
                          return match; // Return the original match if not replaced
                        }
                      ).replace(/\\n/g, "<br />") // Replace \n with <br />
                    : "",
                }}
              ></p>
              {userData[0]["Role_Name"] == "Content Manager" ? (
                <div className="d-flex justify-content-end">
                  {postGeneratedContent[0]?.Instagram_Post_Status ==
                  "Published" ? (
                    <button class="btn cust-btn-success font-bold font-16 text-nowrap  box-shadow-none">
                      Published
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        postInstagram();
                      }}
                      className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                    >
                      Post
                    </button>
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="theme-border rounded-3 p-3 mb-3">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <p
                className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
                htmlFor="fbHighlight"
              >
                Social Media Image
              </p>
              <span className="d-flex align-items-center fon">
                <button
                  onClick={() => {
                    handleDownload();
                  }}
                  className="edit-btn"
                >
                  <img src="images/download-icon.svg" alt="table-edit" />
                </button>
                <button
                  onClick={() => {
                    regenerateImage();
                  }}
                  className="edit-btn"
                >
                  <img src="images/refresh.svg" alt="table-edit" />
                </button>
              </span>
            </div>
            <img
              src={postGeneratedContent[0]?.Social_Media_Image_Url}
              alt="ai-gen-img"
              className="social-media-gen mb-3"
            />
          </div>
        </div>
      </div>
    </>
  );
}
