import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function CreateWebPagesModal(props) {
  const location = useLocation();
  const [webpageType, setWebpageType] = useState("");
  const [webpageTypeId, setWebpageTypeId] = useState("");
  const contentTypeId = location?.state?.contentTypeId;
  const contentType = location.state?.contentType;

  const navigate = useNavigate();

  console.log(webpageType, setWebpageType);

  return (
    <div
      className="modal fade"
      id="createWebPages"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="createnewaccLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content popup-content py-2 px-3">
          <div className="modal-header border-0">
            <h5
              className="modal-title font-18 font-bold popup-header-color"
              id="createnewaccLabel"
            >
              Create Web Pages
              <span className="d-block font-14 font-regular color-grey">
                What type of web page you want to create?
              </span>
            </h5>
            <button
              type="button"
              className="btn-close shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body border-0">
            {props.webpageTypes.map((value, index) => (
              <label
                key={index}
                className="popup-radio d-flex align-items-center gap-3 mb-3"
                htmlFor={value.Webpage_Types.toLowerCase()}
              >
                <input
                  className="form-check-input mt-0 green-radio"
                  type="radio"
                  name="createWebPage"
                  id={value.Webpage_Types.toLowerCase()}
                  checked={webpageTypeId == value.Webpage_Type_Id}
                  onClick={() => {
                    setWebpageType(value.Webpage_Types);
                    setWebpageTypeId(value.Webpage_Type_Id);
                  }}
                />
                <img
                  src={`images/${value.Webpage_Types.toLowerCase()}-green-icon.svg`}
                  alt={`${value.Webpage_Types.toLowerCase()}-green-icon`}
                  className="ms-2"
                />
                <label
                  className="form-check-label"
                  htmlFor={value.Webpage_Types.toLowerCase()}
                >
                  <span className="d-block font-14 font-bold">
                    {value.Webpage_Types}
                  </span>
                  <span className="d-block font-14 font-regular color-grey">
                    {value.Webpage_Types} inputs will be there
                  </span>
                </label>
              </label>
            ))}
          </div>
          <div className="modal-footer border-0">
            <button
              className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
              data-bs-dismiss="modal"
              onClick={() => {
                navigate("/createcontent", {
                  state: {
                    contentTypeId: contentTypeId,
                    contentName: "",
                    configurationJson: {},
                    contentType: contentType,
                    contentId: "",
                    webpagetype: webpageType,
                    webpageTypeId: webpageTypeId,
                  },
                });
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateWebPagesModal;
