import React from 'react';
import { useCreateProductContext } from '../../../contexts/CreateProductContext';

const AwsReview = () => {
  // if (!awsArray || awsArray.length === 0) {
  //   return <span className="compare-header-color">NA</span>;
  // }
  // const renderReviews = (averageRating) => {
  //   if (averageRating === null) {
  //     return <span className="compare-header-color">NA</span>;
  //   }

  //   const [rating, reviews] = averageRating.split(' ');
  //   const filledStars = Math.round(parseFloat(rating) / 2); // Calculate filled stars out of 5

  //   return (
  //     <span className="d-flex align-items-center gap-1">
  //       {[...Array(filledStars)].map((_, index) => (
  //         <span key={`filled-${index}`}><img src="images/rating-filled-icon.svg" alt="rating-filled-icon" /></span>
  //       ))}
  //       {[...Array(5 - filledStars)].map((_, index) => (
  //         <span key={`unfilled-${index}`}><img src="images/rating-unfilled-icon.svg" alt="rating-unfilled-icon" /></span>
  //       ))}
  //       <span className="mt-2">{reviews} reviews</span>
  //     </span>
  //   );
  // };

  const {
    azureReviewArray,
    setAzureReviewArray,
    azurePopupArray,
    setAzurePopupArray } = useCreateProductContext()


    function renderStars(averageStars) {
      let coundOfFilledStars = 0;
      if (!Number.isNaN(averageStars)) {
        coundOfFilledStars = Math.round(Number(averageStars))
      }
  
      let indexArray = [1, 2, 3, 4, 5]
      return indexArray.map((values, index) => {
        console.log(values, "This is the value of the stars")
        return values <= coundOfFilledStars ? <img src="images/rating-filled-icon.svg" alt="rating-filled-icon" className={(values == 5) ? "me-2" : ""} />: <img src="images/rating-unfilled-icon.svg" alt="rating-unfilled-icon" className={(values == 5) ? "me-2" : ""} />
      })
    }
  return (
    <tr>
      <td className="compare-header-color">AWS marketplace reviews</td>
      {
        azureReviewArray.map((value,index) => {
          return <td className="vertical-align-middle">
          <span className="d-flex align-items-center gap-1">
            {
              renderStars(value.Aws_Average ? value.Aws_Average : 0)
            }
            <span className="mt-2">{value.Aws_Ratings_reviews ? value.Aws_Ratings_reviews : "0" } reviews</span>
          </span>
        </td>
        })
      }
    </tr>
  );
};

export default AwsReview;
