import React, { useEffect, useState } from "react";
import { useMyEventContext } from "../../contexts/MyEventContext";
import moment from "moment";

export default function DateNavigationButtons() {
  const {
    month,
    setMonth,
    year,
    setYear,
    isDayView,
    currentDateForEv,
    setCurrentDateForEv,
    myEvents,
    setCurrentDateEvent,
  } = useMyEventContext();

  const handlePrevMonth = () => {
    if (isDayView) {
      const prevDate = new Date(currentDateForEv);
      prevDate.setDate(prevDate.getDate() - 1);
      setCurrentDateForEv(prevDate.toISOString().split("T")[0]);
      if (month != prevDate.getMonth() + 1) {
        setMonth(prevDate.getMonth() + 1);
      }
      if (year != prevDate.getFullYear()) {
        setYear(prevDate.getFullYear());
      }
    } else {
      if (month === 1) {
        setMonth(12);
        setYear(year - 1);
      } else {
        setMonth(month - 1);
      }
    }
  };

  const handleNextMonth = () => {
    if (isDayView) {
      const nextDate = new Date(currentDateForEv);
      nextDate.setDate(nextDate.getDate() + 1);
      setCurrentDateForEv(nextDate.toISOString().split("T")[0]);
      if (month != nextDate.getMonth() + 1) {
        setMonth(nextDate.getMonth() + 1);
      }
      if (year != nextDate.getFullYear()) {
        setYear(nextDate.getFullYear());
      }
    } else {
      if (month === 12) {
        setMonth(1);
        setYear(year + 1);
      } else {
        setMonth(month + 1);
      }
    }
  };

  const handleTodayClick = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    setMonth(parseInt(currentDate.split("-")[1]));
    setYear(parseInt(currentDate.split("-")[0]));

    if (isDayView) {
      setCurrentDateForEv(currentDate);
    }
  };

  const getCurrentDateEvents = () => {
    const filteredEvents = myEvents.filter((event) => {
      const eventDate = moment(event.Event_From_Date);
      return eventDate.format("YYYY-MM-DD") === currentDateForEv;
    });

    const groupedEvents = {};

    filteredEvents.forEach((event) => {
      const eventTime = event.Event_From_Time;
      if (!groupedEvents[eventTime]) {
        groupedEvents[eventTime] = [];
      }
      groupedEvents[eventTime].push(event);
    });

    const groupedEventsList = Object.entries(groupedEvents).map(
      ([key, value]) => ({
        Event_From_Time: key,
        Other_Event_Details: value,
      })
    );

    setCurrentDateEvent(groupedEventsList);
  };
  useEffect(() => {
    getCurrentDateEvents();
  }, [currentDateForEv, myEvents]);

  return (
    <div className="d-flex gap-2">
      <button
        className="btn shadow-btn d-flex align-items-center py-2 px-3 "
        onClick={handlePrevMonth}
      >
        <img src="images/left-arrow.svg" alt="left-arrow" />
      </button>
      <button
        className="btn shadow-btn d-flex align-items-center py-2 px-3  font-bold font-12"
        onClick={handleTodayClick}
      >
        {isDayView ? "Today" : "This month"}
      </button>
      <button
        className="btn shadow-btn d-flex align-items-center py-2 px-3 "
        onClick={handleNextMonth}
      >
        <img
          src="images/left-arrow.svg"
          alt="left-arrow"
          className="transform-180"
        />
      </button>
    </div>
  );
}
