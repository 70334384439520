import { useEffect } from "react";
import { useMyEventContext } from "../../contexts/MyEventContext";
import moment from "moment";

export default function MonthlyCalender() {
  const { dateAndDays, currentDateForEv, setCurrentDateForEv } =
    useMyEventContext();

  return (
    <div className="col-md-3 ">
      <div className="mt-4 cal-table">
        <table className="table font-14 table-borderless">
          <thead className="font-regular">
            <tr>
              <th>
                <span>S</span>
              </th>
              <th>
                <span>M</span>
              </th>
              <th>
                <span>T</span>
              </th>
              <th>
                <span>W</span>
              </th>
              <th>
                <span>T</span>
              </th>
              <th>
                <span>F</span>
              </th>
              <th>
                <span>S</span>
              </th>
            </tr>
          </thead>
          <tbody className="font-bold">
            {dateAndDays.map((values, index) => {
              console.log(values, "Tes");
              return (
                <tr>
                  {values.map((date, index) => {
                    console.log(date, "This is date");
                    return (
                      <td
                        className={
                          !date.fullDate
                            ? "dis"
                            : date?.date == moment(currentDateForEv).date()
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          if (date.fullDate) {
                            setCurrentDateForEv(date?.fullDate);
                          } else {
                          }
                        }}
                      >
                        <span>{date?.date}</span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
