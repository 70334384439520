import { useNavigate } from "react-router-dom";
import { useCommonContext } from "../../contexts/CommonContext";
import { useEffect, useState } from "react";
import {
  getAllUpcomingEventsHubApi,
  getDropDownDetailsApi,
  insertUpcomingEventsStatusApi,
} from "../../services/api";
import moment from "moment";

export default function UpcommingEventsHub() {
  const [upcommingevents, setUpcomingEvents] = useState([]);
  const { userData } = useCommonContext();
  const [whichClick, setWhichClick] = useState({});
  const [rvspTypes, setRvspTypes] = useState([]);

  async function getUpcommingEvents() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        count: 4,
      };

      const responseData = await getAllUpcomingEventsHubApi(payload);
      setUpcomingEvents(responseData.data.data.data);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getUpcommingEvents();
  }, []);

  async function getDropDownData() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        tableName: "RVSP_Types",
      };

      const responseData = await getDropDownDetailsApi(payload);

      setRvspTypes(responseData.data.data.data);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getDropDownData();
  }, []);

  const handleRsvpDropdownChange = async (e) => {
    const value = e.target.value;

    if (value) {
      // Upcoming_Events_58
      const data = {
        userId: userData[0]["User_Id"],
        eventId: whichClick.Upcomming_Event_Id,
        rvspTypeId: value,
      };
      // Upcoming_Events_59

      try {
        // Upcoming_Events_61
        const response = await insertUpcomingEventsStatusApi(data);
        // Upcoming_Events_64

        if (response.status == 200) {
          console.log("response success true");
          document.getElementById("EventsDetClose").click();
          getUpcommingEvents();
        }
        e.target.value = "";
      } catch (error) {
        // Upcoming_Events_74
        console.error(error);
      }
    }
  };

  return (
    <>
      {upcommingevents.length != 0 ? (
        <div className id="upcomingContaimer" style={{ display: "block" }}>
          {upcommingevents.map((event, index) => {
            return (
              <div className="bg-white border-0 shadow-sm py-2 px-4 radius-16 mb-3 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-3">
                  <div className="border-end pe-3 text-center">
                    <span className="font-bold font-12 d-block">
                      {moment(event?.Event_From_Date).format("MMM")}
                    </span>
                    <span className="font-bold font-22 d-block">
                      {moment(event?.Event_From_Date).format("DD")}
                    </span>
                  </div>
                  <div>
                    <span className="font-bold font-12 d-block mb-2 ">
                      {event?.Event_Name}
                    </span>
                    <span className="d-flex align-items-center font-medium font-12 gap-3 color-grey">
                      <span className="d-flex align-items-center gap-2">
                        <img
                          src="images/clock-green-icon.svg"
                          alt="clock-green-icon"
                        />
 {event?.Event_From_Time && event?.Event_To_Time ? 
    `${event?.Event_From_Time}-${event?.Event_To_Time}` : 
    event?.Event_From_Time ? event?.Event_From_Time : '-'
}

                      </span>
                      <span className="d-flex align-items-center gap-2">
                        <img
                          src="images/video-green-icon.svg"
                          alt="clock-green-icon"
                        />
                        {event?.Location ? event?.Location : "Online"}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3">
                  {event?.User_Id_List?.split(",").includes(
                    userData[0]["User_Id"]
                  ) ? (
                    <span className="status-green py-1 px-2 font-bold font-12 rounded-pill">
                      Attending
                    </span>
                  ) : null}

                  <button
                    className="btn shadow-btn d-flex align-items-center py-2 px-3  font-medium font-12"
                    data-bs-toggle="modal"
                    data-bs-target="#eventDetails"
                    onClick={() => {
                      setWhichClick(event);
                    }}
                  >
                    Event details
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className="h-100"
          id="upcomingContaimer"
          style={{ display: "block" }}
        >
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="d-block text-center">
              <span className="font-bold font-14 d-block mb-3">
                <img src="images/no-events-icon.svg" alt="no-post-icon" />
              </span>
              <span className="font-bold font-14 d-block">
                No Upcoming Events
              </span>
              <span className="font-bold font-12 d-block mb-3 color-grey">
                No recent posts have been made
              </span>
              <a className="font-bold font-12 d-block blue-link text-decoration-none">
                View My Upcoming Events
              </a>
            </div>
          </div>
        </div>
      )}
      <div
        className="modal fade"
        id="eventDetails"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <h5
                className="font-18 font-bold popup-header-color mb-0"
                id="AddmanuallyLabel"
              >
                {whichClick?.Event_Name}
              </h5>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                id="EventsDetClose"
                aria-label="Close"
              />
            </div>
            <div className="modal-body border-0">
              <p className="font-medium font-16 mb-0">Description</p>
              <p className="font-regular font-16 color-grey mb-4">
                {whichClick?.Event_Description}
              </p>
              <div className="row ">
                <div className="col-md-4">
                  <p className="font-medium font-16 mb-0">
                    Start date & time
                  </p>
                  <p className="font-regular font-16 color-grey mb-4">
                    {whichClick.Event_From_Time} {whichClick.Event_From_Time}
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="font-medium font-16 mb-0">
                    End date & time
                  </p>
                  <p className="font-regular font-16 color-grey mb-4">
                    {whichClick.Event_To_Time} {whichClick.Event_To_Time}
                  </p>
                </div>
              </div>
              <div className="modal-footer border-0 d-flex align-items-center flex-nowrap ps-0 justify-content-between">
                <select
                  className="form-select custom-form  font-14 font-bold w-auto  font-18"
                  id="eventType"
                  value={
                    whichClick?.User_Id_List?.split(",").includes(
                      userData[0]["User_Id"]
                    )
                      ? rvspTypes.filter((value) => {
                          return value.Rvsp_Type == "Attending";
                        })[0]["Rvsp_Type_Id"]
                      : ""
                  }
                  onChange={(e) => {
                    if (e.target.value != "") {
                      handleRsvpDropdownChange(e);
                    }
                  }}
                >
                  <option value={""} className="font-bold">
                    RSVP
                  </option>
                  {rvspTypes.map((rvsp, index) => {
                    return (
                      <option value={rvsp?.Rvsp_Type_Id}>
                        {rvsp?.Rvsp_Type}
                      </option>
                    );
                  })}
                </select>
                <div className="d-flex align-items-center gap-3">
                  <button
                    className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {whichClick.Event_Url ? (
                    <button
                      type="button"
                      className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        window.open(whichClick.Event_Url, "_blank");
                      }}
                    >
                      More Info
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}