import React, { useEffect, useState } from "react";
import { getAllProductsApi } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { useCommonContext } from "../../contexts/CommonContext";
import Loader from "../Loader/Loader";

export default function ProductContrast() {
    const [sortColumn, setSortColumn] = useState('');
    const [sortOrder, setSortOrder] = useState(false);
    const [count, setCount] = useState(7);
    const [isLoading, setIsLoading] = useState(false);
    const [rowCount, setRowCount] = useState();
    const [productData, setProductData] = useState([]);

   

    const { setSelectedCompetitors,name } = useCreateProductContext();
    const { userData, isNavCollapsed } = useCommonContext();
    const navigate = useNavigate();

    useEffect(() => {
        getAllProducts();
        setSelectedCompetitors([]);
    }, [sortColumn, sortOrder, count]);

    const getAllProducts = async () => {
        try {
            const payload = {
                userId: userData[0]["User_Id"],
                orderByColumn: sortColumn,
                orderByDirection: sortOrder ? "ASC" : "DESC",
                dataCount: count
            };
            setIsLoading(true);
            const responseData = await getAllProductsApi(payload);
            setIsLoading(false);
            setProductData(responseData.data.data);
            setRowCount(responseData.data.data[0].Total_Row_Count);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    const loadMore = () => {
        if (rowCount > count) {
            setCount(prevCount => prevCount + 10);
        }
    };


    return (
        <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
            {isLoading && <Loader />}
            <div className="row">
                <div className="col-md-12">
                    {!isLoading && (
                        <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
                            <h2 className="font-bold font-22 mb-0">Product Contrast </h2>
                            <div className="d-flex gap-3">
                                <button className="btn primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                                    onClick={() => {
                                        navigate("/product", {
                                            state: {
                                                productId: null,
                                            },
                                        });
                                    }}
                                >
                                    <img src="images/add-icon.svg" alt="add-icon" /> New Product Contrast
                                </button>
                            </div>
                        </div>
                    )}
                    {!isLoading && (
                        <div className="table-responsive theme-tbl">
                            {
                                (productData.length === 0 && !isLoading) ?
                                    <table className="table table-borderless">
                                        <tbody className="font-medium">
                                            <tr className="grid-acc-open-brd">
                                                <td className="ps-3 text-center">
                                                    <span>
                                                        <img src="images/no-product.svg" alt="no-product" className="my-3" />
                                                    </span>
                                                    <h5 className="font-14 font-bold primary-darkblue mt-1">No Products Compared</h5>
                                                    <p className="font-12 font-regular grid-norec-gray">It looks like you haven't compared any product yet!</p>
                                                    <button className="border-0 font-12 font-medium px-0 text-blue-btn" type="button"
                                                        onClick={() => {
                                                            navigate("/product", {
                                                                state: {
                                                                    productId: null,
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        Create New Product
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    :
                                    <table className="table table-borderless" hidden={isLoading}>
                                        <thead className="font-medium bg-white">
                                            <tr>
                                                <th>
                                                    Product Name
                                                    <img
                                                        className="cursor-pointer ms-2"
                                                        src={
                                                            sortOrder && sortColumn === "Product_Name"
                                                                ? "images/sort-up.svg"
                                                                : "images/sort-down.svg"
                                                        }
                                                        alt="sort-up"
                                                        onClick={() => {
                                                            setSortOrder(!sortOrder);
                                                            setSortColumn("Product_Name");
                                                        }}
                                                    />
                                                </th>
                                                <th>Products Compared</th>
                                                <th>
                                                    Last Comparison Date
                                                    <img
                                                        className="cursor-pointer ms-2"
                                                        src={
                                                            sortOrder && sortColumn === "Last_Compared_Date"
                                                                ? "images/sort-up.svg"
                                                                : "images/sort-down.svg"
                                                        }
                                                        alt="sort-up"
                                                        onClick={() => {
                                                            setSortOrder(!sortOrder);
                                                            setSortColumn("Last_Compared_Date");
                                                        }}
                                                    />
                                                </th>

                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>

                                        <tbody className="font-medium">
                                            {productData.map((values, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <a onClick={() => {
                                                            navigate("/product", { state: { productId: values.Product_Id } });
                                                        }}>
                                                            {values.Product_Name}
                                                        </a>
                                                    </td>

                                                    <td>
                                                        <span className="d-flex align-items-center">
                                                            <div>
                                                                {values.Top_3_Logos && values.Top_3_Logos.split(',').map((logo, index) => (
                                                                    <span key={index}>
                                                                        <img src={logo.trim()} alt={""} className="grid-prod-icon me-2" />
                                                                    </span>
                                                                ))}
                                                            </div>
                                                            <span className="cursor-pointer" data-bs-toggle="tooltip" title={values.Products_Compared}>
                                                                {values.Products_Compared > 3 ? '3+' : values.Products_Compared}
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td>{values.Last_Compared_Date}</td>
                                                    <td className="text-center">
                                                        <span className="d-flex align-items-center justify-content-center gap-2">
                                                            <button className="edit-btn"><img src="images/table-edit.svg" alt="table-edit"
                                                                onClick={() => {
                                                                    navigate("/product", { state: { productId: values.Product_Id, }, });
                                                                }}
                                                            /></button>
                                                            <span className="border-sape" />
                                                            <button className="green-btn"><img src="images/commit-history-icon.svg" alt="commit-history-icon"
                                                                onClick={() => {
                                                                    navigate("/history", {
                                                                        state: {
                                                                            productId: values.Product_Id,
                                                                            productName:values.Product_Name
                                                                        },
                                                                    });
                                                                }}
                                                            /></button>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                            }
                        </div>
                    )}
                </div>
            </div>
            {!isLoading && (
                <div className="d-flex align-items-center justify-content-center mt-4 mb-4">
                    {rowCount > count && (
                        <button className="btn shadow-btn d-flex align-items-center py-2 px-3  font-bold font-16"
                            value={count}
                            disabled={rowCount <= count}
                            onClick={loadMore}>
                            Load More
                        </button>
                    )}
                </div>
            )}
        </div>

    );
}
