import { useEffect, useState } from "react";
import { getMediaPostsApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";
import { useNavigate } from "react-router-dom";

export default function RecentPosts() {
  console.info("Recepyhdshjdsjhdshjbsdhjhjsd");
  const { userData } = useCommonContext();
  const [postArray, setPostArray] = useState([]);
  const [platform, setPlatform] = useState("Instagram");

  const navigate = useNavigate()
  const getMediaPosts = async () => {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        platform: platform,
      };

      const responseData = await getMediaPostsApi(payload);

      if (responseData?.status === 200) {
        setPostArray(responseData.data.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getMediaPosts();
  }, [platform]);

  console.info(postArray,"iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
  console.info(platform,"SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS")


  return (
    <div className="bg-white p-3 radius-16 h-100">
      {postArray.length != 0 ? (
        <>
          <div className="justify-content-between d-flex align-items-center mb-3">
            <span className="font-18 font-bold">Recent Posts</span>
            <div className="dropdown">
              <button
                className="custom-form bg-white d-flex align-items-center gap-2 p-2"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={
                    platform === "Instagram"
                      ? "images/instagram-line.svg"
                      : platform === "Facebook"
                      ? "images/fb-line.svg"
                      : "images/twitter-line.svg"
                  }
                  alt="twitter-line"
                />{" "}
                <img src="images/dropdown-icon.svg" alt="dropdown-icon" />
              </button>
              <ul className="dropdown-menu ask-ai-drop position-absolute">
                <li
                  className="d-flex align-items-center p-2 gap-2 ask-drop-list cursor-pointer rounded-3 font-medium"
                  onClick={() => {
                    setPlatform("Instagram");
                  }}
                >
                  <img src="images/instagram-line.svg" alt="instagram" />
                  Instagram
                </li>
                <li
                  className="d-flex align-items-center p-2 gap-2 ask-drop-list cursor-pointer rounded-3 font-medium"
                  onClick={() => {
                    console.log("I am called from facebook");
                    setPlatform("Facebook");
                  }}
                >
                  <img src="images/fb-line.svg" alt="fb-line" />
                  FaceBook
                </li>
                <li
                  className="d-flex align-items-center p-2 gap-2 ask-drop-list cursor-pointer rounded-3 font-medium"
                  onClick={() => {
                    setPlatform("Twitter");
                  }}
                >
                  <img
                    src="images/twitter-line.svg"
                    alt="twitter-line"
                    className="ps-1"
                  />
                  Twitter
                </li>
              </ul>
            </div>
          </div>
          <div className="post-parent">
            {postArray.map((post, index) => {
              return (
                <div className="post-content mb-3">
                  <div className="d-flex align-items-center gap-2 mb-3">
                    <span className="post-icon">
                      <img
                        src={
                          platform === "Instagram"
                            ? "images/instagram-line.svg"
                            : platform === "Facebook"
                            ? "images/fb-line.svg"
                            : "images/twitter-line.svg"
                        }
                        alt="cloudgen-icon"
                      />
                    </span>
                    <span>
                      <span className="d-block font-bold font-16">
                        {post?.name}
                        {console.info(post.name,"CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC")}
                      </span>
                      <span className="d-block  font-medium font-12 color-grey">
                        @{post?.userName}
                      </span>
                    </span>
                  </div>
                  <p className="font-14 font-regular" style={{ lineHeight: 2 }}>
                    {post?.caption
                      ? post?.caption
                          .replace(
                            /\\u([0-9a-fA-F]{4})|\\U([0-9a-fA-F]{8})/g,
                            (match, grp1, grp2) => {
                              if (grp1) {
                                return String.fromCharCode(parseInt(grp1, 16));
                              } else if (grp2) {
                                return String.fromCodePoint(parseInt(grp2, 16));
                              }
                              return match; // Return the original match if not replaced
                            }
                          )
                          .replace(/\\n/g, "\n") // Replace \n with newline character (\n)
                      : ""}
                  </p>
                  <div className="d-flex align-items-center justify-content-between mt-4 border-bottom pb-4">
                    <button className="edit-btn">
                      <img src="images/comment-icon.svg" alt="table-edit" />
                    </button>
                    <button className="edit-btn">
                      <img src="images/retweet-icon.svg" alt="table-edit" />
                    </button>
                    <button className="edit-btn">
                      <img src="images/heart-icon.svg" alt="table-edit" />
                    </button>
                    <button className="edit-btn">
                      <img src="images/share-icon.svg" alt="table-edit" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className="justify-content-between d-flex align-items-center mb-3">
            <span className="font-18 font-bold">Recent Posts</span>
            <div className="dropdown">
              <button
                className="custom-form bg-white d-flex align-items-center gap-2 p-2"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={
                    platform === "Instagram"
                      ? "images/instagram-line.svg"
                      : platform === "Facebook"
                      ? "images/fb-line.svg"
                      : "images/twitter-line.svg"
                  }
                  alt="twitter-line"
                />{" "}
                <img src="images/dropdown-icon.svg" alt="dropdown-icon" />
              </button>
              <ul className="dropdown-menu ask-ai-drop position-absolute">
                <li
                  className="d-flex align-items-center p-2 gap-2 ask-drop-list cursor-pointer rounded-3 font-medium"
                  onClick={() => {
                    setPlatform("Instagram");
                  }}
                >
                  <img src="images/instagram-line.svg" alt="instagram" />
                  Instagram
                </li>
                <li
                  className="d-flex align-items-center p-2 gap-2 ask-drop-list cursor-pointer rounded-3 font-medium"
                  onClick={() => {
                    console.log("I am called from facebook");
                    setPlatform("Facebook");
                  }}
                >
                  <img src="images/fb-line.svg" alt="fb-line" />
                  FaceBook
                </li>
                <li
                  className="d-flex align-items-center p-2 gap-2 ask-drop-list cursor-pointer rounded-3 font-medium"
                  onClick={() => {
                    setPlatform("Twitter");
                  }}
                >
                  <img
                    src="images/twitter-line.svg"
                    alt="twitter-line"
                    className="ps-1"
                  />
                  Twitter
                </li>
              </ul>
            </div>
          </div>

          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="d-block text-center">
              <span className="font-bold font-14 d-block mb-3">
                <img src="images/no-post-icon.svg" alt="no-post-icon" />
              </span>
              <span className="font-bold font-14 d-block">No Recent Post</span>
              <span className="font-bold font-12 d-block mb-3 color-grey">
                No recent posts have been made
              </span>
              <a className="font-bold font-12 d-block blue-link text-decoration-none"  style={{ cursor: 'pointer' }} onClick={() => {
                      navigate("/createsocialmediacontent", {
                        state: {
                          contentTypeId: "1436759C-49D5-4369-A0BA-FE444D4E80C7",
                          contentName: "",
                          configurationJson: {},
                          contentType: "Social Media Post",
                          socialMediaId: "",
                          contentId: "",
                        },
                      });
                    }}>
                Create Post
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
