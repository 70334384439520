import { useRef, useState } from "react";
import { useCommonContext } from "../../contexts/CommonContext";
import { useCreateMyEventContext } from "../../contexts/CreateMyEventContext";
import {
  createContentForMediaApi,
  createEventPostsApi,
  createImageForMediaApi,
  getEventPostsApi,
  postFaceBookApi,
  postInstagramApi,
  postLinkedInApi,
  postTwitterApi,
  savePostContentApi,
} from "../../services/api";
import Loader from "../Loader/Loader";

export default function EventSocialMedia() {
  const [faceBook, setfacebook] = useState(false);
  const facebookRef = useRef(null);
  const [instagram, setInstagram] = useState(false);
  const instagramRef = useRef(null);
  const [linkedin, setLinkedin] = useState(false);
  const linkedinRef = useRef(null);
  const [twitter, setTwitter] = useState(false);
  const twitterRef = useRef(null);

  const { userData } = useCommonContext();

  const {
    socialMediaContent,
    formData,
    meetingLink,
    eventId,
    socialMediaId,
    setSocialMediaContent,
    isLoading,
    setIsLoading
    
  } = useCreateMyEventContext();
  const { openToast } = useCommonContext();

  async function regeneratePost(platform) {
    try {
      let newFormData = formData;
      newFormData["meetingUrl"] = meetingLink;
      const payload = {
        configJson: newFormData,
        userId: userData[0]["User_Id"],
        socialMediaId: socialMediaId,
        platform: platform,
        account_choosed: formData.accountName,
      };
      await createContentForMediaApi(payload);

      const getPayload = {
        userId: userData[0]["User_Id"],
        socialMediaId: socialMediaId,
      };
      
      const getEventRes = await getEventPostsApi(getPayload);
      

      setSocialMediaContent(getEventRes.data.data.data);

      openToast(
        "The event has been successfully created",
        "images/toast-check.svg"
      );
    } catch (error) {}
  }

  async function regenerateImage() {
    try {
      const imagePayload = {
        userId: userData[0]["User_Id"],
        socialMediaContentId: socialMediaId,
        platform: "socialmediaImage",
        config_json: formData,
        account_choosed: formData.accountName,
      };
      // Push each API call promise to the array
      const respnse = await createImageForMediaApi(imagePayload);
      const getPayload = {
        userId: userData[0]["User_Id"],
        socialMediaId: socialMediaId,
      };
      
      
      const getEventRes = await getEventPostsApi(getPayload);
      // setIsLoading(false)

      setSocialMediaContent(getEventRes.data.data.data);
    } catch (error) {
      console.error(error.message);
    }
  }

  // const downloadImage = () => {
  //   const blobUrl = socialMediaContent[0]?.Social_Media_Image_Url;
  //   const filename = "eventName.jpg"; // Replace with the desired filename
  //   const anchor = document.createElement("a");
  //   anchor.href = blobUrl;
  //   anchor.download = filename;
  //   anchor.click();
  //   URL.revokeObjectURL(blobUrl);
  // };

  const downloadImage = () => {
    const blobUrl = socialMediaContent[0]?.Social_Media_Image_Url; // Replace with your actual blob URL
    const filename = formData.eventName + ".jpg"; // Replace with the desired filename
    const anchor = document.createElement("a");
    anchor.href = blobUrl;
    anchor.download = filename; // Set the download attribute
    anchor.style.display = "none"; // Hide the anchor element
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor); // Clean up the anchor element
    URL.revokeObjectURL(blobUrl);
  };

  async function savePostContent(platform) {
    try {
      let content;
      let ref;

      switch (platform) {
        case "Facebook_Content = ? ":
          ref = facebookRef;
          break;
        case "Instagram_Content = ? ":
          ref = instagramRef;
          break;
        case "Linkedin_Content = ? ":
          ref = linkedinRef;
          break;
        case "Twitter_Content = ? ":
          ref = twitterRef;
          break;
        default:
          throw new Error("Invalid platform");
      }

      console.info(content);

      if (ref && ref.current) {
        content = Array.from(ref.current.children)
          .map((child) => child.textContent)
          .join("");
      } else {
        throw new Error("Ref is null or undefined");
      }

      console.info(content);

      const payload = {
        userId: userData[0]["User-Id"],
        socialMediaId: socialMediaId,
        platform: platform,
        content: content,
      };

      setfacebook(false);

      const responseData = await savePostContentApi(payload);

      const getPayload = {
        userId: userData[0]["User_Id"],
        socialMediaId: socialMediaId,
      };
          
      // setIsLoading(true)

      const getEventRes = await getEventPostsApi(getPayload);
      // setIsLoading(false)

      setSocialMediaContent(getEventRes.data.data.data);
    } catch (error) {
      console.error("Error saving post content:", error);
    }
  }

  const postInstagram = async () => {
    try {
      const data = {
        userId: userData[0]?.["User_Id"],
        image_url: socialMediaContent[0]?.Social_Media_Image_Url,
        content_id: socialMediaContent[0]?.Social_Media_Content_Id,
        account_id: socialMediaContent[0]?.Account_Id,
        caption: socialMediaContent[0]?.Instagram_Content,
      };
      const responseData = await postInstagramApi(data);

      const getPayload = {
        userId: userData[0]["User_Id"],
        socialMediaId: socialMediaId,
      };
      // setIsLoading(true)

      const getEventRes = await getEventPostsApi(getPayload);
      // setIsLoading(false)

      setSocialMediaContent(getEventRes.data.data.data);
      if (responseData.status === 200) {
      }
    } catch (error) {
      // openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  const postFaceBook = async () => {
    try {
      const data = {
        userId: userData[0]?.["User_Id"],
        image_url: socialMediaContent[0]?.Social_Media_Image_Url,
        contentId: socialMediaContent[0]?.Social_Media_Content_Id,
        Account_Id: socialMediaContent[0]?.Account_Id,
        caption_message: socialMediaContent[0]?.Facebook_Content,
        // caption_message, Account_Id, contentId"
      };
      const responseData = await postFaceBookApi(data);

      const getPayload = {
        userId: userData[0]["User_Id"],
        socialMediaId: socialMediaId,
      };

      const getEventRes = await getEventPostsApi(getPayload);
      // setIsLoading(false)

      setSocialMediaContent(getEventRes.data.data.data);
      if (responseData.status === 200) {
        // openToast(
        //   "FaceBook Content Posted Successfully ",
        //   "images/toast-check.svg"
        // );
      }
    } catch (error) {
      // openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  const postTwitter = async () => {
    try {
      const data = {
        userId: userData[0]?.["User_Id"],
        imageUrl: socialMediaContent[0]?.Social_Media_Image_Url,
        contentId: socialMediaContent[0]?.Social_Media_Content_Id,
        accountId: socialMediaContent[0]?.Account_Id,
        caption: socialMediaContent[0]?.Twitter_Content,
      };
      const responseData = await postTwitterApi(data);
      const getPayload = {
        userId: userData[0]["User_Id"],
        socialMediaId: socialMediaId,
      };
      // setIsLoading(true)
      const getEventRes = await getEventPostsApi(getPayload);
      // setIsLoading(false)

      setSocialMediaContent(getEventRes.data.data.data);
      if (responseData.status === 200) {
        // openToast(
        //   "Twitter Content Posted Successfully ",
        //   "images/toast-check.svg"
      }
    } catch (error) {
      // openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };
  {
    console.info(
      socialMediaContent[0]["Facebook_Post_Status"] !== "Published",
      "social media posttttttttttttttttttttttttttttttttt"
    );
  }

  const postLinkedin = async () => {
    try {
      const data = {
        userId: userData[0]?.["User_Id"],
        image_url: socialMediaContent[0]?.Social_Media_Image_Url,
        content_id: socialMediaContent[0]?.Social_Media_Content_Id,
        account_id: socialMediaContent[0]?.Account_Id,
        caption: socialMediaContent[0]?.Linkedin_Content,
      };
      const responseData = await postLinkedInApi(data);
      const getPayload = {
        userId: userData[0]["User_Id"],
        socialMediaId: socialMediaId,
      };
      // setIsLoading(true)

      const getEventRes = await getEventPostsApi(getPayload);
      // setIsLoading(false)

      setSocialMediaContent(getEventRes.data.data.data);
      if (responseData.status === 200) {
      }
    } catch (error) {}
  };

  return (
    
    <>
    {isLoading && <Loader />}
      <h3 className="font-24 font-bold mb-4 px-0">Social Media Posts</h3>
      {/* Facebook */}
      {socialMediaContent[0]["Facebook_Content"] ? (
        <div className="card border-0 shadow-sm p-4 radius-16 mb-4">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <p
              className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
              htmlFor="fbHighlight"
            >
              <img src="images/facebook-post-icon.svg" alt="Facebook post" />
              Facebook post
            </p>
            {/* Edit buttons conditionally rendered */}
            {socialMediaContent[0]["Facebook_Post_Status"] !== "Published" && (
              <span className="d-flex align-items-center fon">
                {!faceBook ? (
                  <button
                    className="edit-btn"
                    onClick={() => {
                      setfacebook(true);
                      facebookRef.current.focus();
                    }}
                  >
                    <img src="images/edit-black.svg" alt="table-edit" />
                  </button>
                ) : (
                  <button
                    className="edit-btn"
                    onClick={() => {
                      savePostContent("Facebook_Content = ? ");
                    }}
                  >
                    <img src="images/tick.svg" alt="table-edit" />
                  </button>
                )}
                <button
                  className="edit-btn"
                  onClick={() => {
                    regeneratePost("facebook");
                  }}
                >
                  <img src="images/refresh.svg" alt="table-edit" />
                </button>
              </span>
            )}
          </div>
          {/* Facebook content */}
          <p
            className="font-14 font-bold color-grey"
            contentEditable={faceBook}
            style={{
              outlineColor: faceBook ? "#fc845c" : "transparent",
            }}
            ref={facebookRef}
            dangerouslySetInnerHTML={{
              __html: socialMediaContent[0]["Facebook_Content"]
                ? socialMediaContent[0]["Facebook_Content"]
                    .replace(
                      /\\u([0-9a-fA-F]{4})|\\U([0-9a-fA-F]{8})/g,
                      (match, grp1, grp2) => {
                        if (grp1) {
                          return String.fromCharCode(parseInt(grp1, 16));
                        } else if (grp2) {
                          return String.fromCodePoint(parseInt(grp2, 16));
                        }
                        return match; // Return the original match if not replaced
                      }
                    )
                    .replace(/\\n/g, "<br />") // Replace \n with <br />
                : "",
            }}
          ></p>
          <div className="d-flex justify-content-end">
            {/* Post button conditionally rendered */}
            {socialMediaContent[0]["Facebook_Post_Status"] !== "Published" ? (
              <button
                className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none"
                onClick={() => {
                  postFaceBook();
                }}
              >
                Post
              </button>
            ) : (
              <button className="btn cust-btn-success font-bold font-16 text-nowrap box-shadow-none">
                Published
              </button>
            )}
          </div>
        </div>
      ) : null}

      {socialMediaContent[0]["Instagram_Content"] ? (
        <div className="card border-0 shadow-sm p-4 radius-16 mb-4">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <p
              className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
              htmlFor="fbHighlight"
            >
              <img src="images/insta-caption-icon.svg" alt="Facebook post" />
              Instagram Caption
            </p>
            {/* Edit buttons conditionally rendered */}
            {socialMediaContent[0]["Instagram_Post_Status"] !== "Published" && (
              <span className="d-flex align-items-center fon">
                {!instagram ? (
                  <button
                    className="edit-btn"
                    onClick={() => {
                      setInstagram(true);
                      instagramRef.current.focus();
                    }}
                  >
                    <img src="images/edit-black.svg" alt="table-edit" />
                  </button>
                ) : (
                  <button
                    className="edit-btn"
                    onClick={() => {
                      savePostContent("Instagram_Content = ? ");
                    }}
                  >
                    <img src="images/tick.svg" alt="table-edit" />
                  </button>
                )}
                <button
                  className="edit-btn"
                  onClick={() => {
                    regeneratePost("instagram");
                  }}
                >
                  <img src="images/refresh.svg" alt="table-edit" />
                </button>
              </span>
            )}
          </div>
          {/* Facebook content */}
          <p
            className="font-14 font-bold color-grey"
            contentEditable={instagram}
            style={{
              outlineColor: instagram ? "#fc845c" : "transparent",
            }}
            ref={instagramRef}
            dangerouslySetInnerHTML={{
              __html: socialMediaContent[0]["Instagram_Content"]
                ? socialMediaContent[0]["Instagram_Content"]
                    .replace(
                      /\\u([0-9a-fA-F]{4})|\\U([0-9a-fA-F]{8})/g,
                      (match, grp1, grp2) => {
                        if (grp1) {
                          return String.fromCharCode(parseInt(grp1, 16));
                        } else if (grp2) {
                          return String.fromCodePoint(parseInt(grp2, 16));
                        }
                        return match; // Return the original match if not replaced
                      }
                    )
                    .replace(/\\n/g, "<br />") // Replace \n with <br />
                : "",
            }}
          ></p>
          {console.info(
            socialMediaContent[0]["Instagram_Post_Status"],
            "This is insta post"
          )}
          <div className="d-flex justify-content-end">
            {/* Post button conditionally rendered */}
            {socialMediaContent[0]["Instagram_Post_Status"] !== "Published" ? (
              <button
                className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none"
                onClick={() => {
                  postInstagram();
                }}
              >
                Post
              </button>
            ) : (
              <button className="btn cust-btn-success font-bold font-16 text-nowrap box-shadow-none">
                Published
              </button>
            )}
          </div>
        </div>
      ) : null}

      {socialMediaContent[0]["Linkedin_Content"] ? (
        <div className="card border-0 shadow-sm p-4 radius-16 mb-4">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <p
              className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
              htmlFor="fbHighlight"
            >
              <img src="images/linkedin-post-icon.svg" alt="Facebook post" />
              Linkedin post
            </p>
            {/* Edit buttons conditionally rendered */}
            {socialMediaContent[0]["Linkedin_Post_Status"] !== "Published" && (
              <span className="d-flex align-items-center fon">
                {!linkedin ? (
                  <button
                    className="edit-btn"
                    onClick={() => {
                      setLinkedin(true);
                      linkedinRef.current.focus();
                    }}
                  >
                    <img src="images/edit-black.svg" alt="table-edit" />
                  </button>
                ) : (
                  <button
                    className="edit-btn"
                    onClick={() => {
                      savePostContent("Linkedin_Content = ? ");
                    }}
                  >
                    <img src="images/tick.svg" alt="table-edit" />
                  </button>
                )}
                <button
                  className="edit-btn"
                  onClick={() => {
                    regeneratePost("linkedIn");
                  }}
                >
                  <img src="images/refresh.svg" alt="table-edit" />
                </button>
              </span>
            )}
          </div>
          {/* Facebook content */}
          <p
            className="font-14 font-bold color-grey"
            contentEditable={linkedin}
            style={{
              outlineColor: linkedin ? "#fc845c" : "transparent",
            }}
            ref={linkedinRef}
            dangerouslySetInnerHTML={{
              __html: socialMediaContent[0]["Linkedin_Content"]
                ? socialMediaContent[0]["Linkedin_Content"]
                    .replace(
                      /\\u([0-9a-fA-F]{4})|\\U([0-9a-fA-F]{8})/g,
                      (match, grp1, grp2) => {
                        if (grp1) {
                          return String.fromCharCode(parseInt(grp1, 16));
                        } else if (grp2) {
                          return String.fromCodePoint(parseInt(grp2, 16));
                        }
                        return match; // Return the original match if not replaced
                      }
                    )
                    .replace(/\\n/g, "<br />") // Replace \n with <br />
                : "",

              //socialMediaContent[0]["Linkedin_Content"],
            }}
          ></p>
          <div className="d-flex justify-content-end">
            {/* Post button conditionally rendered */}
            {socialMediaContent[0]["Linkedin_Post_Status"] !== "Published" ? (
              <button
                className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none"
                onClick={() => {
                  postLinkedin();
                }}
              >
                Post
              </button>
            ) : (
              <button className="btn cust-btn-success font-bold font-16 text-nowrap box-shadow-none">
                Published
              </button>
            )}
          </div>
        </div>
      ) : null}

      {socialMediaContent[0]["Twitter_Content"] ? (
        <div className="card border-0 shadow-sm p-4 radius-16 mb-4">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <p
              className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
              htmlFor="fbHighlight"
            >
              <img src="images/twitter-post-icon.svg" alt="Facebook post" />
              Twitter post
            </p>
            {/* Edit buttons conditionally rendered */}
            {socialMediaContent[0]["Twitter_Post_Status"] !== "Published" && (
              <span className="d-flex align-items-center fon">
                {!twitter ? (
                  <button
                    className="edit-btn"
                    onClick={() => {
                      setTwitter(true);
                      twitterRef.current.focus();
                    }}
                  >
                    <img src="images/edit-black.svg" alt="table-edit" />
                  </button>
                ) : (
                  <button
                    className="edit-btn"
                    onClick={() => {
                      savePostContent("Twitter_Content = ? ");
                    }}
                  >
                    <img src="images/tick.svg" alt="table-edit" />
                  </button>
                )}
                <button
                  className="edit-btn"
                  onClick={() => {
                    regeneratePost("twitter");
                  }}
                >
                  <img src="images/refresh.svg" alt="table-edit" />
                </button>
              </span>
            )}
          </div>
          {/* Facebook content */}
          <p
            className="font-14 font-bold color-grey"
            contentEditable={twitter}
            style={{
              outlineColor: twitter ? "#fc845c" : "transparent",
            }}
            ref={twitterRef}
            dangerouslySetInnerHTML={{
              __html: socialMediaContent[0]["Twitter_Content"]
                ? socialMediaContent[0]["Twitter_Content"]
                    .replace(
                      /\\u([0-9a-fA-F]{4})|\\U([0-9a-fA-F]{8})/g,
                      (match, grp1, grp2) => {
                        if (grp1) {
                          return String.fromCharCode(parseInt(grp1, 16));
                        } else if (grp2) {
                          return String.fromCodePoint(parseInt(grp2, 16));
                        }
                        return match; // Return the original match if not replaced
                      }
                    )
                    .replace(/\\n/g, "<br />") // Replace \n with <br />
                : "",

              //socialMediaContent[0]["Twitter_Content"],
            }}
          ></p>
          <div className="d-flex justify-content-end">
            {/* Post button conditionally rendered */}
            {socialMediaContent[0]["Twitter_Post_Status"] !== "Published" ? (
              <button
                className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none"
                onClick={() => {
                  postTwitter();
                }}
              >
                Post
              </button>
            ) : (
              <button className="btn cust-btn-success font-bold font-16 text-nowrap box-shadow-none">
                Published
              </button>
            )}
          </div>
        </div>
      ) : null}

      <div className="card border-0 shadow-sm p-4 radius-16 mb-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <p
            className="d-flex align-items-center gap-3 font-16 font-bold mb-0"
            htmlFor="fbHighlight"
          >
            Social Media Image
          </p>
          <span className="d-flex align-items-center fon">
            <button
              className="edit-btn"
              onClick={() => {
                downloadImage();
              }}
            >
              <img src="images/download-icon.svg" alt="table-edit" />
            </button>
            <button
              className="edit-btn"
              onClick={() => {
                regenerateImage();
              }}
            >
              <img src="images/refresh.svg" alt="table-edit" />
            </button>
          </span>
        </div>
        <img
          src={socialMediaContent[0]["Social_Media_Image_Url"]}
          alt="ai-gen-img"
          className="social-media-gen mb-3"
        />
      </div>
    </>
  );
}
