import { useState } from "react";
import { useMyEventContext } from "../../contexts/MyEventContext";
import moment from "moment";

export default function DateAndDay() {
  const { isDayView, setIsDayView, year, month, currentDateForEv } =
    useMyEventContext();

  return !isDayView ? (
    <p className="font-bold font-16 d-flex align-items-center primary-darkblue mb-0">
      {moment()
        .month(month - 1)
        .format("MMMM")}{" "}
      <span className="font-medium grey-v1 ms-2">{year}</span>{" "}
    </p>
  ) : (
    <p className="font-bold font-16 d-flex align-items-center primary-darkblue mb-0">
      {moment(currentDateForEv, "YYYY-MM-DD").format("MMMM DD,")}{" "}
      <span className="font-medium grey-v1 ms-2">
        {moment(currentDateForEv, "YYYY-MM-DD").format("YYYY")}
      </span>{" "}
      <span className="dot-sapa mx-2" />{" "}
      <span className="font-medium grey-v1">
        {moment(currentDateForEv, "YYYY-MM-DD").format("dddd")}
      </span>
    </p>
  );
}
