export default function PricingLogo(props) {
  console.log(props.logoArray, "this is the logo array");
  return (
    <tr>
      <td></td>
      {props?.logoArray?.map((value, index) => {
        return (
          <td className="text-left">
            <img src={value} alt="alt-img" height={"75px"} />
          </td>
        );
      })}
    </tr>
  );
}




