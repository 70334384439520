import React, { useState } from "react";
import AccountAndPlatform from "../AccountsAndPlatform";
import { useCreateContentContext } from "../../../contexts/CreateContentContext";
import { generateContentApi } from "../../../services/api";
import { getContentByIdApi } from "../../../services/api";
import { useCommonContext } from "../../../contexts/CommonContext";
import { ReactSortable } from "react-sortablejs";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

export default function ArticleFields() {
  const {
    setGeneratedContent,
    formData,
    setFormData,
    contentID,
    setContentID,
    approvalStatus,
    account,
    setAccount,
    setIsLoading,
    contentTypeId,
    state,
    setState,
    selected,
    setSelected,
    keywords,
    setKeywords,
    contentName,setContentName
  } = useCreateContentContext();

  const [fileUpload, setFileUpload] = useState([]);

  // const [state, setState] = useState([{ id: 1, name: "" }]);

  const { userData } = useCommonContext();

  //generate content function starts here;
  async function generateContent() {
    try {
      let formDataNew = formData;
      formDataNew.References_Citations = state;

      const payload = {
        userId: userData[0]["User_Id"],
        contentTypeId: contentTypeId,
        contentName: "",
        configurationJson: formDataNew,
        contentType: "Article",
        contentId: contentID,
      };

      setIsLoading(true);
      const resonseData = await generateContentApi(payload);
      setIsLoading(false);

      let contentId = resonseData.data.data.contentId;

      setContentID(contentId);

      const contentByIdPayload = {
        userId: userData[0]["User_Id"],
        contentId: contentId,
      };
      const contentResponseData = await getContentByIdApi(contentByIdPayload);

      console.log(
        contentResponseData.data.data.data[0]["Generated_Content"],
        "This is the html stting from the content gen api"
      );

      setContentName(contentResponseData.data.data.data[0]["Content_Name"])

      let htmlString =
        contentResponseData.data.data.data[0]["Generated_Content"];

      htmlString = htmlString.replaceAll(/^`\\html|\\`$/g, "");

      let counter = 1;
      const getReplacementHtmlSnippet = (count) => {
        return `<div class="position-relative content-upload text-center mb-4"><button class="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none" onClick="generateImage(${count})"><img src="images/generate-icon.svg" alt="generate-icon" class="me-2"/>Generate image</button><p class="font-16 font-medium mt-4 mb-1">or <label class="primary-theme-color cursor-pointer" for="uploadImg2">upload files</label> from your computer</p><p class="font-14 font-regular color-grey">( .png, .jpg, .pdf up to 25MB in size)</p><input type="file" class="cust-file" id="uploadImg2" accept="image/*" onchange="initialFileUpload(event,${count})"/></div>`;
      };

      // Replace all occurrences of <ImagePlaceHolder/> with the replacement HTML snippet
      htmlString = htmlString.replaceAll("<ImagePlaceHolder/>", () => {
        const replacement = getReplacementHtmlSnippet(counter);
        counter++; // Increment counter for the next occurrence
        return replacement;
      });

      setGeneratedContent(htmlString);
    } catch (error) {
      console.log(error.message);
    }
  }

  console.info(formData, "abcdefghijklmnop");
  console.info(selected, "RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");

  const handleInputChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleReferencesChange = (index, value) => {
    setFormData((prevData) => ({
      ...prevData,
      References_Citations: {
        ...prevData.References_Citations,
        references: [
          ...prevData.References_Citations.references.slice(0, index),
          value,
          ...prevData.References_Citations.references.slice(index + 1),
        ],
      },
    }));
  };

  const handleUploadFilesChange = (files) => {
    setFormData((prevData) => ({
      ...prevData,
      References_Citations: {
        ...prevData.References_Citations,
        form_Upload: files,
      },
    }));
  };

  function handleKeyDown(e) {
    console.info("Your are Done");
    if (e.key === "Enter") {
      console.info("Your are Done");
      if (formData.Keyword_SEO.specify_SEO.trim() !== "") {
        setSelected([...selected, formData.Keyword_SEO.specify_SEO]); // Append current value of keywords
        handleInputChange("Keyword_SEO", "specify_SEO", "");
      }
    }
  }

  function removeTag(index) {
    setSelected(selected.filter((_, i) => i !== index));
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    setFileUpload(fileName);
    const reader = new FileReader();
    console.info(file, "????????????????????????????????");
    reader.onload = (e) => {
      try {
        let array = formData.References_Citations.form_Upload;
        let jsonArray = {
          fileData: e.target.result,
          fileName: file.name,
          fileSize: file.size,
        };
        array.push(jsonArray);
        // array.push(e.target.result);
        setFormData((prevData) => ({
          ...prevData,
          References_Citations: {
            ...prevData.References_Citations,
            form_Upload: array,
          },
        }));
      } catch (error) {
        console.error("Error parsing JSON file:", error);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleAddDiv = () => {
    const newItem = { id: state.length + 1, name: "" };
    setState([...state, newItem]);
  };

  const handleRemoveDiv = (id) => {
    if (state.length === 1) {
      // If there's only one item, do not remove it
      return;
    }
    const updatedState = state.filter((item) => item.id !== id);
    setState(updatedState);
  };

  const handleChangeName = (id, value) => {
    const updatedState = state.map((item) =>
      item.id === id ? { ...item, name: value } : item
    );
    setState(updatedState);
  };
  console.info(formData, "FormDARAAAAAAAA");
  console.info(
    account,
    "mmmmmmmmmmnnnnnnnnnnnnnnnnnmmmmmmmmmmmmmmmmmmnnnnnnnnnnnnnnn"
  );

  const isPendingOrApproved =
    approvalStatus === "Pending" || approvalStatus === "Approved";

  return (
    <div className="col-md-3 mt-4">
      <div className="px-3">
        {/* Topic & Key Points starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#topicKeyPoints"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Topic &amp; Key Points{" "}
          </p>
          <div className="collapse show" id="topicKeyPoints">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="topicOrtheme"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Topic or theme of the article/ blog{" "}
                </label>
                <span
                  data-bs-toggle="tooltip"
                  title=" An input field where the user can input the main topic or theme of their article or blog."
                >
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
                <input
                  type="text"
                  placeholder="Enter your article's topic or theme"
                  className="form-control custom-form  font-14 font-regular"
                  id="topicOrtheme"
                  value={formData.topic_And_Key_Points.topic_or_theme}
                  onChange={(e) =>
                    handleInputChange(
                      "topic_And_Key_Points",
                      "topic_or_theme",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="specifyKeyContent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Specify key content highlights{" "}
                  <span className="mandatory-red">*</span>
                  <span
                    data-bs-toggle="tooltip"
                    title="A text box where the user can specify the key highlights or main points of the content."
                  >
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Describe some key content highlights"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="specifyKeyContent"
                  value={formData.topic_And_Key_Points.specify_Key_Content}
                  onChange={(e) =>
                    handleInputChange(
                      "topic_And_Key_Points",
                      "specify_Key_Content",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Topic & Key Points ends */}
        {/* Purpose  starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#Purpose"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Purpose{" "}
          </p>
          <div className="collapse show" id="Purpose">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="purposeOfTheContent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Purpose of the content{" "}
                </label>
                <span
                  data-bs-toggle="tooltip"
                  title="A dropdown menu where the user can able to select the Main purpose of the Content."
                >
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="purposeOfTheContent"
                  value={formData.Purpose.purpose_of_The_Content}
                  onChange={(e) =>
                    handleInputChange(
                      "Purpose",
                      "purpose_of_The_Content",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  <option value="">Select</option>
                  <option value="Informative">Informative</option>
                  <option value="Promotional">Promotional</option>
                  <option value="Entertain">Entertain</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Purpose starts */}
        {/* Target Audience starts */}
        <div className="border-bottom mb-4 cust-border">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#target"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Target Audience{" "}
          </p>
          <div className="collapse show" id="target">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="targetAudience"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Target audience of the content{" "}
                </label>
                <span
                  data-bs-toggle="tooltip"
                  title=" allows the user to select the primary audience category for the content."
                >
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="targetAudience"
                  value={formData.Target_Audience.target_audience}
                  onChange={(e) =>
                    handleInputChange(
                      "Target_Audience",
                      "target_audience",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  <option value="">Select</option>
                  <option value="General Readers">General Readers</option>
                  <option value="Professionals">Professionals</option>
                  <option value="Beginners">Beginners</option>
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="audienceLevel"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Audience level of expertise{" "}
                </label>
                <span
                  data-bs-toggle="tooltip"
                  title="Enables the user to specify the expertise level of the target audience."
                >
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="audienceLevel"
                  value={formData.Target_Audience.audience_level}
                  onChange={(e) =>
                    handleInputChange(
                      "Target_Audience",
                      "audience_level",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  <option value="">Select</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                  <option value="Expert">Expert</option>
                  <option value="All Levels">All Levels</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Target Audience starts */}

        {/* Tone and style starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#toneStyle"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Tone &amp; Style{" "}
          </p>
          <div className="collapse show" id="toneStyle">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="toneContent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Tone of the content{" "}
                </label>
                <span
                  data-bs-toggle="tooltip"
                  title="provides options for selecting the appropriate tone for the content."
                >
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="toneContent"
                  value={formData.Tone_And_Style.tone_of_The_Content}
                  onChange={(e) =>
                    handleInputChange(
                      "Tone_And_Style",
                      "tone_of_The_Content",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  <option value="">Select</option>
                  <option value="Formal">Formal</option>
                  <option value="Conversational">Conversational</option>
                  <option value="Technical">Technical</option>
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="styleContent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Style of the content{" "}
                </label>
                <span
                  data-bs-toggle="tooltip"
                  title="empowers users to specify the stylistic approach or narrative technique employed in the generated content."
                >
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="styleContent"
                  value={formData.Tone_And_Style.style_of_The_Content}
                  onChange={(e) =>
                    handleInputChange(
                      "Tone_And_Style",
                      "style_of_The_Content",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  <option value="">Select</option>
                  <option value="Humor">Humor</option>
                  <option value="Storytelling">Storytelling</option>
                  <option value="Data-driven">Data-driven</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Tone and style starts */}
        {/* */}
        {/* Accounts and Platform starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#accountPlatform"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Account &amp; Platform{" "}
          </p>
          <div className="collapse show" id="accountPlatform">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="socialMediaAccount"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Social media account <span className="mandatory-red">*</span>{" "}
                  <span
                    data-bs-toggle="tooltip"
                    title="Displays a list of accounts integrated on the integration page.Users can select the desired social media account from this dropdown."
                  >
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="socialMediaAccount"
                  value={formData.Account_And_Platform?.socialMediaAccount}
                  onChange={(e) =>
                    handleInputChange(
                      "Account_And_Platform",
                      "socialMediaAccount",
                      e.target.value
                    )
                  }
                >
                  <option value="Select">Select</option>
                  {account?.map((value, index) => {
                    return (
                      <option value={value?.Account_Id}>
                        {value?.Account_Name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {formData.Account_And_Platform?.socialMediaAccount != "Select" ? (
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="platform"
                    className="form-label mb-0 cust-label font-14 font-medium mb-2"
                  >
                    Platforms
                    <span
                      data-bs-toggle="tooltip"
                      title="Lorem ipsumis a dummy"
                    >
                      <img
                        className="ms-2 mb-1"
                        src="images/info-icon.svg"
                        alt="info-icon"
                      />
                    </span>
                  </label>
                  {account
                    .filter(
                      (value) =>
                        value.Account_Id ===
                        formData.Account_And_Platform?.socialMediaAccount
                    )
                    .map((filteredAccount) => {
                      return (
                        <>
                          <div
                            className="form-check"
                            hidden={filteredAccount.Facebook_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="fbPost"
                              checked={formData.Account_And_Platform.fbPost}
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "fbPost",
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              className="form-check-label font-14 font-medium check-label"
                              htmlFor="fbPost"
                            >
                              Facebook post
                            </label>
                          </div>

                          <div
                            className="form-check"
                            hidden={filteredAccount?.Linkedin_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="LinkedinPost"
                              checked={
                                formData.Account_And_Platform.linkedinPost
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "linkedinPost",
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="LinkedinPost"
                            >
                              Linkedin post
                            </label>
                          </div>

                          <div
                            className="form-check"
                            hidden={filteredAccount.Twitter_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="twitterPost"
                              checked={
                                formData.Account_And_Platform.twitterPost
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "twitterPost",
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="twitterPost"
                            >
                              Twitter post
                            </label>
                          </div>
                          <div
                            className="form-check"
                            hidden={filteredAccount.Instagram_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="instaCap"
                              checked={formData.Account_And_Platform.instaCap}
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "instaCap",
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="instaCap"
                            >
                              Instagram caption
                            </label>
                          </div>
                        </>
                      );
                    })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* Accounts and Platform ends */}
        {/* Length & Structure starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#lengthStructure"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Length &amp; Structure
          </p>
          <div className="collapse show" id="lengthStructure">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="lengthStructureLabel"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Preferred article length{" "}
                </label>
                <span
                  data-bs-toggle="tooltip"
                  title="allows users to select the desired length of the article.."
                >
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="lengthStructureLabel"
                  value={
                    formData.Length_And_Structure.length_and_Structure_Label
                  }
                  onChange={(e) =>
                    handleInputChange(
                      "Length_And_Structure",
                      "length_and_Structure_Label",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  <option value="">Select</option>
                  <option value="Short">Short</option>
                  <option value="Medium">Medium</option>
                  <option value="Long">Long</option>
                  <option value="Very Long">Very Long</option>
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="PreferredContentStructure"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Preferred content structure{" "}
                </label>
                <span
                  data-bs-toggle="tooltip"
                  title="enables users to outline the preferred structure of the article content."
                >
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
                <textarea
                  placeholder="Describe some key content highlights"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="PreferredContentStructure"
                  value={
                    formData.Length_And_Structure.preferred_Content_Structure
                  }
                  disabled={isPendingOrApproved}
                  onChange={(e) =>
                    handleInputChange(
                      "Length_And_Structure",
                      "preferred_Content_Structure",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {/* Length & Structure ends */}
        {/* keyword seo starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#keySeo"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Keyword &amp; SEO
          </p>
          <div className="collapse show" id="keySeo">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="SpecifySEO"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Specify SEO keywords{" "}
                </label>
                <div
                  className="custom-form w-100 px-2 py-1 d-flex align-items-center flex-wrap"
                  htmlFor="availInteration"
                >
                {selected.map((item, index) => (
                  <span
                    key={index}
                    className="blue-batch d-inline-flex align-items-center me-2 mb-2"
                  >
                    <span className="font-14 font-medium">{item}</span>
                    <img
                      src="images/close-icon.svg"
                      alt="close-icon"
                      className="ms-3 cursor-pointer"
                      onClick={() => removeTag(index)}
                    />
                  </span>
                ))}
              
                <input
                  type="text"
                  placeholder="Describe broader industry or market context."
                  
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="SpecifyFormat"
                  value={formData.Keyword_SEO.specify_SEO}
                  onChange={(e) =>
                    handleInputChange(
                      "Keyword_SEO",
                      "specify_SEO",
                      e.target.value
                    )
                  }
                  onKeyDown={handleKeyDown}
                  disabled={isPendingOrApproved}
                />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="SpecifySEO"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Specify formatting guidelines{" "}
                </label>
                <span
                  data-bs-toggle="tooltip"
                  title="text box where users can specify formatting guidelines for their content."
                >
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
                <textarea
                  placeholder="Describe broader industry or market context."
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="SpecifyFormat"
                  value={formData.Keyword_SEO.specify_Format}
                  onChange={(e) =>
                    handleInputChange(
                      "Keyword_SEO",
                      "specify_Format",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* keyword seo ends */}

       

        {/* References & Citationse starts */}
        <div className="">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#RefCit"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Reference &amp; Citation
          </p>
          <div className="collapse show" id="RefCit">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="SpecifySEO"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  References{" "}
                </label>
                <span
                  data-bs-toggle="tooltip"
                  title="input field where users should able to enter reference URLs. Users should able to add multiple reference URLs by clicking the + icon next to the input field."
                >
                  <img
                    className="ms-2 mb-1"
                    src="images/info-icon.svg"
                    alt="info-icon"
                  />
                </span>
                <ReactSortable list={state} setList={setState}>
                  {state.map((item) => (
                    <div
                      className="d-flex align-items-center gap-2 grab-comp mb-2"
                      key={item.id}
                    >
                      <span>
                        <img
                          src="images/drag-dots.svg"
                          className="cursor-grab"
                        />
                      </span>
                      <input
                        className="form-control custom-form font-14 font-regular"
                        value={item.name}
                        onChange={(e) =>
                          handleChangeName(item.id, e.target.value)
                        }
                      />
                      <span>
                        <img
                          src="images/plus-icon.svg"
                          className="cursor-pointer d-block"
                          onClick={handleAddDiv}
                        />
                      </span>
                      {state.length > 1 && ( // Render minus icon only if there's more than one div
                        <span>
                          <img
                            src="images/minus-icon.svg"
                            className="cursor-pointer d-block py-2"
                            onClick={() => handleRemoveDiv(item.id)}
                          />
                        </span>
                      )}
                    </div>
                  ))}
                </ReactSortable>
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="formUpload"
                    className="form-label mb-0 cust-label font-14 font-medium mb-2"
                  >
                    Upload
                    <span
                      data-bs-toggle="tooltip"
                      title="Users should be able to upload files related to references.This upload feature allows users to attach files directly from their computer by either selecting them or dragging and dropping them into the designated area."
                    >
                      <img
                        src="images/info-icon.svg"
                        alt="info-icon"
                        className="ms-2 mb-1"
                      />
                    </span>
                  </label>
                  <div className=" position-relative mb-3">
                    <label
                      className="upload-form w-100 text-center"
                      htmlFor="formUpload"
                    >
                      <img src="images/upload-icon.svg" alt="upload-icon" />
                      <span className="d-block mb-2 font-medium font-12">
                        Upload files from computer
                      </span>
                      <span className="d-block mb-2 font-regular font-10 color-grey">
                        or drag and drop here
                      </span>
                      <span className="d-block mb-2 font-regular font-8 color-grey">
                        (Supported format: Microsoft Word up to 50MB)
                      </span>
                    </label>
                    <input
                      onChange={(e) => handleFileChange(e)}
                      type="file"
                      className="cust-file"
                      id="formUpload"
                    />
                  </div>
                  {formData.References_Citations.form_Upload?.map(
                    (data, index) => {
                      return (
                        <label className="file-path d-flex justify-content-between align-items-center">
                          <span className="d-block">
                            <span className="d-block font-medium font-14">
                              {data.fileName}
                            </span>
                            <span className="d-block font-regular color-grey font-14">
                              ({data.fileSize})
                            </span>
                          </span>
                          <span className="d-block">
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="fbPost"
                            />
                          </span>
                        </label>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ReactSortable list={state} setList={setState}>
          {state.map((item) => (
            <div
              className="d-flex align-items-center gap-2 grab-comp mb-2"
              key={item.id}
            >
              <span>
                <img src="images/drag-dots.svg" className="cursor-grab" />
              </span>
              <input
                className="form-control custom-form font-14 font-regular"
                value={item.name}
                onChange={(e) => handleChangeName(item.id, e.target.value)}
              />
              <span>
                <img
                  src="images/plus-icon.svg"
                  className="cursor-pointer d-block"
                  onClick={handleAddDiv}
                />
              </span>
              {state.length > 1 && ( // Render minus icon only if there's more than one div
                <span>
                  <img
                    src="images/minus-icon.svg"
                    className="cursor-pointer d-block py-2"
                    onClick={() => handleRemoveDiv(item.id)}
                  />
                </span>
              )}
            </div>
          ))}
        </ReactSortable> */}
        {/* <div className="col-md-12 mb-3">
          <label
            htmlFor="formUpload"
            className="form-label mb-0 cust-label font-14 font-medium mb-2"
          >
            Upload
            <span data-bs-toggle="tooltip" title="Lorem ipsumis a dummy">
              <img
                src="images/info-icon.svg"
                alt="info-icon"
                className="ms-2 mb-1"
              />
            </span>
          </label>
          <div className=" position-relative mb-3">
            <label
              className="upload-form w-100 text-center"
              htmlFor="formUpload"
            >
              <img src="images/upload-icon.svg" alt="upload-icon" />
              <span className="d-block mb-2 font-medium font-12">
                Upload files from computer
              </span>
              <span className="d-block mb-2 font-regular font-10 color-grey">
                or drag and drop here
              </span>
              <span className="d-block mb-2 font-regular font-8 color-grey">
                (Supported format: Microsoft Word up to 50MB)
              </span>
            </label>
            <input
              onChange={(e) => handleFileChange(e)}
              type="file"
              className="cust-file"
              id="formUpload"
            />
          </div>
          {formData.References_Citations.form_Upload?.map((data, index) => {
            return (
              <label className="file-path d-flex justify-content-between align-items-center">
                <span className="d-block">
                  <span className="d-block font-medium font-14">
                    {data.fileName}
                  </span>
                  <span className="d-block font-regular color-grey font-14">
                    ({data.fileSize})
                  </span>
                </span>
                <span className="d-block">
                  <input
                    className="form-check-input cust-check box-shadow-none"
                    type="checkbox"
                    defaultValue
                    id="fbPost"
                  />
                </span>
              </label>
            );
          })}
        </div> */}
        {/* References & Citations ends */}
        {/* form action starts here */}
        <div className="d-flex justify-content-end py-3 form-foot">
          <button
            className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
            disabled={isPendingOrApproved}
          >
            Cancel
          </button>
          <button
            disabled={
              isPendingOrApproved ||
              formData.topic_And_Key_Points.specify_Key_Content == "" ||
              formData.Account_And_Platform.socialMediaAccount == "Select"
            }
            className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
            onClick={() => {
              generateContent();
            }}
          >
            <img
              src="images/generate-icon.svg"
              alt="generate-icon"
              className="me-2"
            />
            {contentID == "" ? "Generate" : "Regenerate"}
          </button>
        </div>
        {/* form action ends here */}
      </div>
    </div>
  );
}
