export default function BasePricing(props) {
  return (
    <tr>
      <td className="compare-header-color">Base Pricing Plan</td>
      {props?.basePricing?.map((value, index) => {
        console.log(value,"pricing");
        return (
          <td key={index} style={{ verticalAlign: "middle", textAlign: "left" }} title={value}>
            {value ? (
              value.length > 120 ? value.slice(0, 120) + "..." : value
            ) : (
              <span className="compare-header-color" style={{ display: "block", textAlign: "left", verticalAlign: "middle" }}>NA</span>
            )}
          </td>
        );
      })}
    </tr>
  );
}
