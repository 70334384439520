import { useEffect } from "react";
import { useHubContext } from "../../contexts/HubContext";

export default function EventToggleHeaders() {
  const { isUpcomming, setIsUpcomming } = useHubContext();

  return (
    <div className="justify-content-between d-flex align-items-center mb-3">
      <span className="font-18 font-bold">My Events</span>
      <div className="tab-toggle ms-3">
        <input
          type="radio"
          className="btn-check day"
          name="options"
          id="events"
          checked={!isUpcomming}
          onClick={() => {
            setIsUpcomming(false);
          }}
        />
        <label
          className="btn box-shadow-none color-grey font-12 font-bold"
          htmlFor="events"
        >
          My Events
        </label>
        <input
          type="radio"
          className="btn-check day color-grey"
          name="options"
          id="upcoming"
          checked={isUpcomming}
          onClick={() => {
            setIsUpcomming(true);
          }}
        />
        <label
          className="btn box-shadow-none color-grey font-12 font-bold"
          htmlFor="upcoming"
        >
          Upcoming Events
        </label>
      </div>
    </div>
  );
}
