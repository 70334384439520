import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updatePasswordApi } from "../../services/api";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });
  const passwordRegex =
  /^(?!^\d)(?!^[@$!%*?&])(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_,:"".#$^()+-?><,`~{}]{8,}$/;
  const [userId, setUserId] = useState("");
  const [isAllDone, setisAllDone] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Define showConfirmPassword state

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  console.log(token, "Retrived tokennnnnnnnnnnnnnnnnn");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswords({
      ...passwords,
      [name]: value,
    });
  };

  console.log(userId, "logged user id");

  const handleResetPassword = async () => {
    const isPasswordValid = passwordRegex.test(passwords.password);

    if (isPasswordValid && passwords.password === passwords.confirmPassword) {
      const data = {
        userId: token,
        password: passwords.password,
      };
      try {
        const responseData = await updatePasswordApi(data);
        if (responseData.status === 200) {
          setisAllDone(true);
          //   navigate("/login");
        }
      } catch (error) {
        console.error(error);
      }
    }
    {
      console.log(
        passwordRegex.test(passwords.password) &&
        passwords.password === passwords.confirmPassword,
        "disable consoleee"
      );
      console.log(
        passwordRegex.test(passwords.password),
        "password satisfy regex"
      );
      console.log(
        passwords.password === passwords.confirmPassword,
        "password satisfy regex"
      );
    }
  };

  useEffect(() => {
    let currentIndex = 0; // Initialize index to track which button to click
    const interval = setInterval(() => {
      document.getElementById(`button${currentIndex + 1}`).click();
      currentIndex = (currentIndex + 1) % 3; // Ensure index loops between 0, 1, and 2
    }, 3000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="container-fluid">
      <div className="row ">
        {!isAllDone ? (
          <>
            {/* Left container starts here */}
            <div className=" col-xl-7 d-xl-block d-none py-2 pe-2">
              <div className="login-bg-height position-relative my-0">
                <div className="login-bg-height login-bg-img">
                  <div
                    id="carouselExampleIndicators"
                    className="carousel pt-5 slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators custom-carousel justify-content-start mx-5" >
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={0}
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                        id="button1"
                        class="active"
                      />
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={1}
                        aria-label="Slide 2"
                        id="button2"
                      />
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={2}
                        aria-label="Slide 3"
                        id="button3"
                      />
                    </div>
                    <div className="carousel-inner mx-5 py-5 mt-5">
                      <div className="carousel-item active">
                        <h5 className="mb-3 font-bold color-white font-28">
                        Rapid Article Production
                        </h5>
                        <p className="mt-3 mb-5 font-18 font-regular color-white content-wrap">
                        AI-Powered Article Creation.
                        </p>
                      </div>
                      <div className="carousel-item">
                        <h5 className="mb-3 font-bold color-white font-32">
                        Seamless Social Media Post Generation
                        </h5>
                        <p className="mt-3 mb-5 font-16 font-regular color-white content-wrap">
                        Instantly create social media post with AI.
                        </p>
                      </div>
                      <div className="carousel-item">
                        <h5 className="mb-3 font-bold color-white font-32">
                         Event Management
                        </h5>
                        <p className="mt-3 mb-5 font-16 font-regular color-white content-wrap">
                        Transform your event experience with AI-driven content creation for seamless engagement across articles, blogs, and social platforms.

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Left container ends here */}
            {/* Right container starts here */}
            <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
              <div className="form-signup">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-sm-12 py-lg-0 py-4">
                    <h5 className="mb-3 font-bold primary-darkblue font-32">
                      Set New Password
                    </h5>
                    <p className="mt-3 mb-5 font-16 font-regular grey-v2">
                      Must be at least 8 characters
                    </p>
                    <label
                      htmlFor="Password"
                      className="form-label mb-0 cust-label font-14 font-medium mb-2"
                    >
                      Password
                    </label>
                    <div style={{ position: 'relative' }}>
  <input
    type={showPassword ? 'text' : 'password'}
    placeholder="Enter password"
    className="form-control custom-form py-2 font-14 font-regular"
    id="Password"
    name="password"
    value={passwords.password}
    onChange={handleInputChange}
    style={{ paddingRight: '2.5rem', WebkitTextSecurity: showPassword ? 'none' : 'disc', textSecurity: showPassword ? 'none' : 'disc' }}
  />
  <span
    style={{
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      zIndex: 1,
    }}
    onClick={() => setShowPassword(!showPassword)}
  >
    {showPassword ? (
      <i className="fas fa-eye-slash"></i>
    ) : (
      <i className="fas fa-eye"></i>
    )}
  </span>
</div>

                    <label
                      htmlFor="ConfirmPassword"
                      className="form-label mb-0 cust-label font-14 font-medium mb-2"
                    >
                      Confirm Password
                    </label>
                    <div style={{ position: 'relative' }}>
  <input
    type={showConfirmPassword ? 'text' : 'password'} // Use separate state for confirm password
    placeholder="Confirm password"
    className="form-control custom-form py-2 font-14 font-regular"
    id="ConfirmPassword"
    name="confirmPassword"
    value={passwords.confirmPassword}
    onChange={handleInputChange}
    style={{ paddingRight: '2.5rem', WebkitTextSecurity: showConfirmPassword ? 'none' : 'disc', textSecurity: showConfirmPassword ? 'none' : 'disc' }}
  />
  <span
    style={{
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      zIndex: 1,
    }}
    onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Use setShowConfirmPassword to toggle state
  >
    {showConfirmPassword ? (
      <i className="fas fa-eye-slash"></i>
    ) : (
      <i className="fas fa-eye"></i>
    )}
  </span>
</div>

                    <button
                      type="button"
                      className={`btn px-4 mt-5 w-100 primary-gradient-btn text-white gap-2 text-center font-bold font-16 text-nowrap `}
                      disabled={
                        passwords.password.trim() === "" ||
                        passwords.confirmPassword.trim() === "" ||
                        passwords.password !== passwords.confirmPassword ||
                        !passwordRegex.test(passwords.password)
                      }
                      data-bs-dismiss="modal"
                      onClick={handleResetPassword}
                    >
                      {console.log(
                        passwordRegex,
                        passwords.password,
                        passwordRegex.test(passwords.password),
                        "This is the password test"
                      )}
                      {console.log(
                        passwords.password.trim() === "" &&
                          passwords.confirmPassword.trim() === "" &&
                          !passwordRegex.test(passwords.password) &&
                          passwords.password !== "" &&
                          passwords.confirmPassword !== ""
                          ? passwords.password !== passwords.confirmPassword
                          : true
                      )}
                      {console.log(
                        passwords.password.trim() === "",
                        passwords.confirmPassword.trim() === "",
                        !passwordRegex.test(passwords.password),
                        passwords.password !== "" &&
                          passwords.confirmPassword !== ""
                          ? passwords.password !== passwords.confirmPassword
                          : true
                      )}
                      Reset Password
                    </button>
                    <button
                      type="button"
                      className="btn px-4 mt-3 w-100 violet-btn rounded-3 shadow-none  d-flex align-items-center justify-content-center font-bold font-16 text-nowrap"
                      data-bs-dismiss="modal"
                      onClick={() => navigate("/")}
                    >
                      Back to Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Right container ends here */}
          </>
        ) : (
          <>
            {/* Left container starts here */}
            <div className=" col-xl-7 d-xl-block d-none py-2 pe-2">
              <div className="login-bg-height position-relative my-0">
                <div className="login-bg-height login-bg-img">
                  <div
                    id="carouselExampleIndicators"
                    className="carousel pt-5 slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators custom-carousel justify-content-start mx-5">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={0}
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      />
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={1}
                        aria-label="Slide 2"
                      />
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={2}
                        aria-label="Slide 3"
                      />
                    </div>
                    <div className="carousel-inner mx-5 py-5 mt-5">
                      <div className="carousel-item active">
                        <h5 className="mb-3 font-bold color-white font-28">
                          Effortless Content Creation 1
                        </h5>
                        <p className="mt-3 mb-5 font-18 font-regular color-white content-wrap">
                          Instantly create compelling content with AI for
                          articles, blogs, and social media.
                        </p>
                      </div>
                      <div className="carousel-item">
                        <h5 className="mb-3 font-bold color-white font-32">
                          Effortless Content Creation 2
                        </h5>
                        <p className="mt-3 mb-5 font-16 font-regular color-white content-wrap">
                          Instantly create compelling content with AI for
                          articles, blogs, and social media.
                        </p>
                      </div>
                      <div className="carousel-item">
                        <h5 className="mb-3 font-bold color-white font-32">
                          Effortless Content Creation 3
                        </h5>
                        <p className="mt-3 mb-5 font-16 font-regular color-white content-wrap">
                          Instantly create compelling content with AI for
                          articles, blogs, and social media.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Left container ends here */}
            {/* Right container starts here */}
            <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
              <div className="form-signup">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-sm-12 py-lg-0 py-4">
                    <span>
                      <img
                        src="images/login-success-icon.svg"
                        alt="login-success-icon"
                        className="login-succ-icon mb-5"
                      />
                    </span>
                    <h5 className="mb-3 font-bold primary-darkblue font-32">
                      All done
                    </h5>
                    <p className="mt-3 mb-5 font-16 font-regular grey-v2">
                      Your password has been successfully reset
                    </p>
                    <button
                      type="button"
                      className="btn px-4 mt-5 w-100 primary-gradient-btn text-white  gap-2 text-center font-bold font-16 text-nowrap"
                      data-bs-dismiss="modal"
                      onClick={() => navigate(`/`)}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Right container ends here */}
          </>
        )}
      </div>
    </div>
  );
}