import moment from "moment";
import { useMyEventContext } from "../../contexts/MyEventContext";
import { useCommonContext } from "../../contexts/CommonContext";
import { useEffect, useState } from "react";
import { getMyEventsApi } from "../../services/api";

export default function MyEventsHub() {
  const { userData } = useCommonContext();
  const currentDate = new Date();
  const [month, setMonth] = useState(currentDate.getMonth() + 1);
  const [year, setYear] = useState(currentDate.getFullYear());
  const [dateAndDays, setDateAndDays] = useState([]);

  function getDaysInMonth(year, month) {
    const daysInMonth = new Date(year, month, 0).getDate();
    const days = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month - 1, day);
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
      const fullDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      days.push({ date: day, dayOfWeek, fullDate });
    }

    return days;
  }
  function splitByWeek(data) {
    const result = [];
    let week = [];

    data.forEach((item) => {
      week.push(item);
      if (item.dayOfWeek === "Saturday") {
        result.push(week);
        week = [];
      }
    });
    if (week.length > 0) {
      result.push(week);
    }

    return result;
  }
  function getPreviousDays(dateString, currentDayOfWeek) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date(dateString);
    const previousDays = [];

    // Calculate the number of previous days based on the current day of the week
    let numPreviousDays;
    switch (currentDayOfWeek) {
      case "Saturday":
        numPreviousDays = 6;
        break;
      case "Friday":
        numPreviousDays = 5;
        break;
      case "Thursday":
        numPreviousDays = 4;
        break;
      case "Wednesday":
        numPreviousDays = 3;
        break;
      case "Tuesday":
        numPreviousDays = 2;
        break;
      case "Monday":
        numPreviousDays = 1;
        break;
      default:
        numPreviousDays = 0;
        break;
    }

    // Calculate and push the previous dates and days
    for (let i = 1; i <= numPreviousDays; i++) {
      const previousDate = new Date(currentDate);
      previousDate.setDate(previousDate.getDate() - i);
      const previousDayOfWeek = daysOfWeek[previousDate.getDay()];
      previousDays.push({
        date: previousDate.getDate(),
        dayOfWeek: previousDayOfWeek,
      });
    }

    return previousDays;
  }
  function getNextDays(dateString, currentDayOfWeek) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date(dateString);
    const nextDays = [];

    // Calculate the number of next days based on the current day of the week
    let numNextDays;
    switch (currentDayOfWeek) {
      case "Sunday":
        numNextDays = 6;
        break;
      case "Monday":
        numNextDays = 5;
        break;
      case "Tuesday":
        numNextDays = 4;
        break;
      case "Wednesday":
        numNextDays = 3;
        break;
      case "Thursday":
        numNextDays = 2;
        break;
      case "Friday":
        numNextDays = 1;
        break;
      case "Saturday":
        numNextDays = 0;
        break;
      default:
        numNextDays = 0;
        break;
    }

    // Calculate and push the next dates and days
    for (let i = 1; i <= numNextDays; i++) {
      const nextDate = new Date(currentDate);
      nextDate.setDate(nextDate.getDate() + i);
      const nextDayOfWeek = daysOfWeek[nextDate.getDay()];
      nextDays.push({
        date: nextDate.getDate(),
        dayOfWeek: nextDayOfWeek,
      });
    }

    return nextDays;
  }

  useEffect(() => {
    getMyEvents();
  }, [month, year]);

  async function getMyEvents() {
    let dateData = getDaysInMonth(year, month);
    let splitArray = splitByWeek(dateData);
    console.log(splitArray, "this is the split array");

    for (let i = 0; i < splitArray.length; i++) {
      let week = splitArray[i];
      if (week.length !== 7) {
        if (week[0].dayOfWeek != "Sunday") {
          let firstDayOfMonth = week[0].dayOfWeek;
          let firstDate = `${year}-${month}-${week[0].date}`;
          const weekDay = getPreviousDays(firstDate, firstDayOfMonth);
          splitArray[i] = weekDay.reverse().concat(week);
          console.log(splitArray[i]);
        }
        if (week[week.length - 1].dayOfWeek != "Saturday") {
          let lastDayOfMonth = week[week.length - 1].dayOfWeek;
          let firstDate = `${year}-${month}-${week[week.length - 1].date}`;
          const weekDay = getNextDays(firstDate, lastDayOfMonth);
          splitArray[i] = splitArray[i].concat(weekDay);
          console.log(splitArray[i], "Last");
        }
      }
    }

    console.log(splitArray, "this is split");

    let dateOfMonth = "";
    for (let val of splitArray) {
      for (let value of val) {
        if (value.fullDate) {
          dateOfMonth = value.fullDate;
          break;
        }
      }
    }

    const payload = {
      userId: userData[0]["User_Id"],
      date: dateOfMonth,
    };
    const responseData = await getMyEventsApi(payload);

    const myEventData = responseData.data.data;

    let daysAndDate = splitArray;
    for (let values of daysAndDate) {
      for (let eachDay of values) {
        eachDay.events = [];
        for (let event of myEventData) {
          console.info(
            moment(event?.Event_From_Date).format("YYYY-MM-DD"),
            eachDay?.fullDate
          );
          if (
            moment(event?.Event_From_Date).format("YYYY-MM-DD") ==
            eachDay?.fullDate
          ) {
            eachDay.events.push(event);
          }
        }
      }
    }
    setDateAndDays(daysAndDate);
  }

  console.log(dateAndDays);

  return dateAndDays.length != 0 ? (
    <div
      className="month-calendar hub "
      id="eventsContainer"
      style={{ display: "block" }}
    >
      <table className="table">
        <thead>
          <tr>
            <th className="text-end border-0 font-medium font-14 weight-500">
              Sun
            </th>
            <th className="text-end border-0 font-medium font-14 weight-500">
              Mon
            </th>
            <th className="text-end border-0 font-medium font-14 weight-500">
              Tue
            </th>
            <th className="text-end border-0 font-medium font-14 weight-500">
              Wed
            </th>
            <th className="text-end border-0 font-medium font-14 weight-500">
              Thu
            </th>
            <th className="text-end border-0 font-medium font-14 weight-500">
              Fri
            </th>
            <th className="text-end border-0 font-medium font-14 weight-500">
              Sat
            </th>
          </tr>
        </thead>
        <tbody className="font-regular font-14">
          {dateAndDays.map((value, index) => {
            return (
              <tr key={index}>
                {value?.map((date, index) => {
                  console.log(date);
                  return (
                    <td
                      className={
                        moment().format("YYYY-MM-DD") === date.fullDate
                          ? "today font-bold"
                          : ""
                      }
                    >
                      <span className="d-block text-end">
                        {moment().format("YYYY-MM-DD") === date.fullDate
                          ? "Today"
                          : date?.date}
                      </span>
                      {date?.events?.length != 0 ? (
                        <span className="day-content mt-1">
                          {date?.events?.map((event, index) => {
                            return (
                              <>
                                <span
                                  className="month-calendar-highlight font-10 font-regular p-2 w-100 d-flex gap-1 align-items-center text-nowrap"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span
                                    className={`cal-legend ${
                                      event?.Event_Type == "Conferences"
                                        ? "bg-blue"
                                        : event?.Event_Type == "Webinars"
                                        ? "bg-orange"
                                        : event?.Event_Type == "Training"
                                        ? "bg-yellow"
                                        : event?.Event_Type == "Hackathon"
                                        ? "bg-indigo"
                                        : ""
                                    }`}
                                  ></span>
                                  {event.Event_Name.length > 5
                                    ? `${event.Event_Name.slice(0, 5)}...`
                                    : event.Event_Name}

                                  {/* Digital Innovation... */}
                                </span>
                                <div
                                  className="meeting-content position-absolute bg-white dropdown-menu"
                                  style={{ zIndex: 9, width: 490 }}
                                >
                                  <p className="d-block font-16 font-bold d-flex align-items-center gap-2 mb-2">
                                    <span
                                      className={`cal-legend ${
                                        event?.Event_Type == "Conferences"
                                          ? "bg-blue"
                                          : event?.Event_Type == "Webinars"
                                          ? "bg-orange"
                                          : event?.Event_Type == "Training"
                                          ? "bg-yellow"
                                          : event?.Event_Type == "Hackathon"
                                          ? "bg-indigo"
                                          : ""
                                      }`}
                                    ></span>
                                    {event?.Event_Name}
                                  </p>
                                  <p className="font-regular font-16 color-grey mb-3">
                                    {event?.Event_Description}
                                  </p>
                                  <p className="d-flex align-items-center gap-2 mb-3 font-regular font-12 grey-v1">
                                    <img
                                      src="images/calender-icon.svg"
                                      alt="calender-icon"
                                    />
                                    <span>
                                      {moment(event?.Event_From_Date)
                                        .add(
                                          moment.duration(
                                            event?.Event_From_Time
                                          )
                                        )
                                        .format("MMM DD, YYYY h:mm A")}
                                    </span>{" "}
                                    <img
                                      src="images/tail-arrow.svg"
                                      alt="calender-icon"
                                    />{" "}
                                    <span>
                                      {moment(event?.Event_To_Date)
                                        .add(
                                          moment.duration(event?.Event_To_Time)
                                        )
                                        .format("MMM DD, YYYY h:mm A")}
                                    </span>
                                  </p>
                                  <p className="d-flex align-items-center gap-2 mb-3 font-regular font-12 grey-v1">
                                    <img
                                      src="images/location-icon.svg"
                                      alt="location-icon"
                                    />
                                    <span className="ps-1">
                                      {event?.Meeting_Platform}
                                    </span>
                                  </p>
                                  <p className="d-flex align-items-center gap-2 mb-3 font-medium font-12 grey-v1">
                                    <img
                                      src="images/link-icon.svg"
                                      alt="link-icon"
                                    />
                                    <a className="blue-link text-decoration-none">
                                      {event?.Meeting_Url}
                                    </a>{" "}
                                    <span className="cursor-pointer">
                                      <img src="images/copy-icon.svg" />
                                    </span>
                                  </p>
                                  <button
                                    className="btn shadow-btn d-flex align-items-center py-2 px-3 box-shadow-none font-medium font-14"
                                    onClick={() => {
                                      window.open(event?.Meeting_Url, "_blank");
                                    }}
                                  >
                                    Join Meeting
                                  </button>
                                </div>
                              </>
                            );
                          })}
                        </span>
                      ) : null}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <div
      className="month-calendar hub"
      id="eventsContainer"
      style={{ display: "block" }}
    >
      <div className="h-100 d-flex align-items-center justify-content-center">
        <div className="d-block text-center mt-4">
          <span className="font-bold font-14 d-block mb-3">
            <img src="images/no-events-icon.svg" alt="no-post-icon" />
          </span>
          <span className="font-bold font-14 d-block">No Events Created</span>
          <span className="font-bold font-12 d-block mb-3 color-grey">
            No recent events have been made
          </span>
          <a
            href="#"
            className="font-bold font-12 d-block blue-link text-decoration-none"
          >
            View My Events
          </a>
        </div>
      </div>
    </div>
  );
}
