import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useCreateContentContext } from "../../contexts/CreateContentContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function Header(props) {
    const location = useLocation()
    const navigate = useNavigate();

    const { contentName } = props;




    return (
        <h3 className="head-bar font-20 font-bold p-3 sticky-top bg-white mb-0 d-flex align-items-center justify-content-between">
            <span
                className="d-flex align-items-center"
                onClick={() => {
                    navigate("/requests");
                }}
            >
                <img
                    src="images/back-icon.svg"
                    alt="back-icon"
                    className="me-2 cursor-pointer"
                />
                {contentName}
            </span>{" "}
        </h3>
    );
}
