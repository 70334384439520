import React from 'react';
import { useCreateProductContext } from '../../../contexts/CreateProductContext';

const AzureReview = () => {


  const {
    azureReviewArray,
    setAzureReviewArray,
    azurePopupArray,
    setAzurePopupArray } = useCreateProductContext()


    console.log(azureReviewArray,"This is the azure review array!!!")


  // const azureReviewArray = [{
  //   "Product_Logo": "https://avaeusspstgbsdev.blob.core.windows.net/imagecontainer/Cloudgen_9f7365e9-3d3a-4c08-a8f7-33990b8e3654.png",
  //   "Product_Overview": "Terraform codegeneration",
  //   "Feature_Description": "Direct Terraform deployment",
  //   "Pricing_Cost": "100",
  //   "Pricing_Unit": "Month",
  //   "Azure_Average": "4.6",
  //   "Azure_Ratings": "17",
  //   "Azure_Ratings_reviews": "17",
  //   "stars_5": "0",
  //   "stars_4": "0",
  //   "stars_3": "0",
  //   "stars_2": "0",
  //   "stars_1": "0",
  //   "Aws_Average": null,
  //   "Aws_Ratings_reviews": null
  // }]





  // if (azureArray === null) {
  //   return <span className="compare-header-color">NA</span>;
  // }

  // const renderStars = (averageRating) => {
  //   if (averageRating === null) {
  //     return <span className="compare-header-color">NA</span>;
  //   }

  //   const [rating, reviews] = averageRating.split(' ');
  //   const filledStars = Math.floor(parseFloat(rating));
  //   const unfilledStars = 5 - filledStars;

  //   const starIcons = [];
  //   for (let i = 0; i < filledStars; i++) {
  //     starIcons.push(<span key={`filled-${i}`}><img src="images/rating-filled-icon.svg" alt="rating-filled-icon" /></span>);
  //   }
  //   for (let i = 0; i < unfilledStars; i++) {
  //     starIcons.push(<span key={`unfilled-${i}`}><img src="images/rating-unfilled-icon.svg" alt="rating-unfilled-icon" /></span>);
  //   }

  //   return (
  //     <span className="d-flex align-items-center gap-1">
  //       {starIcons}
  //       <a href="" className="mt-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{averageRating}</a>
  //     </span>
  //   );
  // };


  console.log(azureReviewArray, "This is the azure review array!!!")

  function renderStars(averageStars) {
    let coundOfFilledStars = 0;
    if (!Number.isNaN(averageStars)) {
      coundOfFilledStars = Math.round(Number(averageStars))
    }

    let indexArray = [1, 2, 3, 4, 5]
    return indexArray.map((values, index) => {
      console.log(values, "This is the value of the stars")
      return values <= coundOfFilledStars ? <img src="images/rating-filled-icon.svg" alt="rating-filled-icon" className={(values == 5) ? "me-2" : ""} />: <img src="images/rating-unfilled-icon.svg" alt="rating-unfilled-icon" className={(values == 5) ? "me-2" : ""} />
    })
  }


  return (
    <>
      <tr>
        <td className="compare-header-color">Azure Ratings & Reviews </td>
        {
          azureReviewArray.map((value, index) => {
            return <td className="vertical-align-middle">
              <span className="d-flex align-items-center gap-1">

                {
                  renderStars(value?.Azure_Average)
                }
              </span>
              <a href className="mt-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={
                () => {
                  setAzurePopupArray(value)
                }
              }>{value?.Azure_Average} ({value?.Azure_Ratings ? value?.Azure_Ratings : "0" })</a>
            </td>
          })
        }

      </tr>


    </>

  );
};

export default AzureReview;
