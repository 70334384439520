import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { save_roadmap_featuresApi, update_roadmap_featuresApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";


export default function CreateProduct() {
    const {Upcomingfeature,roadmapfeatureDescription, roadmapId,
         getAllRoadmap, setUpcomingFeature, setRoadmapFeatureDescription, setRoadmapId,productId } = useCreateProductContext();
    const { userData } = useCommonContext()


    const saveRoadmapFeature = async () => {
        try {
            // console.log("saveFeature is called");
            const payload = {
                userId: userData[0]["User_Id"],
                Upcomingfeature: Upcomingfeature,
                RoadmapFeatureDescription: roadmapfeatureDescription,
                productId: productId
            };
            const responseData = await save_roadmap_featuresApi(payload);
            // console.log(responseData.data, "saved")
            if (responseData.data.success === true)
            getAllRoadmap()
            setRoadmapId('')
            setUpcomingFeature('');
            setRoadmapFeatureDescription('');
        } catch (error) {
            console.error("Error saving feature:", error);
            // insertErrorApi(error);
        }
    }

    const updateRoadmapFeature = async () => {
        try {
            // console.log("updateFeature is called", roadmapId);
            const payload = {
                userId: userData[0]["User_Id"],
                Upcomingfeature: Upcomingfeature,
                roadmapId: roadmapId,
                RoadmapFeatureDescription: roadmapfeatureDescription,
                productId: productId
            };
            const responseData = await update_roadmap_featuresApi(payload);
            // console.log(responseData.data, "updated")
            if (responseData.data.success === true)
            console.log(" Inside the update_roadmap_featuresAp ")
            getAllRoadmap();
            setRoadmapId('');
            console.log(" below the set state upcoming feataure")
            setUpcomingFeature('');
            setRoadmapFeatureDescription('');
            console.log(Upcomingfeature,"Upcomingfeature")
            console.log(roadmapfeatureDescription,"roadmapfeatureDescription")
        } catch (error) {
            console.error("Error saving feature:", error);
            // insertErrorApi(error);
        }
    }

    return (
        <>
            <h5 className="font-bold font-18 grey-v3 mb-4 pb-2">Roadmap Features</h5>
            <div className="col-md-12 mb-3">
                <label htmlFor="YourFeature" className="form-label mb-0 cust-label font-14 font-medium mb-2"> Upcoming Feature Name</label>
                <input type="text" placeholder="Enter your upcoming feature name" className="form-control custom-form py-2  font-14 font-regular" id="YourFeature" onChange={(e) => setUpcomingFeature(e.target.value.replace(/[^a-zA-Z0-9\s.,'-]/g, ''))} value={Upcomingfeature} />
            </div>
            <div className="col-md-12 mb-3">
                <label htmlFor="RoadmapFeatureDescription" className="form-label mb-0 cust-label font-14 font-medium mb-2">Feature Description</label>
                <textarea type="text" rows={3} placeholder="Enter your upcoming feature description" className="form-control py-2 custom-form resize-none font-14 font-regular" id="RoadmapFeatureDescription" value={roadmapfeatureDescription}
                    onChange={(e) => setRoadmapFeatureDescription(e.target.value.replace(/[^a-zA-Z0-9\s.,'-]/g, ''))} />
            </div>
            <div className="d-flex justify-content-end">
                <button type="button" className="btn  px-4 py-3  mt-3 violet-btn rounded-3 shadow-none  d-flex align-items-center justify-content-center font-medium font-16 text-nowrap" style={{ height: '44px' }} onClick={(roadmapId === "") ? saveRoadmapFeature : updateRoadmapFeature} disabled={
                    Upcomingfeature === "" || roadmapfeatureDescription === ""
                }>
                    Save
                </button>

                {/* <CreateFeatureAccordian /> */}

            </div>
        </>
    )
}
