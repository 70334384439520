import React, { useEffect, useState } from "react";
import { useCommonContext } from "../../contexts/CommonContext.jsx";
import { useNavigate } from "react-router-dom";
import {
  fetchAllUserApi,
  deleteUserApi,
  addUserApi,
  getDropDownDetailsApi,
} from "../../services/api.js";

export default function ManageUsers() {
  const { userData, openToast } = useCommonContext();
  const [dropDownDetails, setDropDownDetails] = useState([]);
  const navigate = useNavigate();
  const [manageUserDetails, setManageUserDetails] = useState([]);
  const [Name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState([]);
  const [Roles, setRoles] = useState([]);
  const [rolesDropDown, setRolesDropDown] = useState([]);
  const [insertStatus, setInsertStatus] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [addButton, setAddButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const [editId, setEditId] = useState([]);
  const [validEmail, setIsEmailValid] = useState(false);
  const [loadMore, setLoadMore] = useState();
  const [count, setCount] = useState(10);


  const { isNavCollapsed, setIsNavCollapsed } = useCommonContext();

  console.log(Name.split(" ")[0], "asdasd");
  console.log(Name.split(" ")[1], "asdasd");
  console.log(emailAddress, "ea");
  console.log(Roles, "R");

  useEffect(() => {
    fetchAllUser();
  }, [count]);

  // useEffect(() => {
  //   fetchAllUser();
  // }, [count]);

  const handleEmailChange = (e) => {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    setIsEmailValid(emailRegex.test(e.target.value));
    setEmailAddress(e.target.value);

  };

  const fetchAllUser = async () => {
    try {
      setIsLoading(true);
      const userId = userData[0]["User_Id"];
      const data = {
        admin_id: userId,
        count: count,
      };
      const responseData = await fetchAllUserApi(data);
      if (responseData.status === 200 || responseData.status === 201) {
        setManageUserDetails(responseData.data.data.data);
        setLoadMore(responseData.data.data.rowCount);
        const DropDowndata = {
          userId: userData[0]["User_Id"],
          tableName: "Roles",
        };
        const dropDownResponse = await getDropDownDetailsApi(DropDowndata);
        if (responseData.status === 200) {
          setDropDownDetails(dropDownResponse.data.data.data);
          console.log(dropDownDetails[0].Role_Name, "===================");
        }
        setIsLoading(false);

      } else if (responseData.status === 403) {
        // Session Expired
      } else if (responseData.status === 401) {
        // Invalid Token
      } else {
        // Something went wrong
        throw new Error("Unexpected status code");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUser = async () => {
    try {
      setIsLoading(true);
      const data = {
        user_id: userData[0]["User_Id"],
        delete_id: deleteId,
      };
      const responseData = await deleteUserApi(data);
      if (responseData.status === 200 || responseData.status === 201) {
        setManageUserDetails(responseData.data.data.data);
        setIsLoading(false);
        fetchAllUser();
        openToast("User Deleted successfully", "images/toast-check.svg");
        setIsLoading(false);
      }
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  const addUser = async () => {
    try {
      setIsLoading(true);
      const adminId = userData[0]["User_Id"];

      const firstName = Name.split(" ")[0];
      const lastName = Name.split(" ")[1];

      console.log(lastName, "This is the last name for update")
      console.log(firstName, "This is the username for the insret")
      const data = {
        admin_id: adminId,
        user_id: editId,
        type: insertStatus ? "update" : "insert",
        first_name: firstName,
        last_name: lastName ? lastName : '',
        email_id: emailAddress,
        role_Id: Roles,
      };
      const responseData = await addUserApi(data);
      if (responseData.status === 200 || responseData.status === 201) {
        setManageUserDetails(responseData.data.data.data);
        fetchAllUser();
        setEditId("");
        setName("");
        setEmailAddress("");
        setRoles("");
        openToast(
          editId == ""
            ? "User Added successfully"
            : "User Updated Successfully",
          "images/toast-check.svg"
        );
      }
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  return (
    <>
      <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
        <div className="row">
          <div className="col-md-12">
            <h2 className="font-bold font-22 my-4">Manage Users </h2>
            <div className="table-responsive theme-tbl manageusers-tbl">
              <table className="table table-borderless mb-0">
                <thead className="font-medium bg-white">
                  <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Role</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="font-medium">
                  <tr className="grid-acc-open-brd">
                    <td>
                      <input
                        type="text"
                        placeholder="Enter name"
                        className="form-control custom-form  font-14 font-regular"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={Name}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="Enter email address"
                        className="form-control custom-form  font-14 font-regular"
                        onChange={handleEmailChange}
                        value={emailAddress}
                      />
                    </td>
                    <td>
                      <select
                        className="form-select custom-form  font-14 font-regular"
                        onChange={(e) => setRoles(e.target.value)}
                        value={Roles}
                      >
                        <option value="select">select</option>
                        <option value={dropDownDetails[2]?.Role_Id}>
                          {dropDownDetails[2]?.Role_Name}
                        </option>
                        <option value={dropDownDetails[1]?.Role_Id}>
                          {dropDownDetails[1]?.Role_Name}
                        </option>
                        <option value={dropDownDetails[0]?.Role_Id}>
                          {dropDownDetails[0]?.Role_Name}
                        </option>
                      </select>
                    </td>
                    <td className="text-center">
                      <span className="d-flex align-items-center justify-content-center gap-2">
                        {/* <button type="button" class="shadow-none border-0 p-0 bg-white"></button> */}
                        <button
                          type="button"
                          className="btn p-2 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            // if (insertStatus == "false") {
                            addUser();
                            // }
                          }}
                          disabled={
                            Name == "" ||
                            emailAddress == "" ||
                            Roles == "" ||
                            !validEmail
                          }
                        >
                          <img
                            src="images/inline-grid-add-icon.svg"
                            alt="inlinegrid-add-icon"
                          />
                        </button>
                      </span>
                    </td>
                  </tr>

                  {manageUserDetails?.length != 0 ? (
                    manageUserDetails?.map((user) => (
                      <tr>
                        <td>{user.Firstname + " " + user.Lastname}</td>
                        <td>{user.Email_Id}</td>
                        <td>{user.Role_name}</td>
                        <td className="text-center pe-0">
                          <span className="d-flex align-items-center justify-content-center gap-2">
                            <button
                              className="edit-btn"
                              onClick={() => {
                                setName(user.Firstname + " " + user.Lastname);
                                setEmailAddress(user.Email_Id);
                                setRoles(user.Role_Id);
                                setEditId(user.User_Id);
                                setInsertStatus(true);
                                setIsEmailValid(true);
                              }}
                            >
                              <img
                                src="images/table-edit.svg"
                                alt="table-edit"
                              />
                            </button>
                            <span className="border-sape" />
                            <button
                              className="delete-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#delete"
                              onClick={() => {
                                setDeleteId(user.User_Id);
                              }}
                            >
                              <img
                                src="images/table-delete.svg"
                                alt="table-delete"
                              />
                            </button>
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="grid-acc-open-brd">
                      <td colSpan={4} className="ps-3 text-center">
                        <span>
                          <img
                            src="images/no-users-added.svg"
                            alt="no-users-added"
                            className="my-3"
                          />
                        </span>
                        <h5 className="font-14 font-bold primary-darkblue mt-1">
                          No Users Added
                        </h5>
                        <p className="font-12 font-regular grid-norec-gray">
                          It looks like you haven't added any users yet!
                        </p>
                        <button
                          className="border-0 font-12 font-medium px-0 text-blue-btn"
                          type="button"
                        >
                          Add User
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/**Delete account starts here */}
          </div>
        </div>
      </div>
      {manageUserDetails?.length != 0 ? (<div className="d-flex justify-content-center mt-3">
        {loadMore <= 10 || count >= loadMore ? null : (
          <button
            className="btn shadow-btn d-flex align-items-center py-2 px-3  font-bold font-16"
            onClick={() => {
              setCount(count + 10);
            }}
          >
            Load More
          </button>
        )}
      </div>) : null}
      <div
        className="modal fade"
        id="delete"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="connectFacebookLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content popup-content py-2 px-3">
            <div className="modal-header border-0">
              <span className="modal-title" id="connectFacebookLabel">
                <img
                  src="images/delete-popup-icon.svg"
                  alt="delete-popup-icon"
                  className="popup-logo"
                />
              </span>
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body border-0">
              <h5 className="font-18 font-bold popup-header-color">Delete</h5>
              <p className="font-14 font-regular popup-data-color">
                Are you sure you want to remove the user ?
              </p>
            </div>
            <div className="modal-footer border-0">
              <button
                className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger px-4 cust-reject text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                data-bs-dismiss="modal"
                onClick={() => {
                  deleteUser();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}