import React, { useContext, useEffect, useState } from "react";
import { useCommonContext } from "./CommonContext";
import { getImpressionApi } from "../services/api";

const HubContext = React.createContext();

export function HubContextProvider({ children }) {
  const { userData, openToast } = useCommonContext();
  const [metrics, setMetrics] = useState([]);
  const [isUpcomming, setIsUpcomming] = useState(true);

  useEffect(() => {}, [userData]);

  async function getImpression() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
      };

      const responseData = await getImpressionApi(payload);

      setMetrics(responseData?.data?.data);
    } catch (error) {
      // openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  }

  useEffect(() => {
    getImpression();
  }, [userData]);

  return (
    <HubContext.Provider
      value={{ metrics, setMetrics, isUpcomming, setIsUpcomming }}
    >
      {children}
    </HubContext.Provider>
  );
}

export function useHubContext() {
  return useContext(HubContext);
}
