export default function Overview(props) {
  return (
    <tr>
      <td className="compare-header-color">Overview</td>

      {props?.overviewArray?.map((value, index) => {
        return (
          <td key={index} style={{ verticalAlign: "middle", textAlign: "left" }} title={value}>
            {value ? (
              value.length > 120 ? value.slice(0, 120) + "..." : value
            ) : (
              <span className="compare-header-color" style={{ display: "block", textAlign: "center", verticalAlign: "middle" }}>NA</span>
            )}
          </td>
        );
      })}
    </tr>
  );
}
