import axios from "axios";
import { endpoints } from "./endpoints";
import { environment } from "./api";

export async function client(data, url, method) {
  if (localStorage.getItem('token')) {
    if (!ValidateToken()) {
      return
    }
  }
  let token = window.localStorage.getItem("token");
  if (url.includes("set_new_password")) {
    token = data.userId;
  }
  const config = {
    method,
    url,
    data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios(config);
}

async function ValidateToken() {
  try {
    let data = JSON.stringify({
      "token": localStorage.getItem("token"),
    });
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: endpoints[environment]["validate"],
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    await axios.request(config)
    return true
  } catch (error) {
    document.getElementById('toastMessages').classList.add("dark-toast", "font-bold", "d-flex", "align-items-center", "gap-2");
    document.getElementById('toastMessages').innerHTML = `<img src="images/red-cross-toast.svg" alt="toast-check" id="toastImage" /> ${error?.response?.data?.message}`;
    setTimeout(() => {
      window.location.href = "/";
      localStorage.clear();
    }, 3000)
    return false
  }
}