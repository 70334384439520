import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useCreateContentContext } from "../../contexts/CreateContentContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function Header() {
  const location = useLocation()
  const navigate = useNavigate();
  const { pdfRef, generatedContent, contentTypeId,contentName,setContentName } = useCreateContentContext();
  const contentType = location.state.contentType

  console.info(location.state.contentType,"contentTypes")

  const downloadPDF = () => {
    const input = pdfRef.current;

    console.info(input,"thus is the input to download the content")

    const elementsToRemove = input.querySelectorAll('.position-relative.content-upload.text-center.mb-4');

elementsToRemove.forEach(element => {
    element.remove();
});

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save(`${contentName}.pdf`);
    });
  };

  return (
    <h3 className="head-bar font-20 font-bold p-3 sticky-top bg-white mb-0 d-flex align-items-center justify-content-between">
      <span
        className="d-flex align-items-center"
        onClick ={() =>{
          let toLink = "";
    if (contentType === "Case Study") {
      toLink = "/yourcasestudies";
    } else if (contentType === "Web Pages") {
      toLink = "/yourwebpages";
    } else if (contentType === "Press Release") {
      toLink = "/yourpressreleases";
    } else if (contentType === "Article") {
      toLink = "/yourarticles";
    } else if (contentType === "Social Media Post") {
      toLink = "/yoursocialmediaposts";
    }
    navigate(toLink, {
      state: {
        contentTypeId: contentTypeId,
        contentType: contentType,
      },
    });

    
        }}
      >
        <img
          src="images/back-icon.svg"
          alt="back-icon"
          className="me-2 cursor-pointer"
          
        />
        Create New {contentType}
      </span>{" "}
      {generatedContent != "" ? (
        <button
          onClick={downloadPDF}
          className="download-btn d-flex align-items-center justify-content-center"
        >
          <img src="images/download-icon.svg" alt="download-icon" />
        </button>
      ) : null}
    </h3>
  );
}
