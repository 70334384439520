import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { useCreateContentContext } from "../../contexts/CreateContentContext";

export default function AskButton() {
  const {
    position,
    setPosition,
    selectedText,
    setSelectedText,
    isAskAiOpened,
    setIsAskAiOpened,
    isTextSelected,
    setIsTextSelected,
    generatedContent,
    isAskAiPrompt, 
    setIsAskAiPrompt
  } = useCreateContentContext();

  console.log(selectedText, "Selected text from the html");
  return (
    <button
      className="ask-btn font-12 font-medium "
      style={{
        left: `${position.x}px`,
        top: `${position.y + 20}px`,
        height: "30px",
      }}
      hidden={!isTextSelected}
      onClick={() => {
        setIsAskAiOpened(true);
        setIsTextSelected(false);
        setIsAskAiPrompt(false)
        setIsAskAiOpened(false)
      }}
    >
      {" "}
      <span className="d-flex align-items-center gap-2 text-nowrap">
        <img src="images/ask-ai-icon.svg" alt="ask-ai-icon" />
        Ask AI
      </span>
    </button>
  );
}
