import { useCommonContext } from "../../contexts/CommonContext";
import { useCreateContentContext } from "../../contexts/CreateContentContext";

export default function DeleteModel() {
  const { deleteContentId, setDeleteContentId, contentDelete } =
    useCommonContext();
  const { userData } = useCommonContext();
  return (
    <div
      className="modal fade"
      id="delete"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="connectFacebookLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content popup-content py-2 px-3">
          <div className="modal-header border-0">
            <span className="modal-title" id="connectFacebookLabel">
              <img
                src="images/delete-popup-icon.svg"
                alt="delete-popup-icon"
                className="popup-logo"
              />
            </span>
            <button
              type="button"
              id="deleteClose"
              className="btn-close shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body border-0">
            <h5 className="font-18 font-bold popup-header-color">Delete</h5>
            <p className="font-14 font-regular popup-data-color">
              Are you sure you want to delete this Article ?
            </p>
          </div>
          <div className="modal-footer border-0">
            <button
              className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger px-4 cust-reject text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
              data-bs-dismiss="modal"
              onClick={() => {
                contentDelete();
                document.getElementById("deleteClose").click();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
