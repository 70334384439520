import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { saveFeatureApi, updateFeatureApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";


export default function CreateProduct() {
    const {setFeatureId, setFeatureName, setFeatureDescription, featureName, featureDescription, featureId, getAllfeatures,productId } = useCreateProductContext();
    const { userData } = useCommonContext()


    const saveFeature = async () => {
        try {
            // console.log("saveFeature is called");
            const payload = {
                userId: userData[0]["User_Id"],
                featureName: featureName,
                featureDescription: featureDescription,
                productId: productId
            };
            const responseData = await saveFeatureApi(payload);
            // console.log(responseData.data, "saved")
            if (responseData.data.success === true)
            getAllfeatures()
            setFeatureId('')
            setFeatureName('');
            setFeatureDescription('');
        } catch (error) {
            console.error("Error saving feature:", error);
            // insertErrorApi(error);
        }
    }

    const updateFeature = async () => {
        try {
            // console.log("updateFeature is called", featureId);
            const payload = {
                userId: userData[0]["User_Id"],
                featureName: featureName,
                featureId: featureId,
                featureDescription: featureDescription,
                productId: productId
            };
            const responseData = await updateFeatureApi(payload);
            // console.log(responseData.data, "updated")
            if (responseData.data.success === true)
            getAllfeatures()
            setFeatureId('')
            setFeatureName('');
            setFeatureDescription('');
        } catch (error) {
            console.error("Error saving feature:", error);
            // insertErrorApi(error);
        }
    }

    return (
        <>
            <h5 className="font-bold font-18 grey-v3 mb-4 pb-2">Features</h5>
            <div className="col-md-12 mb-3">
                <label htmlFor="YourFeature" className="form-label mb-0 cust-label font-14 font-medium mb-2">Your Feature Name</label>
                <input type="text" placeholder="Enter your feature name" className="form-control custom-form py-2  font-14 font-regular" id="YourFeature" onChange={(e) => setFeatureName(e.target.value.replace(/[^a-zA-Z0-9\s.,'-]/g, ''))} value={featureName} />
            </div>
            <div className="col-md-12 mb-3">
                <label htmlFor="FeatureDescription" className="form-label mb-0 cust-label font-14 font-medium mb-2">Feature Description</label>
                <textarea type="text" rows={3} placeholder="Enter your feature description" className="form-control py-2 custom-form resize-none font-14 font-regular" id="FeatureDescription" value={featureDescription}
                    onChange={(e) => setFeatureDescription(e.target.value.replace(/[^a-zA-Z0-9\s.,'-]/g, ''))} />
            </div>
            <div className="d-flex justify-content-end">
                <button type="button" className="btn  px-4 py-3  mt-3 violet-btn rounded-3 shadow-none  d-flex align-items-center justify-content-center font-medium font-16 text-nowrap" style={{ height: '44px' }} onClick={(featureId === "") ? saveFeature : updateFeature} disabled={
                    featureName === "" || featureDescription === ""
                }>
                    Save
                </button>

                {/* <CreateFeatureAccordian /> */}

            </div>
        </>
    )
}
