import { useMyEventContext } from "../../contexts/MyEventContext";
import HourBlocks from "./DaysHour";
import MonthlyCalender from "./MonthlyCalender";
import moment from "moment";

export default function DayView() {
  const { currentDateEvent } = useMyEventContext();

  function calculateTimeDifference(fromTime, toTime) {
    // Parse the times using moment
    const time1 = new Date(`2000-01-01T${fromTime}`);
    const time2 = new Date(`2000-01-01T${toTime}`);

    const timeDifferenceMs = Math.abs(time2 - time1);

    const hours = timeDifferenceMs / (1000 * 60 * 60);

    return hours;
  }

  function timeToPixel(startTime) {
    // Split the start time into hours and minutes
    const [hours, minutes, period] = startTime
      .split(/:| /)
      .map((val, index) => (index === 0 ? parseInt(val) % 12 : parseInt(val)));

    // Convert hours to 24-hour format
    const hours24 = period === "PM" ? hours + 12 : hours;

    // Calculate total minutes from midnight
    const totalMinutes = hours24 * 60 + minutes;

    // Define the range of pixels (0px to 45px per half-hour)
    const pixelsPerHalfHour = 45;

    // Map the total minutes to pixels
    const pixels = (totalMinutes / 30) * pixelsPerHalfHour;

    return pixels;
  }

  function bindEvent() {
    return currentDateEvent.map((value, index) => {
      return (
        <div className="d-flex position-absolute start-0 end-0">
          {value?.Other_Event_Details?.map((event, index) => {
            const formattedFromTime = moment(
              event?.Event_From_Time,
              "HH:mm:ss.SSSSSSS"
            ).format("hh:mm A");
            const formattedToTime = moment(
              event?.Event_To_Time,
              "HH:mm:ss.SSSSSSS"
            ).format("hh:mm A");

            let hourDiff = calculateTimeDifference(
              event?.Event_From_Time,
              event?.Event_To_Time
            );

            console.info(hourDiff, "this is the hour difrec");

            let pxCount = (hourDiff / 0.5) * 45 + "px";

            let marginPx = timeToPixel(formattedFromTime);
            return (
              <>
                <div
                  className={`meeting-highlight  ${
                    event?.Event_Type == "Conferences"
                      ? "border-blue"
                      : event?.Event_Type == "Webinars"
                      ? "border-orange"
                      : event?.Event_Type == "Training"
                      ? "border-yellow"
                      : event?.Event_Type == "Hackathon"
                      ? "border-indigo"
                      : ""
                  }  bg-white`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ height: pxCount, marginTop: marginPx }}
                >
                  <p className="d-block font-12 font-bold d-flex align-items-center gap-2 mb-0">
                    {event?.Event_Name}
                  </p>
                </div>
                <div
                  className="meeting-content position-absolute bg-white dropdown-menu"
                  style={{ zIndex: 9 }}
                >
                  <p className="d-block font-16 font-bold d-flex align-items-center gap-2 mb-2">
                    <span
                      className={`cal-legend ${
                        event?.Event_Type == "Conferences"
                          ? "bg-blue"
                          : event?.Event_Type == "Webinars"
                          ? "bg-orange"
                          : event?.Event_Type == "Training"
                          ? "bg-yellow"
                          : event?.Event_Type == "Hackathon"
                          ? "bg-indigo"
                          : ""
                      }`}
                    ></span>
                    {event?.Event_Name}
                  </p>
                  <p className="font-regular font-16 color-grey mb-3">
                    {event?.Event_Description}
                  </p>
                  <p className="d-flex align-items-center gap-2 mb-3 font-regular font-12 grey-v1">
                    <img src="images/calender-icon.svg" alt="calender-icon" />
                    {/* <span>
                      {moment(event?.Event_From_Date)
                        .add(moment.duration(event?.Event_From_Time))
                        .format("MMM DD, YYYY h:mm A")}
                    </span>{" "}
                    <img src="images/tail-arrow.svg" alt="calender-icon" />{" "}
                    <span>
                      {moment(event?.Event_To_Date)
                        .add(moment.duration(event?.Event_To_Time))
                        .format("MMM DD, YYYY h:mm A")}
                    </span> */}
                    <span>
                      {new Date(
                        `${event?.Event_From_Date.split("T")[0]}T${
                          event?.Event_From_Time
                        }`
                      ).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </span>{" "}
                    <img src="images/tail-arrow.svg" alt="calender-icon" />{" "}
                    <span>
                      {new Date(
                        `${event?.Event_To_Date.split("T")[0]}T${
                          event?.Event_To_Time
                        }`
                      ).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </span>
                  </p>
                  <p className="d-flex align-items-center gap-2 mb-3 font-regular font-12 grey-v1">
                    <img src="images/location-icon.svg" alt="location-icon" />
                    <span className="ps-1">{event?.Meeting_Platform}</span>
                  </p>
                  <p className="d-flex align-items-center gap-2 mb-3 font-medium font-12 grey-v1">
                    <img src="images/link-icon.svg" alt="link-icon" />
                    <a className="blue-link text-decoration-none">
                      {event?.Meeting_Url}
                    </a>{" "}
                    <span className="cursor-pointer">
                      <img src="images/copy-icon.svg" />
                    </span>
                  </p>
                  <button
                    className="btn shadow-btn d-flex align-items-center py-2 px-3 box-shadow-none font-medium font-14"
                    onClick={() => {
                      window.open(event?.Meeting_Url, "_blank");
                    }}
                  >
                    Join Meeting
                  </button>
                </div>
              </>
            );
          })}
        </div>
      );
    });
  }

  return (
    <>
      <div className="col-md-9 ">
        <div className="position-relative mt-4 border-bottom ms-5">
          {/* drop down starts */}
          {bindEvent()}
          {/* drop down ends */}

          <HourBlocks />
        </div>
      </div>
      <MonthlyCalender />
    </>
  );
}