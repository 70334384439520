import { useEffect, useState } from "react";
import { getAllContentsApi, getDropDownDetailsApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import CreateWebPagesModal from "./CreateWebPageModel";
import DeleteModel from "./DeleteModel";
import { useCreateContentContext } from "../../contexts/CreateContentContext";
import Loader from "../Loader/Loader";

export default function YourPressRelease() {
  const { deleteContentId, setDeleteContentId } = useCommonContext();
  const location = useLocation();
  const { userData } = useCommonContext();
  const [contentsData, setContentsData] = useState([]);
  const [count, setCount] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [contentTypeId, setContentTypeId] = useState(
    location?.state?.contentTypeId
  );
  const {isNavCollapsed, setIsNavCollapsed } = useCommonContext();
  const [contentType, setContentType] = useState(location?.state?.contentType);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [sortColumn, setSortColumn] = useState("c.Modified_Date");
  const [isAsc, setIsAsc] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [statusArray, setStatusArray] = useState([]);
  const [webpageTypes, setWebpageTypes] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (deleteContentId == "") {
      getAllContents(
        count,
        search,
        fromDate,
        toDate,
        sortColumn,
        isAsc,
        approvalStatus
      );
    }
  }, [deleteContentId]);

  useEffect(() => {
    setContentTypeId(location?.state?.contentTypeId);
    setContentType(location?.state?.contentType);
  }, [location.state]);

  const getAllContents = async (
    count,
    search,
    fromDate,
    toDate,
    sortColumn,
    isAsc,
    approvalStatus
  ) => {
    try {
      const payload = {
        userId: userData[0]?.User_Id,
        contentTypeId: contentTypeId,
        search: search,
        fromDate: fromDate,
        toDate: toDate,
        sortColumn: sortColumn,
        sortOrder: isAsc ? "ASC" : "DESC",
        count: count,
        approvalStatus: approvalStatus,
      };
      setIsLoading(true);
      const responseData = await getAllContentsApi(payload);
      setIsLoading(false);
      console.log(responseData.data.data.data, "This is the Contents data");
      setContentsData(responseData.data.data.data);
      setRowCount(responseData.data.data.rowCount);
    } catch (error) {}
  };
  useEffect(() => {
    if (userData.length != 0) {
      getAllContents(
        count,
        search,
        fromDate,
        toDate,
        sortColumn,
        isAsc,
        approvalStatus
      );
    }
  }, [count, isAsc, sortColumn, userData, location.state.contentTypeId]);

  useEffect(() => {
    if (search === "") {
      getAllContents(
        count,
        "",
        fromDate,
        toDate,
        sortColumn,
        isAsc,
        approvalStatus
      );
    }
  }, [search]);

  console.info(contentsData,".o.o.o.o.o.o.o.o.o.o.o.o.o.o.o.o.oo.o.o..o.")

  async function getWebPageTypes() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        tableName: "Webpage_Types",
      };
      const dropdownData = await getDropDownDetailsApi(payload);
      setWebpageTypes(dropdownData.data.data.data);
    } catch (error) {}
  }

  async function getStatus() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        tableName: "Approval_Status",
      };
      const dropdownData = await getDropDownDetailsApi(payload);
      setStatusArray(dropdownData.data.data.data);
    } catch (error) {}
  }

  useEffect(() => {
    getWebPageTypes();
    getStatus()
  }, []);

  const searchClick = () => {
    getAllContents(
      count,
      search,
      fromDate,
      toDate,
      sortColumn,
      isAsc,
      approvalStatus
    );
  };

  return (
    <>
      <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-between pt-3 pb-4 bg-white sticky-top">
              <h2 className="font-bold font-22">
                Your {contentType} <span>({rowCount})</span>
              </h2>
              <div className="d-flex gap-3">
                {/* search starts here */}
                <div
                  className="input-group search-field"
                  hidden={contentsData.length === 0 && search == "" && fromDate == "" && toDate == "" && approvalStatus == ""} 
                >
                  <input
                    type="search"
                    className="form-control box-shadow-none font-16 font-medium"
                    placeholder="Search"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <button
                    className="btn white-btn box-shadow-none d-flex align-items-center"
                    type="button"
                    id="button-addon2"
                    onClick={() => {
                      searchClick();
                    }}
                  >
                    <img src="images/search-icon.svg" alt="search-icon" />
                  </button>
                </div>
                {/* search starts here */}
                {/* filter starts here */}
                <div className="dropdown" hidden={contentsData.length === 0 && search == "" && fromDate == "" && toDate == "" && approvalStatus == ""}>
                  <button
                    className="btn white-btn d-flex align-items-center p-3 box-shadow-none"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="images/filter-line.svg" alt="filter-line" />
                  </button>
                  <div className="dropdown-menu dropdown-menu-end filter-drop">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="font-20 font-bold m-0">Date</p>
                      <img
                        src="images/close-icon.svg"
                        alt="close-icon"
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="fromDate"
                          className="form-label mb-0 cust-label font-14 font-medium"
                        >
                          From
                        </label>
                        <input
                          type="date"
                          className="form-control custom-form cust-date font-14 font-regular"
                          id="fromDate"
                          onChange={(e) => {
                            setFromDate(e.target.value);
                          }}
                          value={fromDate}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="toDate"
                          className="form-label mb-0 cust-label font-14 font-medium"
                        >
                          To
                        </label>
                        <input
                          type="date"
                          className="form-control custom-form cust-date font-14 font-regular"
                          id="toDate"
                          onChange={(e) => {
                            setToDate(e.target.value);
                          }}
                          value={toDate}
                        />
                      </div>
                      {userData[0]["Role_Name"] == "Content Writer" && (
                        <div className="col-md-6 mb-3">
                          <label
                            htmlFor="status"
                            className="form-label mb-0 cust-label font-14 font-medium"
                          >
                            Approval status
                          </label>
                          <select
                            className="form-select custom-form font-14 font-regular"
                            id="status"
                            value={approvalStatus}
                            onChange={(e) => {
                              setApprovalStatus(e.target.value);
                            }}
                          >
                            <option value="">Select</option>
                            {statusArray.map((value, index) => {
                              return (
                                <option value={value.Approval_Status_Id}>
                                  {value.Approval_Status_Name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <button className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"  onClick={() => {
                          setCount(10);
                          setSearch("");
                          setFromDate("");
                          setToDate("");
                          setSortColumn("c.Modified_Date");
                          setIsAsc(false);
                          setApprovalStatus("");
                          getAllContents(
                            10,
                            "",
                            "",
                            "",
                            "c.Modified_Date",
                            false,
                            ""
                          );
                        }}>
                        Cancel
                      </button>
                      <button
                        className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                        onClick={() => {
                          getAllContents(
                            count,
                            search,
                            fromDate,
                            toDate,
                            sortColumn,
                            isAsc,
                            approvalStatus
                          );
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
                {/* filter ends here */}
                {contentType != "Web Pages" ? (
                  <button
                    id="createNewContentBtn"
                    className="btn primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                    onClick={() => {
                      navigate("/createcontent", {
                        state: {
                          contentTypeId: contentTypeId,
                          contentName: "",
                          configurationJson: {},
                          contentType: contentType,
                          contentId: "",
                        },
                      });
                    }}
                  >
                    <img src="images/add-icon.svg" alt="add-icon" />
                    Create {contentType}
                  </button>
                ) : (
                  <button
                    id="createNewContentBtn"
                    className="btn primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#createWebPages"
                    // onClick={() => {
                    //   navigate("/createcontent", {
                    //     state: {
                    //       contentTypeId: contentTypeId,
                    //       contentName: "",
                    //       configurationJson: {},
                    //       contentType: contentType,
                    //       contentId: "",
                    //     },
                    //   });
                    // }}
                  >
                    <img src="images/add-icon.svg" alt="add-icon" />
                    Create {contentType}
                  </button>
                )}
              </div>
            </div>
            <div
              className="theme-tbl table-responsive"
              hidden={contentsData.length === 0 && isLoading}
            >
              <table className="table table-borderless">
                <thead className="font-medium bg-white">
                  <tr>
                    <th>
                      Title{" "}
                      <img
                        className="cursor-pointer ms-2"
                        src={
                          isAsc && sortColumn === "c.Content_Name"
                            ? "images/sort-up.svg"
                            : "images/sort-down.svg"
                        }
                        alt="sort-up"
                        onClick={() => {
                          setIsAsc(!isAsc);
                          setSortColumn("c.Content_Name");
                        }}
                      />
                    </th>
                    {contentType === "Web Pages" ? (
                      <th>
                        Type{" "}
                        <img
                          className="cursor-pointer ms-2"
                          src={
                            isAsc && sortColumn === "wt.Webpage_Types"
                              ? "images/sort-up.svg"
                              : "images/sort-down.svg"
                          }
                          alt="sort-up"
                          onClick={() => {
                            setIsAsc(!isAsc);
                            setSortColumn("wt.Webpage_Types");
                          }}
                        />
                      </th>
                    ) : null}
                    <th>
                      Last modified{" "}
                      <img
                        className="cursor-pointer ms-2"
                        src={
                          isAsc && sortColumn === "c.Modified_Date"
                            ? "images/sort-up.svg"
                            : "images/sort-down.svg"
                        }
                        alt="sort-up"
                        onClick={() => {
                          setIsAsc(!isAsc);
                          setSortColumn("c.Modified_Date");
                        }}
                      />
                    </th>
                    {contentType === "Press Release" ? (
                      <th>
                        Distributed Channel{" "}
                        <img
                          className="cursor-pointer ms-2"
                          src={
                            isAsc && sortColumn === "c.Distributed_Channel"
                              ? "images/sort-up.svg"
                              : "images/sort-down.svg"
                          }
                          alt="sort-up"
                          onClick={() => {
                            setIsAsc(!isAsc);
                            setSortColumn("c.Distributed_Channel");
                          }}
                        />
                      </th>
                    ) : null}
                    {userData[0]?.Role_Name === "Content Writer" ? (
                      <th className="text-center">Status </th>
                    ) : null}
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="font-medium">
                  {contentsData.map((values, index) => {
                    console.log("This is the faya");
                    return (
                      <tr>
                        <td
                        style={{ cursor: 'pointer' }}
                          onClick={() => {
                            navigate("/createcontent", {
                              state: {
                                contentId: values.Content_Id,
                                approvalStatus: values.Approval_Status_Name,
                                contentTypeId: contentTypeId,
                                contentName: values?.Content_Name,
                                configurationJson: {},
                                contentType: contentType,
                              },
                            });
                          }}
                        >
                          <a>{values?.Content_Name}</a>
                        </td>
                        {contentType === "Web Pages" ? (
                          <td>{values?.Webpage_Types}</td>
                        ) : null}
                        <td>
                          {moment(values?.Modified_Date).format(
                            "MMM DD, YYYY hh:mm A"
                          )}
                        </td>
                        {contentType === "Press Release" ? (
                          <td className="text-center">{                          
                            values?.Distributed_Channel == "" ? "-":values?.Distributed_Channel                         
                            
                            }</td>
                        ) : null}
                     
                        {userData[0]?.Role_Name === "Content Writer" ? (
                          <td className="text-center">
                            {values.Approval_Status_Name ? (
                              <span
                                className={`${
                                  values.Approval_Status_Name === "Pending"
                                    ? "tbl-status-yellow"
                                    : values.Approval_Status_Name === "Approved"
                                    ? "tbl-status-green"
                                    : "tbl-status-red"
                                } tbl-status`}
                              >
                                {values?.Approval_Status_Name}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                        ) : null}
                        <td className="text-center">
                          <span className="d-flex align-items-center justify-content-center gap-2">
                            <button
                              className="edit-btn"
                              onClick={() => {
                                navigate("/createcontent", {
                                  state: {
                                    contentId: values.Content_Id,
                                    approvalStatus: values.Approval_Status_Name,
                                    contentTypeId: contentTypeId,
                                    contentName: values?.Content_Name,
                                    configurationJson: {},
                                    contentType: contentType,
                                  },
                                });
                              }}
                            >
                              <img
                                src="images/table-edit.svg"
                                alt="table-edit"
                              />
                            </button>
                            <span className="border-sape" />
                            <button
                              className="delete-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#delete"
                              onClick={() => {
                                setDeleteContentId(values?.Content_Id);
                              }}
                            >
                              <img
                                src="images/table-delete.svg"
                                alt="table-delete"
                              />
                            </button>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
           
            {isLoading && <Loader />}

            {contentsData.length == 0 && !isLoading && (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "500px" }}
              >
                <div className="d-block text-center">
                  <span className="font-bold font-14 d-block mb-3">
                    <img src="images/no-article-icon.svg" alt="no-post-icon" />
                  </span>
                  <span className="font-bold font-14 d-block">
                    No {contentType} Generated
                  </span>
                  <span className="font-regular mt-2 font-12 d-block mb-3 color-grey">
                    It seems like you have not generated any {contentType} yet
                  </span>
                  <a
                    onClick={() => {
                      document.getElementById("createNewContentBtn").click();
                    }}
                    className="font-bold font-12 d-block blue-link text-decoration-none"
                  >
                    Create {contentType}
                  </a>
                </div>
              </div>
            )}

            {!(rowCount <= count) ? (
              <div
                className="d-flex justify-content-center mt-3"
                hidden={contentsData.length === 0}
              >
                <button
                  className="btn shadow-btn d-flex align-items-center py-2 px-3  font-bold font-16"
                  onClick={() => {
                    setCount((prevCount) => prevCount + 10);
                  }}
                >
                  Load More
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <CreateWebPagesModal webpageTypes={webpageTypes} />
      <DeleteModel />
    </>
  );
}
