
//COMPARISON_HISTORY_2
import React, { useEffect, useState } from 'react';
//COMPARISON_HISTORY_3
import { getComparisonsByProductIdApi } from '../../services/api';
//COMPARISON_HISTORY_4
import { useNavigate, useLocation } from 'react-router-dom';

import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { useCommonContext } from "../../contexts/CommonContext";



//COMPARISON_HISTORY_5
export default function ComparisonHistory() {
    //COMPARISON_HISTORY_6
    const [count, setCount] = useState(4);
    const [rowCount, setRowCount] = useState(0);
    const [comparisonData, setComparisonData] = useState([]);
    const [pdfUrl, setPdfUrl] = useState('');
    const [blobbase64, setBlobbase64] = useState('');

    const { productId,productName } = useCreateProductContext();

    const { userData , isNavCollapsed} = useCommonContext()
    console.log(productName,"namename")

    const location = useLocation();

    //COMPARISON_HISTORY_7
    const navigate = useNavigate();
    // const location = useLocation();

    useEffect(() => {
        //COMPARISON_HISTORY_8
        getComparisonsByProductId();
    }, [count]);

    //COMPARISON_HISTORY_9
    const getComparisonsByProductId = async () => {
        console.log("getComaparison function");
        try {
            //COMPARISON_HISTORY_10
            const payload = {
                userId: userData[0]["User_Id"],
                productId: productId,
                dataCount: count
            };
            //COMPARISON_HISTORY_11
            const responseData = await getComparisonsByProductIdApi(payload);
            setComparisonData(responseData.data.data);
            console.log(responseData);
            //COMPARISON_HISTORY_28
            setRowCount(responseData.data.data[0].Total_Row_Count);
            //COMPARISON_HISTORY_29
            setPdfUrl(responseData.data.data[0].Report_Url)
            //COMPARISON_HISTORY_30
            bloburl_to_pdf_base64(responseData.data.data[0].Report_Url)
            console.log(responseData.data.data[0].Total_Row_Count)
        } catch (error) {
            console.error(error);
        }
    };
    //COMPARISON_HISTORY_33
    const loadMore = () => {
        //COMPARISON_HISTORY_34
        if (rowCount > count) {
            //COMPARISON_HISTORY_35
            setCount(prevCount => prevCount + 10);
        }
    };
    console.log(pdfUrl, "Link");
    //COMPARISON_HISTORY_38
    const handleDownload = () => {
        //COMPARISON_HISTORY_40
        const link = document.createElement('a');
        console.log(link);
        //COMPARISON_HISTORY_41
        link.href = pdfUrl;
        //COMPARISON_HISTORY_42
        link.download = pdfUrl;
        //COMPARISON_HISTORY_43
        document.body.appendChild(link);
        //COMPARISON_HISTORY_44
        link.click();
        //COMPARISON_HISTORY_45
        document.body.removeChild(link);
    };


    const bloburl_to_pdf_base64 = async (blobUrl) => {
        console.log("Blob_to_url Called");

        if (blobUrl) {
            try {
                const response = await fetch(blobUrl, {
                    headers: {
                        'Accept': 'application/pdf' // Specify that you expect PDF content
                    }
                });

                const blob = await response.blob();

                const reader = new FileReader();
                reader.onloadend = () => {
                    let base64Data = reader.result;
                    base64Data = base64Data.replace("/octet-stream", "/pdf")
                    setBlobbase64(base64Data);
                    console.log(base64Data, "base uri link for the PDF");
                };
                reader.readAsDataURL(blob); // Convert blob to base64
            } catch (error) {
                console.error('Error converting blob URL to base64:', error);
            }
        }
    };

    //COMPARISON_HISTORY_48
    const handlePrint = (base64Uri) => {
        try {
            // Decode the base64 URI to get the raw base64 data
            const base64Data = atob(base64Uri.split(',')[1]);

            // Converting the base64 data to a Uint8Array is necessary because Blob expects its data to be in the form of a Uint8Array or ArrayBuffer.
            const uint8Array = new Uint8Array(base64Data.length);
            for (let i = 0; i < base64Data.length; i++) {
                uint8Array[i] = base64Data.charCodeAt(i);
            }

            // Create a Blob from the Uint8Array
            const blob = new Blob([uint8Array], { type: 'application/pdf' });

            // Create a URL for the Blob
            const blobUrl = URL.createObjectURL(blob);

            // Open the new tab with the Blob URL
            const printWindow = window.open(blobUrl);

            //COMPARISON_HISTORY_49
            if (!printWindow) {
                throw new Error('Failed to open print window.');
            }

            // Handle errors or other logic
            // ...
        } catch (error) {
            console.error('Error printing PDF:', error);
            // Handle the error, e.g., display an error message to the user
        }
    };



    return (

        <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
            <div className="row g-2 py-4">
                <div className="col-auto ps-4">
                    <a href="#">
                        <img src="images/back-icon.svg" alt="back-icon" className=" me-3" onClick={() => {
                            navigate("/productcontrast")
                        }} />
                    </a>
                </div>
                <div className="col">
                    <h2 className="font-bold font-22 mb-4">{productName}</h2>
                    <div className="table-responsive theme-tbl">
                        <table className="table table-borderless">
                            <thead className="font-medium bg-white">
                                <tr>
                                    <th>Last Comparison Date</th>
                                    <th className="text-center">Report Generation Status</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody className="font-medium">
                                {comparisonData.map((values, index) => (
                                    <tr key={index}>
                                        <td>{values.Comparison_Date}</td>
                                        <td className="text-center">
                                            <span className={`batch-sty font-12 font-medium ${values.Is_Report_Generated === true ? 'batch-green' : 'batch-orange'}`}>
                                                {values.Is_Report_Generated === true ? 'Completed' : 'Pending'}
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <span className="d-flex align-items-center justify-content-center gap-2">
                                                <button className="edit-btn" onClick={() => { handleDownload(); setPdfUrl(values.Report_Url); }}>
                                                    <img src="images/blue-download.svg" alt="table-edit" />
                                                </button>

                                                <span className="border-sape" />
                                                <button className="green-btn" onClick={() => { handlePrint(blobbase64) }}>
                                                    <img src="images/view-doc-icon.svg" alt="view-doc-icon" />
                                                </button>

                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4 mb-4">
                    {rowCount > count && (<button
                        onClick={loadMore} className="btn shadow-btn d-flex align-items-center py-2 px-3  font-bold font-16">
                        Load More
                    </button>
                    )}
                </div>
            </div>
        </div>

    )
}