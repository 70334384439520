import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCommonContext } from "../../contexts/CommonContext";
import { getDropDownDetailsApi, logout } from "../../services/api";

export default function Navigation() {
  const { openToast, NavigationTest, SetNavigationTest, isNavCollapsed, setIsNavCollapsed } = useCommonContext();
  // const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const { userData } = useCommonContext();
  const [contentTypes, setContentTypes] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();


  async function logoutC() {
    try {

      const payload = {
        "userId": userData[0]["User_Id"]
      }

      const logoutApiResponse = await logout(payload)
      return true

    } catch (error) {
      return false

    }
  }


  useEffect(() => {
    const handleNavCollapseClick = () => {
      setIsNavCollapsed((prevCollapsed) => !prevCollapsed);
      const navListNames = document.getElementsByClassName("navName");
      const navCollapseBtn = document.getElementById("navCollapseBtn");
      const navBar = document.getElementById("sideNavbar");
      const navLogo = document.getElementById("sideNavLogo");
      const contentAdj = document.getElementById("content-collapse");
      const transformCollapse = document.getElementById("btn-arrow");
      const contentCont = document.getElementById("contentBox");
      const navDropTog = document.getElementsByName("navDropTog");

      if (navCollapseBtn) {
        for (let i = 0; i < navListNames.length; i++) {
          const navListName = navListNames[i];
          if (navListName.classList.contains("d-inline-block")) {
            navListName.classList.remove("d-inline-block");
            navListName.classList.add("d-none");
          } else {
            navListName.classList.remove("d-none");
            navListName.classList.add("d-inline-block");
          }
        }
        // Toggle between "side-nav-expand" and "side-nav-collapse" classes
        navBar.className = navBar.className.includes("side-nav-expand")
          ? "side-nav-collapse"
          : "side-nav-expand";
        navLogo.className = navLogo.className.includes("nav-logo-section")
          ? "nav-logo-section-collapse"
          : "nav-logo-section";
        contentAdj.className = contentAdj.className.includes("conten-between")
          ? "conten-center"
          : "conten-between";
        transformCollapse.className = transformCollapse.className.includes(
          "transform-0"
        )
          ? "transform-180"
          : "transform-0";
        contentCont.className = contentCont.className.includes(
          "content-conatiner"
        )
          ? "collapsed-con"
          : "content-conatiner";
      }
    };

    const navCollapseBtn = document.getElementById("navCollapseBtn");
    if (navCollapseBtn) {
      navCollapseBtn.addEventListener("click", handleNavCollapseClick);
    }

    return () => {
      if (navCollapseBtn) {
        navCollapseBtn.removeEventListener("click", handleNavCollapseClick);
      }
    };
  }, []);

  async function getContentIds() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        tableName: "Content_Types",
      };

      const responseData = await getDropDownDetailsApi(payload);
      setContentTypes(responseData.data.data.data);
    } catch (error) {
      openToast(error?.response?.data?.message, "");
    }
  }

  async function contentNavigator(type) {
    try {
      const contentTypeId = contentTypes.filter((value, index) => {
        return value.Content_Type === type;
      })[0]?.Content_Type_Id;
      let toLink;
      if (type === "Case Study") {
        toLink = "/yourcasestudies";
      } else if (type === "Web Pages") {
        toLink = "/yourwebpages";
      } else if (type === "Press Release") {
        toLink = "/yourpressreleases";
      } else if (type === "Article") {
        toLink = "/yourarticles";
      } else if (type === "Social Media Post") {
        toLink = "/yoursocialmediaposts";
      }
      navigate(toLink, {
        state: {
          contentTypeId: contentTypeId,
          contentType: type,
        },
      });
    } catch (error) { }
  }
  useEffect(() => {
    if (window.localStorage.getItem("token")) {
      getContentIds();
    }
  }, []);

  console.log(userData, "This is location data in notididdidi");

  console.info(contentTypes, "this");
  return (
    <div className="side-nav-expand" id="sideNavbar">
      <div className="nav-logo-section" id="sideNavLogo">
        <a
          onClick={() => {
            if (userData[0]["Role_Name"] == "Content Manager") {
              navigate("/hub");
            } else if (userData[0]["Role_Name"] == "Content Writer") {
              contentNavigator("Article");
            } else if (userData[0]["Role_Name"] == "Product Owner") {
              navigate("/productcontrast");
            }
          }}
          className="d-inline-block navName align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
        >
          <img src="images/brand-logo.svg" alt="brand-logo" />
        </a>
        <button
          id="navCollapseBtn"
          className="nav-arrow align-items-center d-flex justify-content-center"
        >
          <img
            src="images/nav-arrow.svg"
            alt="nav-arrow"
            id="btn-arrow"
            className="transform-0"
          />
        </button>
      </div>
      <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow">
        {userData[0]["Role_Name"] === "Content Manager" ? (
          <li
            className={`side-nav-item d-flex align-items-center mb-2 font-14 font-medium gap-3 ${location.pathname === "/hub" ? "active" : ""
              } `}
            onClick={() => {
              navigate("/hub");
            }}
          >
            <img src="images/hub-icon.svg" alt="hub-icon" />
            <span className="d-inline-block navName">Hub</span>
          </li>
        ) : null}
        {userData[0]["Role_Name"] === "Content Manager" ? (
          <li
            className={`side-nav-item d-flex align-items-center mb-2 font-14 font-medium gap-3 ${location.pathname === "/requests" || location.pathname == "/approvecontent" ? "active" : ""
              }`}
            onClick={() => {
              navigate("/requests");
            }}
          >
            <img src="images/request-icon.svg" alt="hub-icon" />
            <span className="d-inline-block navName">Requests</span>
          </li>
        ) : null}
        {userData[0]["Role_Name"] !== "Product Owner" ? (
          <li
            className={`side-nav-item side-nav-drop d-flex align-items-center mb-2 font-14 font-medium justify-content-between collapsed ${location.state?.contentType == "Press Release" ||
              location.state?.contentType == "Web Pages" ||
              location.state?.contentType == "Social Media Post" ||
              location.state?.contentType == "Case Study" ||
              location.state?.contentType == "Article"
              ? "active"
              : ""
              }`}
            data-bs-toggle="collapse"
            data-bs-target="#contentGenList"
          >
            <span
              className="d-flex justify-content-between align-items-center w-100"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="d-flex align-items-center gap-3">
                <img src="images/content-icon.svg" alt="hub-icon" />
                <span className="d-inline-block navName">
                  Content Generation
                </span>
              </span>
              <img
                src="images/list-arrow.svg"
                alt="list-arrow"
                className="list-arrow d-inline-block navName"
              />
            </span>
            <ul
              className="dropdown-menu dropdown-menu-dark nav-drop font-medium"
              name="navDropTog"
              hidden={!isNavCollapsed}
            >
              <li
                className="cursor-pointer"
                onClick={() => {
                  contentNavigator("Article");
                }}
              >
                Article
              </li>
              <li
                className="cursor-pointer"
                onClick={() => {
                  contentNavigator("Case Study");
                }}
              >
                Case Study
              </li>
              <li
                className="cursor-pointer"
                onClick={() => {
                  contentNavigator("Social Media Post");
                }}
              >
                Social Media Post
              </li>
              <li
                className="cursor-pointer"
                onClick={() => {
                  contentNavigator("Web Pages");
                }}
              >
                Web Pages
              </li>
              <li
                className="cursor-pointer"
                onClick={() => {
                  contentNavigator("Press Release");
                }}
              >
                Press Release
              </li>
            </ul>
          </li>
        ) : null}
        <li className="collapse px-4" id="contentGenList">
          <ul className="sub-list list-unstyled ps-4 font-14 font-medium d-inline-block navName">
            <li
              className={`mb-2 cursor-pointer ${location.state?.contentType == "Article" ? "active" : ""
                }`}
              onClick={() => {
                contentNavigator("Article");
              }}
            >
              Article
            </li>
            <li
              className={`mb-2 cursor-pointer ${location.state?.contentType == "Case Study" ? "active" : ""
                }`}
              onClick={() => {
                contentNavigator("Case Study");
              }}
            >
              Case Study
            </li>
            <li
              className={`mb-2 cursor-pointer ${location.state?.contentType == "Social Media Post"
                ? "active"
                : ""
                }`}
              onClick={() => {
                contentNavigator("Social Media Post");
              }}
            >
              Social Media Post
            </li>
            <li
              className={`mb-2 cursor-pointer ${location.state?.contentType == "Web Pages" ? "active" : ""
                }`}
              onClick={() => {
                contentNavigator("Web Pages");
              }}
            >
              Web Pages
            </li>
            <li
              className={`mb-2 cursor-pointer ${location.state?.contentType == "Press Release" ? "active" : ""
                }`}
              onClick={() => {
                contentNavigator("Press Release");
              }}
            >
              Press Release
            </li>
          </ul>
        </li>

        {userData[0]["Role_Name"] !== "Product Owner" &&
          userData[0]["Role_Name"] !== "Content Writer" ? (
          <li
            className={`side-nav-item side-nav-drop d-flex align-items-center mb-2 font-14 font-medium justify-content-between collapsed ${location.pathname === "/myevents" ||
              location.pathname === "/createmyevents" ||
              location.pathname === "/upcommingevents"
              ? "active"
              : ""
              }`}
            data-bs-toggle="collapse"
            data-bs-target="#eventsList"
          >
            <span
              className="d-flex justify-content-between align-items-center w-100"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="d-flex align-items-center gap-3">
                <img src="images/event-icon.svg" alt="hub-icon" />
                <span className="d-inline-block navName">Event Management</span>
              </span>
              <img
                src="images/list-arrow.svg"
                alt="list-arrow"
                className="list-arrow d-inline-block navName"
              />
            </span>
            <ul
              className="dropdown-menu dropdown-menu-dark nav-drop font-medium"
              name="navDropTog"
              hidden={!isNavCollapsed}
            >
              {userData[0]["Role_Name"] === "Content Manager" ? (
                <li
                  className="cursor-pointer"
                  onClick={() => {
                    navigate("/myevents");
                  }}
                >
                  My Events
                </li>
              ) : null}
              <li
                className="cursor-pointer"
                onClick={() => {
                  navigate("/upcommingevents");
                }}
              >
                Upcoming Events
              </li>
            </ul>
          </li>
        ) : null}
        <li className="collapse px-4" id="eventsList">
          <ul className="sub-list list-unstyled ps-4 font-14 font-medium d-inline-block navName">
            {userData[0]["Role_Name"] === "Content Manager" ? (
              <li
                className={`mb-2 cursor-pointer ${location.pathname === "/myevents" ||
                  location.pathname === "/createmyevents"
                  ? "active"
                  : ""
                  }`}
                onClick={() => {
                  navigate("/myevents");
                }}
              >
                My Events
              </li>
            ) : null}
            <li
              className={`mb-2 cursor-pointer ${location.pathname === "/upcommingevents" ? "active" : ""
                }`}
              onClick={() => {
                navigate("/upcommingevents");
              }}
            >
              Upcoming Events
            </li>
          </ul>
        </li>

        {userData[0]["Role_Name"] === "Content Writer" ? (
          <li
            className={`side-nav-item d-flex align-items-center mb-2 font-14 font-medium gap-3 ${location.pathname === "/upcommingevents" ? "active" : ""
              }`}
            onClick={() => {
              navigate("/upcommingevents");
            }}
          >
            <img src="images/event-icon.svg" alt="hub-icon" />
            <span className="d-inline-block navName">Upcoming Events</span>
          </li>
        ) : null}

        <li
          className={`side-nav-item d-flex align-items-center mb-2 font-14 font-medium gap-3 ${(location.pathname === "/productcontrast" || location.pathname === "/history" || location.pathname === "/product" || location.pathname === "/comparisionmetrics" || location.pathname === "/feature" || location.pathname === "/result") ? "active" : ""
            }`}
          onClick={() => {
            navigate("/productcontrast");
          }}
        >
          <img src="images/product-contrast-icon.svg" alt="hub-icon" />
          <span className="d-inline-block navName">Product Contrast</span>
        </li>
        {userData[0]["Role_Name"] === "Content Manager" ? (
          <li
            className={`side-nav-item d-flex align-items-center mb-2 font-14 font-medium gap-3 ${location.pathname === "/integrations" ? "active" : ""
              }`}
            onClick={() => {
              navigate("/integrations");
            }}
          >
            <img src="images/integration-icon.svg" alt="hub-icon" />
            <span className="d-inline-block navName">Integrations</span>
          </li>
        ) : null}
        {userData[0]["Role_Name"] === "Content Manager" ? (
          <li
            className={`side-nav-item d-flex align-items-center mb-2 font-14 font-medium gap-3 ${location.pathname === "/manageusers" ? "active" : ""
              }`}
            onClick={() => {
              navigate("/manageusers");
            }}
          >
            <img src="images/manager-user-icon.svg" alt="hub-icon" />
            <span className="d-inline-block navName">Manage Users</span>
          </li>
        ) : null}
      </ul>

      <ul className="nav nav-pills flex-column">
        {userData[0]["Role_Name"] !== "Product Owner" ? (
          <li
            className={`side-nav-item d-flex align-items-center mb-2 font-14 font-medium gap-3 ${location.pathname === "/notifications" ? "active" : ""
              }`}
            onClick={() => {
              navigate("/notifications");
            }}
          >
            <img src="images/notification-icon.svg" alt="hub-icon" />
            <span className="d-inline-block navName">Notifications</span>
          </li>
        ) : null}
      </ul>

      <span className="nav-saperator d-block mb-3" />
      <div className="conten-between" id="content-collapse">
        <div
          className="d-flex gap-2"
          onClick={() => {
            navigate("/userprofile");
          }}
        >
          <img
            src={userData[0]["Profile_Image_Url"]}
            alt="user-img"
            className="user-img"
          />
          <div className="d-inline-block navName">
            <span className="d-block font-14 font-medium">
              {userData[0]["Firstname"] + " " + userData[0]["Lastname"]}
            </span>
            <span className="d-block font-12 font-regular">
              {userData[0]["Email_Id"]}
            </span>
          </div>
        </div>
        <img
          onClick={async () => {
            await logoutC()
            SetNavigationTest(true);
            navigate("/");
          }}
          src="images/logout-icon.svg"
          alt="logout-icon"
          className="cursor-pointer d-inline-block navName"
        />
      </div>
    </div>
  );
}