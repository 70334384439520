export default function SingleProductroadmaprow(props) {
    return (
      <tr>
        {props?.RoadMapDetailsArray?.map((value, index) => {
          return (
            <>
              <td>
                {(value) ?<><label className="font-medium font-12 mb-1">
                  {(value?.Upcoming_Feature) ? value?.Upcoming_Feature : ""}
                </label>
                <p className="font-12 font-medium grey-v5">
                  {(value?.Feature_Description) ? value?.Feature_Description : "Null" }
                </p></>:"-"}
              </td>
            </>
          );
        })}
      </tr>
    );
  }