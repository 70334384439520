import { useCreateProductContext } from "../../contexts/CreateProductContext";
import {
  pdftoBackendApi,
  generateFeaturepdfApi,
  generateOverviewpdfApi,
  generatePricingpdfApi,
  generateRecommendationFeaturepdfApi,
  generateRecommendationPricingpdfApi,
} from "../../services/api";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCommonContext } from "../../contexts/CommonContext";
import OverviewResult from "./OverviewResult";
import FeatureResult from "./FeatureResult";
import FeatureRecommondations from "./FeatureRecommondations";
import PricingResult from "./PricingResult";
import PricingRecommondation from "./PricingRecommondations";
import ProductRanking from "./ProductRanking";
import ProductRoadMapResult from"./ProductRoadMapResult"
import { ProductRoadMapRecommendations } from "./ProductRoadMapRecommendations";

export default function ComparisonResult() {
  const { productId, name } = useCreateProductContext();

  const [generatedTime, setGeneratedTime] = useState(false);

  const navigate = useNavigate();
  console.log(productId, "oejdojwd"); 

  const location = useLocation();
  const { userData,isNavCollapsed } = useCommonContext();

  const isFeatureNeeded = location.state.isFeatureNeeded;
  const isPricingNeeded = location.state.isPricingNeeded;
  const isRoadmapNeeded = location.state.isRoadmapNeeded;




  console.info(isFeatureNeeded, isPricingNeeded,isRoadmapNeeded, "rwrwerwr");


  useEffect(() => {
    generateComparisons();
  }, []);

  const generateComparisons = async () => {
    try {
      const payload = {
        productId: productId,
        userId: userData[0]["User_Id"],
      };

      const [
        overviewResponsepdf,
        featureResponsepdf,
        pricingResponsepdf,
        recommendationfeatureResponsepdf,
        recommendationpricingResponsepdf,
      ] = await Promise.all([
        generateOverviewpdfApi(payload),
        generateFeaturepdfApi(payload),
        generatePricingpdfApi(payload),
        generateRecommendationFeaturepdfApi(payload),
        generateRecommendationPricingpdfApi(payload),
      ]);

      const overviewPdf = overviewResponsepdf.data.data.overview_pdf;
      const featurePdf = featureResponsepdf.data.data.feature_pdf;
      const pricingPdf = pricingResponsepdf.data.data.pricing_pdf;
      const recommendation_feature_Pdf = recommendationfeatureResponsepdf.data.data.recommendation_feature_pdf;
      const recommendation_pricing_Pdf = recommendationpricingResponsepdf.data.data.recommendation_pricing_pdf;


      const generatedTimeString = true;
      setGeneratedTime(generatedTimeString);

      //data to send back to the backend
      const pdfData = {
        productId: productId,
        userId: userData[0]["User_Id"],
        overviewPdf: overviewPdf,
        featurePdf: featurePdf,
        pricingPdf: pricingPdf,
        Recommendation_feature_Pdf: recommendation_feature_Pdf,
        Recommendation_pricing_Pdf: recommendation_pricing_Pdf,
        name: name,
      };
      // Send additional data back to the backend
      const backendResponse = await pdftoBackendApi(pdfData);

      // Handle backend response if needed
      console.log(backendResponse);
    } catch (error) {
      console.info(error.message);
    }
  };

  return (
    <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
      <div className="row g-2 py-4">
        <div className="col-auto ps-4">
          <a>
            <img
              src="images/back-icon.svg"
              alt="back-icon"
              className=" me-3"
              onClick={() =>
                navigate("/feature", {
                  state: {
                    productId: productId,
                    isFeatureNeeded: isFeatureNeeded,
                    isPricingNeeded: isPricingNeeded,
                    isRoadmapNeeded: isRoadmapNeeded,
                  },
                })
              }
            />
          </a>
        </div>
        <div className="col">
          <div className="d-flex justify-content-between mb-4">
            <h2 className="font-bold font-22 mb-0">
              {name} Comparison Results
            </h2>
            {generatedTime === true ? (
              <label className="generate-lable d-flex align-items-center font-15 font-medium">
                <span className="font-bold me-2">Generated on: </span>
                {new Date().toLocaleDateString()} & {" "}
                {new Date().toLocaleTimeString()}
              </label>
            ) : null}
          </div>
          <div className="row">
            <div className=" col-md-12">
              <ul
                className="nav nav-pills border-bottom"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link cus-tab-link cus-tab-border px-0 py-3 active me-3"
                    id="Overview-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#Overview"
                    type="button"
                    role="tab"
                    aria-controls="Overview"
                    aria-selected="true"
                  >
                    <span>Overview</span>
                  </button>
                </li>
                {isFeatureNeeded ? (
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link cus-tab-link cus-tab-border px-0 py-3 me-3"
                      id="Features-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#Features"
                      type="button"
                      role="tab"
                      aria-controls="Features"
                      aria-selected="false"
                    >
                      <span>Features</span>
                    </button>
                  </li>
                ) : null}

                {isPricingNeeded ? (
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link cus-tab-link cus-tab-border px-0 py-3 me-3"
                      id="Pricing-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#Pricing"
                      type="button"
                      role="tab"
                      aria-controls="Pricing"
                      aria-selected="false"
                    >
                      <span>Pricing</span>
                    </button>
                  </li>
                ) : null}

                {isRoadmapNeeded ? (
                <li class="nav-item" role="presentation">
                                    <button class="nav-link cus-tab-link cus-tab-border px-0 py-3 me-5" id="product-roadmap-tab" data-bs-toggle="pill" data-bs-target="#product-roadmap" type="button" role="tab" aria-controls="product-roadmap" aria-selected="false">
                                      <span>Product Roadmap</span>
                                  </button>
                </li>
                ) : null}


                <li class="nav-item" role="presentation">
                                    <button class="nav-link cus-tab-link cus-tab-border px-0 py-3" id="product-ranking-tab" data-bs-toggle="pill" data-bs-target="#product-ranking" type="button" role="tab" aria-controls="product-ranking-tab" aria-selected="false">
                                      <span>Product Ranking</span>
                                  </button>
                 </li>

              </ul>

              <div className="tab-content px-0" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="Overview"
                  role="tabpanel"
                  aria-labelledby="Overview-tab"
                >
                  <div className="table-responsive theme-tbl p-0 compar-grid my-3 border-0">
                    {/* overview page starts here */}
                    <OverviewResult />
                    {/* overview page ends here */}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="Features"
                  role="tabpanel"
                  aria-labelledby="Features-tab"
                >
                  <div className="table-responsive theme-tbl p-0 compar-grid my-3 border-0">
                    {/* Feature table starts here */}
                    <FeatureResult />
                    <FeatureRecommondations />
                    {/* Feature table ends here */}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="Pricing"
                  role="tabpanel"
                  aria-labelledby="Pricing-tab"
                >
                  <div className="table-responsive theme-tbl p-0 compar-grid my-3 border-0">
                    <PricingResult />
                  </div>

                  {/* Recommendation pricing starts here */}
                  <PricingRecommondation />
                  {/* Recommendation pricing ends here */}
                </div>

                <div
                  className="tab-pane fade"
                  id="product-roadmap"
                  role="tabpanel"
                  aria-labelledby="product-roadmap"
                >
                  <div className="table-responsive theme-tbl p-0 compar-grid my-3 border-0">
                    <ProductRoadMapResult />
                  </div>

                    <ProductRoadMapRecommendations />
                </div>

                <div
                  className="tab-pane fade"
                  id="product-ranking"
                  role="tabpanel"
                  aria-labelledby="product-ranking"
                >
                  <div className="table-responsive theme-tbl p-0 compar-grid my-3 border-0">
                    <ProductRanking />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
