import { useCommonContext } from "../../contexts/CommonContext";
import { useMyEventContext } from "../../contexts/MyEventContext";
import DayView from "./DayView";
import EventHeader from "./EventHeader";
import MonthView from "./MonthView";

import {useNavigate} from "react-router-dom"

export default function MyEvents() {
  const {
    myEvents,
    setMyEvents,
    isDayView,
    currentDateForEv,
    setCurrentDateForEv,
  } = useMyEventContext();

  const {isNavCollapsed, setIsNavCollapsed } = useCommonContext();

  const navigate = useNavigate()

  return (
    <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
      <div className="row ">
        <div className="col-md-12 content-generated">
          <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
            <h2 className="font-bold font-22">My Events</h2>
            <button className="btn primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap" onClick={()=>{
              navigate("/createmyevents")
            }}>
              <img src="images/add-icon.svg" alt="add-icon" />
              Create New Event
            </button>
          </div>
          <div className="card border-0 shadow-sm p-4 mb-4">
            <EventHeader />
            <div className="row gx-5">
              {isDayView ? <DayView /> : <MonthView />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
