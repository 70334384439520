import React, { useState } from "react";
import AccountAndPlatform from "../AccountsAndPlatform";
import { useCreateContentContext } from "../../../contexts/CreateContentContext";
import { generateContentApi } from "../../../services/api";
import { getContentByIdApi } from "../../../services/api";
import { useCommonContext } from "../../../contexts/CommonContext";
import { ReactSortable } from "react-sortablejs";

export default function CaseStudyFields() {
  const {
    setGeneratedContent,
    caseStudyformData,
    setCaseStudyFormData,
    account,
    setAccount,
    contentID,
    setContentID,
    approvalStatus,
    contentTypeId,
setIsLoading,
contentName,setContentName
  } = useCreateContentContext();

  const [state, setState] = useState([{ id: 1, name: "" }]);

  const { userData } = useCommonContext();
  const [caseStudyFile, setCaseStudyFile] = useState([]);

  //generate content function starts here;
  async function generateContent() {
    console.info(caseStudyformData, "+++++++++++++++++++++++++++++++++");
    console.info(account, "------------------------------");
    try {
      let formDataNew = caseStudyformData;
      formDataNew.References_Citations = state;
      const payload = {
        userId: userData[0]["User_Id"],
        contentTypeId: contentTypeId,
        contentName: "",
        configurationJson: formDataNew,
        contentType: "Case Study",
        contentId: "",
      };

setIsLoading(true);
      const resonseData = await generateContentApi(payload);
setIsLoading(false);

      let contentId = resonseData.data.data.contentId;

      setContentID(contentId);

      console.info(contentID, "content Id");

      const contentByIdPayload = {
        userId: userData[0]["User_Id"],
        contentId: contentId,
      };
      const contentResponseData = await getContentByIdApi(contentByIdPayload);

      console.log(
        contentResponseData.data.data.data[0]["Generated_Content"],
        "This is the html stting from the content gen api"
      );

      setContentName(contentResponseData.data.data.data[0]["Content_Name"])
      let htmlString =
        contentResponseData.data.data.data[0]["Generated_Content"];

      htmlString = htmlString.replaceAll(/^`\\html|\\`$/g, "");

      let counter = 1;
      const getReplacementHtmlSnippet = (count) => {
        return `<div class="position-relative content-upload text-center mb-4"><button class="btn primary-gradient-btn font-bold text-white font-16 text-nowrap box-shadow-none" onClick="generateImage(${count})"><img src="images/generate-icon.svg" alt="generate-icon" class="me-2"/>Generate image</button><p class="font-16 font-medium mt-4 mb-1">or <label class="primary-theme-color cursor-pointer" for="uploadImg2">upload files</label> from your computer</p><p class="font-14 font-regular color-grey">( .png, .jpg, .pdf up to 25MB in size)</p><input type="file" class="cust-file" id="uploadImg2" accept="image/*" onchange="initialFileUpload(event,${count})"/></div>`;
      };

      // Replace all occurrences of <ImagePlaceHolder/> with the replacement HTML snippet
      htmlString = htmlString.replaceAll("<ImagePlaceHolder/>", () => {
        const replacement = getReplacementHtmlSnippet(counter);
        counter++; // Increment counter for the next occurrence
        return replacement;
      });

      setGeneratedContent(htmlString);
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleInputChange = (section, field, value) => {
    setCaseStudyFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleReferencesChange = (index, value) => {
    setCaseStudyFormData((prevData) => ({
      ...prevData,
      References_Citations: {
        ...prevData.References_Citations,
        references: [
          ...prevData.References_Citations.references.slice(0, index),
          value,
          ...prevData.References_Citations.references.slice(index + 1),
        ],
      },
    }));
  };

  const handleUploadFilesChange = (files) => {
    setCaseStudyFormData((prevData) => ({
      ...prevData,
      References_Citations: {
        ...prevData.References_Citations,
        form_Upload: files,
      },
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    setCaseStudyFile(fileName);
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        let array = caseStudyformData?.References_Citations?.uploadFile;
        console.info(caseStudyformData, "AAArraaaayyyyyy");
        let jsonArray = {
          fileData: e.target.result,
          fileName: file.name,
          fileSize: file.size,
        };
        console.log("JSON Array");
        array.push(jsonArray);
        console.log("JSON Array 2");
        // array.push(e.target.result);
        setCaseStudyFormData((prevData) => ({
          ...prevData,
          References_Citations: {
            ...prevData.References_Citations,
            uploadFile: array,
          },
        }));
        console.log("Json array 3");
      } catch (error) {
        console.error("Error parsing JSON file:", error);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleAddDiv = () => {
    const newItem = { id: state.length + 1, name: "" };
    setState([...state, newItem]);
  };

  const handleRemoveDiv = (id) => {
    if (state.length === 1) {
      // If there's only one item, do not remove it
      return;
    }
    const updatedState = state.filter((item) => item.id !== id);
    setState(updatedState);
  };

  const handleChangeName = (id, value) => {
    const updatedState = state.map((item) =>
      item.id === id ? { ...item, name: value } : item
    );
    setState(updatedState);
  };

  const isPendingOrApproved =
    approvalStatus === "Pending" || approvalStatus === "Approved";

  return (
    <div className="col-md-3 mt-4">
      <div className="px-3">
        {/* Working Title */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#CaseStudyTitile"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Case Study Title
          </p>
          <div className="collapse show" id="CaseStudyTitile">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="topicOrtheme"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Working Title <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title="provides users with an text area where they can input the working title of their case study. ">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Working Title"
                  className="form-control custom-form  font-14 font-regular"
                  id="topicOrtheme"
                  value={caseStudyformData.Working_Title.workingTitle}
                  onChange={(e) =>
                    handleInputChange(
                      "Working_Title",
                      "workingTitle",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Working title ends */}
        {/* Introduction starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#introduction"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Introduction
          </p>
          <div className="collapse show" id="introduction">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="PreferredContentStructure"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Client/Organization overview{" "}
                  <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title="">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Briefly introduce the client and challenge"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="PreferredContentStructure"
                  defaultValue={""}
                  value={caseStudyformData.Introduction.introduction}
                  onChange={(e) =>
                    handleInputChange(
                      "Introduction",
                      "introduction",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Introduction ends */}
        {/* Challenge Identification  */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#challenge"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Challenge Identification
          </p>
          <div className="collapse show" id="challenge">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="PreferredContentStructure"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Problem statement <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title=" A text box enabling users to articulate the problem statement associated with the identified challenge.Users should be able to input detailed information regarding the specific challenge they have identified.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="The specific problem or challenge was identified through..."
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="PreferredContentStructure"
                  defaultValue={""}
                  value={
                    caseStudyformData.Challenge_Identification
                      .challengeIdentification
                  }
                  onChange={(e) =>
                    handleInputChange(
                      "Challenge_Identification",
                      "challengeIdentification",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Challenge Identification ends here */}
        {/* Solution Overview starts */}
        <div className="border-bottom mb-4 cust-border">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#overview"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Solution Overview{" "}
          </p>
          <div className="collapse show" id="overview">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="PreferredContentStructure"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Solution<span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title=" A text box allowing users to outline the proposed solution to address the identified challenge.Users should be able to enter detailed information regarding the proposed solution">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Describe the solution or approach implemented to address the challenge"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="PreferredContentStructure"
                  defaultValue={""}
                  value={caseStudyformData.Solution_Overview.solutionOverview}
                  onChange={(e) =>
                    handleInputChange(
                      "Solution_Overview",
                      "solutionOverview",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#Result"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Result &amp; Outcomes{" "}
          </p>
          <div className="collapse show" id="Result">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="PreferredContentStructure"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Outcomes <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title=" A text box enabling users to document the results and outcomes achieved through the implementation of the proposed solution.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Achieved measurable results"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="PreferredContentStructure"
                  defaultValue={""}
                  value={caseStudyformData.Result_Outcomes.resultOutcomes}
                  onChange={(e) =>
                    handleInputChange(
                      "Result_Outcomes",
                      "resultOutcomes",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Solution Overview ends */}
        {/* Target Audience starts */}
        <div className="border-bottom mb-4 cust-border">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#target"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Target Audience{" "}
          </p>
          <div className="collapse show" id="target">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="targetAudience"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Target audience <span className="mandatory-red">*</span>
                  <span data-bs-toggle="tooltip" title=" allows users to select the primary audience category for the content.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="targetAudience"
                  value={caseStudyformData.Target_Audience.targetAudience}
                  onChange={(e) =>
                    handleInputChange(
                      "Target_Audience",
                      "targetAudience",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  <option>Select</option>
                  <option>Industry Professionals</option>
                  <option>Potential Clients</option>
                  <option>Stakeholders</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Target Audience starts */}
        {/* Tone and style starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#toneStyle"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Tone &amp; Style{" "}
          </p>
          <div className="collapse show" id="toneStyle">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="toneContent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Tone of the content{" "}
                  <span data-bs-toggle="tooltip" title="User can select the appropriate tone for the content.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="toneContent"
                  value={caseStudyformData.Tone_And_Style.toneContent}
                  onChange={(e) =>
                    handleInputChange(
                      "Tone_And_Style",
                      "toneContent",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  {" "}
                  <option>Select</option>
                  <option>Formal</option>
                  <option>Conversational</option>
                  <option>Technical</option>
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="styleContent"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Style of the content{" "}
                  <span data-bs-toggle="tooltip" title="allow users to specify the stylistic approach or narrative technique employed in the generated content.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="styleContent"
                  value={caseStudyformData.Tone_And_Style.styleContent}
                  onChange={(e) =>
                    handleInputChange(
                      "Tone_And_Style",
                      "styleContent",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  {" "}
                  <option>Select</option>
                  <option>Humor</option>
                  <option>Storytelling</option>
                  <option>Data-driven</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Tone and style starts */}
        {/* Tone and style starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#accountPlatform"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Account &amp; Platform{" "}
          </p>
          <div className="collapse show" id="accountPlatform">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="socialMediaAccount"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Social media account <span className="mandatory-red">*</span>{" "}
                  <span data-bs-toggle="tooltip" title="Displays a list of accounts integrated on the integration page.Users can select the desired social media account from this dropdown">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                </label>
                <select
                  className="form-select custom-form  font-14 font-regular"
                  id="socialMediaAccount"
                  value={
                    caseStudyformData.Account_And_Platform?.socialMediaAccount
                  }
                  onChange={(e) =>
                    handleInputChange(
                      "Account_And_Platform",
                      "socialMediaAccount",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                >
                  <option value="Select">Select</option>
                  {account?.map((value, index) => {
                    return (
                      <option value={value?.Account_Id}>
                        {value?.Account_Name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {caseStudyformData.Account_And_Platform?.socialMediaAccount !=
              "Select" ? (
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="platform"
                    className="form-label mb-0 cust-label font-14 font-medium mb-2"
                  >
                    Platforms
                    <span
                      data-bs-toggle="tooltip"
                      title="Lorem ipsumis a dummy"
                    >
                      <img
                        className="ms-2 mb-1"
                        src="images/info-icon.svg"
                        alt="info-icon"
                      />
                    </span>
                  </label>
                  {account
                    .filter(
                      (value) =>
                        value.Account_Id ===
                        caseStudyformData.Account_And_Platform
                          ?.socialMediaAccount
                    )
                    .map((filteredAccount) => {
                      return (
                        <>
                          <div
                            className="form-check"
                            hidden={filteredAccount.Facebook_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="fbPost"
                              checked={
                                caseStudyformData.Account_And_Platform.fbPost
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "fbPost",
                                  e.target.checked
                                )
                              }
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label font-14 font-medium check-label"
                              htmlFor="fbPost"
                            >
                              Facebook post
                            </label>
                          </div>

                          <div
                            className="form-check"
                            hidden={filteredAccount.Linkedin_Credentials
                              == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="LinkedinPost"
                              checked={
                                caseStudyformData.Account_And_Platform
                                  .linkedinPost
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "linkedinPost",
                                  e.target.checked
                                )
                              }
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="LinkedinPost"
                            >
                              Linkedin post
                            </label>
                          </div>

                          <div
                            className="form-check"
                            hidden={filteredAccount.Twitter_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="twitterPost"
                              checked={
                                caseStudyformData.Account_And_Platform
                                  .twitterPost
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "twitterPost",
                                  e.target.checked
                                )
                              }
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="twitterPost"
                            >
                              Twitter post
                            </label>
                          </div>
                          <div
                            className="form-check"
                            hidden={filteredAccount.Instagram_Credentials == 0}
                          >
                            <input
                              className="form-check-input cust-check box-shadow-none"
                              type="checkbox"
                              defaultValue
                              id="instaCap"
                              checked={
                                caseStudyformData.Account_And_Platform.instaCap
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Account_And_Platform",
                                  "instaCap",
                                  e.target.checked
                                )
                              }
                              disabled={isPendingOrApproved}
                            />
                            <label
                              className="form-check-label  font-14 font-medium check-label"
                              htmlFor="instaCap"
                            >
                              Instagram caption
                            </label>
                          </div>
                        </>
                      );
                    })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* Tone and style starts */}
        {/* Implementation process starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#implementation"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Implementation process
          </p>
          <div className="collapse show" id="implementation">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="PreferredContentStructure"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Implementation
                  <span data-bs-toggle="tooltip" title="A text box allowing users to describe the implementation process for executing the proposed solution.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Step by step process included..."
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="PreferredContentStructure"
                  defaultValue={""}
                  value={
                    caseStudyformData.Implementation_Process
                      .implementationProcess
                  }
                  onChange={(e) =>
                    handleInputChange(
                      "Implementation_Process",
                      "implementationProcess",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Implementation process ends */}
        {/* Impact on business starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#businessImpact"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Impact on Business
          </p>
          <div className="collapse show" id="businessImpact">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="PreferredContentStructure"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Business Impact
                  <span data-bs-toggle="tooltip" title="A text box enabling users to document the impact of the implemented solution on the business.Users should be able to enter detailed information regarding the positive or negative effects of the solution on various aspects of the business, such as revenue, productivity, customer satisfaction, etc.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Positive impact by..."
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="PreferredContentStructure"
                  defaultValue={""}
                  value={caseStudyformData.Impact_On_Business.impactOnBusiness}
                  onChange={(e) =>
                    handleInputChange(
                      "Impact_On_Business",
                      "impactOnBusiness",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Impact on business ends */}
        {/* Future Consideration starts */}
        <div className="border-bottom cust-border mb-4">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#futureConsideration"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Future Considerations
          </p>
          <div className="collapse show" id="futureConsideration">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="PreferredContentStructure"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  Future strategies
                  <span data-bs-toggle="tooltip" title="A text box allows users to outline future strategies or considerations related to the implemented solution.Users should be able to enter detailed information regarding potential enhancements, adjustments, or expansions to the solution in the future.">
                    <img
                      src="images/info-icon.svg"
                      alt="info-icon"
                      className="ms-2 mb-1"
                    />
                  </span>
                </label>
                <textarea
                  placeholder="Ongoing strategies and future consideration involve"
                  rows={7}
                  className="form-control custom-form cust-tarea  font-14 font-regular"
                  id="PreferredContentStructure"
                  defaultValue={""}
                  value={
                    caseStudyformData.Future_Considerations.futureConsiderations
                  }
                  onChange={(e) =>
                    handleInputChange(
                      "Future_Considerations",
                      "futureConsiderations",
                      e.target.value
                    )
                  }
                  disabled={isPendingOrApproved}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Future Consideration ends */}
        {/* References & Citationse starts */}
        {/* References & Citationse starts */}
        <div className="">
          <p
            className="font-14 font-medium cursor-pointer "
            data-bs-toggle="collapse"
            data-bs-target="#RefCit"
          >
            <img
              src="images/form-acc-arrow.svg"
              alt="form-acc-arrow"
              className="me-2 form-arrow"
            />
            Reference &amp; Citation
          </p>
          <div className="collapse show" id="RefCit">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="SpecifySEO"
                  className="form-label mb-0 cust-label font-14 font-medium mb-2"
                >
                  References{" "}
                </label>
                <span data-bs-toggle="tooltip" title="input field where users should able to enter reference URLs. Users should able to add multiple reference URLs by clicking the + icon next to the input field.">
                    <img
                      className="ms-2 mb-1"
                      src="images/info-icon.svg"
                      alt="info-icon"
                    />
                  </span>
                  <ReactSortable list={state} setList={setState}>
          {state.map((item) => (
            <div
              className="d-flex align-items-center gap-2 grab-comp mb-2"
              key={item.id}
            >
              <span>
                <img src="images/drag-dots.svg" className="cursor-grab" />
              </span>
              <input
                className="form-control custom-form font-14 font-regular"
                value={item.name}
                onChange={(e) => handleChangeName(item.id, e.target.value)}
              />
              <span>
                <img
                  src="images/plus-icon.svg"
                  className="cursor-pointer d-block"
                  onClick={handleAddDiv}
                />
              </span>
              {state.length > 1 && ( // Render minus icon only if there's more than one div
                <span>
                  <img
                    src="images/minus-icon.svg"
                    className="cursor-pointer d-block py-2"
                    onClick={() => handleRemoveDiv(item.id)}
                  />
                </span>
              )}
            </div>
          ))}
        </ReactSortable>
        <div className="col-md-12 mb-3">
          <label
            htmlFor="formUpload"
            className="form-label mb-0 cust-label font-14 font-medium mb-2"
          >
            Upload
            <span data-bs-toggle="tooltip" title="Users should be able to upload files related to references.This upload feature allows users to attach files directly from their computer by either selecting them or dragging and dropping them into the designated area.">
              <img
                src="images/info-icon.svg"
                alt="info-icon"
                className="ms-2 mb-1"
              />
            </span>
          </label>
          <div className=" position-relative mb-3">
            <label
              className="upload-form w-100 text-center"
              htmlFor="formUpload"
            >
              <img src="images/upload-icon.svg" alt="upload-icon" />
              <span className="d-block mb-2 font-medium font-12">
                Upload files from computer
              </span>
              <span className="d-block mb-2 font-regular font-10 color-grey">
                or drag and drop here
              </span>
              <span className="d-block mb-2 font-regular font-8 color-grey">
                (Supported format: Microsoft Word up to 50MB)
              </span>
            </label>
            <input
              onChange={(e) => handleFileChange(e)}
              type="file"
              className="cust-file"
              id="formUpload"
            />
          </div>
          {caseStudyformData.References_Citations?.uploadFile?.map((data, index) => {
            return (
              <label className="file-path d-flex justify-content-between align-items-center">
                <span className="d-block">
                  <span className="d-block font-medium font-14">
                    {data.fileName}
                  </span>
                  <span className="d-block font-regular color-grey font-14">
                    ({data.fileSize})
                  </span>
                </span>
                <span className="d-block">
                  <input
                    className="form-check-input cust-check box-shadow-none"
                    type="checkbox"
                    defaultValue
                    id="fbPost"
                  />
                </span>
              </label>
            );
          })}
        </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ReactSortable list={state} setList={setState}>
          {state.map((item) => (
            <div
              className="d-flex align-items-center gap-2 grab-comp mb-2"
              key={item.id}
            >
              <span>
                <img src="images/drag-dots.svg" className="cursor-grab" />
              </span>
              <input
                className="form-control custom-form font-14 font-regular"
                value={item.name}
                onChange={(e) => handleChangeName(item.id, e.target.value)}
              />
              <span>
                <img
                  src="images/plus-icon.svg"
                  className="cursor-pointer d-block"
                  onClick={handleAddDiv}
                />
              </span>
              {state.length > 1 && ( // Render minus icon only if there's more than one div
                <span>
                  <img
                    src="images/minus-icon.svg"
                    className="cursor-pointer d-block py-2"
                    onClick={() => handleRemoveDiv(item.id)}
                  />
                </span>
              )}
            </div>
          ))}
        </ReactSortable>
        <div className="col-md-12 mb-3">
          <label
            htmlFor="formUpload"
            className="form-label mb-0 cust-label font-14 font-medium mb-2"
          >
            Upload
            <span data-bs-toggle="tooltip" title="Lorem ipsumis a dummy">
              <img
                src="images/info-icon.svg"
                alt="info-icon"
                className="ms-2 mb-1"
              />
            </span>
          </label>
          <div className=" position-relative mb-3">
            <label
              className="upload-form w-100 text-center"
              htmlFor="formUpload"
            >
              <img src="images/upload-icon.svg" alt="upload-icon" />
              <span className="d-block mb-2 font-medium font-12">
                Upload files from computer
              </span>
              <span className="d-block mb-2 font-regular font-10 color-grey">
                or drag and drop here
              </span>
              <span className="d-block mb-2 font-regular font-8 color-grey">
                (Supported format: Microsoft Word up to 50MB)
              </span>
            </label>
            <input
              onChange={(e) => handleFileChange(e)}
              type="file"
              className="cust-file"
              id="formUpload"
              disabled={isPendingOrApproved}
            />
          </div>
          {caseStudyformData.References_Citations?.uploadFile?.map(
            (data, index) => {
              return (
                <label className="file-path d-flex justify-content-between align-items-center">
                  <span className="d-block">
                    <span className="d-block font-medium font-14">
                      {data.fileName}
                    </span>
                    <span className="d-block font-regular color-grey font-14">
                      ({data.fileSize})
                    </span>
                  </span>
                  <span className="d-block">
                    <input
                      className="form-check-input cust-check box-shadow-none"
                      type="checkbox"
                      defaultValue
                      id="fbPost"
                    />
                  </span>
                </label>
              );
            }
          )}
        </div> */}
        {/* References & Citations ends */}
        {/* References & Citations ends */}

        {/* form action starts here */}
        <div className="d-flex justify-content-end py-3 form-foot">
          <button className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2">
            Cancel
          </button>
          <button
            onClick={() => {
              generateContent();
            }}
            disabled={
              isPendingOrApproved ||
              caseStudyformData.Working_Title?.workingTitle == "" ||
              caseStudyformData.Introduction?.introduction == "" ||
              caseStudyformData.Challenge_Identification
                .challengeIdentification == "" ||
              caseStudyformData.Solution_Overview.solutionOverview == "" ||
              caseStudyformData.Result_Outcomes.resultOutcomes == "" ||
              caseStudyformData.Target_Audience.targetAudience == "Select" ||
              caseStudyformData.Account_And_Platform.socialMediaAccount ==
                "Select"
            }
            className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
          >
            <img
              src="images/generate-icon.svg"
              alt="generate-icon"
              className="me-2"
            />
            {contentID == "" ? "Generate" : "Regenerate"}
          </button>
        </div>
        {/* form action ends here */}
      </div>
    </div>
  );
}
