import { useCreateMyEventContext } from "../../contexts/CreateMyEventContext";

export default function CreateMyEventsFormAction() {
  const {
    formData,
    isEventDetailOpen,
    setIsEventDetailOpen,
    meetingLink,
    setMeetingLink,
    eventId,
    setEventId,
    setIsLoading
  } = useCreateMyEventContext();

  const areAllRequiredFieldsFilled = () => {
    const requiredFields = [
      "eventName",
      "eventDescription",
      "fromDateEvent",
      "fromTimeEvent",
      "toDateEvent",
      "toTimeEvent",
      "eventType",
      "accountName",
      "meetingPlatform",
    ];
    for (let field of requiredFields) {
      if (!formData[field]) {
        return false; // Return false if any required field is missing
      }
    }
    return true; // Return true if all required fields are filled
  };

  return (
    <div className="d-flex justify-content-end py-3 form-foot">
      <button className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2">
        Cancel
      </button>
      <button
        className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
        onClick={() => {
          console.info(formData);
          setIsEventDetailOpen(true);
          setMeetingLink("");
          setIsLoading(false)
          
        }}
        disabled={!areAllRequiredFieldsFilled()}
      >
        <img
          src="images/magic-wizard-white.svg"
          alt="generate-icon"
          className="me-2"
        />
        {eventId == "" ? "Generate" : "Regenerate"}
      </button>
    </div>
  );
}
