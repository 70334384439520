export const endpoints = {
    "nonProd": {
        "validate": 'https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/validate_token?code=Ub0p2csop2ZQbczyTbf0RFiUErNvTOYqOY4TpPgro4KzAzFuAGAtYQ==',
        "getComparisionmetricbyProductidApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_comparison_metrics_by_product_id?code=tKGE-jZ7XZ8ynwaoIj5Osig9N7te1jTBYNs1NB86xK3MAzFuxuN2QA==",
        "getWatchlistbyProductidApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_watchlist_by_product_id?code=G08jViNhmlQnqT7Pkj4ob3X6xFJ0WNHoJ2ZCRQ333raBAzFu7nSJhg==",
        "selectCompetitorApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/select_competitors?code=Wc16vfmAz-4mWhb0kyuaFqx6qUVDjIqfsZfifWKzK4PEAzFuiAOKPQ==",
        "generateOverviewApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_overview?code=k_eLIKEAnPS8iKyf36FpljxFjBkaXiO1A6cK4FMSNMy1AzFu6bm11Q==",
        "generateFeatureApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_feature?code=a3WtmA9lNh6RRSDiCpFn_7cLZDQ6BihCCcqHg8a0R6Y0AzFu7cXyPw==",
        "generatePricingApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_pricing?code=O9ukY-aRevwSd7rMdwJZ_GE9m2tgBkDmNoqbCZSWyBi8AzFu41UY6w==",
        "generateRecommendationApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_recommendation?code=E4SlhBpL8ey_dMwDDO9f0y2e6h_FHuQ3lh5XIGO5iT-gAzFuq6q9uQ==",
        "pdftoBackendApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_email?code=fELZDnVtiH9fPBePKdqKgMC3g15mtsQKNGbzRQem5L7kAzFuJd35nA==",
        "insertCompetitorsApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_links_and_insert_competitors?code=GsmQs2JLPeFA9R4dT-6AneXqCUL_vxknlA3BIvE2iFz4AzFuGxgCaw==",
        "insertHtmlApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_html_and_update_competitors?code=DB9ZvAU1G9AEGZblAd5lJ-0cZbvmksnmPGeA9BC8kSyrAzFucnFALQ==",
        "insert_nameApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_name?code=L9atQm9lbwUS9SZPGQyI-Pjch1fjkdrIN555oz4iCTv_AzFups_B4w==",
        "insert_overviewApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_overview?code=TjfxN_aseLcU7xlmYZyPXICmMF4kkQpSBfSlqCxguQHkAzFubGpxpQ==",
        "insert_featureApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_feature?code=ye-ZRLPWrnCUN9oShbq_Gc0l1HLtd5r1YV5YlrJaUSHDAzFuuWkmhQ==",
        "insert_pricingApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_pricing?code=Ic_E5r-5A-AKxpSd2_1yOtsdCOQo_D6IQ5texKzqbwOcAzFuPXytpQ==",
        "insert_feature_tableApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_feature_table?code=16STvB3gOFWOUyVm7JXEvkNVP8ANFlL8PtZD-Dhid387AzFu9yxGPA==",
        "insert_pricing_tableApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_pricing_table?code=TPbgqEpqTqF_9sunArfALWJy2j2Myy8A__OIvjPXaOh2AzFubb3Uig==",
        "postFacebookApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/post_in_facebook?code=-zBAKt-9cN4iQq-hiVPgD0MB0dNQ2QeWVH-SgeSKICEyAzFuf0xxqQ==",
        "getAiContentsApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/get_highlight_details?code=5AmaYuUK8omaaOTc_d-2E-CQGW7E3U6rFhBzfxJKnuXMAzFuMolUjg==",
        "get_configJson": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/get_content_by_id?code=eF25vV-kY61ignSBJQMziD4vcGgkh_E55k-rYcgCwCWIAzFu41bQjQ==",
        "generateSocialMediaContentApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/generate_social_media_content?code=uQJouOobotkapOa1VRhxXJFZ3pwNFm9giIa3FklDb49DAzFukAjx5g==",
        "approveStatusApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/submit_request?code=_1GBKsmIW8RwQnrhvmlp8eeFALkDL1MIvMOWj1Lfo0iOAzFugi-Lvg==",
        "createSocialApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/generate_social_media_content?code=AgAAqPaxZOXssSz1wAVQv0XQdJk1DtmV8d-84BtUyFYzAzFuDj5mGQ==",
        "createContentForMediaApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/create_content_for_media?code=LlxEiLMHmE8GDiCRCvgWBbn6guRIbGHT0_ZrsXlpD5w5AzFuy6vZLA==",
        "createImageForMediaApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/generate_image?code=Qdo9a5vVvOR2yBA1rMtWvzXyuRaBc0rgtlMrpFLwWKl2AzFufXpuAQ==",
        "generateImageSmApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/generate_image?code=Qdo9a5vVvOR2yBA1rMtWvzXyuRaBc0rgtlMrpFLwWKl2AzFufXpuAQ==",
        "getContentIdApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/insert_content_for_media?code=8sKkGyyBzZlVEb9YUPwIVztmOkk9-_pzi-swdibIoQrcAzFuWw2N7Q==",
        "insertSocialMediaTableApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/generate_social_media_content?code=AgAAqPaxZOXssSz1wAVQv0XQdJk1DtmV8d-84BtUyFYzAzFuDj5mGQ==",
        "generateOverviewpdfApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_overview_pdf?code=GjRKbHIoxop0A2qezgTSV7rZ5gTrgiCm0pcc0HkdHHgIAzFuUiD9wA==",
        "generateRecommendationFeatureApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_recommendation_feature?code=oDKbk5ZZV5ujLRbooCZry11d0kyUl-MoqerrbOSxIs_bAzFuqAaxzQ==",
        "generateRecommendationPricingApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_recommendation_pricing?code=qh4MmyE-fpyyOaVl5Q9zIdFjfZ32aKvBVOMg-hNQaPsgAzFu0fVKfA==",
        "generateRecommendationFeaturepdfApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_recommendation_feature_pdf?code=A65PmDG4NfShwQXjQaHK791H-jkZuTIrT3bFRt2L4IvPAzFuksNlZQ==",
        "generateRecommendationPricingpdfApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_recommendation_pricing_pdf?code=YrTo1U6aLqc-VAKuDagusB5yKxlpgUjLncJBeBwdvQNjAzFuIokyIA==",
        "generateFeaturepdfApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_feature_pdf?code=Gsj_ncg-WzrzL10XAZBOgbGHCP2lmkXwFo9Jzddox4YhAzFulCIS8g==",
        "generatePricingpdfApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_pricing_pdf?code=2BlI5IdLcioSvxWmovKc_y-kI244sqT6Urp5J9XK6QfuAzFuqNQ68g==",
        "generateRecommendationpdfApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_recommendation_pdf?code=TQjPP75e3oqFXJAH7Dkcggj2SZpADfbsexRl8gizDL4XAzFuPm99Xw==",
        "imageuploadApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/update_and_delete?code=JzclTLDw9BRMVUzKsd0s7-SvzqEBr2iVkTzTBlMAoELzAzFuEjxB-A==",
        "select_one_competitor": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/select_one_competitor?code=COamLa6eEoTF89VUtgU2n0T_Mf4IzJFwS_GGCd0eQy69AzFuuBAyWA==",
        "get_pricing_result": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_pricing_result?code=fc9O1jrBy31A5jjij8yPjiZ8EsgTJQbZ0-dKEIcsYxmpAzFuIYOcfw==",
        "insert_pricing_recommendation": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_pricing_recommendation?code=j-sxX1jfa6jxObzzPgICD86eK6ti_vZu-bKIYXi47bxHAzFu7h22Cw==",
        "get_productbyId_FeaturePricing": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_productbyId_FeaturePricing?code=KlROq3hzE9RSyfs2QBT4V62XGHiSAcHLWIv_QZLrbMDPAzFumqj9JA==",
        "get_feature_tick": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_feature_tick?code=5nNAAJPLCLZ4iZOcGGfmZwtOGyQTLenSGF8_bMAmTgyUAzFuzY0Vyg==",
        "insert_feature_recommendation": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_feature_recommendation?code=_OWXi11uGrgdueWJWmadLhrBgXRz6zt_TKJ41FkhFH7HAzFuYa4wWA==",
        "logout": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/logout?code=OprXj_H75vpopR5eIpQe1mVU0IhEsNyQ0Uw6ha4AvRIPAzFuN7o8JQ==",
        "getAllUpcomingEventsHubApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/get_upcomming_events_hub?code=SgSHnwXhQXkBgJafiHmGnPOIwqF4rK32aqBP4Eu06l2KAzFu9LDocg==",
        "getAllUpcomingEventsApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/get_all_upcomming_events?code=C9mQKoaT96TvqzZdnZVaG1ZT3JMsKE6EUDt3ucFKyiZCAzFuoSUo_Q==",
        "insertUpcomingEventsStatusApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/update_event_status?code=0VSj_QgIySjOGXT6m0dL7ILmEAvzb77Yft2Jkon-bw03AzFuT0KF0Q==",
        "getAllContentsApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/get_all_contents?code=bwqqz16RNeIU9wXAxGLToDwyPbdc-tiYRtF7BdZN8mFZAzFuUz5CVQ==",
        "deleteContentApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/delete_content?code=JcdBq_lfNXdheXuaMttGIuorJm1QmX-THnTujf0JRU2eAzFuk3xvRA==",
        "getAllRequestApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/get_all_request?code=NkqsvmWBoH8m_45rj9qYC6SrsMd0OM-BQBtzKcrzfti0AzFuxwTD6w==",
        "updateUserDataApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/update_user_data?code=5IAcCO175ijelO_cFxEgaYBrbuqkMAGywpdNQMVdp6PYAzFuxWQjxw==",
        "createEventPostsApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/create_event_posts?code=erchhN_HtG-9HeA_njoG1kdJr2kPROCX9Ra3G1gonLbQAzFuVJMFFw==",
        "getEventPostsApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/get_event_posts?code=dl1yDw5O1oMTgGpVbpxXBllkRZOozmN7Zo-xeBFiRWKiAzFubBqiIA==",
        "savePostContentApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/save_post_content?code=gBkbB7W_PvYrMD40g7M9aEoMjaMeab4lWi1f5dXbRB1bAzFuKDw10w==",
        "getContentByIdApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/get_content_by_id?code=eF25vV-kY61ignSBJQMziD4vcGgkh_E55k-rYcgCwCWIAzFu41bQjQ==",
        "generateContentApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/generate_content?code=hURVVHHIGB6WgLmPzgC09pHMPHjIM4nEHL8KDsBYTr_yAzFuQ0gyag==",
        "askAiApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/ask_ai?code=PaQBb-HQc4FpgZwMfJJ5GgWmatI00Z8tjVDJGbXoifiuAzFuRmMYSA==",
        "generateImageApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/generate_image?code=LhEbVOSxbTceAtIQHif0_SOYv1U0asYLXdULyKELML4jAzFucPwWiQ==",
        "submitRequestApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/submit_request_cg?code=YCYxyC23aAVUxc5K12Cit-NSL28snuMCu02nsXtVO_TaAzFuQ8B4fg==",
        "saveContentApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/save_content?code=2N2hRazVCf1NohYwJEnN1-9vRYLI4vxYKE6t4ak5IgncAzFuV1toVw==",
        "saveFeatureApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/save_feature?code=VJwVmVuQ_HB1CAe12kEKJ50lanBdSVfVHPenJrqfYBxxAzFuS0Wkmg==",
        "getAllFeatureApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_all_features?code=tuGjj9s_PNG6LxRF9CgEro3mVGiB2kPUUnPzoyWgL_BOAzFuNNg2nw==",
        "updateFeatureApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/update_feature?code=hM0w4zRIqjZm-YOe3M6Zi_VtBgZBkz7qVT9GYcy7ZWaSAzFutVTwig==",
        "deleteFeatureApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/delete_feature?code=6cakXPhvaUUoinbDLjQVeUweT8KWGeyLo-hkQkb_CvPfAzFuMK5LIA==",
        "savePricingApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/save_pricing?code=YWjIVZ7SZP8_XEp2OhLHukQaPruI1CmhmW7TuugMfM0kAzFu1w41vQ==",
        "getAllPricingApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_all_pricing?code=GyskZtA4kOlowCVRQVjwCjxyrQfDps-H-u5kkKkuM1KNAzFuiQLQTA==",
        "updatePricingApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/update_pricing?code=_lXEYEUesrVgfHVIdE1wbA96vYTFwgBNgHa2Mqa3B8cfAzFusBEy2w==",
        "deletePricingApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/delete_pricing?code=CwH-H2-rkIPgdm7SN9jy1iPanzD-eNdCB98oBt6S-6goAzFuaE8iUg==",
        "getAllProductsApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_all_products?code=qafpzMA01LDa66vWjqVdU61n5YzFLW2nub8I9Urc8tWIAzFuAz3HZQ==",
        "getProductbyIdApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/select_product_by_id?code=Ax_LQy1SEmTFNiRCeW6mUqGh4LZd4bM-QyoJytQsu6jkAzFuTBtsPw==",
        "getComparisonsByProductIdApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_comparisons_by_product_id?code=M_k2zCQmEMCZ2yyGp3gwUW5QdKu8C0dJ_kHaV_Nppk3CAzFuPquZZA==",
        "getAllCompetitorsApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_all_competitors?code=YilCtHMYMoDax-pxbfHkik-NdXgKGwIkfl2Us6rwmih5AzFuPAqcfQ==",
        "saveComparisonMetricsApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/save_comparision_metrics?code=_H38ptC3zEdSWlyi0swoIavGP7in0nd-b13Z-cfAUSGtAzFuTk5SJQ==",
        "addCompetitorsManuallyApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/add_competitors_manually?code=LPyvMheVzCBLEE90Qtw3H4xCxyCmbtB9tSdVfqix4YobAzFuNOV7pQ==",
        "createProductApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/create_product?code=YIVWlTVlURVdEmjeIF6Y0ajoeF7rl5oaQ8SkXgGPypEYAzFufMql-w==",
        "getUserDataApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/get_user_data?code=_Lh8VTuxuCt3wmhPO2NHlnrTs7fQSA2G6d6eBOvxSlmWAzFu3C-2lw==",
        "checkDetailsApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/login?code=HI5ZdYOp0KqPuKsIqptjuPhN-_cmPuWNJy_KvdsrMsHbAzFumJwRjg==",
        "insertDetailsApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/signup?code=IfAwZTTFCLpEpTy0AiiiNSl4o4UftWh1X0dCjY_ZbEVHAzFuaStSCA==",
        "checkMailDetailsApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/forgot_password?code=Q1alvUuP81cvxIXso7_ULfg3bHS2EtAXoijqkiXoDvCTAzFuPVqF_g==",
        "updatePasswordApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/set_new_password?code=QIDxvwousFZH9eWWI00QN23_wafxfHUYf3c8AhI5oxkGAzFu5Ugd7w==",
        "getPendingRequestCountApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/pending_request_count?code=-7nvEPDRUcRe_s8LinJMSZUBAaf1DsGuEekxpnH5BHTKAzFu7WgyPA==",
        "getRecentActivitiesApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/get_recent_activity?code=qr7JRQEqWKM_NhfqxTU6Xw4vSi3C4bKN5YjaBDRR9OXFAzFupOR1Rg==",
        "getMediaPostsApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/get_recent_posts?code=xmLJZ-enz8Cb8MwY7F7t_BgzEOncAx6Pi0bG4RudvSHHAzFu9z6FUw==",
        "getImpressionApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/get_impression?code=OVVcxT6_zbO-g9FTW0E1hBSoU_kBK7mf0WDTJIm-7etJAzFujlVBWA==",
        "getAllAccountApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/get_account?code=Epc8UX908cth6eYmuXYoKHTyKp9YqwLeMOdEByl1ZsCjAzFuWLx-EQ==",
        "createAccountApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/create_account?code=a1swLgAGdMyzUwsathnHhBGhoP0bE7GpnYPch6VxCxK1AzFu741esg==",
        "deleteAccountApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/delete_account?code=sCgsfyhhif48VhYRlE4TOn2QUerUlWQ47TUdsxYW1-IpAzFuMg2wgg==",
        "editAccountApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/edit_account?code=H4VUNrnfqQLO9Qw3pG6c_QWkBpSLAykI0S88YdRTqk1FAzFu8JrsUQ==",
        "addPlatformApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/add_platform?code=jwbtyBhbmMxbdbbzBoTv5fxSKxaSuSG5_VpJYCYzdZktAzFuZ4qO7w==",
        "platformConnectionApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/platform_connection?code=sFcNwijNJo3AXwogHT3-EmFzB2RGZi1x4eb1KeQwoK5gAzFuvB1ixg==",
        "editPlatformApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/edit_platform?code=f1AVXC_LtGNe6UQpa6pTy9rAsFj5RuiS3qg_D9tzs2VWAzFuoYi43Q==",
        "getAllSoicalMediaContentApi": "http://localhost:7075/api/get_highlight_details",
        "postInstagramApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/post_instagram_content?code=kACoIrQOh9qWQg-131PGzfUQWa3bxn9MSvz78qmTyTVZAzFuZkZ9HQ==",
        "postFaceBookApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/post_in_facebook?code=-zBAKt-9cN4iQq-hiVPgD0MB0dNQ2QeWVH-SgeSKICEyAzFuf0xxqQ==",
        "postTwitterApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/post_in_twitter?code=Ft1SpIiga635xOT9aTCS4DYMBKL5fdzGsgb9cs2mbjwhAzFuafFTbw==",
        "postLinkedInApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/post_linkedin_content?code=HYbTNElEDlcGdkq1JCv4SLhvs7wh2tDrQgIhPpvdY5O1AzFun7LzCA==",
        "regeneratePostApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/generate_social_media_content?code=uQJouOobotkapOa1VRhxXJFZ3pwNFm9giIa3FklDb49DAzFukAjx5g==",
        "fetchAllUserApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/select_all_user?code=l5BoVh2Wq1TYBf1_xSRHNu6fJIgB-uiDKKJCD7Il4MmXAzFunDbTJw==",
        "deleteUserApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/delete_user?code=1fheRHhihbSlvEXsdFdt1n0ngKpGssKl2ttXNVLwcmH3AzFumGoVyg==",
        "addUserApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/insert_edit_user?code=GRndOBaFlAjvA8m5dlt5PGFKRGU2DAzR37Epz_G3iOkJAzFu1sqisg==",
        "getDropDownDetailsApi": "https://ava-eus-sp-fn-hub-dev.azurewebsites.net/api/get_dropdown_data?code=odg8dDe9YaODpxO4KOr2CdEjdUVuC_1Vjt9W3TzRv2DWAzFuYUxbdw==",
        "fetchNotificationApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/get_all_notification?code=mXfSHJ0MpvFTrInppwTXhTkS9O7Pgn22lC4py68zq4-jAzFuOLIPGQ==",
        "createMyEventApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/create_my_events?code=L3z4JVU0kblPPXMwB_cxMW19G9vg4ZE4J9iSLnR5-bv5AzFuoaMBqA==",
        "getAllAccountsApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/get_all_accounts?code=XyAOcE5NcrNcQluiHAnj4G2Bx_Bqsj4r45G7LmCaYGi_AzFuOwk6ww==",
        "getMyEventsApi": "https://ava-eus-sp-fn-sm-dev.azurewebsites.net/api/get_my_events?code=xDypoeSFyRlf3eti3dSX5rKPtnIv_p1bQfUivwBlp4yuAzFuYfj9Mg==",
        
        "save_roadmap_featuresApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/save_roadmap_features?code=TtJjaoPBdXnXlhfiyiufJqLnecVHd9eoDPJkPWwl5AzYAzFu-4kHvA==",
        "update_roadmap_featuresApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/update_roadmap_features?code=_w8N2I5MK0wcBcpyuoEEWgMa77-2g4w9xQQorOUc-jwcAzFujkPUtw==",
        "delete_roadmap_featuresApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/delete_roadmap_features?code=WXi5uvEW9iYG1d68y1gkxxisHTQ3DwrB-fgvG7RekFSqAzFuzMvheg==",
        "get_all_roadmap_featuresApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_all_roadmap_features?code=M0Lx5yuGb130Ton-gzieqUeX284HuEqh0-WYWr0zViuqAzFuSYsjDA==",
        "get_competitor_roadmap_featuresApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_competitor_roadmap_features?code=mHIADlYRkMVdEO3g7vVlrKNtA-7Ajhm2VW8WG0Bb7LMqAzFutkNL3g==",
        "insert_product_azure_review_tableApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_product_azure_review_table?code=k8Mm-LgTHb7ZQGCFhlxifahCcCWEbIJ-ak9ZrA6__A8gAzFuVibjbw==",
        "insert_product_aws_review_tableApi":"https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_product_aws_review_table?code=gzdy1d5tZFOJ4_o_MrPnMLx5F82uZfM26i6-cz9k_U_ZAzFusB8uTg==",
        "insert_competitor_azure_reviewApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_competitor_azure_review?code=8zgRGPTB3POisJ9cDNXa-zsXsH1t25uKH1n_zpqbmc9VAzFu7SohKw==",
        "insert_competitor_aws_reviewApi":  "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_competitor_aws_review?code=rTx8gtHxWUmU-WmSwSRj7nh-3i5cdzPxWIbptyYHSscOAzFuaeeanA==",
        "insert_competitor_roadmap_tableApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_competitor_roadmap_table?code=ceOLBzBy-viayz9LdK7Zwfqll31gP-unaWZmNNz1Ck7YAzFuhQRs6g==",
        "insert_roadmap_recommendation": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/insert_roadmap_features_recommendation?code=lacGR4bCXsm3KkV1DOsiTtN2aqQqa8rRInvOV9kjj_dXAzFuJEAiew==",
        "get_product_ranking":  "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/get_product_ranking?code=oqtr7XQd8LOvh2Mhpht6gFm_7AEgo8qDPeH3NNHipghnAzFuSh6k0A=="





    },
    "prod": {
        "validate": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/validate_token?code=UMLnZMwl1mLD8XED1TDBEzcY7idpt-9b_jqmM_XAmQkQAzFuIz-rbw==",
        "getComparisionmetricbyProductidApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_comparison_metrics_by_product_id?code=WgB4cKkSIXsPKotDtMER0QNomzTeM_O2IYCBrNCxiGXSAzFuwd9mlA==",
        "getWatchlistbyProductidApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_watchlist_by_product_id?code=lhNn9H-esLuyZzY1LAGiLRw4xXNpvJ9oHrQhHZA7G3AwAzFucSSXlw==",
        "selectCompetitorApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/select_competitors?code=zNaTqXQLP_kUPsGs_rHgFopaEBKrKU0anXNOle3nyQOBAzFuKxhqRg==",
        "generateOverviewApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/generate_overview?code=RncvBLsDmF2cgzEx0sZGck0PmVtxr32Cdfj0jRdqMFGnAzFu0_E5YA==",
        "generateFeatureApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/generate_feature?code=vFoLwj1Ial1K4taJsPuzGCgtbSm47PUtXK2Fx3_h_eglAzFukdBhDQ==",
        "generatePricingApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/generate_pricing?code=Uw_qRnykBijKdtmLvZ64yJ2dWtYuMAhjYXG-8zlh90pTAzFu3hmaxA==",
        "generateRecommendationApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_recommendation?code=mVWLilylSPWFs98VAX8UQYmqhb3joEELYsd1VOtF13-nAzFuhV8tpw==",
        "pdftoBackendApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/generate_email?code=WOkeXdHN8yqm_Fqqe1d_unJu5AJcZnuyx1zKJPLWKrf6AzFuIS8faQ==",
        "insertCompetitorsApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_links_and_insert_competitors?code=4hMoCRPpyFtgjbEoLujNBj5QdP1mwNFUl9lfzwJnz0uZAzFuVBTHPQ==",
        "insertHtmlApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_html_and_update_competitors?code=xEmlY3JnHAhbX5kRViqGoFPN_ljFdaoyWrEaZmOtlm-PAzFufBAcQA==",
        "insert_nameApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_name?code=PtRXxEcDQ0mY3vUBtJSH9_7bTjDhl1O5qsnsRoeaISM0AzFus9VK5Q==",
        "insert_overviewApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_overview?code=cs1jsGaF2F-jpk0I_WOWDli6miN1SfoS6o-1hn9OjV_pAzFuX-8Nzg==",
        "insert_featureApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_feature?code=6JbNi3SrAscKDRcZ9S2cXJcytqaV_Ji1XZZfzq-jxGZ5AzFusE0Pbw==",
        "insert_pricingApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_pricing?code=qTRYy_Ldjye5_6Sm38a33SBRWoWbL6KfAI5FFZpGW1f0AzFugXnXAw==",
        "insert_feature_tableApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_feature_table?code=iSH0xR-wpYXivcLQPp8yKqG9g669FrqRORDAkZ68rS33AzFu-WUW0Q==",
        "insert_pricing_tableApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_pricing_table?code=FNWLj8zqYLN_rysN2N91wrBgNWP1sMMb7AYeGuvhd4DAAzFuUWhwZg==",
        "postFacebookApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/post_in_facebook?code=P8EBtSY1MF71LLWC0grm4U26RnZJ3DI7e68-UsWMxYYIAzFuFhXgBQ==",
        "getAiContentsApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/get_highlight_details?code=yV_fz5eIaFBcfK4i8YQgngM1YUZOe109WsKWDI8lWPmkAzFuUpZiKQ==",
        "get_configJson": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/get_content_by_id?code=RbQMdnXh6OatK4not8VTWjAvG-6EuIRHqk79GMESSHVSAzFuxlIAAQ==",
        "generateSocialMediaContentApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/generate_social_media_content?code=QsnPZNeNg_V8QTQALxnmGj40NzQbp6Nj_rPN8zjcRer3AzFuD1Ascg==",
        "approveStatusApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/submit_request?code=v_rFjRXaepzXMZ-71IxqnrCF862Q4ZMtL-X3iAehEjpYAzFuQYS5zg==",
        "createSocialApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/generate_social_media_content?code=f3oNUhX09JTi7U-roBW_Uj6N6-mgEWBWyFBx7OvHEPghAzFuwYUcAQ==",
        "createContentForMediaApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/create_content_for_media?code=voXfYyt-7jVindFl1zhxtjI1G1vS25cT3KC1R-293dUXAzFu_Dt0hg==",
        "createImageForMediaApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/generate_image?code=5yPETN2J0AdlFjVF27B9mtxyAOytYtEQaBzx9Mhnp4duAzFuIImVbQ==",
        "generateImageSmApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/generate_image?code=5yPETN2J0AdlFjVF27B9mtxyAOytYtEQaBzx9Mhnp4duAzFuIImVbQ==",
        "getContentIdApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/insert_content_for_media?code=Evyfy95A6Lmph_Dofc0i3cQU6vivzkScaQsn4FbLmGznAzFuzqQ5XQ==",
        "insertSocialMediaTableApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/generate_social_media_content?code=f3oNUhX09JTi7U-roBW_Uj6N6-mgEWBWyFBx7OvHEPghAzFuwYUcAQ==",
        "generateOverviewpdfApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/generate_overview_pdf?code=yuJyfXA6mhG8tACnBY9bcKNUPm7Qc70Bu9n5ijrNSJrVAzFu8hnuzw==",
        "generateRecommendationFeatureApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/generate_recommendation_feature?code=HQwyqTzsNzq8VThuwOhPhfTVQCqcvqUF2A6mWnw9OuwdAzFuIWX20w==",
        "generateRecommendationPricingApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/generate_recommendation_pricing?code=-uyRh3IQxGG72wiHYV5-v-_lrTp38FHxvIBFR1esHzRIAzFuOCEe6w==",
        "generateRecommendationFeaturepdfApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/generate_recommendation_feature_pdf?code=MlDI5URvDsQ-X-CS29v1uIRxbkcovmMIvAPFVfdRKEvFAzFuq21hgw==",
        "generateRecommendationPricingpdfApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/generate_recommendation_pricing_pdf?code=p0PIAJZ2ln0mzqF00T3fY9WZrVV95qY5z1ZDwbHjwDVJAzFueKqK5Q==",
        "generateFeaturepdfApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/generate_feature_pdf?code=s3-qqct1PqiYpUlDr46UYVacLgjXyuRk_-jnJEqxda9xAzFufegmfw==",
        "generatePricingpdfApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/generate_pricing_pdf?code=nAnf-r4tv3CjZHod-z5VlbVriEfWRHRLj6vYNe_SW8SDAzFuxavMtg==",
        "generateRecommendationpdfApi": "https://ava-eus-sp-fn-pc-dev.azurewebsites.net/api/generate_recommendation_pdf?code=TQjPP75e3oqFXJAH7Dkcggj2SZpADfbsexRl8gizDL4XAzFuPm99Xw==",
        "imageuploadApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/update_and_delete?code=wTf04JCLURTaBvBRgMRZfctYAHOyyDO_gEcE4ojUsse2AzFu8chucQ==",
        "select_one_competitor": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/select_one_competitor?code=iXk55GwUEud67yvwBXA7v4I5bcgjRBkWk2bALw4ma-0LAzFu_WAANg==",
        "get_pricing_result": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_pricing_result?code=qz9kMIVJFa-GCC7XjJVlWJxi7lbpepNx8JK-mfa83gb2AzFunJXupg==",
        "insert_pricing_recommendation": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_pricing_recommendation?code=mVWLilylSPWFs98VAX8UQYmqhb3joEELYsd1VOtF13-nAzFuhV8tpw==",
        "get_productbyId_FeaturePricing": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_productbyId_FeaturePricing?code=Okaa8lnhxWJBOUruoYDcKnn1eMy4_tqVabnWueOkHlRrAzFuGql-4Q==",
        "get_feature_tick": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_feature_tick?code=qRdkwOIjJIaO0ku9qbnb1g-ypJZfUiNHW3pUXEop8WGsAzFuBc-7yg==",
        "insert_feature_recommendation": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_feature_recommendation?code=_sdBfD5P2YEGxAwzTeMFvBWCEVn5xoApsEP1H4NhqiAlAzFusQtknQ==",
        "logout": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/logout?code=aGEaIIYQZ5XOv5shJrMnu-YlL8I5zzqgqts4RYADOTsQAzFuwVq8RA==",
        "getAllUpcomingEventsHubApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/get_upcomming_events_hub?code=dwnUh6E8MsCQIdrihCzzBTbGHbK-uCZaNJyyQzUzjPmeAzFuGCrldA==",
        "getAllUpcomingEventsApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/get_all_upcomming_events?code=zfJFKAnT25rxxhxVBaYFUgJIOR-PTUfKm5BiffeBZxZVAzFuf09Ypg==",
        "insertUpcomingEventsStatusApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/update_event_status?code=LZ0xAV21LHVRo7FS2jwgGWGlV2c6XJIUUefXPQiGI5ZdAzFueYGBpw==",
        "getAllContentsApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/get_all_contents?code=_FrkVjvX2_4daolggxICDsXS4LDqM34eF0Z5-3LJ19tVAzFuNwM-xw==",
        "deleteContentApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/delete_content?code=TT8g-783Jh4CGGHrkcy4yG1osf7fFCEysvZwMyc5PhmlAzFuIKVL-Q==",
        "getAllRequestApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/get_all_request?code=KjSxy2x6HvnyUZuNbelpkgn2iXYyoOu0Z_qw2Q3S7sBiAzFujt0rZQ==",
        "updateUserDataApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/update_user_data?code=k9_U9MnyaT25MEsN4a-n6NklsPXdkbSrIaHvkrBgM9hlAzFumH_tuA==",
        "createEventPostsApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/create_event_posts?code=phLQJMe82ZZ6lJrPGj1wbDiCge1UkwLApCITLyL2V8mIAzFuq89T5g==",
        "getEventPostsApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/get_event_posts?code=JSSTNjOWHZ8anRxjx08_q7DhG7ofPb780ilRu05Qtc6nAzFuvwuy1w==",
        "savePostContentApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/save_post_content?code=E46CYv_qMbN71PKSAJdLsyG65yQhAr4IJrqbM845dWk2AzFuWqO_Ow==",
        "getContentByIdApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/get_content_by_id?code=RbQMdnXh6OatK4not8VTWjAvG-6EuIRHqk79GMESSHVSAzFuxlIAAQ==",
        "generateContentApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/generate_content?code=-acRYVJvV1Zp6QlqofUK0VYbIzuQ9uEincQ39gpbC6jXAzFuzNy1rQ==",
        "askAiApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/ask_ai?code=GD7pxmLry9doV2Aw7fEdejMuY3O0nxEHU4rdyK5ZhXTkAzFuljJmkQ==",
        "generateImageApi": "https://ava-eus-sp-fn-cg-dev.azurewebsites.net/api/generate_image?code=LhEbVOSxbTceAtIQHif0_SOYv1U0asYLXdULyKELML4jAzFucPwWiQ==",
        "submitRequestApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/submit_request_cg?code=3hnPi4taMEu_1epUwwQld1vd1fXL9AXmwZ-s8RzdyfTsAzFu4Pv0UA==",
        "saveContentApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/save_content?code=iRWm-8-gKZOl94P1tGOCV8Tri6eGQX66W-iP9jLR2UFYAzFurVlFLw==",
        "saveFeatureApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/save_feature?code=ZACgCGy3nCCyMz4RuJLbSZh-5GZ65O3Hvw9ez5d-9j6wAzFuZSDZyA==",
        "getAllFeatureApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_all_features?code=8qJC3LyD8ro0dyEyD6OvNPDsPsynQSuSSdYipsG3I-8DAzFuNKvKDQ==",
        "updateFeatureApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/update_feature?code=G-aqcfOSDnCJWfk4Wt7oBF2gBl1n6MfaVoNOz600r4QNAzFuGzJScg==",
        "deleteFeatureApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/delete_feature?code=keQ5MTaJSZh9pUk1l6zObw9c6Z8gi-XPEW0enUlUss0OAzFufw6YDA==",
        "savePricingApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/save_pricing?code=xS9Zgho8a8cnWg4w__r7iGOEMM9QIgaDBJZNkdg_50K_AzFuOiZiwA==",
        "getAllPricingApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_all_pricing?code=C3p1FM2H8qX0Xo2fDQBvOJIFjnX5GD8XknVYASC6WtaYAzFu75D86w==",
        "updatePricingApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/update_pricing?code=eq4ZYsJU5GZrHdFuj9GLCo0uVleLG6fv6pudquu6fgHPAzFuvedNlg==",
        "deletePricingApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/delete_pricing?code=HidtxQHm-TdvxtHGSRi0PQojNd0ei0PC_Zpro0cRNnU7AzFuMYh2Qw==",
        "getAllProductsApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_all_products?code=F_jl2etHZDob8ymh0WbmsS-oNTn_9BzMXCBhKolTAmeJAzFuLYg9MQ==",
        "getProductbyIdApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/select_product_by_id?code=nhLi553YhhzAH8CBOUsQybK6ntySlZNXeHInHQRnguL6AzFuWMYYCQ==",
        "getComparisonsByProductIdApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_comparisons_by_product_id?code=8x40aC8goionOWBv0egbi1QPu7m8Y54cYspOFhF5sukgAzFuGn-xvw==",
        "getAllCompetitorsApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_all_competitors?code=seV8XdTdbvhhAkAFOBINfsUToC86lqEQ6gWsQelx2qIHAzFutt0TLw==",
        "saveComparisonMetricsApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/save_comparision_metrics?code=XS3WFYhmBXRx2NJI0xAZO4USZi2BOk4QLj53Omk7X6xvAzFu3ooxZg==",
        "addCompetitorsManuallyApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/add_competitors_manually?code=bRjDmScFGJswVQRJnk-N0eYJQkxDwggsl1q-n9EnWrL9AzFuLFy1rQ==",
        "createProductApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/create_product?code=9TnFLbFkUHmjaUn-kJSP4ZcM1TfmNbTfwIiC0nhxCO6eAzFujwcU1w==",
        "getUserDataApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/get_user_data?code=VnazGoxeqN5p4NalZNo34W8hK5Gt9qhXVkkf0TmDggeFAzFuNYCsQQ==",
        "checkDetailsApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/login?code=I20gR-CUH881kwZ-NG24zs1M1qAEf37hnfL2WOqRJUulAzFuJjr8aQ==",
        "insertDetailsApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/signup?code=1qdcwcZutnTP9qzMGjKgKjDFnF2tYKKWg8eLlbbxGGpRAzFu6EbRUA==",
        "checkMailDetailsApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/forgot_password?code=ysR8U-Mq8GumCLRrMsyZ9KB-7YUsL7FYVYYRUUzQJW1YAzFuJFKM0g==",
        "updatePasswordApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/set_new_password?code=dFuc3XesA67KpOpNNAEIOlYhq9cBniAm7P8fu2yAPEvBAzFub2vSHg==",
        "getPendingRequestCountApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/pending_request_count?code=-IHjzZ-fF_9Rv2-bXpBe6IzSuq0N4vGB7IQxbzKAT5kIAzFuaK7ffw==",
        "getRecentActivitiesApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/get_recent_activity?code=NVIxeSH-MzwO8ftWgm59QQyTPKceD_BQuHPJK3BTjge2AzFuswO9VQ==",
        "getMediaPostsApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/get_recent_posts?code=q3KF5sEfFgKpP8502WeLO45NFY0V24zzgVuLVUHP-iMDAzFuaJdQSQ==",
        "getImpressionApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/get_impression?code=stbxDGXJvX6d8v1Iv9shz_N4GHutWIk8vSpwksCwoipLAzFujPYiiw==",
        "getAllAccountApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/get_account?code=rTHFk-qfS7iVNful0dwhu4yW-Gu4pijWiJkiWPy07onBAzFumBv7Qw==",
        "createAccountApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/create_account?code=hNETFZ85nnhfP9SPyOqLbffmuiZtoTzr01cI1H9JxF5eAzFuyr7iNQ==",
        "deleteAccountApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/delete_account?code=rdkX9J6JRemvttu56eR_zjolZ-mzqrXoMjvk5AoMIImyAzFucur9lA==",
        "editAccountApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/edit_account?code=NjOYzdJ_2XoV98tNDGboOQop2brXnoFIPC6yWyot-jrKAzFuEm-QSA==",
        "addPlatformApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/add_platform?code=H9ZavBPbjOn-JrP4DlYwhca1PZ65biUedtykwm2Mh63RAzFu6g9KgQ==",
        "platformConnectionApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/platform_connection?code=8HXDf7-fbXVj0Pt-PxpTJlrvLnTLFBQ-CraB74uDYWbsAzFunie5xg==",
        "editPlatformApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/edit_platform?code=08KJVRykh0RceHL_QqnzWcv_0UmTw3v4BZrIUy2YFK1bAzFun7rFag==",
        "getAllSoicalMediaContentApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/get_highlight_details?code=yV_fz5eIaFBcfK4i8YQgngM1YUZOe109WsKWDI8lWPmkAzFuUpZiKQ==",
        "postInstagramApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/post_instagram_content?code=QdOiwnu7xrYhZVpF7SYB5ZA_LYOWDfMA2iwibM7CQlSlAzFumj69gg==",
        "postFaceBookApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/post_in_facebook?code=P8EBtSY1MF71LLWC0grm4U26RnZJ3DI7e68-UsWMxYYIAzFuFhXgBQ==",
        "postTwitterApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/post_in_twitter?code=hEvvmuF0asIv1e_FuhRyoxfmW251lch9a7UYq9LnzjHGAzFu6rQsuA==",
        "postLinkedInApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/post_linkedin_content?code=My79LRpgHZDrORmprwK3B6ALEuWXp0jkNgBBnmYozkbsAzFuQieTUA==",
        "regeneratePostApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/generate_social_media_content?code=QsnPZNeNg_V8QTQALxnmGj40NzQbp6Nj_rPN8zjcRer3AzFuD1Ascg==",
        "fetchAllUserApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/select_all_user?code=OdhovlNpYU1SxVSZz8Y60b_T1LLbqtaAro3j0JuFjlt0AzFu_LnK5w==",
        "deleteUserApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/delete_user?code=tlxSxzm3eaVJsjmPvBIVQRfSpEimJ-t7JIYD7opDAY6UAzFu8BBnRQ==",
        "addUserApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/insert_edit_user?code=hKhdzZUseQLBUHaLwg1652snIyum42YYqvjFuQ3p0_-MAzFucikrHg==",
        "getDropDownDetailsApi": "https://ava-eus-sp-fn-hub-prod.azurewebsites.net/api/get_dropdown_data?code=ZNNcGBSuymQHna7vHMs02ngVTzFXMcdeLDWCmXF2YnewAzFu-ggANw==",
        "fetchNotificationApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/get_all_notification?code=cTZFPlkjF4KFWtEzuy_r1pcb8nzKmOnpjCUiZkHRzhAlAzFuB7kXXA==",
        "createMyEventApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/create_my_events?code=z2tj8YA42ZxzXLB7DRaVDao-14tnZYpTEAxYGHNCTGEqAzFu_LzhPQ==",
        "getAllAccountsApi": "https://ava-eus-sp-fn-cg-prod.azurewebsites.net/api/get_all_accounts?code=e1XzlSWTB4-mYOFqe35XQ2b2veInCLDzTZ4DRpmkXGHrAzFu07KTlw==",
        "getMyEventsApi": "https://ava-eus-sp-fn-sm-prod.azurewebsites.net/api/get_my_events?code=dtY0U3CBpCfZ4UF7anHN0lWzeqSrIfJbAAyYOCRm1-9LAzFuDvcZUg==",
        
        "save_roadmap_featuresApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/save_roadmap_features?code=54WDBHwrokmejOx199kz5JX6fCmK-lqF0IEHV6jh8bcMAzFuGeMDcw==",
        "update_roadmap_featuresApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/update_roadmap_features?code=_5XnOuxGebumK00MTl7dqWCzb52Vy65hyfw_hC0NOwFNAzFuw-vj8w==",
        "delete_roadmap_featuresApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/delete_roadmap_features?code=toOo6bM1h580fCyvoNukL_Cn4FS6qoBqfIFtmqDNCHjQAzFuxXi9LA==",
        "get_all_roadmap_featuresApi":"https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_all_roadmap_features?code=Inv0o10KFeo_ilc6wVCVM2cz1ZH2NnbD8IDNUor6yJPmAzFuL7pYSQ==",
        "get_competitor_roadmap_featuresApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_competitor_roadmap_features?code=14q8ujY2qlCOptYwKvBGiII4z1u2MABLnO3rk2fjbSMUAzFuWhvgTQ==",
        "insert_product_azure_review_tableApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_product_azure_review_table?code=vUIo4YJc68qKgzoP_fxYWAVUr8wa4srpe5TEy1S9shl6AzFuTWr67g==",
        "insert_product_aws_review_tableApi":"https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_product_aws_review_table?code=73V-5Wgv54NRs8VngFDd76jfS0IYrQE_cn8hNKwOM_OEAzFu8W45cg==" ,
        "insert_competitor_azure_reviewApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_competitor_azure_review?code=kUr4Rc9BOmC__mYoGalt3KRLj4qAz8kiXa6TbdR9xNTPAzFu_xXA7w==",
        "insert_competitor_aws_reviewApi":  "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_competitor_aws_review?code=FoX_2-KoaKdZRCtOVW_dUAVEl56jOWxhQ92OOkSMn82LAzFufand2g==",
        "insert_competitor_roadmap_tableApi": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_competitor_roadmap_table?code=UFqS3onsybepoKO9x3QqPy0njY6YxfrPqByiG1E4KWQrAzFuB0RB0g==",
        "insert_roadmap_recommendation": "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/insert_roadmap_features_recommendation?code=o95m4Mk_Vp9Zaxkz0-OkooQJSwj6LU5C6gRNx1cFJFHJAzFuMJ4WCw==",
        "get_product_ranking":  "https://ava-eus-sp-fn-pc-prod.azurewebsites.net/api/get_product_ranking?code=esoGjF9wbXLtEDm6ycqtlZkpxS3eBe0SfZe4VdpSrfcBAzFuhXrfOw=="
    },
    "local": {
        "validate": "http://localhost:7002/api/validate_token?code=UMLnZMwl1mLD8XED1TDBEzcY7idpt-9b_jqmM_XAmQkQAzFuIz-rbw==",
        "getComparisionmetricbyProductidApi": "http://localhost:7003/api/get_comparison_metrics_by_product_id?code=WgB4cKkSIXsPKotDtMER0QNomzTeM_O2IYCBrNCxiGXSAzFuwd9mlA==",
        "getWatchlistbyProductidApi": "http://localhost:7003/api/get_watchlist_by_product_id?code=lhNn9H-esLuyZzY1LAGiLRw4xXNpvJ9oHrQhHZA7G3AwAzFucSSXlw==",
        "selectCompetitorApi": "http://localhost:7003/api/select_competitors?code=zNaTqXQLP_kUPsGs_rHgFopaEBKrKU0anXNOle3nyQOBAzFuKxhqRg==",
        "generateOverviewApi": "http://localhost:7003/api/generate_overview?code=RncvBLsDmF2cgzEx0sZGck0PmVtxr32Cdfj0jRdqMFGnAzFu0_E5YA==",
        "generateFeatureApi": "http://localhost:7003/api/generate_feature?code=vFoLwj1Ial1K4taJsPuzGCgtbSm47PUtXK2Fx3_h_eglAzFukdBhDQ==",
        "generatePricingApi": "http://localhost:7003/api/generate_pricing?code=Uw_qRnykBijKdtmLvZ64yJ2dWtYuMAhjYXG-8zlh90pTAzFu3hmaxA==",
        "generateRecommendationApi": "http://localhost:7003/api/generate_recommendation?code=mVWLilylSPWFs98VAX8UQYmqhb3joEELYsd1VOtF13-nAzFuhV8tpw==",
        "pdftoBackendApi": "http://localhost:7003/api/generate_email?code=WOkeXdHN8yqm_Fqqe1d_unJu5AJcZnuyx1zKJPLWKrf6AzFuIS8faQ==",
        "insertCompetitorsApi": "http://localhost:7003/api/get_links_and_insert_competitors?code=4hMoCRPpyFtgjbEoLujNBj5QdP1mwNFUl9lfzwJnz0uZAzFuVBTHPQ==",
        "insertHtmlApi": "http://localhost:7003/api/get_html_and_update_competitors?code=xEmlY3JnHAhbX5kRViqGoFPN_ljFdaoyWrEaZmOtlm-PAzFufBAcQA==",
        "insert_nameApi": "http://localhost:7003/api/insert_name?code=PtRXxEcDQ0mY3vUBtJSH9_7bTjDhl1O5qsnsRoeaISM0AzFus9VK5Q==",
        "insert_overviewApi": "http://localhost:7003/api/insert_overview?code=cs1jsGaF2F-jpk0I_WOWDli6miN1SfoS6o-1hn9OjV_pAzFuX-8Nzg==",
        "insert_featureApi": "http://localhost:7003/api/insert_feature?code=6JbNi3SrAscKDRcZ9S2cXJcytqaV_Ji1XZZfzq-jxGZ5AzFusE0Pbw==",
        "insert_pricingApi": "http://localhost:7003/api/insert_pricing?code=qTRYy_Ldjye5_6Sm38a33SBRWoWbL6KfAI5FFZpGW1f0AzFugXnXAw==",
        "insert_feature_tableApi": "http://localhost:7003/api/insert_feature_table?code=iSH0xR-wpYXivcLQPp8yKqG9g669FrqRORDAkZ68rS33AzFu-WUW0Q==",
        "insert_pricing_tableApi": "http://localhost:7003/api/insert_pricing_table?code=FNWLj8zqYLN_rysN2N91wrBgNWP1sMMb7AYeGuvhd4DAAzFuUWhwZg==",
        "postFacebookApi": "http://localhost:7001/api/post_in_facebook?code=P8EBtSY1MF71LLWC0grm4U26RnZJ3DI7e68-UsWMxYYIAzFuFhXgBQ==",
        "getAiContentsApi": "http://localhost:7001/api/get_highlight_details?code=yV_fz5eIaFBcfK4i8YQgngM1YUZOe109WsKWDI8lWPmkAzFuUpZiKQ==",
        "get_configJson": "http://localhost:7001/api/get_content_by_id?code=RbQMdnXh6OatK4not8VTWjAvG-6EuIRHqk79GMESSHVSAzFuxlIAAQ==",
        "generateSocialMediaContentApi": "http://localhost:7001/api/generate_social_media_content?code=QsnPZNeNg_V8QTQALxnmGj40NzQbp6Nj_rPN8zjcRer3AzFuD1Ascg==",
        "approveStatusApi": "http://localhost:7001/api/submit_request?code=v_rFjRXaepzXMZ-71IxqnrCF862Q4ZMtL-X3iAehEjpYAzFuQYS5zg==",
        "createSocialApi": "http://localhost:7002/api/generate_social_media_content?code=f3oNUhX09JTi7U-roBW_Uj6N6-mgEWBWyFBx7OvHEPghAzFuwYUcAQ==",
        "createContentForMediaApi": "http://localhost:7002/api/create_content_for_media?code=voXfYyt-7jVindFl1zhxtjI1G1vS25cT3KC1R-293dUXAzFu_Dt0hg==",
        "createImageForMediaApi": "http://localhost:7002/api/generate_image?code=5yPETN2J0AdlFjVF27B9mtxyAOytYtEQaBzx9Mhnp4duAzFuIImVbQ==",
        "generateImageSmApi": "http://localhost:7002/api/generate_image?code=5yPETN2J0AdlFjVF27B9mtxyAOytYtEQaBzx9Mhnp4duAzFuIImVbQ==",
        "getContentIdApi": "http://localhost:7002/api/insert_content_for_media?code=Evyfy95A6Lmph_Dofc0i3cQU6vivzkScaQsn4FbLmGznAzFuzqQ5XQ==",
        "insertSocialMediaTableApi": "http://localhost:7002/api/generate_social_media_content?code=f3oNUhX09JTi7U-roBW_Uj6N6-mgEWBWyFBx7OvHEPghAzFuwYUcAQ==",
        "generateOverviewpdfApi": "http://localhost:7003/api/generate_overview_pdf?code=yuJyfXA6mhG8tACnBY9bcKNUPm7Qc70Bu9n5ijrNSJrVAzFu8hnuzw==",
        "generateRecommendationFeatureApi": "http://localhost:7003/api/generate_recommendation_feature?code=HQwyqTzsNzq8VThuwOhPhfTVQCqcvqUF2A6mWnw9OuwdAzFuIWX20w==",
        "generateRecommendationPricingApi": "http://localhost:7003/api/generate_recommendation_pricing?code=-uyRh3IQxGG72wiHYV5-v-_lrTp38FHxvIBFR1esHzRIAzFuOCEe6w==",
        "generateRecommendationFeaturepdfApi": "http://localhost:7003/api/generate_recommendation_feature_pdf?code=MlDI5URvDsQ-X-CS29v1uIRxbkcovmMIvAPFVfdRKEvFAzFuq21hgw==",
        "generateRecommendationPricingpdfApi": "http://localhost:7003/api/generate_recommendation_pricing_pdf?code=p0PIAJZ2ln0mzqF00T3fY9WZrVV95qY5z1ZDwbHjwDVJAzFueKqK5Q==",
        "generateFeaturepdfApi": "http://localhost:7003/api/generate_feature_pdf?code=s3-qqct1PqiYpUlDr46UYVacLgjXyuRk_-jnJEqxda9xAzFufegmfw==",
        "generatePricingpdfApi": "http://localhost:7003/api/generate_pricing_pdf?code=nAnf-r4tv3CjZHod-z5VlbVriEfWRHRLj6vYNe_SW8SDAzFuxavMtg==",
        "generateRecommendationpdfApi": "http://localhost:7003/api/generate_recommendation_pdf?code=TQjPP75e3oqFXJAH7Dkcggj2SZpADfbsexRl8gizDL4XAzFuPm99Xw==",
        "imageuploadApi": "http://localhost:7002/api/update_and_delete?code=wTf04JCLURTaBvBRgMRZfctYAHOyyDO_gEcE4ojUsse2AzFu8chucQ==",
        "select_one_competitor": "http://localhost:7003/api/select_one_competitor?code=iXk55GwUEud67yvwBXA7v4I5bcgjRBkWk2bALw4ma-0LAzFu_WAANg==",
        "get_pricing_result": "http://localhost:7003/api/insert_pricing_result?code=qz9kMIVJFa-GCC7XjJVlWJxi7lbpepNx8JK-mfa83gb2AzFunJXupg==",
        "insert_pricing_recommendation": "http://localhost:7003/api/insert_pricing_recommendation?code=mVWLilylSPWFs98VAX8UQYmqhb3joEELYsd1VOtF13-nAzFuhV8tpw==",
        "get_productbyId_FeaturePricing": "http://localhost:7003/api/get_productbyId_FeaturePricing?code=Okaa8lnhxWJBOUruoYDcKnn1eMy4_tqVabnWueOkHlRrAzFuGql-4Q==",
        "get_feature_tick": "http://localhost:7003/api/get_feature_tick?code=qRdkwOIjJIaO0ku9qbnb1g-ypJZfUiNHW3pUXEop8WGsAzFuBc-7yg==",
        "insert_feature_recommendation": "http://localhost:7003/api/insert_feature_recommendation?code=_sdBfD5P2YEGxAwzTeMFvBWCEVn5xoApsEP1H4NhqiAlAzFusQtknQ==",
        "logout": "http://localhost:7000/api/logout?code=aGEaIIYQZ5XOv5shJrMnu-YlL8I5zzqgqts4RYADOTsQAzFuwVq8RA==",
        "getAllUpcomingEventsHubApi": "http://localhost:7000/api/get_upcomming_events_hub?code=dwnUh6E8MsCQIdrihCzzBTbGHbK-uCZaNJyyQzUzjPmeAzFuGCrldA==",
        "getAllUpcomingEventsApi": "http://localhost:7002/api/get_all_upcomming_events?code=zfJFKAnT25rxxhxVBaYFUgJIOR-PTUfKm5BiffeBZxZVAzFuf09Ypg==",
        "insertUpcomingEventsStatusApi": "http://localhost:7000/api/update_event_status?code=LZ0xAV21LHVRo7FS2jwgGWGlV2c6XJIUUefXPQiGI5ZdAzFueYGBpw==",
        "getAllContentsApi": "http://localhost:7001/api/get_all_contents?code=_FrkVjvX2_4daolggxICDsXS4LDqM34eF0Z5-3LJ19tVAzFuNwM-xw==",
        "deleteContentApi": "http://localhost:7001/api/delete_content?code=TT8g-783Jh4CGGHrkcy4yG1osf7fFCEysvZwMyc5PhmlAzFuIKVL-Q==",
        "getAllRequestApi": "http://localhost:7001/api/get_all_request?code=KjSxy2x6HvnyUZuNbelpkgn2iXYyoOu0Z_qw2Q3S7sBiAzFujt0rZQ==",
        "updateUserDataApi": "http://localhost:7000/api/update_user_data?code=k9_U9MnyaT25MEsN4a-n6NklsPXdkbSrIaHvkrBgM9hlAzFumH_tuA==",
        "createEventPostsApi": "http://localhost:7002/api/create_event_posts?code=phLQJMe82ZZ6lJrPGj1wbDiCge1UkwLApCITLyL2V8mIAzFuq89T5g==",
        "getEventPostsApi": "http://localhost:7002/api/get_event_posts?code=JSSTNjOWHZ8anRxjx08_q7DhG7ofPb780ilRu05Qtc6nAzFuvwuy1w==",
        "savePostContentApi": "http://localhost:7002/api/save_post_content?code=E46CYv_qMbN71PKSAJdLsyG65yQhAr4IJrqbM845dWk2AzFuWqO_Ow==",
        "getContentByIdApi": "http://localhost:7001/api/get_content_by_id?code=RbQMdnXh6OatK4not8VTWjAvG-6EuIRHqk79GMESSHVSAzFuxlIAAQ==",
        "generateContentApi": "http://localhost:7001/api/generate_content?code=-acRYVJvV1Zp6QlqofUK0VYbIzuQ9uEincQ39gpbC6jXAzFuzNy1rQ==",
        "askAiApi": "http://localhost:7001/api/ask_ai?code=GD7pxmLry9doV2Aw7fEdejMuY3O0nxEHU4rdyK5ZhXTkAzFuljJmkQ==",
        "generateImageApi": "http://localhost:7001/api/generate_image?code=1FK8k7XwtnlxzLu50eJE9fSs6tDSTx4agGf24sWvicTaAzFuZbnlKQ==",
        "submitRequestApi": "http://localhost:7001/api/submit_request_cg?code=3hnPi4taMEu_1epUwwQld1vd1fXL9AXmwZ-s8RzdyfTsAzFu4Pv0UA==",
        "saveContentApi": "http://localhost:7001/api/save_content?code=iRWm-8-gKZOl94P1tGOCV8Tri6eGQX66W-iP9jLR2UFYAzFurVlFLw==",
        "saveFeatureApi": "http://localhost:7003/api/save_feature?code=ZACgCGy3nCCyMz4RuJLbSZh-5GZ65O3Hvw9ez5d-9j6wAzFuZSDZyA==",
        "getAllFeatureApi": "http://localhost:7003/api/get_all_features?code=8qJC3LyD8ro0dyEyD6OvNPDsPsynQSuSSdYipsG3I-8DAzFuNKvKDQ==",
        "updateFeatureApi": "http://localhost:7003/api/update_feature?code=G-aqcfOSDnCJWfk4Wt7oBF2gBl1n6MfaVoNOz600r4QNAzFuGzJScg==",
        "deleteFeatureApi": "http://localhost:7003/api/delete_feature?code=keQ5MTaJSZh9pUk1l6zObw9c6Z8gi-XPEW0enUlUss0OAzFufw6YDA==",
        "savePricingApi": "http://localhost:7003/api/save_pricing?code=xS9Zgho8a8cnWg4w__r7iGOEMM9QIgaDBJZNkdg_50K_AzFuOiZiwA==",
        "getAllPricingApi": "http://localhost:7003/api/get_all_pricing?code=C3p1FM2H8qX0Xo2fDQBvOJIFjnX5GD8XknVYASC6WtaYAzFu75D86w==",
        "updatePricingApi": "http://localhost:7003/api/update_pricing?code=eq4ZYsJU5GZrHdFuj9GLCo0uVleLG6fv6pudquu6fgHPAzFuvedNlg==",
        "deletePricingApi": "http://localhost:7003/api/delete_pricing?code=HidtxQHm-TdvxtHGSRi0PQojNd0ei0PC_Zpro0cRNnU7AzFuMYh2Qw==",
        "getAllProductsApi": "http://localhost:7003/api/get_all_products?code=F_jl2etHZDob8ymh0WbmsS-oNTn_9BzMXCBhKolTAmeJAzFuLYg9MQ==",
        "getProductbyIdApi": "http://localhost:7003/api/select_product_by_id?code=nhLi553YhhzAH8CBOUsQybK6ntySlZNXeHInHQRnguL6AzFuWMYYCQ==",
        "getComparisonsByProductIdApi": "http://localhost:7003/api/get_comparisons_by_product_id?code=8x40aC8goionOWBv0egbi1QPu7m8Y54cYspOFhF5sukgAzFuGn-xvw==",
        "getAllCompetitorsApi": "http://localhost:7003/api/get_all_competitors?code=seV8XdTdbvhhAkAFOBINfsUToC86lqEQ6gWsQelx2qIHAzFutt0TLw==",
        "saveComparisonMetricsApi": "http://localhost:7003/api/save_comparision_metrics?code=XS3WFYhmBXRx2NJI0xAZO4USZi2BOk4QLj53Omk7X6xvAzFu3ooxZg==",
        "addCompetitorsManuallyApi": "http://localhost:7003/api/add_competitors_manually?code=bRjDmScFGJswVQRJnk-N0eYJQkxDwggsl1q-n9EnWrL9AzFuLFy1rQ==",
        "createProductApi": "http://localhost:7003/api/create_product?code=9TnFLbFkUHmjaUn-kJSP4ZcM1TfmNbTfwIiC0nhxCO6eAzFujwcU1w==",
        "getUserDataApi": "http://localhost:7000/api/get_user_data?code=VnazGoxeqN5p4NalZNo34W8hK5Gt9qhXVkkf0TmDggeFAzFuNYCsQQ==",
        "checkDetailsApi": "http://localhost:7000/api/login?code=I20gR-CUH881kwZ-NG24zs1M1qAEf37hnfL2WOqRJUulAzFuJjr8aQ==",
        "insertDetailsApi": "http://localhost:7000/api/signup?code=1qdcwcZutnTP9qzMGjKgKjDFnF2tYKKWg8eLlbbxGGpRAzFu6EbRUA==",
        "checkMailDetailsApi": "http://localhost:7000/api/forgot_password?code=ysR8U-Mq8GumCLRrMsyZ9KB-7YUsL7FYVYYRUUzQJW1YAzFuJFKM0g==",
        "updatePasswordApi": "http://localhost:7000/api/set_new_password?code=dFuc3XesA67KpOpNNAEIOlYhq9cBniAm7P8fu2yAPEvBAzFub2vSHg==",
        "getPendingRequestCountApi": "http://localhost:7000/api/pending_request_count?code=-IHjzZ-fF_9Rv2-bXpBe6IzSuq0N4vGB7IQxbzKAT5kIAzFuaK7ffw==",
        "getRecentActivitiesApi": "http://localhost:7000/api/get_recent_activity?code=NVIxeSH-MzwO8ftWgm59QQyTPKceD_BQuHPJK3BTjge2AzFuswO9VQ==",
        "getMediaPostsApi": "http://localhost:7000/api/get_recent_posts?code=q3KF5sEfFgKpP8502WeLO45NFY0V24zzgVuLVUHP-iMDAzFuaJdQSQ==",
        "getImpressionApi": "http://localhost:7000/api/get_impression?code=stbxDGXJvX6d8v1Iv9shz_N4GHutWIk8vSpwksCwoipLAzFujPYiiw==",
        "getAllAccountApi": "http://localhost:7001/api/get_account?code=rTHFk-qfS7iVNful0dwhu4yW-Gu4pijWiJkiWPy07onBAzFumBv7Qw==",
        "createAccountApi": "http://localhost:7001/api/create_account?code=hNETFZ85nnhfP9SPyOqLbffmuiZtoTzr01cI1H9JxF5eAzFuyr7iNQ==",
        "deleteAccountApi": "http://localhost:7001/api/delete_account?code=rdkX9J6JRemvttu56eR_zjolZ-mzqrXoMjvk5AoMIImyAzFucur9lA==",
        "editAccountApi": "http://localhost:7001/api/edit_account?code=NjOYzdJ_2XoV98tNDGboOQop2brXnoFIPC6yWyot-jrKAzFuEm-QSA==",
        "addPlatformApi": "http://localhost:7001/api/add_platform?code=H9ZavBPbjOn-JrP4DlYwhca1PZ65biUedtykwm2Mh63RAzFu6g9KgQ==",
        "platformConnectionApi": "http://localhost:7001/api/platform_connection?code=8HXDf7-fbXVj0Pt-PxpTJlrvLnTLFBQ-CraB74uDYWbsAzFunie5xg==",
        "editPlatformApi": "http://localhost:7001/api/edit_platform?code=08KJVRykh0RceHL_QqnzWcv_0UmTw3v4BZrIUy2YFK1bAzFun7rFag==",
        "getAllSoicalMediaContentApi": "http://localhost:7001/api/get_highlight_details?code=yV_fz5eIaFBcfK4i8YQgngM1YUZOe109WsKWDI8lWPmkAzFuUpZiKQ==",
        "postInstagramApi": "http://localhost:7001/api/post_instagram_content?code=QdOiwnu7xrYhZVpF7SYB5ZA_LYOWDfMA2iwibM7CQlSlAzFumj69gg==",
        "postFaceBookApi": "http://localhost:7001/api/post_in_facebook?code=P8EBtSY1MF71LLWC0grm4U26RnZJ3DI7e68-UsWMxYYIAzFuFhXgBQ==",
        "postTwitterApi": "http://localhost:7001/api/post_in_twitter?code=hEvvmuF0asIv1e_FuhRyoxfmW251lch9a7UYq9LnzjHGAzFu6rQsuA==",
        "postLinkedInApi": "http://localhost:7001/api/post_linkedin_content?code=My79LRpgHZDrORmprwK3B6ALEuWXp0jkNgBBnmYozkbsAzFuQieTUA==",
        "regeneratePostApi": "http://localhost:7001/api/generate_social_media_content?code=QsnPZNeNg_V8QTQALxnmGj40NzQbp6Nj_rPN8zjcRer3AzFuD1Ascg==",
        "fetchAllUserApi": "http://localhost:7000/api/select_all_user?code=OdhovlNpYU1SxVSZz8Y60b_T1LLbqtaAro3j0JuFjlt0AzFu_LnK5w==",
        "deleteUserApi": "http://localhost:7000/api/delete_user?code=tlxSxzm3eaVJsjmPvBIVQRfSpEimJ-t7JIYD7opDAY6UAzFu8BBnRQ==",
        "addUserApi": "http://localhost:7000/api/insert_edit_user?code=hKhdzZUseQLBUHaLwg1652snIyum42YYqvjFuQ3p0_-MAzFucikrHg==",
        "getDropDownDetailsApi": "http://localhost:7000/api/get_dropdown_data?code=ZNNcGBSuymQHna7vHMs02ngVTzFXMcdeLDWCmXF2YnewAzFu-ggANw==",
        "fetchNotificationApi": "http://localhost:7001/api/get_all_notification?code=cTZFPlkjF4KFWtEzuy_r1pcb8nzKmOnpjCUiZkHRzhAlAzFuB7kXXA==",
        "createMyEventApi": "http://localhost:7002/api/create_my_events?code=z2tj8YA42ZxzXLB7DRaVDao-14tnZYpTEAxYGHNCTGEqAzFu_LzhPQ==",
        "getAllAccountsApi": "http://localhost:7001/api/get_all_accounts?code=e1XzlSWTB4-mYOFqe35XQ2b2veInCLDzTZ4DRpmkXGHrAzFu07KTlw==",
        "getMyEventsApi": "http://localhost:7002/api/get_my_events?code=dtY0U3CBpCfZ4UF7anHN0lWzeqSrIfJbAAyYOCRm1-9LAzFuDvcZUg=="
    }
}