import { useEffect, useState } from "react";
import { getPendingRequestCountApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";

export default function PendingRequestCard() {
  const [pendingRequestCount, setPendingRequestCount] = useState(0);
  const { verifyJwtData } = useCommonContext();

  const getPendingRequestCount = async () => {
    try {
      console.log("I am called");
      const payload = {
        userId: verifyJwtData("User_Id"),
      };

      const responseData = await getPendingRequestCountApi(payload);

      setPendingRequestCount(responseData.data.data.count);
    } catch (error) {}
  };

  useEffect(() => {
    getPendingRequestCount();
  }, []);

  return (
    <div className="col-md-3 mb-3">
      <div className="bg-white p-3 radius-16 h-100">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <img src="images/pending-req-icon.svg" alt="pending-req-icon" />
          <span
            data-bs-toggle="modal"
            data-bs-target="#impressionPop"
            className="cursor-pointer"
          >
            {/* <img src="images/link-arrow-icon.svg" alt="link-arrow-icon" /> */}
          </span>
        </div>
        <span className="d-block font-14 font-medium grey-v1 color-grey">
          Pending Requests
        </span>
        <span className="d-block font-20 font-bold">{pendingRequestCount}</span>
      </div>
    </div>
  );
}
