//ComparisonResult_123
import React, { useEffect, useState } from "react";
import { get_all_roadmap_featuresApi, get_competitor_roadmap_featuresApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";
import { useCreateProductContext } from "../../contexts/CreateProductContext";
import RoadmapLogo from "./Logo/RoadmapLogo";
import SingleProductroadmaprow from "./OurProdRoadMapDetails/SingleProductroadmaprow";


export default function ProductRoadMapResult() {
  const { productId , logoArray} = useCreateProductContext();
  const { userData } = useCommonContext();
  const [isLoading, setIsLoading] = useState(false);
  const [OurProductRoadmap, setOurProductRoadmap] = useState([])
  const [CompetitorProductRoadMap, setCompetitorProductRoadMap] = useState([])
  // const [logoArray, setLogoArray] = useState(  []  )
  const [RoadMapDetailsArray, setRoadMapDetailsArray] = useState(
    []
  )
  //ComparisonResult_131
  useEffect(() => {
    fetchData();
  }, []);

  //ComparisonResult_132
  async function fetchData() {
    try {

      const payloadFirst = {
        productId: productId,
        userId: userData[0]["User_Id"]
      };

      setIsLoading(true)

      // let clonedLogoArray = logoArray



      const [responseData, responseData1] = await Promise.all([
        //ComparisonResult_134
        get_all_roadmap_featuresApi(payloadFirst),
        get_competitor_roadmap_featuresApi(payloadFirst)
      ])
      console.log(responseData1, "response data")

      setIsLoading(false)


      // clonedLogoArray.push(
      //   responseData.data.data[0].Product_Logo
      // )


      let competitor_logo = responseData1.data.data.map((value, index) => {
        return value.Product_Logo
      })

      let competitorLogos = {};

      responseData1.data.data.forEach((value, index) => {
        if (responseData1.data.data.findIndex(v => v[0].Competitor_Name === value[0].Competitor_Name) === index) {
          competitorLogos[value[0].Competitor_Name] = value[0].Product_Logo;
        }
      });

      let uniqueCompetitorLogos = Object.values(competitorLogos);
      console.log(uniqueCompetitorLogos, "Unique competitor logos");

      console.log(competitorLogos, "competitorLogos");

      // clonedLogoArray = clonedLogoArray.concat(uniqueCompetitorLogos);
      // console.log(clonedLogoArray, "clonedLogoArray");
      // setLogoArray(clonedLogoArray);



      let clonedRoadMapArray = RoadMapDetailsArray

      responseData.data.data.map((value, index) => {
        clonedRoadMapArray.push([value]); // Push each object from responseData wrapped in an array into clonedRoadMapArray
      });




      let competitorFeatures = responseData1.data.data;
      let competitorFeatureData = {}

      for (let [index, value] of competitorFeatures.entries()) {
        competitorFeatureData[index] = value
      }

      //  console.log(competitorFeatureData,"This is the data for the competitor")




      //   console.log(clonedRoadMapArray, "roadmap detail array");
      let ind = 0;


      for (let value of clonedRoadMapArray) {
        for (let key in competitorFeatureData) {
          value.push(competitorFeatureData[key][ind])
        }
        ind = ind + 1
      } setRoadMapDetailsArray(clonedRoadMapArray); // Update RoadMapDetailsArray with the modified clonedRoadMapArray







    } catch (error) {
      console.error(error);
      setIsLoading(false)
    }
  }


  console.log(
    RoadMapDetailsArray,"This is the roadmap details array!!!"
  )
  return (
<>
  <div className="table-responsive theme-tbl p-0 compar-grid my-3 border-0">
    <table className="table table-borderless">
      <tbody className="font-medium">
        <div className="logo-details-container">
          <RoadmapLogo logoArray={logoArray} />
          {RoadMapDetailsArray.map((value, index) => (
            <SingleProductroadmaprow
              key={index}
              RoadMapDetailsArray={value}
            />
          ))}
        </div>
      </tbody>
    </table>
  </div>
</>
  );
}   