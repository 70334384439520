
//ComparisonResult_153
import React, { useEffect, useState } from "react";
import { get_product_ranking } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";
import { useCreateProductContext } from "../../contexts/CreateProductContext";

export default function ProductRanking() {
  const { userData } = useCommonContext();
  const { productId } = useCreateProductContext();
  const [isLoading, setIsLoading] = useState(false);
  const [productRanking, setProductRanking] = useState({});
  const [sortOrder, setSortOrder] = useState("asc");

//ComparisonResult_157
  useEffect(() => {
    getRankingDetails();
  }, []);

//ComparisonResult_158
  async function getRankingDetails() {
    try {
      const payload = {
        productId: productId,
        userId: userData[0]["User_Id"]
      };
      setIsLoading(true);
      //ComparisonResult_160
      const responseData = await get_product_ranking(payload);
      setProductRanking(responseData.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product ranking details:", error);
      setIsLoading(false);
    }
  }

  const toggleSortOrder = (key) => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);

    const sortedValues = [...productRanking[key]].sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.competitor_name.localeCompare(b.competitor_name); // Sort alphabetically ascending
      } else {
        return b.competitor_name.localeCompare(a.competitor_name); // Sort alphabetically descending
      }
    });

    setProductRanking({ ...productRanking, [key]: sortedValues });
  };

  return (
    <div className="table-responsive mt-4">
    {Object.entries(productRanking).map(([key, value]) => (
      <div key={key}>
        <table className="table table-borderless">
          <tbody className="font-medium">
            <tr className="grid-acc-open-brd">
              <td width="2%">
                <p
                  className="font-14 mb-0 font-medium cursor-pointer"
                  data-bs-toggle="collapse"
                  data-bs-target={`#${key.replaceAll(" ", "-")}`}
                  aria-expanded="true"
                >
                  <img src="images/table-accordion-arrow.svg" alt="form-acc-arrow" className="me-2 grid-arrow" />
                </p>
              </td>
              <td>{key}</td>
            </tr>
            <tr className="collapse show" id={key.replaceAll(" ", "-")}>
              <td colSpan={5}>
                <div className="table-responsive theme-tbl comparison-result">
                  <table className="table table-borderless mb-0">
                    <thead className="font-medium bg-white">
                      <tr>
                        <th className="ps-3" width="10px"></th>
                        <th className="ps-3" onClick={() => toggleSortOrder(key)}>
                          Product
                          <img
                            className="cursor-pointer ms-2"
                            src={`images/sort-${sortOrder === "asc" ? "down" : "up"}.svg`}
                            alt={`sort-${sortOrder}`}
                          />
                        </th>
                        <th className="text-center" width="140px">
                          SERP rank
                        </th>
                        <th className="ps-3">Site URL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {value.map((product, index) => (
                        <tr key={`${key}_${index}`}>
                          <td>{index + 1}</td> {/* Updated to start from 1 */}
                          <td className="ps-3">
                            <span className="d-flex align-items-center">
                              <img height={35} width={35} src={product.competitor_logo} alt={`${product.competitor_name} logo`} className="grid-icons-2 me-3" />
                              {product.competitor_name}
                            </span>
                          </td>
                          <td className="text-end pe-5 me-3" width="140px">
                            #{product.rank}
                          </td>
                          <td>
    {product.link && (
      <a href={product.link} target="_blank" rel="noopener noreferrer">
        {product.link.length > 20 ? product.link.slice(0, 20) + "..." : product.link}
      </a>
    )}
  </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ))}
  </div>
  );
}