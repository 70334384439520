// User_Profile_FE_2
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserDataApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";

// User_Profile_FE_3

// import { useCommonContext } from '../../contexts/CommonContext';

// User_Profile_FE_4
export default function UserProfile() {
  const { verifyJwtData, getUserData, userData, openToast, isNavCollapsed } =
    useCommonContext();

  // User_Profile_FE_7
  const [firstName, setFirstName] = useState("");
  const [validateFname, setValidateFname] = useState("");
  const [lastName, setLastName] = useState("");
  const [validateLname, setValidateLname] = useState("");

  const [isEditMode, setIsEditMode] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [newEmailId, setNewEmailId] = useState("");
  const [isEmailEditMode, setIsEmailEditMode] = useState(false);
  const [isPasswordEditMode, setIsPasswordEditMode] = useState(false);
  const [Password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [backupUserRec, setBackupUserRec] = useState([{}]);
  const [fileImage, setFileImage] = useState("");
  const [validateEmail, setValidateEmail] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  const [validateConfirmPassword, setValidateConfirmPassword] = useState(false);

  // User_Profile_FE_9

  const setUserDetails = async () => {
    console.log("entered into the setuserDetails", userData);

    setBackupUserRec(JSON.parse(JSON.stringify(userData)));
    // a ='5' // a space
    // b= a // b sep space

    // a= [] // a space
    // b =a // b --> a

    if (userData.length == 1) {
      console.log("the userData is set");
      setFirstName(await verifyJwtData("Firstname"));
      setLastName(await verifyJwtData("Lastname"));
      setEmailId(await verifyJwtData("Email_Id"));
      setPassword(await verifyJwtData("Password"));
      setFileImage(await verifyJwtData("Profile_Image_Url"));
      console.log(emailId, "emailllllllllllllllllll");
      console.log(userData, "user data ");
    }

    // console.log(fname,lastName,emailId,Password,"verify jwt resulttttttttttttttttttt appended to the state variables")
  };

  // User_Profile_FE_8
  useEffect(() => {
    setUserDetails();
  }, [userData]);
  // useEffect(()=>{

  // },[isEmailEditMode])

  // User_Profile_FE_13
  const onChangeInNamefields = (e) => {
    setIsEditMode(true); // Set edit mode to true on any change in the form fields

    // Update the state based on the input field's name
    switch (e.target.name) {
      case "firstName":
        if (e.target.value === "") {
          setValidateFname(false);
        }
        setFirstName(e.target.value);
        break;
      case "lastName":
        if (e.target.value === "") {
          setValidateLname(false);
        }

        setLastName(e.target.value);
        break;
      case "email":
        setEmailId(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      case "newEmailId":
        setNewEmailId(e.target.value);
        break;
      // case "newPassword":
      //   setNewPassword(e.target.value);
      //   break;
      // case "confirmPassword":
      //   setConfirmPassword(e.target.value);
      //   break;
      // case "oldPassword":
      //   setOldPassword(e.target.value);
      //   break;

      default:
        // Handle other fields if needed
        break;
    }
  };

  const handleFileInputChange = (e) => {
    // User_Profile_FE_33
    setIsEditMode(true);
    // User_Profile_FE_34
    const file = e.target.files[0];
    // User_Profile_FE_35
    if (file) {
      // User_Profile_FE_36
      const reader = new FileReader();
      // User_Profile_FE_37
      reader.addEventListener("loadend", () => {
        // User_Profile_FE_39
        console.log(reader.result);
        setFileImage(reader.result);
      });
      // User_Profile_FE_38
      reader.readAsDataURL(file);
    }
  };

  const saveChanges = async () => {
    // User_Profile_FE_41
    try {
      debugger;
      console.log("entered into the try block of the  save changes  function ");
      // User_Profile_FE_42
      let errorCount = 0;
      console.log(errorCount, "errror count value");
      // User_Profile_FE_43
      if (newEmailId !== "") {
        console.log("newEmailId is  not null");
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(newEmailId)) {
          console.log("entered in the  email regex");
          errorCount++;
          setValidateEmail(true);
        }
      }

      if (newPassword !== "") {
        console.log("newpassword is not null");
        const passwordRegex = /^(?!^\d)(?!^[@$!%*?&])(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_,:"".#$^()+-?><,`~{}]{8,}$/;
        if (!passwordRegex.test(newPassword)) {
          errorCount++;
          setValidatePassword(true);
        }
        // User_Profile_FE_44
        if (newPassword !== confirmPassword) {
          errorCount++;
          setValidateConfirmPassword(true);
        }
      }

      // User_Profile_FE_45
      if (errorCount === 0) {
        console.log("entered into the errorcount zero partttttttttttttttt");
        let payload = {
          userId: verifyJwtData("User_Id"),
          firstName: firstName,
          lastName: lastName,
          emailId: newEmailId !== "" ? newEmailId : emailId,
          password: Password,
          newPassword: newPassword !== "" ? newPassword : "",
          profileImageUrl: fileImage,
          oldPassword: oldPassword,
        };
        // User_Profile_FE_46
        console.info(payload, "durrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
        const responseData = await updateUserDataApi(payload);
        // User_Profile_FE_57
        if (responseData.status === 200) {
          // User_Profile_FE_58
          openToast("User data updated successfully", "images/toast-check.svg");
          setIsEditMode(false);
          setValidateEmail(false);
          setValidatePassword(false);

          getUserData();

        }
      }
    } catch (error) {
      // User_Profile_FE_60
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
      console.error(error);
    }
  };
  console.table(
    isEditMode &&
    (emailId !== backupUserRec[0]?.Email_Id ||
      Password !== backupUserRec[0]?.Password ||
      firstName !== backupUserRec[0]?.Firstname ||
      (lastName !== backupUserRec[0]?.Lastname &&
        !(firstName.trim().length == 0 && lastName.trim().length == 0) &&
        oldPassword.trim().length != 0 &&
        newPassword === confirmPassword &&
        newPassword.trim().length != 0 &&
        confirmPassword.trim().length != 0)),
    "vurrrrrrr"
  );
  console.table(isEditMode, "isEditMode ");
  console.info(
    emailId !== backupUserRec[0]?.Email_Id ||
    Password !== backupUserRec[0]?.Password ||
    firstName !== backupUserRec[0]?.Firstname ||
    lastName !== backupUserRec[0]?.Lastname,
    "( emailId!== backupUserRec[0]?.Email_Id  || Password!== backupUserRec[0]?.Password || firstName!== backupUserRec[0]?.Firstname || lastName!== backupUserRec[0]?.Lastname"
  );

  console.info(
    emailId !== backupUserRec[0]?.Email_Id,
    emailId,
    "comp email",
    backupUserRec[0]?.Email_Id
  );
  console.info(
    Password !== backupUserRec[0]?.Password,
    Password,
    "comp pass",
    backupUserRec[0]?.Password
  );
  console.info(
    firstName !== backupUserRec[0]?.Firstname,
    firstName,
    "comp first",
    backupUserRec[0]?.Firstname
  );
  console.info(
    lastName !== backupUserRec[0]?.Lastname,
    lastName,
    "comp lastName",
    backupUserRec[0]?.Lastname
  );
  console.info(
    firstName.trim().length == 0 || lastName.trim().length == 0,
    " ( firstName.trim().length==0 || lastName.trim().length==0  )",
    firstName,
    lastName
  );

  console.info(
    firstName.trim().length !== 0 || lastName.trim().length !== 0,
    " (  firstName.trim().length!=0 && lastName.trim().length!=0  )",
    firstName,
    lastName
  );
  console.info(
    !isEditMode || // Disable if not in edit mode
      !(
        // Disable if no changes made
        (
          emailId !== backupUserRec[0]?.Email_Id ||
          Password !== backupUserRec[0]?.Password ||
          firstName !== backupUserRec[0]?.Firstname ||
          lastName !== backupUserRec[0]?.Lastname ||
          fileImage !== backupUserRec[0]?.Profile_Image_Url ||
          oldPassword.trim().length !== 0 || // Enable if old password entered (indicating password change)
          (newPassword !== confirmPassword && isPasswordEditMode) || // Enable if new password and confirm password don't match (only if in password edit mode)
          (isPasswordEditMode &&
            newPassword.trim().length !== 0 &&
            confirmPassword.trim().length !== 0)
        ) // Enable if new and confirm password are entered (only if in password edit mode)
      ) ||
      firstName.trim().length === 0 || // Disable if first name is empty
      lastName.trim().length === 0 // Disable if last name is empty
      ? true
      : false,
    "total disabled "
  );

  console.info(
    confirmPassword === newPassword &&
    oldPassword.trim().length !== 0 &&
    confirmPassword.trim().length !== 0 &&
    newPassword.trim().length !== 0,
    "nnnnnnnnnnnnnnnnnnnnnnnnnnn"
  );
  console.info(validateEmail, "email_validation");
  return (
    <div
      className={`${isNavCollapsed ? "collapsed-con" : "content-conatiner"}`}
      id="contentBox"
    >
      <div className="col-md-12 ">
        <h2 className="font-bold font-22 my-4">Profile Details</h2>
        <div className="bg-white rounded-3 px-4 py-5 mb-5">
          <div className="row pb-3 border-bottom">
            <div className="text-center mb-4">
              <span className="position-relative d-inline-block">
                <span>
                  <img
                    src={fileImage}
                    alt="user-img"
                    className="user-profile-icon"
                  />
                </span>
                <label
                  className="position-absolute profile-edit-icon cursor-pointer"
                  htmlFor="uploadImage"
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="images/profile-edit-icon.svg"
                    alt="edit icon"
                    className="cursor-pointer"
                  />

                  <input
                    type="file"
                    className="cust-file cursor-pointer"
                    id="uploadImage"
                    onChange={handleFileInputChange}
                  />
                </label>
              </span>
            </div>
            <div className="col-md-6 mb-3">
              <label
                htmlFor="FirstName"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                First Name
              </label>
              <input
                type="text"
                placeholder="Enter First Name"
                className="form-control custom-form py-2 font-14 font-regular"
                id="FirstName"
                name="firstName"
                value={firstName}
                onChange={onChangeInNamefields}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label
                htmlFor="LastName"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                placeholder="Enter Last Name"
                className="form-control custom-form py-2 font-14 font-regular"
                id="LastName"
                value={lastName}
                onChange={onChangeInNamefields}
              />
            </div>
          </div>
          <div className="row pb-3 border-bottom">
            <h2 className="font-bold font-20 my-4">Email Address </h2>
            {!isEmailEditMode ? (
              <div className="d-flex align-items-center mb-3">
                <label
                  className="font-20 font-medium"
                  value={emailId}
                  name="email"
                  onChange={onChangeInNamefields}
                >
                  {emailId}
                </label>
                <button
                  type="button"
                  className="px-4 ms-3 violet-btn primary-darkblue font-medium font-18 text-nowrap box-shadow-none"
                  onClick={() => {
                    setIsEmailEditMode(true);
                  }}
                >
                  {" "}
                  Change
                </button>
              </div>
            ) : null}

            <div className="col-md-6 mb-3" hidden={!isEmailEditMode}>
              <label
                htmlFor="CurrentEmailAddress"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Current Email Address
              </label>
              <input
                type="email"
                placeholder="Enter Current Email"
                name="oldEmail"
                className="form-control custom-form py-2 font-14 font-regular"
                id="CurrentEmailAddress"
                value={emailId}
                disabled
              />
            </div>
            <div className="col-md-6 mb-3" hidden={!isEmailEditMode}>
              <label
                htmlFor="NewEmailAddress"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                New Email Address
              </label>
              <input
                type="email"
                placeholder="Enter New Email Address"
                className="form-control custom-form py-2 font-14 font-regular"
                id="NewEmailAddress"
                disabled={!isEmailEditMode}
                name="newEmailId"
                value={newEmailId}
                onChange={onChangeInNamefields}
              />
              {validateEmail ? (
                <span
                  className="error-message"
                  style={{ color: "red", fontWeight: "bold" }}
                >
                  Enter valid email address
                </span>
              ) : null}
            </div>
          </div>
          <div className="row pb-3">
            <h2 className="font-bold font-20 my-4">Password</h2>
            {!isPasswordEditMode ? (
              <>
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="Password"
                    className="form-label mb-0 cust-label font-14 font-medium mb-2"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    placeholder="Enter password"
                    name="password"
                    className="form-control custom-form py-2 font-14 font-regular"
                    id="Password"
                    value={Password}
                    onChange={onChangeInNamefields}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3 d-flex align-items-end">
                  <button
                    type="button"
                    className="px-4 violet-btn primary-darkblue font-medium font-18 text-nowrap box-shadow-none"
                    style={{ height: "39px" }}
                    onClick={() => setIsPasswordEditMode(true)}
                  >
                    {" "}
                    Change
                  </button>
                </div>{" "}
              </>
            ) : null}
          </div>
          <div className="row " hidden={!isPasswordEditMode}>
            <div className="col-md-6 mb-3">
              <label
                htmlFor="OldPassword"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Old Password
              </label>
              <input
                type="password"
                name="oldPassword"
                placeholder="Enter old password"
                className="form-control custom-form py-2 font-14 font-regular"
                id="OldPassword"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />
              {validatePassword ? (
                <span
                  className="error-message"
                  style={{ color: "red", fontWeight: "bold" }}
                >
                  Enter valid Password
                </span>
              ) : null}
            </div>
          </div>
          <div className="row" hidden={!isPasswordEditMode}>
            <div className="col-md-6 mb-3">
              <label
                htmlFor="NewPassword"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                New Password
              </label>
              <input
                type="password"
                placeholder="Enter new password"
                name="newPassword"
                className="form-control custom-form py-2 font-14 font-regular"
                id="NewPassword"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label
                htmlFor="ConfirmPassword"
                className="form-label mb-0 cust-label font-14 font-medium mb-2"
              >
                Confirm Password
              </label>
              <input
                type="password"
                placeholder="Enter confirm password"
                className="form-control custom-form py-2 font-14 font-regular"
                id="ConfirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
              {validateConfirmPassword ? (
                <span
                  className="error-message"
                  style={{ color: "red", fontWeight: "bold" }}
                >
                  New and Confirm Password didn't match
                </span>
              ) : null}
            </div>
          </div>
          <div className="d-flex justify-content-end mt-5 mb-3">
            <button
              onClick={() => {
                setUserDetails();
                setIsPasswordEditMode(false);
                setIsEmailEditMode(false);
                setIsEditMode(false);
              }}
              className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn px-4 primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap"
              data-bs-dismiss="modal"
              onClick={saveChanges}
              disabled={
                !(
                  isEditMode ||
                  emailId !== backupUserRec[0]?.Email_Id ||
                  Password !== backupUserRec[0]?.Password ||
                  firstName !== backupUserRec[0]?.Firstname ||
                  lastName !== backupUserRec[0]?.Lastname ||
                  fileImage !== backupUserRec[0]?.Profile_Image_Url ||
                  (oldPassword.trim().length !== 0 && // Enable if old password entered (indicating password change)
                    newPassword === confirmPassword && // Enable if new password and confirm password don't match
                    newPassword.trim().length !== 0 && // Enable if new password is entered
                    confirmPassword.trim().length !== 0) || // Enable if confirm password is entered
                  firstName.trim().length === 0 || // Enable if first name is empty
                  lastName.trim().length === 0 || // Enable if last name is empty
                  validateEmail || // Enable if email validation fails and email edit mode is true // Enable if new and confirm email are entered (only if in email edit mode)
                  (isEmailEditMode && newEmailId.trim().length !== 0) || // Enable if a profile picture is uploaded
                  (isEditMode &&
                    fileImage !== backupUserRec[0]?.Profile_Image_Url)
                )
              }
            // ( // Enable if new and confirm passwords are entered (only if in password edit mode)
            // isPasswordEditMode &&
            // newPassword.trim().length !== 0 &&
            // confirmPassword.trim().length !== 0
            //   (confirmPassword!==newPassword && oldPassword.trim().length !== 0 && confirmPassword.trim().length !== 0 && newPassword.trim().length !== 0)
            // ) ||
            >
              Save Changes
            </button>
            {console.log(
              "Checkinh userr ppspspspjnsj",
              !(isEditMode ||
                emailId !== backupUserRec[0]?.Email_Id ||
                Password !== backupUserRec[0]?.Password ||
                firstName !== backupUserRec[0]?.Firstname ||
                lastName !== backupUserRec[0]?.Lastname ||
                fileImage !== backupUserRec[0]?.Profile_Image_Url ||
                oldPassword.trim().length !== 0 || // Enable if old password entered (indicating password change)
                newPassword !== confirmPassword || // Enable if new password and confirm password don't match
                newPassword.trim().length !== 0 || // Enable if new password is entered
                confirmPassword.trim().length !== 0 || // Enable if confirm password is entered
                firstName.trim().length === 0 || // Enable if first name is empty
                lastName.trim().length === 0 || // Enable if last name is empty
                validateEmail, // Enable if email validation fails and email edit mode is true // Enable if new and confirm passwords are entered (only if in password edit mode)
                (isPasswordEditMode &&
                  newPassword.trim().length !== 0 &&
                  confirmPassword.trim().length !== 0,
                  "Enable if new and confirm passwords are entered (only if in password edit mode)"), // Enable if new and confirm email are entered (only if in email edit mode)
                (isEmailEditMode && newEmailId.trim().length !== 0,
                  "Enable if new and confirm email are entered (only if in email edit mode)"), // Enable if a profile picture is uploaded
                (isEditMode && fileImage !== backupUserRec[0]?.Profile_Image_Url,
                  "Enable if a profile picture is uploaded"))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
