import { useCommonContext } from "../../contexts/CommonContext";
import { useCreateContentContext } from "../../contexts/CreateContentContext";
import { askAiApi } from "../../services/api";
export default function AskAiResponsePrompt() {
  const {
    isAskAiResponseReturned,
    askAiResponseText,
    selectedText,
    setIsAskAiResponseReturned,
    generatedContent,
    setGeneratedContent,
    askAiPromptText,
    setIsAskAiOpened,
    setAskAiResponsetext,
    position,
    isAskAiPrompt,
    setIsAskAiPrompt,
    isAskresult,
    setIsAskResult,
  } = useCreateContentContext();

  const { userData } = useCommonContext();
  const replaceText = () => {
    debugger;
    let htmlString = generatedContent;
    htmlString = htmlString.replace(selectedText, askAiResponseText);
    setIsAskAiResponseReturned(false);
    setGeneratedContent(htmlString);
    setIsAskAiOpened(true);
    setIsAskResult(true);
  };

  const discard = () => {
    setIsAskAiOpened(true);
    setIsAskResult(true);
    setIsAskAiResponseReturned(true);
  };

  async function askAi() {
    try {
      const payload = {
        userId: userData[0]["User_Id"],
        prompt: askAiPromptText,
        selectedText: selectedText,
      };

      const responseData = await askAiApi(payload);

      setAskAiResponsetext(responseData.data.data);
      setIsAskAiResponseReturned(true);
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <span
      className="ask-ai-drop position-absolute"
      hidden={isAskresult}
      style={{
        // left: `${position.x}px`,
        top: `${position.y + 77}px`,
        left: "47%",
      }}
    >
      <span
        className="d-flex align-items-center p-2 gap-3 ask-drop-list cursor-pointer rounded-3"
        onClick={() => {
          replaceText();
          setIsAskAiPrompt(true);
        }}
      >
        <img src="images/replace-doc-icon.svg" alt="replace-doc-icon" />
        Replace documentation section
      </span>
      <span
        onClick={() => {
          askAi();
        }}
        className="d-flex align-items-center p-2 gap-3 ask-drop-list cursor-pointer rounded-3"
      >
        <img src="images/try-again-icon.svg" alt="try-again-icon" />
        Try again
      </span>
      <span
        className="d-flex align-items-center p-2 gap-3 ask-drop-list cursor-pointer rounded-3"
        onClick={() => {
          discard();
          setIsAskAiPrompt(true);
        }}
      >
        <img src="images/discard-icon.svg" alt="discard-icon" />
        Discard
      </span>
    </span>
  );
}
