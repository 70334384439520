import { useMyEventContext } from "../../contexts/MyEventContext";

export default function DayMonthToggle() {
  const { isDayview, setIsDayView } = useMyEventContext();

  return (
    <div className="tab-toggle ms-3">
      <input
        type="radio"
        className="btn-check"
        name="options"
        id="day"
        defaultChecked
        onClick={() => {
          setIsDayView(true);
        }}
      />
      <label
        className="btn box-shadow-none color-grey font-12 font-bold"
        htmlFor="day"
      >
        Day
      </label>
      <input
        type="radio"
        className="btn-check color-grey"
        name="options"
        id="month"
        onClick={() => {
          setIsDayView(false);
        }}
      />
      <label
        className="btn box-shadow-none color-grey font-12 font-bold"
        htmlFor="month"
      >
        Month
      </label>
    </div>
  );
}
