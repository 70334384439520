import React, { useEffect, useState } from "react";
import { useCommonContext } from "../../contexts/CommonContext";
import Cards from "./Cards";
import MyEvents from "./MyEvents";
import RecentActivities from "./RecentActivities";
import RecentPosts from "./RecentPosts";
import Loader from "../Loader/Loader";

export default function Hub() {
  const { isNavCollapsed, setIsNavCollapsed } = useCommonContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Set isLoading to true when component mounts
    setIsLoading(true);

    // After 7 seconds, set isLoading to false
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 7000);

    // Cleanup function to clear timeout
    return () => clearTimeout(timeout);
  }, []); // Run this effect only once when the component mounts

  return (
    <div className={!isNavCollapsed ? "content-conatiner" : "collapsed-con"} id="contentBox">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h2 className="font-bold font-22 mt-4 mb-3">Hub</h2>
          <div className="row">
            <Cards />
            <div className="col-md-8 mb-4">
              <RecentActivities />
              <MyEvents />
            </div>
            <div className="col-md-4 mb-4">
              <RecentPosts />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
