import { createContext } from "react";
import AllMediaPostComponent from "./AllMediaPostComponents";
import SocialMediaFields from "./SocialMediaFields";
import { useSocialMediaContext } from "../../../contexts/SocialMediaContext";
import { useCommonContext } from "../../../contexts/CommonContext";
import { submitRequestApi } from "../../../services/api";
import { useNavigate } from "react-router-dom";

export const ContentDetailsContext = createContext();

export default function SocialMediaContentGen() {
  const navigate = useNavigate();
  const {
    specifyKeyContent,
    targetAudience,
    audienceLevel,
    ContentDetails,
    setContentDetails,
    socialMediaAccount,
    toneOfTheContent,
    styleOfTheContent,
    SocialMediaContentId,
    hashtags,
    SocialMediaId,
    setSocialMediaId,
    ContentId,
    contId,
    setContentId,
    socialMediaIds,
    generateSocialMedia,
    editPost,
    setEditPost,
    Prompt,
    setPrompt,
  } = useSocialMediaContext();
  const { userData, openToast } = useCommonContext();
  const {isNavCollapsed, setIsNavCollapsed } = useCommonContext();

  const submitRequest = async () => {
    try {
      const payload = {
        contentId: contId,
        userId: userData[0]?.User_Id,
      };

      const responseData = await submitRequestApi(payload);

      if (responseData.status === 200) {
        openToast("Submitted successfully", "images/toast-check.svg");
        setTimeout(() => {
          // navigate("/yoursocialmediaposts");
        }, 5000);
      }

      console.log(responseData);
    } catch (error) {
      openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
      console.log(error.message);
    }
  };

  return (
    <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"}  id="contentBox">
      <div className="row">
        <h3 className="head-bar font-20 font-bold p-3 sticky-top bg-white mb-0 d-flex align-items-center justify-content-between">
          <span
            className="d-flex align-items-center"
            onClick={() => {
              navigate("/yoursocialmediaposts", {
                state: {
                  contentTypeId: "1436759C-49D5-4369-A0BA-FE444D4E80C7",
                  contentType: "Social Media Post",
                },
              });
            }}
          >
            <img
              src="images/back-icon.svg"
              alt="back-icon"
              className="me-2 cursor-pointer"
            />
            Create New Post
          </span>
        </h3>
        <div className="col-md-12">
          <div className="row">
            <SocialMediaFields />
            <AllMediaPostComponent />
          </div>
        </div>
        <footer
          className="head-bar font-20 font-bold p-3 position-sticky bottom-0  bg-white mb-0 d-flex align-items-center justify-content-end"
          style={{ zIndex: 999 }}
        >
          {ContentDetails !== "" &&
          userData[0]?.Role_Name !== "Content Manager" ? (
            <button
              className="btn btn-success cust-approve d-flex align-items-center gap-2 px-3"
              // Corrected variable name from contentID to ContentId
              onClick={() => {
                submitRequest();
              }}
            >
              Submit
            </button>
          ) : null}
        </footer>
      </div>
    </div>
  );
}
