export default function RoadmapLogo(props) {
    console.log(props.logoArray, "this is the logo array");
    return (
      <tr>
        {props?.logoArray?.map((value, index) => {
          return (
            <td className="text-center">
              <img src={value} alt="alt-img" height={"75px"} />
            </td>
          );
        })}
      </tr>
    );
  }
  