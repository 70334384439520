import React from 'react';


function NotFoundPage() {
    return (
        <div className="ncontainer">
            <div className="ncentered">
                <h1>404 Not Found</h1>
            </div>
        </div>
    );
}

export default NotFoundPage;
