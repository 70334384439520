import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../components/login/Login";
import Signup from "../components/signup/Signup";
import ForgotPassword from "../components/forgotpassword/ForgotPassword";
import ResetPassword from "../components/resetpassword/ResetPassword";
import Hub from "../components/hub/Hub";
// import WrongRoutes from "../components/WrongRoutes/WrongRoutes"
import { useCommonContext } from "../contexts/CommonContext";
import { useEffect, useState, } from "react";
import Navigation from "../components/navigation/Navigation";
import Requests from "../components/requests/Requests";
import { HubContextProvider } from "../contexts/HubContext";
import Integrations from "../components/integrations/Integrations";
import ManageUsers from "../components/manageusers/ManageUsers";
import Notifications from "../components/notifications/Notifications";
import MyEvents from "../components/myevents/MyEvents";
import CreateMyEvents from "../components/createmyevents/CreateMyEvents";
import { MyEventsContextProvider } from "../contexts/MyEventContext";
import { CreateMyEventContextProvider } from "../contexts/CreateMyEventContext";
// import Contents from "../components/contents/content";
import UserProfile from "../components/userprofile/UserProfile";
import CreateContent from "../components/createcontent/CreateContent";
import { CreateContentContextProvider } from "../contexts/CreateContentContext";
import UpcommingEvents from "../components/upcomingevents/UpcommingEvents";
import YourArticles from "../components/contents/YourArticles";
import YourWebPages from "../components/contents/YourWebpages";
import YourSocialMediaPosts from "../components/contents/YourSocialMediaPosts";
import YourPressRelease from "../components/contents/YourPressReleases";
import YourCaseStudies from "../components/contents/YourCaseStudies";
import ProductContrast from "../components/productcontrast/ProductContrast";
import ComparisonHistory from "../components/comparisonhistory/ComparisonHistory";
import Product from "../components/createproduct/Product";
import ComponentHandler from "../components/createproduct/ComponentHandler";
import Feature from "../components/createproduct/Feature";
import ComparisonResult from "../components/comparisonresult/comparisonresult";
import { CreateProductContextProvider } from "../contexts/CreateProductContext";
import SocialMediaContentGen from "../components/createcontent/SocialMedia Post/SocialMediaContentGen";
import { SocialMediaContextProvider } from "../contexts/SocialMediaContext";
import ApproveContent from "../components/ApproveContent/ApproveContent";
import { useNavigate } from "react-router-dom";
import NotFoundPage from "../components/notfound/NotFound";

export default function RoutesComponent() {
  const [email, setEmail] = useState(false);
  // const [isNotUser, setIsNotUser] = useState(true)
  // useEffect(() => {
  //   // if(!localStorage.getItem("token")){
  //   //   
  //   // }
  //   Routes()
  // }, [email]);
  // const Routes = () => {
  //   if(localStorage.getItem("token") == null){
  //     window.location.pathname = "/"
  //     setEmail(true);
  //   }
  //   else{

  //   }

  // };

  // console.info(localStorage, "IOIOIOIOIOIOIOIOIOIOIOOIOIOIOIOIOIO")
  useEffect(() => {
  
    if (localStorage.getItem("token") || window.location.pathname == "/resetpassword" ||window.location.pathname == "/forgotpassword" || window.location.pathname == "/signup" ) {
      setIsNotUser(false)
    }
  }, [localStorage.getItem("token")])





  const { userData, NavigationTest, SetNavigationTest,isNotUser, setIsNotUser } = useCommonContext();
  const [routesArray, setRoutesArray] = useState([]);

  // <Route path="/" element={<ProductContrast />} />
  // <Route path="/history" element={<ComparisonHistory />} />
  // <Route path="/product" element={<Product />} />
  // <Route path="/comparisionmetrics" element={<ComponentHandler />} />
  // <Route path="/feature" element={<Feature />} />
  // <Route path="/result" element={<ComparisonResult />} />

  // console.log(userData.length, userData.length !== 0, "this is userdata");

  const contentManagerRoutes = [
    { path: "*", component: NotFoundPage, isNavNeeded: false },
    { path: "/", component: Login, isNavNeeded: false },
    { path: "/signup", component: Signup, isNavNeeded: false },
    { path: "/forgotpassword", component: ForgotPassword, isNavNeeded: false },
    { path: "/resetpassword", component: ResetPassword, isNavNeeded: false },
    {
      path: "/hub",
      component: Hub,
      isNavNeeded: true,
      context: HubContextProvider,
    },
    { path: "/requests", component: Requests, isNavNeeded: true },
    { path: "/integrations", component: Integrations, isNavNeeded: true },
    { path: "/manageusers", component: ManageUsers, isNavNeeded: true },
    { path: "/notifications", component: Notifications, isNavNeeded: true },
    {
      path: "/myevents",
      component: MyEvents,
      isNavNeeded: true,
      context: MyEventsContextProvider,
    },
    {
      path: "/createmyevents",
      component: CreateMyEvents,
      isNavNeeded: true,
      context: CreateMyEventContextProvider,
    },
    {
      path: "/yourarticles",
      component: YourArticles,
      isNavNeeded: true,
    },
    {
      path: "/yourwebpages",
      component: YourWebPages,
      isNavNeeded: true,
    },
    {
      path: "/yoursocialmediaposts",
      component: YourSocialMediaPosts,
      isNavNeeded: true,
    },
    {
      path: "/yourpressreleases",
      component: YourPressRelease,
      isNavNeeded: true,
    },
    {
      path: "/yourcasestudies",
      component: YourCaseStudies,
      isNavNeeded: true,
    },
    {
      path: "/userprofile",
      component: UserProfile,
      isNavNeeded: true,
    },
    {
      path: "/createcontent",
      component: CreateContent,
      isNavNeeded: true,
      context: CreateContentContextProvider,
    },
    {
      path: "/upcommingevents",
      component: UpcommingEvents,
      isNavNeeded: true,
    },
    {
      path: "/productcontrast",
      component: ProductContrast,
    },
    {
      path: "/history",
      component: ComparisonHistory,
    },
    {
      path: "/product",
      component: Product,
    },
    {
      path: "/comparisionmetrics",
      component: ComponentHandler,
    },
    {
      path: "/feature",
      component: Feature,
    },
    {
      path: "/result",
      component: ComparisonResult,
    },
    {
      path: "/createsocialmediacontent",
      component: SocialMediaContentGen,
      context: SocialMediaContextProvider,
    },
    { path: "/approvecontent", component: ApproveContent },
  ];

  console.log("This is a sample console");

  const contentWriterRoutes = [
    { path: "*", component: NotFoundPage, isNavNeeded: false },
    { path: "/", component: Login, isNavNeeded: false },
    { path: "/signup", component: Signup, isNavNeeded: false },
    { path: "/forgotpassword", component: ForgotPassword, isNavNeeded: false },
    { path: "/resetpassword", component: ResetPassword, isNavNeeded: false },
    { path: "/notifications", component: Notifications, isNavNeeded: true },
    {
      path: "/yourarticles",
      component: YourArticles,
      isNavNeeded: true,
    },
    {
      path: "/yourwebpages",
      component: YourWebPages,
      isNavNeeded: true,
    },
    {
      path: "/yoursocialmediaposts",
      component: YourSocialMediaPosts,
      isNavNeeded: true,
    },
    {
      path: "/yourpressreleases",
      component: YourPressRelease,
      isNavNeeded: true,
    },
    {
      path: "/yourcasestudies",
      component: YourCaseStudies,
      isNavNeeded: true,
    },
    {
      path: "/userprofile",
      component: UserProfile,
      isNavNeeded: true,
    },
    {
      path: "/createcontent",
      component: CreateContent,
      isNavNeeded: true,
      context: CreateContentContextProvider,
    },
    {
      path: "/upcommingevents",
      component: UpcommingEvents,
      isNavNeeded: true,
    },
    {
      path: "/productcontrast",
      component: ProductContrast,
    },
    {
      path: "/history",
      component: ComparisonHistory,
    },
    {
      path: "/product",
      component: Product,
    },
    {
      path: "/comparisionmetrics",
      component: ComponentHandler,
    },
    {
      path: "/feature",
      component: Feature,
    },
    {
      path: "/result",
      component: ComparisonResult,
    },
    {
      path: "/createsocialmediacontent",
      component: SocialMediaContentGen,
      context: SocialMediaContextProvider,
    },
  ];

  const productOwnerRoutes = [
    { path: "*", component: NotFoundPage, isNavNeeded: false },
    { path: "/", component: Login, isNavNeeded: false },
    { path: "/signup", component: Signup, isNavNeeded: false },
    { path: "/forgotpassword", component: ForgotPassword, isNavNeeded: false },
    { path: "/resetpassword", component: ResetPassword, isNavNeeded: false },
    { path: "/notifications", component: Notifications, isNavNeeded: true },
    {
      path: "/userprofile",
      component: UserProfile,
      isNavNeeded: true,
    },
    {
      path: "/upcommingevents",
      component: UpcommingEvents,
      isNavNeeded: true,
    },
    {
      path: "/productcontrast",
      component: ProductContrast,
    },
    {
      path: "/history",
      component: ComparisonHistory,
    },
    {
      path: "/product",
      component: Product,
    },
    {
      path: "/comparisionmetrics",
      component: ComponentHandler,
    },
    {
      path: "/feature",
      component: Feature,
    },
    {
      path: "/result",
      component: ComparisonResult,
    },
    {
      path: "/createsocialmediacontent",
      component: SocialMediaContentGen,
      context: SocialMediaContextProvider,
    },
  ];

  const commonRoutes = [
    { path: "*", component: NotFoundPage, isNavNeeded: false },
    { path: "/", component: Login, isNavNeeded: false },
    { path: "/signup", component: Signup, isNavNeeded: false },
    { path: "/forgotpassword", component: ForgotPassword, isNavNeeded: false },
    { path: "/resetpassword", component: ResetPassword, isNavNeeded: false },
  ];

  useEffect(() => {
    if (userData.length !== 0) {
      if (userData[0]?.Role_Name === "Content Manager") {
        setRoutesArray(contentManagerRoutes);
      } else if (userData[0]?.Role_Name === "Content Writer") {
        setRoutesArray(contentWriterRoutes);
      } else if (userData[0]?.Role_Name === "Product Owner") {
        setRoutesArray(productOwnerRoutes);
      } else {
        setRoutesArray(commonRoutes);
      }
    } else {
      setRoutesArray(commonRoutes);
    }
  }, [userData]);

  // console.log(window.location.pathname, NavigationTest, "IUYGTRFDRGHJKLJHGFD");
  // console.table(routesArray);

  console.info(window.localStorage.getItem('token'),"This is the toekn from the routes component")
  console.info(((window.location.pathname === "/hub" ||
  window.location.pathname === "/requests" ||
  window.location.pathname === "/integrations" ||
  window.location.pathname === "/manageusers" ||
  window.location.pathname === "/notifications" ||
  window.location.pathname === "/myevents" ||
  window.location.pathname === "/createmyevents" ||
  window.location.pathname === "/yourarticles" ||
  window.location.pathname === "/yourwebpages" ||
  window.location.pathname === "/yoursocialmediaposts" ||
  window.location.pathname === "/yourpressreleases" ||
  window.location.pathname === "/yourcasestudies" ||
  window.location.pathname === "/userprofile" ||
  window.location.pathname === "/createcontent" ||
  window.location.pathname === "/upcommingevents" ||
  window.location.pathname === "/productcontrast" ||
  window.location.pathname === "/history" ||
  window.location.pathname === "/product" ||
  window.location.pathname === "/comparisionmetrics" ||
  window.location.pathname === "/feature" ||
  window.location.pathname === "/result" ||
  window.location.pathname === "/createsocialmediacontent" ||
  window.location.pathname === "/approvecontent") && window.localStorage.getItem('token') != null),"This is the fucking condition")

  return (
    <BrowserRouter>
      <CreateProductContextProvider>
        {((window.location.pathname === "/hub" ||
          window.location.pathname === "/requests" ||
          window.location.pathname === "/integrations" ||
          window.location.pathname === "/manageusers" ||
          window.location.pathname === "/notifications" ||
          window.location.pathname === "/myevents" ||
          window.location.pathname === "/createmyevents" ||
          window.location.pathname === "/yourarticles" ||
          window.location.pathname === "/yourwebpages" ||
          window.location.pathname === "/yoursocialmediaposts" ||
          window.location.pathname === "/yourpressreleases" ||
          window.location.pathname === "/yourcasestudies" ||
          window.location.pathname === "/userprofile" ||
          window.location.pathname === "/createcontent" ||
          window.location.pathname === "/upcommingevents" ||
          window.location.pathname === "/productcontrast" ||
          window.location.pathname === "/history" ||
          window.location.pathname === "/product" ||
          window.location.pathname === "/comparisionmetrics" ||
          window.location.pathname === "/feature" ||
          window.location.pathname === "/result" ||
          window.location.pathname === "/createsocialmediacontent" ||
          window.location.pathname === "/approvecontent") && window.localStorage.getItem('token') != null) ? (
          <Navigation />
        ) : null}
        <Routes>
          {routesArray.map(
            ({ path, component, context, isNavNeeded }, index) => {
              const Component = isNotUser ? Login : component;
              const Provider = context;
              const element = Provider ? (
                <Provider>
                  <Component />
                </Provider>
              ) : (
                <>
                  <Component />
                </>
              );

              return <Route key={index} path={path} element={element} />;
            }
          )}
        </Routes>
      </CreateProductContextProvider>
    </BrowserRouter>
  );
}
