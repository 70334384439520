import { useEffect, useState } from "react";
import FeatureLogo from "./Logo/FeatureLogo";
import { useCreateProductContext } from "../../contexts/CreateProductContext";
import { get_feature_tick } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";
import Loader from "../Loader/Loader";


export default function FeatureResult() {
  const { userData } = useCommonContext();
  const { logoArray, productId } = useCreateProductContext();

  const [featureResponse, setFeatureResponse] = useState([]);

  const [featureArray, setFeatureArray] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);


  async function fetchData() {
    try {
      const payloadFirst = {
        productId: productId,
        userId: userData[0]["User_Id"]
      };

      setIsLoading(true)
      const responseData = await get_feature_tick(payloadFirst);
      setIsLoading(false)

      console.log(responseData.data.data.feature_html?.comparison, "get the Product feature_tick");
      console.log(responseData.data.data.feature_html, "get the Product feature_tick without");

      setFeatureResponse(responseData.data.data.feature_html);

      // Process featureResponse data
      const groupedFeatures = {};
      responseData.data.data.feature_html.comparison?.forEach((item) => {
        item.Features.forEach((feature) => {
          const { Feature_Name, is_Available } = feature;

          if (Feature_Name in groupedFeatures) {
            groupedFeatures[Feature_Name].Is_Available?.push(is_Available);
          } else {
            groupedFeatures[Feature_Name] = {
              Feature_Name,
              Is_Available: [is_Available],
            };
          }
        });
      });

      // Convert the object values to an array
      const groupedFeaturesArray = Object.values(groupedFeatures);
      console.log(groupedFeaturesArray, "group");

      // Set the grouped features array to state
      setFeatureArray(groupedFeaturesArray);
    } catch (error) {
      console.error("Error fetching product feature pricing:", error);
    }
  }


  return (
    <div className="d-flex justify-content-center align-items-center">
      {isLoading ? (
        <Loader /> // Loader will be centered
      ) : (
        <table className="table table-borderless">
          <tbody className="font-medium ">
            <FeatureLogo logoArray={logoArray} />
            {featureArray.map((value, index) => {
              return (
                <tr>
                  <td className="compare-header-color">{value?.Feature_Name}</td>
                  {value?.Is_Available?.map((value, index) => {
                    return (
                      <td className="text-center">
                        <img
                          src={`images/${value ? "tick-icon" : "cross-icon"}.svg`}
                          alt="tick-icon"
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
