import { useHubContext } from "../../contexts/HubContext";
import EventToggleHeaders from "./EventsToggleHeader";
import MyEventsHub from "./MyEventsHub";
import UpcommingEventsHub from "./UpcommingEventsHub";

export default function MyEvents() {
  const { isUpcomming, setIsUpcomming } = useHubContext();
  return (
    <div className="bg-white p-3 radius-16  hub-card">
      <EventToggleHeaders />
      {!isUpcomming ? <MyEventsHub /> : <UpcommingEventsHub />}
    </div>
  );
}
