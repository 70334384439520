export default function Logo(props) {
  console.log(props.logoArray, "this is the logo array");
  return (
    <tr>
      <td></td>
      {props?.logoArray?.map((value, index) => {
        return (
          <td className="py-4">
            <img src={value} alt="alt img" height={"75px"} />
          </td>
        );
      })}
    </tr>
  );
}
