import { useState } from "react";
import {
  regeneratePostApi,
  createSocialApi,
  createContentForMediaApi,
  createImageForMediaApi,
  getEventPostsApi,
} from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";
import { useCreateContentContext } from "../../contexts/CreateContentContext";

export default function HighlightSelectCanvas(props) {
  const { generateHighlight, setGenerateHighlight, openToast, userData } =
    useCommonContext();

  const { highlightShow, setHighlightShow, socialMediaId, setSocialMediaId } =
    useCreateContentContext();

  // const [socialMediaId, setSocialMediaId] = useState([]);

  const { config, contentid, account, setConfig } = props;

  const regeneratePost = async (platform) => {
    try {
      // console.warn(
      //   document.getElementById("openhigh").click(),
      //   "This is the delta"
      // );
      // setIsLoading(true);

      const createSocialPayload = {
        userId: userData[0]["User_Id"],
        id: contentid,
        type: "Highlight",
      };

      const responseDataMedia = await createSocialApi(createSocialPayload);
      setSocialMediaId(responseDataMedia.data?.data?.Social_media_content_id);
      const userId = userData[0]["User_Id"];

      let platformChoosed = [];

      if (props.config.Account_And_Platform.fbPost) {
        platformChoosed.push("facebook");
      }

      if (props.config.Account_And_Platform.linkedinPost) {
        platformChoosed.push("linkedIn");
      }
      if (props.config.Account_And_Platform.twitterPost) {
        platformChoosed.push("twitter");
      }
      if (props.config.Account_And_Platform.instaCap) {
        platformChoosed.push("instagram");
      }

      let promises = [];

      for (let platform of platformChoosed) {
        const payload = {
          configJson: props.config,
          userId: userData[0]["User_Id"],
          socialMediaId: responseDataMedia.data?.data?.Social_media_content_id,
          platform: platform,
          account_choosed: config.Account_And_Platform.socialMediaAccount,
        };

        promises.push(createContentForMediaApi(payload));
      }
      const imagePayload = {
        userId: userData[0]["User_Id"],
        socialMediaContentId:
          responseDataMedia.data?.data?.Social_media_content_id,
        platform: "socialmediaImage",
        config_json: props.config,
        account_choosed: config.Account_And_Platform.socialMediaAccount,

        // const response = await createImageForMediaApi(imagePayload);
        // const getPayload = {
        //   userId: userData[0]["User_Id"],
        //   socialMediaId: socialMediaId,
        // };
        // const getEventRes = await getEventPostsApi(getPayload);
        // setSocialMediaContent(getEventRes.data.data.data);
      };

      promises.push(createImageForMediaApi(imagePayload));

      Promise.all(promises)

        .then((results) => {
          document.getElementById("openhigh").click();
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      // const data = {
      //   userId: userId,
      //   config_json: props.config,
      //   platform_selected: platformChoosed,

      //   // platform_selected: ["twitter", "instagram", "facebook", "linkedin"],

      //   type: "Highlight",
      //   Social_Media_Id: "",
      //   account_choosed: config.Account_And_Platform.socialMediaAccount,
      //   Event_Id: props.contentid,
      // };
      // const responseData = await regeneratePostApi(data);
      // if (responseData.status === 200)  {
      //   setGenerateHighlight(responseData.data.data);
      //   setHighlightShow(true)
      //   document.getElementById("openhigh").click();

      //   // openToast("Content Regerated Successfully ", "images/toast-check.svg");
      //   // setIsLoading(false);
      // }
    } catch (error) {
      // openToast(error?.response?.data?.message, "images/red-cross-toast.svg");
    }
  };

  console.info(props);
  const handleInputChange = (section, field, value) => {
    setConfig((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  return (
    <div
      className="offcanvas offcanvas-end cust-canvas"
      tabIndex={-1}
      id="highLightCanvas"
      aria-labelledby="offcanvasRightLabel"
      style={{ zIndex: 1051 }}
    >
      <div className="offcanvas-header align-items-center px-4 border-bottom cust-border">
        <h5 className="font-22 font-bold">Select social media platform</h5>
        <button
          type="button"
          className="btn-close text-reset box-shadow-none"
          data-bs-dismiss="offcanvas"
        />
      </div>
      <div className="offcanvas-body position-relative px-4">
        <ul className="ps-0">
          {account
            .filter(
              (value) =>
                value.Account_Id ===
                config?.Account_And_Platform?.socialMediaAccount
            )
            .map((filteredAccount) => {
              return (
                <>
                  {filteredAccount?.Facebook_Credentials != 0 ? (
                    <li
                      hidden={filteredAccount?.Facebook_Credentials === 0}
                      className="d-flex align-items-center justify-content-between py-3 "
                    >
                      <label
                        className="d-flex align-items-center gap-3"
                        htmlFor="fbHighlight"
                      >
                        <img src="images/facebook-post-icon.svg" />
                        Facebook post
                      </label>
                      <input
                        className="form-check-input cust-check box-shadow-none"
                        type="checkbox"
                        defaultValue
                        id="fbHighlight"
                        checked={props.config.Account_And_Platform.fbPost}
                        onChange={(e) =>
                          handleInputChange(
                            "Account_And_Platform",
                            "fbPost",
                            e.target.checked
                          )
                        }
                 
                      />
                    </li>
                  ) : null}
                  {filteredAccount?.Linkedin_Credentials != 0 ? (
                    <li
                      hidden={filteredAccount?.Linkedin_Credentials === 0}
                      className="d-flex align-items-center justify-content-between py-3"
                    >
                      <label
                        className="d-flex align-items-center gap-3"
                        htmlFor="linkedinHighlight"
                      >
                        {" "}
                        <img src="images/linkedin-post-icon.svg" />
                        Linkedin post
                      </label>
                      <input
                        className="form-check-input cust-check box-shadow-none"
                        type="checkbox"
                        defaultValue
                        id="linkedinHighlight"
                        checked={props.config.Account_And_Platform.linkedinPost}
                        onChange={(e) =>
                          handleInputChange(
                            "Account_And_Platform",
                            "linkedinPost",
                            e.target.checked
                          )
                        }
                      />
                    </li>
                  ) : null}
                  {filteredAccount?.Twitter_Credentials != 0 ? (
                    <li
                      hidden={filteredAccount?.Twitter_Credentials === 0}
                      className="d-flex align-items-center justify-content-between py-3"
                    >
                      <label
                        className="d-flex align-items-center gap-3"
                        htmlFor="twitterHighlight"
                      >
                        <img src="images/twitter-post-icon.svg" />
                        Twitterpost
                      </label>
                      <input
                        className="form-check-input cust-check box-shadow-none"
                        type="checkbox"
                        defaultValue
                        id="twitterHighlight"
                        checked={props.config.Account_And_Platform.twitterPost}
                        onChange={(e) =>
                          handleInputChange(
                            "Account_And_Platform",
                            "twitterPost",
                            e.target.checked
                          )
                        }
                      />
                    </li>
                  ) : null}
                  {filteredAccount?.Instagram_Credentials != 0 ? (
                    <li
                      hidden={filteredAccount?.Instagram_Credentials === 0}
                      className="d-flex align-items-center justify-content-between py-3"
                    >
                      <label
                        className="d-flex align-items-center gap-3"
                        htmlFor="instaCapHighlight"
                      >
                        <img src="images/insta-caption-icon.svg" />
                        Instagram caption
                      </label>
                      <input
                        className="form-check-input cust-check box-shadow-none"
                        type="checkbox"
                        defaultValue
                        id="instaCapHighlight"
                        checked={props.config.Account_And_Platform.instaCap}
                        onChange={(e) =>
                          handleInputChange(
                            "Account_And_Platform",
                            "instaCap",
                            e.target.checked
                          )
                        }
                      />
                    </li>
                  ) : null}
                </>
              );
            })}

          <li className="d-flex align-items-center justify-content-between py-3">
            <label
              className="d-flex align-items-center gap-3"
              htmlFor="socialMediaHighlight"
            >
              <img src="images/social-media-img-icon.svg" />
              Social media image
            </label>
            <input
  className="form-check-input cust-check box-shadow-none"
  type="checkbox"
  defaultChecked  // Add defaultChecked attribute here
  id="socialMediaHighlight"
/>
          </li>
        </ul>
        <div className="d-flex justify-content-end py-3 px-4 end-0 position-fixed bottom-0 bg-white shadow-up canvas-foot">
          <button className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2">
            Cancel
          </button>
          <button
            className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
            aria-controls="offcanvasRight"
            onClick={() => {
              regeneratePost();

              // console.log(props.highlightShow,"tytytytytytytytyty")
            }}
          >
            <img
              src="images/generate-icon.svg"
              alt="generate-icon"
              className="me-2"
            />
            Generate
          </button>
          <button
            data-bs-toggle="offcanvas"
            style={{ display: "none" }}
            id="openhigh"
            data-bs-target="#promotionalContent"
            onClick={() => {
              setHighlightShow(true);
            }}
          ></button>
        </div>
      </div>
    </div>
  );
}