import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllRequestApi, getDropDownDetailsApi } from "../../services/api";
import { useCommonContext } from "../../contexts/CommonContext";
import moment from "moment";
import Loader from "../Loader/Loader";

export default function Requests() {
  const { setExpired, setWrong, setInvalid, verifyJwtToken } =
    useCommonContext();
  const { userData } = useCommonContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [requestDetails, setRequestDetails] = useState([]);
  const [Type, setType] = useState("");
  const [requestedBy, setRequestedBy] = useState("");
  const [Status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropDownDetails, setDropDownDetails] = useState([]);
  const [statusdropDownDetails, setStatusDropDownDetails] = useState([]);
  const [rolesDropDown, setRolesDropDown] = useState([]);
  const [filterOptions, setFilterOptions] = useState(true);
  const [toastMessage, setToastMessage] = useState([]);
  const [toastStatus, setToastStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState();
  const [count, setCount] = useState(10);
  const [sortColumn, setSortColumn] = useState("Requests.Modified_date");
  const [sortOrder, setSortOrder] = useState(true);
  const {isNavCollapsed, setIsNavCollapsed } = useCommonContext();

  useEffect(() => {
    if (search == "") getAllRequest();
  }, [search]);

  useEffect(() => {
    getAllRequest();
  }, [count, sortColumn, sortOrder]);

  const nav = useNavigate();

  console.log(Type, "Types");
  console.log(fromDate);
  console.log(toDate);
  console.log(requestedBy);
  console.log(Status, "Status");

  async function getAllRequest() {
    try {
      const userId = userData[0]["User_Id"];
      const data = {
        user_id: userId,
        type: Type,
        Requested_By: requestedBy,
        Status: Status,
        From_Date: fromDate,
        To_Date: toDate,
        sort_column: sortColumn,
        sort_order: sortOrder ? "ASC" : "DESC",
        search: search,
        count: count,
      };
      setIsLoading(true);

      const responseData = await getAllRequestApi(data);
      setIsLoading(false);
      if (responseData.status === 200) {
        // setRequestDetails("")
        setRequestDetails(responseData.data.data.data);
        setLoadMore(responseData.data.data.rowCount);
        // setType("");
        // setRequestedBy("");
        // setFromDate("");
        // setToDate("");
        // setStatus("");
        const DropDowndata = {
          userId: userData[0]["User_Id"],
          tableName: "Content_Types",
        };
        const dropDownResponse = await getDropDownDetailsApi(DropDowndata);
        if (responseData.status === 200) {
          setDropDownDetails(dropDownResponse.data.data.data);
          console.log(dropDownDetails[0].Content_Type, "===================");
        }

        if (dropDownResponse.status === 200) {
          const StatusDropDowndata = {
            userId: userData[0]["User_Id"],
            tableName: "Approval_Status",
          };
          const statusdropDownResponse = await getDropDownDetailsApi(
            StatusDropDowndata
          );
          if (statusdropDownResponse.status === 200) {
            setStatusDropDownDetails(statusdropDownResponse.data.data.data);
            console.log(
              statusdropDownDetails[0].Approval_Status_Name,
              "==================="
            );
          }
        }

        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={(!isNavCollapsed) ? "content-conatiner" : "collapsed-con"} id="contentBox">
      <div className="row">
        <div className="col-md-12">
          

          
            <>
            {isLoading && <Loader />}
              <div className="d-flex align-items-center justify-content-between pt-3 pb-4 bg-white sticky-top">
                <h2 className="font-bold font-22">
                  Requests <span>({loadMore})</span>
                </h2>
                <div className="d-flex gap-3">
                  {/* search starts here */}
                 {!(requestDetails.length === 0  && (requestedBy =="" && Type=="" && Status =="" && fromDate =="" && toDate =="" && search=="")) ? (  <div className="input-group search-field">
                    <input
                      type="search"
                      className="form-control box-shadow-none font-16 font-medium"
                      placeholder="Search"
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      value={search}
                    />
                    <button
                      className="btn white-btn box-shadow-none d-flex align-items-center"
                      type="button"
                      id="button-addon2"
                      disabled={search == ""}
                      onClick={() => getAllRequest()}
                    >
                      <img src="images/search-icon.svg" alt="search-icon" />
                    </button>
                  </div> ) : null}
                  {/* search starts here */}
                  {/* filter starts here */}
                  {!(requestDetails.length === 0  && (requestedBy =="" && Type=="" && Status =="" && fromDate =="" && toDate =="" && search=="")) ? ( <div className="dropdown">
                    <button
                      className="btn white-btn d-flex align-items-center p-3 box-shadow-none"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src="images/filter-line.svg" alt="filter-line" />
                    </button>
                    <div className="dropdown-menu dropdown-menu-end filter-drop">
                      <div className="col-md-6 mb-4">
                        <label
                          htmlFor="fromDate"
                          className="form-label mb-0 cust-label font-14 font-medium"
                        >
                          Type
                        </label>
                        <select
                          className="form-select custom-form  font-14 font-regular"
                          id="conetentType"
                          onChange={(e) => setType(e.target.value)}
                          value={Type}
                        >
                          <option value="">select</option>
                          <option value={dropDownDetails[4]?.Content_Type_Id}>
                            {dropDownDetails[4]?.Content_Type}
                          </option>
                          <option value={dropDownDetails[3]?.Content_Type_Id}>
                            {dropDownDetails[3]?.Content_Type}
                          </option>
                          <option value={dropDownDetails[1]?.Content_Type_Id}>
                            {dropDownDetails[1]?.Content_Type}
                          </option>
                          <option value={dropDownDetails[2]?.Content_Type_Id}>
                            {dropDownDetails[2]?.Content_Type}
                          </option>
                          <option value={dropDownDetails[0]?.Content_Type_Id}>
                            {dropDownDetails[0]?.Content_Type}
                          </option>
                        </select>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label
                          htmlFor="fromDate"
                          className="form-label mb-0 cust-label font-14 font-medium"
                        >
                          Requested By
                        </label>
                        <input
                          className="form-control custom-form  font-14 font-regular"
                          id="conetentType"
                          placeholder="Enter Name"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          onChange={(e) => {
                            setRequestedBy(e.target.value);
                          }}
                          value={requestedBy}
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label
                          htmlFor="fromDate"
                          className="form-label mb-0 cust-label font-14 font-medium"
                        >
                          Status
                        </label>
                        <select
                          className="form-select custom-form  font-14 font-regular"
                          id="conetentType"
                          onChange={(e) => setStatus(e.target.value)}
                          value={Status}
                        >
                          <option value="">select</option>
                          <option
                            value={
                              statusdropDownDetails[2]?.Approval_Status_Name
                            }
                          >
                            {statusdropDownDetails[2]?.Approval_Status_Name}
                          </option>
                          <option
                            value={
                              statusdropDownDetails[0]?.Approval_Status_Name
                            }
                          >
                            {statusdropDownDetails[0]?.Approval_Status_Name}
                          </option>
                          <option
                            value={
                              statusdropDownDetails[1]?.Approval_Status_Name
                            }
                          >
                            {statusdropDownDetails[1]?.Approval_Status_Name}
                          </option>
                        </select>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <p className="font-20 font-bold m-0">Date</p>
                        <img
                          src="images/close-icon.svg"
                          alt="close-icon"
                          className="cursor-pointer"
                          onClick={() => {
                            setType("");
                            setRequestedBy("");
                            setFromDate("");
                            setToDate("");
                            setStatus("");
                            getAllRequest();
                          }}
                        />
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-6 mb-3">
                          <label
                            htmlFor="fromDate"
                            className="form-label mb-0 cust-label font-14 font-medium"
                          >
                            From
                          </label>
                          <input
                            type="date"
                            className="form-control custom-form cust-date font-14 font-regular"
                            id="fromDate"
                            onChange={(e) => setFromDate(e.target.value)}
                            value={fromDate}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label
                            htmlFor="toDate"
                            className="form-label mb-0 cust-label font-14 font-medium"
                          >
                            To
                          </label>
                          <input
                            type="date"
                            className="form-control custom-form cust-date font-14 font-regular"
                            id="toDate"
                            onChange={(e) => setToDate(e.target.value)}
                            value={toDate}
                            min={fromDate}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          onClick={() => {
                            setType("");
                            setRequestedBy("");
                            setFromDate("");
                            setToDate("");
                            setStatus("");
                            getAllRequest();
                          }}
                          className="btn transp-btn text-black font-bold font-16 text-nowrap box-shadow-none me-2"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            getAllRequest();
                          }}
                          disabled={
                            Type == "" &&
                            toDate == "" &&
                            fromDate == "" &&
                            Status == "" &&
                            requestedBy == ""
                          }
                          className="btn primary-gradient-btn font-bold text-white font-16 text-nowrap  box-shadow-none"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>  ) : null}
                  {/* filter ends here */}
                  {/* <button className="btn primary-gradient-btn text-white d-flex gap-2 align-items-center font-bold font-16 text-nowrap">
                      <img src="images/add-icon.svg" alt="add-icon" />
                      Create Article
                    </button> */}
                </div>
              </div>
              {requestDetails.length !== 0 ? ( <div className="theme-tbl table-responsive">
                <table className="table table-borderless">
                  <thead className="font-medium bg-white">
                    <tr>
                      <th>
                        Title{" "}
                        <img
                            className="cursor-pointer ms-2"
                            src={
                              sortOrder && sortColumn === "Content.Content_Name"
                                ? "images/sort-up.svg"
                                : "images/sort-down.svg"
                            }
                            alt="sort-up"
                            onClick={() => {
                              setSortOrder(!sortOrder);
                              setSortColumn("Content.Content_Name");
                            }}
                          />
                      </th>
                      <th>
                        Type{" "}
                        <img
                            className="cursor-pointer ms-2"
                            src={
                              sortOrder && sortColumn === "Content_Types.Content_Type"
                                ? "images/sort-up.svg"
                                : "images/sort-down.svg"
                            }
                            alt="sort-up"
                            onClick={() => {
                              setSortOrder(!sortOrder);
                              setSortColumn("Content_Types.Content_Type");
                            }}
                          />
                      </th>
                      <th>
                        Date{" "}
                        <img
                            className="cursor-pointer ms-2"
                            src={
                              sortOrder && sortColumn === "Requests.Created_Date"
                                ? "images/sort-up.svg"
                                : "images/sort-down.svg"
                            }
                            alt="sort-up"
                            onClick={() => {
                              setSortOrder(!sortOrder);
                              setSortColumn("Requests.Created_Date");
                            }}
                          />
                      </th>

                      <th>
                        Requested By{" "}
                        <img
                            className="cursor-pointer ms-2"
                            src={
                              sortOrder && sortColumn === "Users.Firstname"
                                ? "images/sort-up.svg"
                                : "images/sort-down.svg"
                            }
                            alt="sort-up"
                            onClick={() => {
                              setSortOrder(!sortOrder);
                              setSortColumn("Users.Firstname");
                            }}
                          />
                      </th>

                      <th>
                        Status{" "}
                        <img
                            className="cursor-pointer ms-2"
                            src={
                              sortOrder && sortColumn === "Approval_Status.Approval_Status_Name"
                                ? "images/sort-up.svg"
                                : "images/sort-down.svg"
                            }
                            alt="sort-up"
                            onClick={() => {
                              setSortOrder(!sortOrder);
                              setSortColumn("Approval_Status.Approval_Status_Name");
                            }}
                          />
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-medium" style={{ cursor: 'pointer' }}>
                    {requestDetails.map((content) => (
                      <tr key={content.id}>
                        <td
                          onClick={() => {
                            nav("/approvecontent", {
                              state: {
                                contentId: content.Content_Id,
                                socialMediaId : content.Social_Media_Content_Id,
                                contentName : content.Content_Name
                              },
                            });
                          }}
                        >
                          {console.log(
                            content.Content_Id,
                            "This is the content id"
                          )}
                          <a>
                            {content?.Content_Name.length > 18
                              ? `${content?.Content_Name.slice(0, 18)}...`
                              : content?.Content_Name}
                          </a>
                        </td>
                        <td>{content.Content_Type}</td>
                        <td>{moment(content.Created_Date).format('YYYY-MM-DD')}</td>
                        <td>{content.Requested_By}</td>
                        <td>
                          <span
                            className={`${
                              content?.Approval_Status_Name === "Pending"
                                ? "tbl-status-yellow"
                                : content.Approval_Status_Name === "Approved"
                                ? "tbl-status-green"
                                : "tbl-status-red"
                            } tbl-status`}
                          >
                            {content?.Approval_Status_Name}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>   ) : null}
            </>
        
          {requestDetails.length != 0 ? null : (
            <>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "500px" }}
              >
                <div className="d-block text-center ">
                  <span className="font-bold font-14 d-block mb-3">
                    <img src="images/no-article-icon.svg" alt="no-post-icon" />{" "}
                  </span>
                  <span className="font-bold font-14 d-block">
                    No Pending Approvals
                  </span>
                  <span className="font-regular mt-2 font-12 d-block mb-3 color-grey">
                    No pending approval requests found at the moment
                  </span>
                </div>
              </div>
            </>
          )}
          {requestDetails.length !== 0 ? (
            <div className="d-flex justify-content-center mt-3">
              {loadMore <= 10 || count >= loadMore ? null : (
                <button
                  className="btn shadow-btn d-flex align-items-center py-2 px-3  font-bold font-16"
                  onClick={() => {
                    setCount(count + 10);
                  }}
                >
                  Load More
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
